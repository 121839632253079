import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";
import HeroImage from "app/layout/heroImage/heroImage";
import banner from "app/assets/images/forms/banner.png";
import bannerMobile from "app/assets/images/forms/banner-mobile.png";
import Breadcrumbs from "app/layout/breadcrumbs";
import Icon from "app/assets/icon/icon";
import lodash from "lodash";
import SeoHelmet from "app/layout/seoHelmet";
import CmsBlock from "core/components/cmsBlock";

const Forms = (props) => {
  let { data, forms, form } = props;
  if (lodash.isUndefined(data) || lodash.isUndefined(data.forms.form))
    return <></>;
  let itemList = data.forms.form;
  return (
    <MDBContainer fluid className={"forms-container"}>
      <MDBRow>
        <MDBCol className={"p-0"}>
          <div className="img item d-lg-block d-none desktop-image">
            <HeroImage banner={banner} alt="Form Banner Main Imag" />
          </div>
          <div className="img item d-lg-none d-block mobile-image">
            <HeroImage banner={bannerMobile} alt="Form Banner Main Imag" />
          </div>
          <SeoHelmet
            url={"/forms"}
            title={"forms"}
            description={
              "paperwork is boring. we'll make it easy as possible to get you to the fun stuff!"
            }
            image={banner}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className={"forms-content"}>
        <MDBCol className={"w-100 mx-auto forms-title p-0"}>
          <MDBBox tag="h2" className="main-title text-center mt-5">
            forms
          </MDBBox>
          <MDBBox tag="p" className="text-center">
            <CmsBlock dangHtml={"true"} id={"forms_heading_content"} />
          </MDBBox>
        </MDBCol>
        <MDBBox className="forms-inner-content">
          {itemList.map((item) => (
            <MDBBox className="forms-items">
              <h3>{item.title}</h3>
              <span style={{ marginRight: "1rem" }}>
                <Icon icon={"pdfIconGray"} />
              </span>
              <a target="_blank" href={`${item.document}`}>
                download <br /> pdf
              </a>
            </MDBBox>
          ))}
        </MDBBox>
      </MDBRow>
    </MDBContainer>
  );
};

export default Forms;
