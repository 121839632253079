import React, { useState } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import Listing from "./listing";
import Controls from "./controls";

const Toolbar = props => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <MDBCol>
      <MDBRow>
        <Listing
          wishlists={props.wishlists}
          activeWishlist={props.activeWishlist}
          setActiveWishlist={props.setActiveWishlist}
          setProjectName={props.setProjectName}
          setIsEditing={setIsEditing}
        />
      </MDBRow>
      <MDBRow>
        <Controls
          activeWishlist={props.activeWishlist}
          setActiveWishlist={props.setActiveWishlist}
          projectName={props.projectName}
          setProjectName={props.setProjectName}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </MDBRow>
    </MDBCol>
  );
};

export default Toolbar;
