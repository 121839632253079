import React, { useState } from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { default as customerAttributes } from "config/customer/attributes";
import { Alert } from "reactstrap";
import Button from "core/components/button";
import CheckButton from "core/components/checkButton";
import InputField from "core/components/inputField";
import Breadcrumbs from "app/layout/breadcrumbs";
import { Link } from "react-router-dom";
import TitleDescription from "app/layout/titleDescription";
import { validate, shouldMarkError } from "./validation";
import SessionMessages from "core/components/sessionMessages";
import CmsBlock from "core/components/cmsBlock";

const RegisterB2C = (props) => {
  let { registerB2CCustomer } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [joinMailingList, setJoinMailingList] = useState(true);
  const [terms, setTerms] = useState(false);
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState("");

  let errors = validate(
    firstName,
    lastName,
    email,
    confirmEmail,
    password,
    confirmPassword,
    terms
  );
  const validateInput = () => {
    let isTrue = true;
    setTouched({
      firstname: true,
      lastname: true,
      email: true,
      confirm_email: true,
      password: true,
      confirm_password: true,
      terms: true,
    });
    if (!terms) {
      setTermsAccepted("please accept Terms + Conditions");
    }
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    if (isTrue) {
      let customerRequest = {
        [customerAttributes.firstname]: firstName,
        [customerAttributes.lastname]: lastName,
        [customerAttributes.password]: password,
        [customerAttributes.email]: email,
      };
      setLoading(true);
      props.lock({
        always: (e) => {},
        fail: (e) => {
          setLoading(false);
        },
        success: (e) => {
          setLoading(false);
        },
      });
      registerB2CCustomer(customerRequest);
    }
  };

  const setFirstNameFn = (e) => {
    setFirstName(e.target.value);
  };
  const setLastNameFn = (e) => {
    setLastName(e.target.value);
  };
  const setEmailFn = (e) => {
    setEmail(e.target.value);
  };
  const setConfirmEmailFn = (e) => {
    setConfirmEmail(e.target.value);
  };
  const setPasswordFn = (e) => {
    setPassword(e.target.value);
  };
  const setConfirmPasswordFn = (e) => {
    setConfirmPassword(e.target.value);
  };
  return (
    <MDBContainer id="trade-register-B2C" className={"page-main-content"}>
      <Breadcrumbs />
      <TitleDescription
        title="create a new account"
        description={
          <CmsBlock dangHtml="true" id={"create_new_account_text"} />
        }
        link="register here"
        to={"/register/trade"}
      ></TitleDescription>
      <MDBRow className={"create-account-form"}>
        <MDBCol lg="6">
          <div className="grey-text">
            <InputField
              className={`custom-input ${
                shouldMarkError("firstname", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("firstname", errors, touched)
                  ? "first name is required*"
                  : "first name*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, firstname: true });
              }}
              group
              type="text"
              validate
              error="wrong"
              success="right"
              value={firstName !== undefined ? firstName : ""}
              onChange={setFirstNameFn}
            />
            <InputField
              className={`custom-input ${
                shouldMarkError("lastname", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("lastname", errors, touched)
                  ? "last name is required*"
                  : "last name*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, lastname: true });
              }}
              group
              type="text"
              validate
              error="wrong"
              success="right"
              value={lastName !== undefined ? lastName : ""}
              onChange={setLastNameFn}
            />
            <InputField
              className={`custom-input ${
                shouldMarkError("email", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("email", errors, touched)
                  ? "email address is required*"
                  : "email address*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, email: true });
              }}
              group
              type="email"
              validate
              error="wrong"
              success="right"
              value={email !== undefined ? email : ""}
              onChange={setEmailFn}
            />
            <InputField
              className={`custom-input ${
                shouldMarkError("confirm_email", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("confirm_email", errors, touched)
                  ? "confirm email address should match email*"
                  : "confirm email address*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, confirm_email: true });
              }}
              group
              type="email"
              validate
              error="wrong"
              success="right"
              value={confirmEmail !== undefined ? confirmEmail : ""}
              onChange={setConfirmEmailFn}
            />
          </div>
        </MDBCol>
        <MDBCol lg="6">
          <div className="grey-text right-column">
            <InputField
              className={`custom-input ${
                shouldMarkError("password", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("password", errors, touched)
                  ? "password is required*"
                  : "password*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, password: true });
              }}
              group
              type="password"
              validate
              error="wrong"
              success="right"
              value={password !== undefined ? password : ""}
              onChange={setPasswordFn}
            />
            <InputField
              className={`custom-input ${
                shouldMarkError("confirm_password", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("confirm_password", errors, touched)
                  ? "confirm password is required*"
                  : "confirm password*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, confirm_password: true });
              }}
              group
              type="password"
              validate
              error="wrong"
              success="right"
              value={confirmPassword !== undefined ? confirmPassword : ""}
              onChange={setConfirmPasswordFn}
            />
          </div>
          <CheckButton
            containerClass={"switch-container check-button"}
            wrapperClass={"slider"}
            label={"Join our mailing list"}
            value="Join our mailing list"
            checked={joinMailingList}
            onChange={() => setJoinMailingList(!joinMailingList)}
            type={"checkbox"}
          />
          <div className="switch-and-required">
            <CheckButton
              containerClass={"switch-container check-button"}
              wrapperClass={`slider`}
              label={
                <span
                  className={`${
                    shouldMarkError("terms", errors, touched)
                      ? "validation-error"
                      : ""
                  }`}
                >
                  I have read the
                  <Link to={"/terms-and-conditions"} target="_blank">
                    Terms + Conditions
                  </Link>
                </span>
              }
              value="I have read the Terms + Conditions"
              checked={terms}
              onChange={() => setTerms(!terms)}
              type={"checkbox"}
              onBlur={(e) => {
                setTouched({ ...touched, terms: true });
              }}
            />
            <div className="text-right required-text-block">
              <span className="required-sign">*</span>
              <span className="required-text">= required</span>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      {termsAccepted !== "" && !terms && (
        <Alert color="danger" isOpen={true} toggle={() => setTermsAccepted("")}>
          {termsAccepted}
        </Alert>
      )}
      <MDBRow className="account-submit">
        <SessionMessages target={`register`} />
        <Button
          className={"right-arrow orange-button account-submit-btn"}
          direction={"ml-3"}
          righticon={"true"}
          icon={"arrow-right"}
          disabled={loading}
          text={loading ? "sending" : "submit"}
          to={"#"}
          onClick={() => validateInput()}
        />
      </MDBRow>
    </MDBContainer>
  );
};

export default RegisterB2C;
