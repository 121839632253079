import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createRequestFromFilters, createSortVariable } from "./functions";
import _ from "lodash";
import WishlistShareListing from "./wishlistShareListing/wishlistShareListing";
import ReactLoader from "app/layout/loader";

const WISHLIST = gql`
  query Products(
    $filters: ProductAttributeFilterInput!
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      filter: $filters
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        count
        label
        options {
          value
          label
        }
      }
      total_count
      items {
        id
        name
        sku
        url_key
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
            ... on CustomizableDropDownOption {
              option_id
              title
              value {
                title
                option_type_id
                price
                price_type
                sku
              }
            }
          }
        }
        media_gallery_entries {
          id
          file
          content {
            base64_encoded_data
            name
            type
          }
          disabled
        }
        categories {
          id
        }
        image {
          label
          url
        }
        small_image {
          label
          url
        }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        tier_prices {
          customer_group_id
          percentage_value
          qty
          value
          website_id
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
      sort_fields {
        options {
          label
          value
        }
        default
      }
    }
  }
`;

const WishlistShareFilterQueryContainer = (props) => {
  let { filters, wishlistskus } = props;
  let variables = {};
  let request = createRequestFromFilters(filters.items, wishlistskus);
  let sort = createSortVariable(filters.sort);
  let pageSize = 12;

  if (!_.isEmpty(request)) {
    if (request.filter === 0) {
      variables = {
        filters: request.filter,
        pageSize:
          filters && filters.data ? filters.data.page_info.page_size : pageSize,
        currentPage:
          typeof props.pageNumber !== "undefined" &&
          typeof props.pageNumber === "number"
            ? props.pageNumber
            : 1,
      };
      variables["filters"]["sku"]["in"] = wishlistskus;
      variables["sort"] = sort;
    } else {
      variables = {
        filters: request.filter,
        sort: sort,
        pageSize:
          filters && filters.data ? filters.data.page_info.page_size : pageSize,
        currentPage:
          typeof props.pageNumber !== "undefined" &&
          typeof props.pageNumber === "number"
            ? props.pageNumber
            : 1,
      };
    }
  }

  const { data, loading, error } = useQuery(WISHLIST, {
    variables: variables,
  });

  if (loading) {
    return <ReactLoader />;
  }
  if (error) return `Error! ${error}`;
  if (data) {
    return <WishlistShareListing {...props} data={data} />;
  }
};

export default WishlistShareFilterQueryContainer;
