export default {
  upsmatrix_upsmatrix: "standard shipping",
  upsmatrix_doortodoormatrix_upsmatrix_doortodoormatrix:
    "standard delivery + curb side",
  doortodoormatrix_doortodoormatrix: "curb side",
  ordersubtotal_ordersubtotal: "white glove",
  ordersubtotal_ordersubtotal_extra_stairs: "white glove + 2 flight of stairs",
  whiteglovematrix_whiteglovematrix: "white glove",
  doortodoormatrix_upsmatrix_doortodoormatrix: "standard delivery + curb side",
  upsmatrix_doortodoormatrix_upsmatrix_doortodoormatrix_white_glove:
    "white glove",
  upsground_upsground: "ups ground",
};
