import React from "react";
import Toolbar from "./toolbar";
import _ from "lodash";
const ToolbarDataContainer = props => {
  let wishlists = [];

  wishlists.push({
    id: parseInt(props.wishlist.id),
    name: "favorites",
    main: true
  });
  props.wishlist.multiwishlist_data.map(wishlist => {
    return wishlists.push({
      id: wishlist.id,
      name: wishlist.name,
      main: wishlist.main
    });
  });

  return (
    <>
      <Toolbar {...props} wishlists={wishlists} />
    </>
  );
};
export default ToolbarDataContainer;
