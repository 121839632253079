import React, { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import { MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";
import Input from "core/components/input";
import _ from "lodash";
import Price from "core/components/price";
import ReactLoader from "app/layout/loader";

const updateCartItemFn = (
  item,
  value,
  updateCart,
  lock,
  quantity,
  setQuantity,
  setLoading
) => {
  if (value < 1 || value === "" || _.isNaN(value)) {
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setQuantity(1);
      },
      fail: () => {
        setLoading(false);
      },
    });
    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: 1,
      },
    ]);
  } else {
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setQuantity(value);
      },
      fail: () => {
        setLoading(false);
      },
    });

    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: value === 1 ? 1 : value,
      },
    ]);
  }
};

const MiniCartItem = memo(
  ({
    item,
    hideMiniCart,
    removeItem,
    quantity,
    setQuantity,
    updateCart,
    lock,
    loading,
    setLoading,
  }) => {
    useEffect(() => {
      if (item.quantity !== quantity) {
        setQuantity(item.quantity);
      }
    }, [item, setQuantity]);

    return (
      <MDBCol className="minicart-item">
        <div className="minicart-item-image">
          <img src={item.product.small_image.url} alt="" />
        </div>
        <div className="minicart-item-content d-flex flex-column justify-content-between align-items-between">
          <Link
            onClick={hideMiniCart}
            className="minicart-item-name"
            to={`/${item.product.url_key}`}
          >
            {item.product.name}
          </Link>

          <div className="minicart-item-price">
            <p>
              <Price price={item.prices.price.value} type={`fixed`} />
            </p>
          </div>
          <div className="minicart-qty-edit-container align-items-center">
            <div className="minicart-item-qty d-flex align-items-center">
              <div>
                <span>Qty</span>
              </div>
              <div
                className={`d-flex align-items-center${
                  quantity === 1 ? " disable-minus" : ""
                }`}
              >
                <Input
                  name={``}
                  type={"number"}
                  wrapperClass={"quantity-field"}
                  className={"qty-control-wrapper"}
                  inputClass={""}
                  value={quantity}
                  plusClick={(e) => {
                    if (!loading) {
                      setLoading(true);
                      updateCartItemFn(
                        item,
                        quantity + 1,
                        updateCart,
                        lock,
                        quantity,
                        setQuantity,
                        setLoading
                      );
                    }
                  }}
                  minusClick={(e) => {
                    if (!loading) {
                      setLoading(true);
                      updateCartItemFn(
                        item,
                        quantity - 1,
                        updateCart,
                        lock,
                        quantity,
                        setQuantity,
                        setLoading
                      );
                    }
                  }}
                  onBlur={(e) => {
                    let value = parseInt(e.target.value);
                    if (!loading) {
                      setLoading(true);
                      updateCartItemFn(
                        item,
                        value,
                        updateCart,
                        lock,
                        quantity,
                        setQuantity,
                        setLoading
                      );
                    }
                  }}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (!loading) {
                      if (value < 1 || value === "" || _.isNaN(value)) {
                        setQuantity(1);
                      } else {
                        setQuantity(value);
                      }
                    }
                  }}
                />
                <ReactLoader
                  visible={loading}
                  type={`ThreeDots`}
                  height={`20`}
                  width={`20`}
                />
              </div>
            </div>
            {/* <Link
              onClick={hideMiniCart}
              className="minicart-item-edit"
              to={{
                pathname: "/" + item.product.url_key,
                state: { editItem: true, cartItem: item }
              }}
            >
              Edit Item
            </Link> */}
            <div className="minicart-sku-remove-container">
              {typeof item.customizable_options !== "undefined" &&
              item.customizable_options.length > 0 ? (
                <span className={`minicart-item-edit mr-2`}>
                  <Link
                    to={"/" + item.product.url_key + `?edit=${item.id}`}
                    className={`edit-link`}
                  >
                    <Icon icon={`edit`} />
                  </Link>
                </span>
              ) : (
                <></>
              )}
              <span
                className="minicart-item-remove"
                onClick={() => removeItem(item.id)}
              >
                <Icon icon="trashCanOrange" />
              </span>
            </div>
          </div>
        </div>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default MiniCartItem;
