import React, { useState, memo } from "react";
import CheckoutAddresses from "./checkoutAddresses";
import _ from "lodash";

const CheckoutAddressStateContainer = memo(
  ({
    lock,
    setBillingAddressOnCart,
    setShippingAddressOnCart,
    setShowPayment,
  }) => {
    const [useSameAddress, setUseSameAddress] = useState(true);
    const initialAddressState = {
      firstname: "",
      lastname: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      zipcode: "",
      countrySelect: {
        label: "United States",
        value: "US",
      },
      selectState: "",
      touched: {
        firstname: false,
        lastname: false,
        email: false,
        countryselect: false,
        address1: false,
        city: false,
        selectstate: false,
        zipcode: false,
        phone: false,
      },
      errors: {},
      customer_address_id: null,
    };
    const [billingAddress, setBillingAddress] = useState(initialAddressState);
    const [shippingAddress, setShippingAddress] = useState(initialAddressState);
    const [loading, setLoading] = useState(false);
    const [shouldChange, setShouldChange] = useState(true);

    const resetTouchedAndErrors = (billing = false, shipping = false) => {
      if (billing) {
        setBillingAddress({
          ...billingAddress,
          errors: {},
          touched: initialAddressState.touched,
        });
      }
      if (shipping) {
        setShippingAddress({
          ...shippingAddress,
          errors: {},
          touched: initialAddressState.touched,
        });
      }
    };

    return (
      <CheckoutAddresses
        //initial address state
        initialAddressState={initialAddressState}
        resetTouchedAndErrors={resetTouchedAndErrors}
        //mandatory parent state props
        setShowPayment={setShowPayment}
        //getters
        useSameAddress={useSameAddress}
        billingAddress={billingAddress}
        shippingAddress={shippingAddress}
        loading={loading}
        shouldChange={shouldChange}
        //setters
        setUseSameAddress={setUseSameAddress}
        setBillingAddress={setBillingAddress}
        setShippingAddress={setShippingAddress}
        setLoading={setLoading}
        setShouldChange={setShouldChange}
        //redux
        lock={lock}
        setBillingAddressOnCart={setBillingAddressOnCart}
        setShippingAddressOnCart={setShippingAddressOnCart}
      />
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default CheckoutAddressStateContainer;
