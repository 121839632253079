import React, { memo } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { isEqual } from "lodash";
import CheckboxReduxContainer from "./checkbox-redux-container";

const CheckboxLayoutContainer = memo(
  ({ option, optionProps }) => {
    let sizes = {
      title: 3,
      selector: 8,
      guide: 1,
    };
    return (
      <MDBRow
        className={`${option.title}-container pb-4 option-container checkbox-option-container`}
      >
        <MDBCol lg={sizes.title} size="6">
          <span className="option-title">{option.title}</span>
        </MDBCol>
        <CheckboxReduxContainer
          option={option}
          sizes={sizes}
          optionProps={optionProps}
        />
      </MDBRow>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default CheckboxLayoutContainer;
