import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import { Redirect } from "react-router-dom";
import CheckoutStateContainer from "./state-container";
import { customerActions } from "core/state/redux/data/customer";

const CheckoutReduxContainer = (props) => {
  if (props.order_id !== null) {
    return <Redirect to={`/thank-you-for-your-purchase`} />;
  }

  if (
    typeof props.customer.data !== "undefined" &&
    props.customer.data.cartToken &&
    typeof props.customer.data.cart !== "undefined"
  ) {
    return (
      <CheckoutStateContainer
        {...props}
        empty={props.customer.data.cart.items.length > 0 ? false : true}
      />
    );
  }
  return <CheckoutStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  cart:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined"
      ? state.customer.data.cart
      : {},
  order_id:
    typeof state.customer !== "undefined" &&
    typeof state.customer.order_id !== "undefined"
      ? state.customer.order_id
      : null,
  cart_grand_total:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.prices !== "undefined" &&
    typeof state.customer.data.cart.prices.grand_total !== "undefined" &&
    typeof state.customer.data.cart.prices.grand_total.value !== "undefined"
      ? state.customer.data.cart.prices.grand_total.value
      : {},
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    setGuestEmailOnCart: (email) => {
      dispatch(customerActions.setGuestEmailOnCart(email));
    },
    setShippingAddressOnCart: (shipping_address) => {
      dispatch(customerActions.setShippingAddressOnCart(shipping_address));
    },
    setBillingAddressOnCart: (billing_address) => {
      dispatch(customerActions.setBillingAddressOnCart(billing_address));
    },
    setPaymentMethod: (payment_method) => {
      if (payment_method.code === "ebizcharge_ebizcharge") {
        dispatch(customerActions.setPaymentMethodAndPlaceOrder(payment_method));
      } else if (
        payment_method.method_code === "checkmo" ||
        payment_method.method_code === "terms"
      ) {
        dispatch(
          customerActions.setPaymentMethodOnCart({
            code: payment_method.method_code,
          })
        );
      } else {
        dispatch(
          customerActions.setPaymentMethodOnCart({
            code: payment_method.method_code,
            tokenbase_data: {
              cc_last4: payment_method.cc_last_4,
              acceptjs_key: payment_method.dataDescriptor,
              acceptjs_value: payment_method.dataValue,
            },
          })
        );
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutReduxContainer);
