import React from "react";
import PlaceholderImage from "app/assets/images/parentCategory/parentCat.png";
import { MDBCol, MDBLink } from "mdbreact";

export const contentRender = (element) => {
  return (
    <MDBCol key={element.id + "-sidebar-" + element.name} md={"12"} lg={"6"}>
      <MDBLink to={"/" + element.url_path}>
        <h4>{element.name}</h4>
        <div
          className={`${
            element.mobile_image !== null ? "d-lg-block d-none" : "d-block"
          }`}
        >
          {(element.image && (
            <img src={element.image} alt={`${element.name}`} />
          )) || <img src={PlaceholderImage} alt={`placeholder`} />}
        </div>
        {element.mobile_image !== null ? (
          <div className={`d-lg-none d-block`}>
            <img src={element.mobile_image} alt={`${element.name}`} />
          </div>
        ) : (
          <></>
        )}
      </MDBLink>
    </MDBCol>
  );
};
