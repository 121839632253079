import React from "react";
import GraphQlWrapper from "core/components/graphQlWrapper";
import { gql } from "graphql.macro";
import FaqDataContainer from "./faq-data-container";

const faq = gql`
  query getFAQ {
    getFAQ {
      entity_id
      title
      items {
        content
        entity_id
        title
        category_id
      }
    }
  }
`;
const FaqQueryContainer = props => {
  return (
    <>
      <GraphQlWrapper
        query={faq}
        variables={{ id: `faq-tabs-id` }}
        loader={true}
      >
        <FaqDataContainer {...props} />
      </GraphQlWrapper>
    </>
  );
};

export default FaqQueryContainer;
