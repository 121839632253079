import React, { useState } from "react";
import ItemsWishlist from "./itemsWishlist/itemsWishlist";
import { MDBBox } from "mdbreact";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";
const WishlistCore = (props) => {
  const [
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    getWishlistItems,
    moveItemToWishlist,
    editWishlist,
    wishlist,
    myWishlists,
    createWishlist,
    deleteWishlist,
  ] = useWishlist();
  let myWishlistArr = getWishlistItems();

  const [activeWishlistName, setActiveWishlistName] = useState("wishlist");
  const [project, setProject] = useState("");
  const [currentSelectedProject, setCurrentSelectedProject] = useState("");
  let style = {
    fontSize: "12px",
  };
  return (
    <MDBBox className={"wishlist-wrapper d-flex flex-wrap"}>
      <MDBBox>
        <h2>Projects</h2>
        <span style={style}>{myWishlistArr.length} items</span>
      </MDBBox>
      <ItemsWishlist
        setActiveWishlistName={setActiveWishlistName}
        activeWishlistName={activeWishlistName}
        project={project}
        setProject={setProject}
        currentSelectedProject={currentSelectedProject}
        setCurrentSelectedProject={setCurrentSelectedProject}
        {...props}
      />
    </MDBBox>
  );
};

export default WishlistCore;
