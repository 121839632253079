import React from "react";
import GraphQlWrapper from "core/components/graphQlWrapper/";
import { gql } from "graphql.macro";
import FindRetailerData from "./findRetailer-data-container";
const GET_STORE_LOCATOR_QUERY = gql`
  query getStoreLocator {
    getStoreLocator {
      store_id
      name
      state
      city
      street
      latitude
      longitude
      store_type
      website
      showroom
    }
  }
`;

const FindRetailerQueryContainer = props => {
  return (
    <GraphQlWrapper
      query={GET_STORE_LOCATOR_QUERY}
      variables={{
        id: `store`,
        url: props.url
      }}
      loader={true}
    >
      <FindRetailerData {...props} />
    </GraphQlWrapper>
  );
};

export default FindRetailerQueryContainer;
