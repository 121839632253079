import React, { useState, useCallback, memo } from "react";
import AuthorizeNetRender from "./authorize-net";
import _ from "lodash";

const AuthorizeNetStateContainer = memo(
  (props) => {
    const [inputs, setInputs] = useState({
      paymentMethod: props.payment_method,
      ccName: undefined,
      ccNumber: undefined,
      ccExpirationDate: undefined,
      ccValidationNumber: undefined,
    });
    const [ccNumber, setCcNumber] = useState("");
    const [ccExpirationDate, setCcExpirationDate] = useState("");
    const [ccExpirationYear, setCcExpirationYear] = useState("");
    const [ccValidationNumber, setCcValidationNumber] = useState("");

    const [shouldUpdate, setShouldUpdate] = useState("authorizenet_acceptjs");
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [preventUpdate, setPreventUpdate] = useState(false);

    const determineCardType = (number) => {
      let _number = number;
      let sanitized_number = _number.split(" ").join("");

      // Visa Electron
      let re = new RegExp("^(4026|417500|4508|4844|491(3|7))");

      if (sanitized_number.match(re) != null) return "VI";

      // visa
      re = new RegExp("^4");

      if (sanitized_number.match(re) != null) return "VI";

      // Mastercard
      re = new RegExp("^5[1-5]");
      if (sanitized_number.match(re) != null) return "MC";

      // AMEX
      re = new RegExp("^3[47][0-9]{13}");
      if (sanitized_number.match(re) != null) return "AE";

      // Discover
      re = new RegExp(
        "^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$"
      );
      if (sanitized_number.match(re) != null) return "DI";

      // JCB
      re = new RegExp("^35(2[89]|[3-8][0-9])");
      if (sanitized_number.match(re) != null) return "JCB";

      return "OT";
    };
    return (
      <AuthorizeNetRender
        {...props}
        ccNumber={ccNumber}
        ccExpirationDate={ccExpirationDate}
        ccExpirationYear={ccExpirationYear}
        ccValidationNumber={ccValidationNumber}
        errors={errors}
        shouldUpdate={shouldUpdate}
        // setters
        setCcNumber={setCcNumber}
        setCcExpirationDate={setCcExpirationDate}
        setCcExpirationYear={setCcExpirationYear}
        setCcValidationNumber={setCcValidationNumber}
        setErrors={setErrors}
        setShouldUpdate={setShouldUpdate}
        determineCardType={determineCardType}
        loading={loading}
        setLoading={setLoading}
        preventUpdate={preventUpdate}
        setPreventUpdate={setPreventUpdate}
      />
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default AuthorizeNetStateContainer;
