import React from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import { commonActions } from "core/state/redux/data/common";
import Newsletter from "./newsletter";

const NewsletterRexduxContainer = props => {
  return <Newsletter {...props} />;
};

const mapStateToProps = state => ({
  loading: state.customer.loading,
  locked: state.common.locked
});

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: request => {
      dispatch(commonActions.lock()).then(
        dispatch(customerActions.updateCustomerInformation(request))
      );
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterRexduxContainer);
