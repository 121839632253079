import React, { useState } from "react";
import TitleDescription from "app/layout/titleDescription/titleDescription";
import CheckButton from "core/components/checkButton";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import { Link } from "react-router-dom";
import workingOnProject from "app/assets/images/custom/workingOnProject.png";
import { validate, shouldMarkError } from "./validation";
import { Alert } from "reactstrap";

const CustomForm = (props) => {
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("");

  const setFirstNameFn = (e) => {
    setFirstName(e.target.value);
  };
  const setLastNameFn = (e) => {
    setLastName(e.target.value);
  };
  const setEmailFn = (e) => {
    setEmail(e.target.value);
  };
  const setPhoneFn = (e) => {
    let value = e.target.value.replace("e", "");
    if (e.target.value !== "") setPhone(value);
  };
  const setCompanyNameFn = (e) => {
    setCompanyName(e.target.value);
  };
  const setDescriptionFn = (e) => {
    setDescription(e.target.value);
  };
  const state = {
    firstName,
    lastName,
    email,
    companyName,
    phone,
    description,
  };

  let errors = validate(
    firstName,
    lastName,
    email,
    companyName,
    phone,
    description
  );
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    companyName: false,
    phone: false,
    description: false,
    privacyPolicy: false,
  });

  const [loading, setLoading] = useState(false);

  const _handleSendEmail = (e) => {
    let isTrue = true;
    setTouched({
      firstName: true,
      lastName: true,
      email: true,
      companyName: true,
      phone: true,
      description: true,
      privacyPolicy: true,
    });

    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    if (isTrue) {
      if (privacyPolicy) {
        setLoading(true);
        props.lock({
          always: () => {
            setLoading(true);
          },
          success: () => {
            setLoading(false);
          },
          fail: () => {
            setLoading(false);
          },
        });
        props.sendEmail({
          input: {
            attributes: [
              { code: "email", value: email },
              { code: "firstname", value: firstName },
              { code: "lastname", value: lastName },
              { code: "phone", value: phone },
              { code: "description", value: description },
              { code: "companyName", value: companyName },
            ],
            template: "10",
          },
        });
      } else {
        props.addMessage("fail to send email", "danger");
      }
    }
  };
  return (
    <div className="custom-form">
      <div className="d-flex block-with-right-img">
        <div className="block-text col-md-6 col-sm-12 block-part">
          <TitleDescription
            title={"working on a project?"}
            description={"let’s get our hands dirty!"}
          />
          <div className="custom-form-top d-flex">
            <div className="custom-form-left custom-form-part">
              <InputField
                className={`custom-input ${
                  shouldMarkError("firstName", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("firstName", errors, touched)
                    ? "first name is required*"
                    : "first name*"
                }`}
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={firstName !== undefined ? firstName : ""}
                onChange={setFirstNameFn}
              />
              <InputField
                className={`custom-input ${
                  shouldMarkError("lastName", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("lastName", errors, touched)
                    ? "last name is required*"
                    : "last name*"
                }`}
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={lastName !== undefined ? lastName : ""}
                onChange={setLastNameFn}
              />
              <InputField
                className={`custom-input ${
                  shouldMarkError("companyName", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("companyName", errors, touched)
                    ? "company name is required*"
                    : "company name*"
                }`}
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={companyName !== undefined ? companyName : ""}
                onChange={setCompanyNameFn}
              />
            </div>
            <div className="custom-form-right custom-form-part">
              <InputField
                className={`custom-input ${
                  shouldMarkError("email", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("email", errors, touched)
                    ? "email address is required*"
                    : "email address*"
                }`}
                group
                type="email"
                validate
                error="wrong"
                success="right"
                value={email !== undefined ? email : ""}
                onChange={setEmailFn}
              />
              <InputField
                className={`custom-input ${
                  shouldMarkError("phone", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("phone", errors, touched)
                    ? "phone is required*"
                    : "phone*"
                }`}
                group
                type="number"
                validate
                error="wrong"
                success="right"
                value={phone !== undefined ? phone : ""}
                onChange={setPhoneFn}
              />
              {!privacyPolicy && touched.privacyPolicy && (
                <Alert
                  color="danger"
                  isOpen={true}
                  toggle={() =>
                    setTouched({ ...touched, privacyPolicy: false })
                  }
                >
                  accept privacy policy
                </Alert>
              )}
              <CheckButton
                containerClass={`
                  ${
                    !privacyPolicy && touched.privacyPolicy
                      ? "switch-container check-button validation-error"
                      : "switch-container check-button"
                  }`}
                wrapperClass={`${
                  !privacyPolicy && touched.privacyPolicy
                    ? "slider validation-error"
                    : "slider"
                }`}
                onChange={() => setPrivacyPolicy(!privacyPolicy)}
                checked={privacyPolicy}
                label={
                  <>
                    Yes, I accept the
                    <Link to={"/privacy-policy"} target={"_blank"}>
                      Privacy Policy
                    </Link>
                  </>
                }
                value="Yes, I accept the Privacy Policy"
                type={"checkbox"}
              />
            </div>
          </div>
          <div className="custom-form-bottom">
            <InputField
              className={`custom-input ${
                shouldMarkError("description", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("description", errors, touched)
                  ? "describe your project is required*"
                  : "describe your project*"
              }`}
              group
              type="textarea"
              validate
              error="wrong"
              success="right"
              labelClass="text-area-label"
              value={description !== undefined ? description : ""}
              onChange={setDescriptionFn}
            />
            <div className="custom-form-button d-flex">
              <div className="left part required-text-block">
                <span className="required-sign">*</span>
                <span className="required-text">= required</span>
              </div>
              <div className="right part">
                <Button
                  className={"right-arrow orange-button"}
                  direction={"ml-3"}
                  righticon={"true"}
                  disabled={loading}
                  icon={loading ? "" : "arrow-right"}
                  children={loading ? "sending..." : "Send"}
                  onClick={() => _handleSendEmail()}
                ></Button>
              </div>
            </div>
          </div>
        </div>
        <div className="block-img col-md-6 col-sm-12 block-part">
          <img src={workingOnProject} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  );
};

export default CustomForm;
