import { ProductSchema } from "./productSchema";
import schemaConfiguration from "config/schemaConfiguration";

export const CategorySchema = (products, category) => {
  let items = "";
  if (typeof products === "undefined" || !products) return "";
  products.map((item) => {
    items += ProductSchema(item);
  });
  const data = {
    "@context": "https://schema.org",
    "@type": "OfferCatalog",
    name: category.name,
    url: schemaConfiguration.url + category.url_path,
    itemListElement: [items],
  };
  return JSON.stringify(data);
};
