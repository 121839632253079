import { loader } from "graphql.macro";

// perhaps this needs to be deprecated?
export default (query) => {
  const mapping = {
    category: "../graphql/queries/category.graphql",
  };

  if (query === "customerOrders") {
    return loader("../graphql/queries/sales/customerOrders.graphql");
  }

  if (query === "category") {
    return loader("../graphql/queries/category.graphql");
  }

  if (query === "categoryProducts") {
    return loader("../graphql/queries/categoryProducts.graphql");
  }

  if (query === "products") {
    return loader("../graphql/queries/products.graphql");
  }

  if (query === "product") {
    return loader("../graphql/queries/product.graphql");
  }

  if (query === "categoryTree") {
    return loader("../graphql/queries/categoryTree.graphql");
  }

  if (query === "cmsPage") {
    return loader("../graphql/queries/cmsPage.graphql");
  }

  if (query === "getProductInfoBySku") {
    return loader("../graphql/queries/getProductInfoBySku.graphql");
  }

  if (query === "Hotspots") {
    return loader("../graphql/queries/hotspots.graphql");
  }

  if (query === "cmsBlock") {
    return loader("../graphql/queries/cmsBlock.graphql");
  }

  // if (query === "urlResolver") {
  //   return loader("../graphql/queries/urlResolver.graphql");
  // }

  if (query === "userLogin") {
    return loader(
      "../graphql/mutations/customer/session/loginMutation.graphql"
    );
  }

  if (query === "userLogout") {
    return loader(
      "../graphql/mutations/customer/session/logoutMutation.graphql"
    );
  }

  if (query === "getCustomer") {
    return loader("../graphql/queries/customer/customer.graphql");
  }

  if (query === "validateCustomerToken") {
    return loader("../graphql/queries/customer/validateCustomerToken.graphql");
  }

  if (query === "checkCustomerAuthorization") {
    return loader("../graphql/queries/customerCheckToken.graphql");
  }

  // if (query === "getCountries") {
  //   return loader("../graphql/queries/countries.graphql");
  // }
  // if (query === "searchProducts") {
  //   return loader("../graphql/queries/searchProducts.graphql");
  // }

  if (query === "registerCustomer") {
    return loader(
      "../graphql/mutations/customer/createCustomerMutation.graphql"
    );
  }

  if (query === "createCustomerAddress") {
    return loader(
      "../graphql/mutations/customer/address/createCustomerAddressMutation.graphql"
    );
  }

  if (query === "updateCustomerInformation") {
    return loader(
      "../graphql/mutations/customer/information/updateCustomerInformation.graphql"
    );
  }

  if (query === "newsletterCustomerSubscribe") {
    return loader(
      "../graphql/mutations/customer/subscribe/newsletterSubscribe.graphql"
    );
  }
  if (query === "editCustomerAddress") {
    return loader(
      "../graphql/mutations/customer/address/editCustomerAddress.graphql"
    );
  }

  if (query === "changeCustomerPassword") {
    return loader(
      "../graphql/mutations/customer/information/changeCustomerPassword.graphql"
    );
  }

  if (query === "generateCache") {
    return loader("../graphql/mutations/generateCache.graphql");
  }

  // if (query === "customerExportList") {
  //   return loader("../graphql/queries/customerExportList.graphql");
  // }

  // if (query === "createExportList") {
  //   return loader("../graphql/mutations/exportList/createExportList.graphql");
  // }

  // if (query === "createFreightRequest") {
  //   return loader(
  //     "../graphql/mutations/exportList/createQuoteRequest.graphql"
  //   );
  // }

  // if (query === "changeExportListName") {
  //   return loader(
  //     "../graphql/mutations/exportList/information/changeExportListName.graphql"
  //   );
  // }

  // if (query === "deleteExportList") {
  //   return loader("../graphql/mutations/exportList/deleteExportList.graphql");
  // }

  // if (query === "addSkuToList")import queryLoader from "../graphql/queryLoader";

  // }

  // if (query === "removeSkuFromList") {
  //   return loader(
  //     "../graphql/mutations/exportList/items/removeSkuFromList.graphql"
  //   );
  // }

  if (query === "generateResetPasswordToken") {
    return loader(
      "../graphql/mutations/customer/generateResetPasswordToken.graphql"
    );
  }

  // if (query === "exportListQuery") {
  //   return loader("../graphql/queries/exportListQuery.graphql");
  // }

  if (query === "resetCustomerPassword") {
    return loader(
      "../graphql/mutations/customer/information/resetPassword.graphql"
    );
  }

  // if (query === "removeAllItemsFromList") {
  //   return loader(
  //     "../graphql/mutations/exportList/removeAllItemsFromList.graphql"
  //   );
  // }

  if (query === "sendEmail") {
    return loader("../graphql/mutations/sendEmail.graphql");
  }

  // if (query === "storeConfig") {
  //   return loader("../graphql/queries/store/storeConfig.graphql");
  // }

  // if (query === "getDynamicContent") {
  //   return loader(
  //     "../graphql/queries/content/getDynamicContentByContentType.graphql"
  //   );
  // }

  // if (query === "getFilters") {
  //   return loader("../graphql/queries/getFilters.graphql");
  // }

  if (query === "createEmptyCart") {
    return loader(
      "../graphql/mutations/customer/cart/customerCreateEmptyCart.graphql"
    );
  }

  if (query === "cartData") {
    return loader("../graphql/queries/customer/cart/cart.graphql");
  }

  if (query === "customerCart") {
    return loader("../graphql/queries/customer/cart/customerCart.graphql");
  }

  if (query === "addProduct") {
    return loader(
      "../graphql/mutations/customer/cart/addProductToCart.graphql"
    );
  }
  if (query === "addConfigurable") {
    return loader(
      "../graphql/mutations/customer/cart/addConfigurableToCart.graphql"
    );
  }

  if (query === "removeProduct") {
    return loader(
      "../graphql/mutations/customer/cart/removeItemsFromCart.graphql"
    );
  }

  if (query === "updateCart") {
    return loader("../graphql/mutations/customer/cart/updateCartItems.graphql");
  }

  if (query === "mergeCarts") {
    return loader("../graphql/mutations/customer/cart/mergeCarts.graphql");
  }

  if (query === "placeOrder") {
    return loader("../graphql/mutations/customer/cart/placeOrder.graphql");
  }

  if (query === "setPaymentMethod") {
    return loader(
      "../graphql/mutations/customer/cart/setPaymentMethodOnCart.graphql"
    );
  }
  if (query === "addToCompare") {
    return loader("../graphql/mutations/compare/addToCompare.graphql");
  }

  if (query === "removeCompare") {
    return loader("../graphql/mutations/compare/removeCompare.graphql");
  }

  throw new Error(
    "Unknown query, you probably forgot to define it in state/graphql/queryLoader.js."
  );
};
