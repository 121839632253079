import React, { useState, useEffect } from "react";
import Loadable from "react-loadable";
import CmsBlock from "core/components/cmsBlock";
import Subscribe from "../middle/subscribe";

const Loading = props => {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <div>Loading...</div>;
  } else {
    return null;
  }
};

const LoadableFooterMiddleMobile = Loadable({
  loader: () => import("./mobile"),
  loading: Loading,
  delay: 300
});

const FooterMiddle = props => {
  const [width, setWidth] = useState(document.documentElement.clientWidth);

  const onResizeSetWidth = () => {
    setWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);

  return (
    <div className="text-center text-md-left footer-middle-content">
      <div className="footer-middle-content-inner">
        <div className="col-md-9 col-sm-12 footer-middle-part">
          {(width < 992 && <LoadableFooterMiddleMobile />) || (
            <CmsBlock dangHtml={true} id="footer-middle" />
          )}
        </div>
        <div className="col-md-3 col-sm-12 footer-middle-part">
          <Subscribe />
        </div>
      </div>
    </div>
  );
};

export default FooterMiddle;
