import React, { useState } from "react";
import Sidebar from "./sidebar";
import Overlay from "app/layout/overlay";
import { useMediaQuery } from "react-responsive";
import { MDBCol, MDBRow, MDBIcon, MDBBtn } from "mdbreact";
import Button from "core/components/button";
import ParentCategoryContent from "./content";
import _ from "lodash";

const ParentCategory = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  let { allCategories, data } = props;
  const isMobile = useMediaQuery({ maxWidth: 992 });
  if (_.isEmpty(data)) {
    return <></>;
  }
  return (
    <MDBRow>
      <MDBCol md={"12"} lg={"3"}>
        {(isMobile && (
          <>
            <div md="12">
              <h3 className="plp-landing-title">{data.name}</h3>
            </div>
            <div className="sidebar-mobile">
              <MDBBtn
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Show categories
              </MDBBtn>
              <Overlay isOpen={isOpen} className={`overlay-${isOpen}`}>
                <MDBRow id="sidebar" className={`sidebar`}>
                  <MDBCol md="12" className="filter-title">
                    <div
                      md="12"
                      className="border-bottom d-flex justify-content-center align-items-center title"
                    >
                      categories
                      <div className="close-btn">
                        <MDBIcon
                          onClick={(e) => {
                            setIsOpen(false);
                          }}
                          far
                          icon="times-circle"
                        />
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol className={`filter-items `}>
                    <div
                      className={`${
                        isOpen
                          ? "fadeInDown animated slow visible"
                          : "invisible fadeOutUp"
                      }`}
                    >
                      <Sidebar
                        allCategories={allCategories}
                        open={data.id}
                        data={data}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="overlay-footer">
                  <div className="overlay-footer-inner">
                    <MDBCol>
                      <Button
                        color={"primary"}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                        className={"orange-button without-icon apply-btn"}
                        children={"apply"}
                      />
                    </MDBCol>
                  </div>
                </MDBRow>
              </Overlay>
            </div>
          </>
        )) || (
          <Sidebar allCategories={allCategories} open={data.id} data={data} />
        )}
      </MDBCol>
      <MDBCol md={"12"} lg={"9"}>
        {(isMobile && <></>) || (
          <MDBRow>
            <MDBCol md="12">
              <h3 className="plp-landing-title">{data.name}</h3>
            </MDBCol>
          </MDBRow>
        )}

        <MDBRow className="plp-landing-content">
          <ParentCategoryContent
            allCategories={allCategories}
            open={data.id}
            data={data}
          />
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default ParentCategory;
