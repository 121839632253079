import React, { memo } from "react";
import { MDBBox } from "mdbreact";

const FaqTabs = memo(
  props => {
    let { tabs, active, setActiveHandler } = props;
    return (
      <>
        <MDBBox className={"tabs-container"}>
          {tabs.map((tab, index) => {
            return (
              <MDBBox
                onClick={e => {
                  setActiveHandler(index);
                }}
                className={`tab ${active === index ? "active" : "not-active"}`}
              >
                {tab.title}
              </MDBBox>
            );
          })}
        </MDBBox>

        <MDBBox>
          {tabs.map((tab, index) => {
            return (
              <h3
                className={"tabs-container"}
                onClick={e => {
                  setActiveHandler(!active);
                }}
                className={`tab ${
                  active === index ? "title-gray show" : "not-show"
                }`}
              >
                {tab.title}
              </h3>
            );
          })}
        </MDBBox>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps === nextProps) {
      return true;
    }
    return false;
  }
);

export default FaqTabs;
