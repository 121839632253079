import React from "react";
import { useLocation } from "react-router-dom";
import OrderGrid from "core/components/orderGrid";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import { isUndefined } from "core/helpers/functions";
import View from "./view";

const Orders = (props) => {
  let location = useLocation();
  let isView = false;

  let paths = location.pathname.split("/");
  let viewId = paths[paths.length - 1];

  if (viewId !== "orders" && viewId !== "") {
    isView = true;
  }

  let hasOrders;
  let orders = props.orderData;
  if (isUndefined(props.orderData)) {
    hasOrders = false;
  } else {
    hasOrders = true;
  }

  if (isView) {
    return <View orderId={viewId} />;
  }

  return (
    <MDBContainer className="account-orders-container mb-5">
      <MDBRow className="account-orders">
        <MDBCol lg="12">
          <h3 className="large">my orders</h3>
        </MDBCol>
        <MDBCol lg="12">
          {hasOrders ? (
            <>
              <OrderGrid orders={orders} />
            </>
          ) : (
            <>You have placed no orders</>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Orders;
