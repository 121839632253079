import Homepage from "app/pages/home";
import Login from "app/pages/customer/login";
import RegisterB2B from "app/pages/customer/register/b2b";
import RegisterB2C from "app/pages/customer/register/b2c";
import Typography from "app/pages/typography";
import thankYouPage from "app/pages/customer/register/b2b/thankYouPage";
import cThankYouPage from "app/pages/customer/register/b2c/thankYouPage";
import AboutUs from "app/pages/static/about-us";
import ContactPage from "app/pages/static/contact";
import FindRetailer from "app/pages/static/find-a-retailer";
import Custom from "app/pages/static/custom";
import AssemblyInstructions from "app/pages/static/assembly-instructions";
import Forms from "app/pages/static/forms";
import Faq from "app/pages/static/faq";
import ForgotPassword from "app/pages/forgotPassword";
import ProductRegistration from "app/pages/static/product-registrations";
import ShopTheLook from "app/pages/static/shop-the-look";
import ShopLookDetail from "app/pages/static/shop-the-look-detail";
import TradeProgram from "app/pages/static/trade-program";
import Cart from "app/pages/cart";
import Customer from "app/pages/customer/account";
import SearchResults from "app/pages/catalog/searchResultsCategory";
import Checkout from "app/pages/checkout";
import WishlistShare from "app/pages/static/wishlist-share";
import OrderSuccess from "app/pages/checkout/order-success";

export default {
  home: { component: Homepage, exact: false },
  "register/trade": { component: RegisterB2B, exact: false },
  "register/success": { component: thankYouPage, exact: false },
  "register/complete": { component: cThankYouPage, exact: false },
  register: { component: RegisterB2C, exact: false },
  login: { component: Login, exact: false },
  typography: { component: Typography, exact: false },
  customer: { component: Customer, exact: false },
  "forgot-password": { component: ForgotPassword, exact: false },
  //static
  contact: { component: ContactPage, exact: false },
  "about-us": { component: AboutUs, exact: false },
  "find-a-retailer": { component: FindRetailer, exact: false },
  custom: { component: Custom, exact: false },
  "assembly-instructions": { component: AssemblyInstructions, exact: false },
  forms: { component: Forms, exact: false },
  "frequently-asked-questions": { component: Faq, exact: false },
  "product-registrations": { component: ProductRegistration, exact: false },
  "shop-the-look": { component: ShopTheLook, exact: false },
  "shop-the-look-detail": { component: ShopLookDetail, exact: false },
  "trade-program": { component: TradeProgram, exact: false },
  cart: { component: Cart, exact: true },
  "search-results": { component: SearchResults, exact: false },
  checkout: { component: Checkout, exact: false },
  "favorites/wishlist-share": { component: WishlistShare, exact: false },
  "thank-you-for-your-purchase": { component: OrderSuccess, exact: true },
};

export { default as ErrorPage } from "app/pages/error";

export { default as TemporaryPage } from "app/pages/temporary";

export const DefaultPage = Homepage;
