import React, { memo } from "react";
import { MDBCol, MDBRow, MDBLink } from "mdbreact";
import InputField from "core/components/inputField";
import CheckButton from "core/components/checkButton";
import Button from "core/components/button";
import { validate, shouldMarkError } from "./validation";
import _ from "lodash";

const CheckoutLogin = memo(
  ({
    showLogin,
    setShowLogin,
    email,
    setEmail,
    password,
    setPassword,
    touched,
    setTouched,
    setGuestEmailOnCart,
    loading,
    setLoading,
    lock,
    login,
    customer,
    setLoggingIn,
    addMessage,
  }) => {
    let errors = validate(email, password);
    const _handleLogin = (e) => {
      e.preventDefault();
      let isTrue = true;
      setTouched({ email: true, password: true });

      Object.keys(errors).map((item) => {
        let error = errors[item];
        if (isTrue) {
          if (error === true) {
            isTrue = false;
          }
        }
        return error;
      });
      if (isTrue) {
        setLoading(true);
        setLoggingIn(true);
        lock({
          always: (e) => {},
          success: (e) => {
            lock({
              always: () => {},
              success: () => {
                lock({
                  always: () => {},
                  success: () => {
                    setLoggingIn(false);
                  },
                  fail: () => {
                    setLoading(false);
                    setLoggingIn(false);
                  },
                });
              },
              fail: () => {
                setLoading(false);
                setLoggingIn(false);
                addMessage(
                  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
                  "danger"
                );
              },
            });
          },
          fail: (e) => {
            setLoading(false);
            setLoggingIn(false);
            addMessage(
              "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
              "danger"
            );
          },
        });
        login(email, password, customer.data.cartToken);
      }
    };

    return (
      <MDBCol
        id={`checkout-email-container`}
        md={`12`}
        className={"checkout-email"}
      >
        <MDBRow>
          <MDBCol size={`12`}>
            <MDBRow>
              <CheckButton
                containerClass={"radio-container check-button radio-yes-no"}
                wrapperClass={"radio"}
                label={"continue as guest"}
                value="yes"
                onChange={(e) => {
                  setShowLogin(false);
                }}
                checked={showLogin === false ? true : false}
                type={"radio"}
              />
              {showLogin === false ? (
                <MDBCol md={`12`}>
                  <MDBRow>
                    <p>you can create an account at the end of checkout</p>
                    <InputField
                      label={`${
                        shouldMarkError("email", errors, touched)
                          ? "email required (ex: info@ducduc.com)"
                          : "email*"
                      }`}
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      value={email !== undefined ? email : ""}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      onBlur={(e) => {
                        setTouched({ ...touched, email: true });
                        if (
                          !shouldMarkError("email", errors, {
                            ...touched,
                            email: true,
                          })
                        ) {
                          setGuestEmailOnCart(email);
                        }
                      }}
                      className={`w-100 ${
                        shouldMarkError("email", errors, touched)
                          ? "validation-error"
                          : ""
                      }`}
                    />
                  </MDBRow>
                </MDBCol>
              ) : (
                <></>
              )}
            </MDBRow>
          </MDBCol>
          <MDBCol size={`12`}>
            <MDBRow>
              <CheckButton
                containerClass={"radio-container check-button radio-yes-no"}
                wrapperClass={"radio"}
                label={"login"}
                value="no"
                onChange={(e) => {
                  setShowLogin(true);
                }}
                checked={showLogin === true ? true : false}
                type={"radio"}
              />
              {showLogin === true ? (
                <MDBCol md={`12`} className={`login-shown`}>
                  <MDBRow>
                    <InputField
                      label={`${
                        shouldMarkError("email", errors, touched)
                          ? "email required (ex: info@ducduc.com)"
                          : "email*"
                      }`}
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      value={email !== undefined ? email : ""}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      onBlur={(e) => {
                        setTouched({ ...touched, email: true });
                        if (
                          !shouldMarkError("email", errors, {
                            ...touched,
                            email: true,
                          })
                        ) {
                          setGuestEmailOnCart(email);
                        }
                      }}
                      className={
                        shouldMarkError("email", errors, touched)
                          ? "validation-error"
                          : ""
                      }
                    />
                    <InputField
                      label="password"
                      group
                      type="password"
                      validate
                      error="wrong"
                      success="right"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <MDBCol className={`md-form pr-0 bottom-of-login-show`}>
                      <MDBRow className={`align-items-center`}>
                        <MDBCol size={`12`} xl={`6`} lg={`6`} md={`6`} xs={`6`}>
                          <Button
                            className={"right-arrow orange-button w-100"}
                            direction={"ml-3"}
                            disabled={loading}
                            righticon={"true"}
                            icon={"arrow-right"}
                            text={`${loading ? "Logging in..." : "Login"}`}
                            onClick={(e) => {
                              _handleLogin(e);
                            }}
                          />
                        </MDBCol>
                        <MDBCol size={`12`} xl={`6`} lg={`6`} md={`6`} xs={`6`}>
                          <MDBLink to={`/forgot-password`}>
                            forgot your password?
                          </MDBLink>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              ) : (
                <></>
              )}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default CheckoutLogin;
