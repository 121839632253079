import React from "react";
import CheckoutReviewItem from "./checkoutReviewItem";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";
import Price from "core/components/price";
import ShippingNames from "config/checkout/shipping_names_mapper";
import DiscountSummary from "app/layout/coupon/discountSummary";

const CheckoutOrderReview = (props) => {
  let { cart } = props;
  let taxPrice = 0;
  let isTaxed = false;
  let shippingPrice = 0;
  let shippingLabel = "shipping";
  if (typeof cart === "undefined") return <></>;
  if (
    typeof cart !== "undefined" &&
    typeof cart.prices !== "undefined" &&
    typeof cart.prices.applied_taxes !== "undefined" &&
    cart.prices.applied_taxes.length > 0
  ) {
    cart.prices.applied_taxes.map((tax) => {
      taxPrice = taxPrice + tax.amount.value;
    });
    isTaxed = true;
  }

  if (
    typeof cart !== "undefined" &&
    typeof cart.shipping_addresses !== "undefined" &&
    typeof cart.shipping_addresses[0] !== "undefined" &&
    typeof cart.shipping_addresses[0].selected_shipping_method !==
      "undefined" &&
    cart.shipping_addresses[0].selected_shipping_method !== null &&
    typeof cart.shipping_addresses[0].selected_shipping_method.amount !==
      "undefined"
  ) {
    shippingPrice =
      typeof cart.shipping_addresses[0].selected_shipping_method !== "undefined"
        ? cart.shipping_addresses[0].selected_shipping_method.amount.value
        : 0;
    shippingLabel =
      typeof cart.shipping_addresses[0].selected_shipping_method !== "undefined"
        ? cart.shipping_addresses[0].selected_shipping_method.method_title.toLowerCase() ===
          "upsmatrix"
          ? "standard shipping"
          : cart.shipping_addresses[0].selected_shipping_method.method_title
        : "shipping";
  }
  if (typeof cart.items === "undefined") return <></>;

  return (
    <>
      <MDBContainer fluid className={"order-summary-container"}>
        <MDBBox className={"order-summary-top-content"}>
          <MDBBox>
            <h3>order summary</h3>
          </MDBBox>
          <MDBBox className={"order-summary-qty"}>
            {cart.items.length} items in cart
          </MDBBox>
        </MDBBox>
        <MDBRow></MDBRow>
        <MDBRow>
          <MDBCol className={`order-items-review`}>
            <CheckoutReviewItem {...props} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBBox className="order-summary-subtotal">
        <div>
          Subtotal
          {typeof cart !== "undefined" &&
          typeof cart.prices !== "undefined" &&
          typeof cart.prices.subtotal_excluding_tax !== "undefined" ? (
            <Price
              price={cart.prices.subtotal_excluding_tax.value}
              type={`fixed`}
            />
          ) : (
            <></>
          )}
        </div>
        {isTaxed ? (
          <div>
            Tax
            <Price price={taxPrice} type={`fixed`} />
          </div>
        ) : (
          <></>
        )}
        <DiscountSummary />
        {typeof cart.shipping_addresses[0] !== "undefined" &&
        typeof cart.shipping_addresses[0].selected_shipping_method !==
          "undefined" &&
        cart.shipping_addresses[0].selected_shipping_method !== null &&
        typeof cart.shipping_addresses[0].selected_shipping_method
          .carrier_code !== "undefined" &&
        typeof cart.shipping_addresses[0].selected_shipping_method
          .method_code !== "undefined" ? (
          <div>
            {
              ShippingNames[
                cart.shipping_addresses[0].selected_shipping_method
                  .carrier_code +
                  "_" +
                  cart.shipping_addresses[0].selected_shipping_method
                    .method_code
              ]
            }
            <Price price={shippingPrice} type={`fixed`} />
          </div>
        ) : (
          <></>
        )}
      </MDBBox>
      <MDBBox className="order-summary-total">
        <div>
          Order total{" "}
          {typeof cart !== "undefined" &&
          typeof cart.prices !== "undefined" &&
          typeof cart.prices.grand_total !== "undefined" ? (
            <Price price={cart.prices.grand_total.value} type={`fixed`} />
          ) : (
            <></>
          )}
        </div>
      </MDBBox>
    </>
  );
};

export default CheckoutOrderReview;
