import React from "react";
import MiniCartItem from "./MiniCartItem";
import { MDBCol, MDBRow, MDBLink } from "mdbreact";
import Button from "core/components/button";
import Price from "core/components/price";

const MiniCart = (props) => {
  let numberOfItems =
    typeof props.items !== "undefined"
      ? props.items.reduce((acc, curr) => acc + curr.quantity, 0)
      : 0;
  return (
    <MDBCol id="minicart" className="minicart-wrapper">
      {numberOfItems !== 0 ? (
        <>
          <MDBRow className="minicart-head">
            <div
              className={`d-flex flex-row justify-content-between align-content-between w-100`}
            >
              <div>
                <p>
                  {numberOfItems} {numberOfItems === 1 ? "item" : "items"}
                </p>
              </div>

              <div className={`d-flex flex-column`}>
                <p className="minicart-subtotal-label">Cart Subtotal</p>
                <p className="minicart-subtotal-value align-self-end">
                  {
                    <Price
                      price={
                        typeof props.subtotal !== "undefined"
                          ? props.subtotal.value
                          : ""
                      }
                      type={`fixed`}
                    />
                  }
                </p>
              </div>
            </div>
            <MDBLink
              to={"/checkout"}
              onClick={props.hideMiniCart}
              className="minicart-link w-100"
            >
              <Button
                color={"primary"}
                className={"right-arrow orange-button w-100"}
                direction={"ml-3"}
                righticon={"true"}
                icon={"arrow-right"}
                text={"Go to checkout"}
              />
            </MDBLink>
          </MDBRow>
          <MDBRow className="minicart-body">
            {props.items.map((item, index) => (
              <MiniCartItem
                key={`minicart-item-${item.id}`}
                item={item}
                removeItem={props.removeItem}
                hideMiniCart={props.hideMiniCart}
                updateCart={props.updateCart}
                lock={props.lock}
              />
            ))}
          </MDBRow>
          <MDBRow className="minicart-foot justify-content-center">
            <MDBLink onClick={props.hideMiniCart} to={`/cart`}>
              View and Edit Cart
            </MDBLink>
          </MDBRow>
        </>
      ) : (
        <>
          <MDBRow className="minicart-head"></MDBRow>
          <MDBRow className="row minicart-body text-center w-100 justify-content-center m-0">
            <p>no items in your shopping cart</p>
          </MDBRow>
          <MDBRow className="minicart-foot"></MDBRow>
        </>
      )}
    </MDBCol>
  );
};

export default MiniCart;
