import React, { memo } from "react";
import CheckoutBillingAddress from "./checkoutBillingAddress";
import CheckoutShippingAddress from "./checkoutShippingAddress";
import Button from "core/components/button";

import { triggerUpdate } from "./actions";
import _ from "lodash";

const CheckoutAddresses = memo(
  ({
    useSameAddress,
    setUseSameAddress,
    billingAddress,
    setBillingAddress,
    shippingAddress,
    setShippingAddress,
    loading,
    setLoading,
    lock,
    initialAddressState,
    setBillingAddressOnCart,
    setShippingAddressOnCart,
    shouldChange,
    setShouldChange,
    resetTouchedAndErrors,
    setShowPayment,
  }) => {
    return (
      <>
        <CheckoutBillingAddress
          useSameAddress={useSameAddress}
          setUseSameAddress={setUseSameAddress}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          setShouldChange={setShouldChange}
        />
        {!useSameAddress ? (
          <CheckoutShippingAddress
            useSameAddress={useSameAddress}
            shippingAddress={shippingAddress}
            setShippingAddress={setShippingAddress}
            setShouldChange={setShouldChange}
          />
        ) : (
          <></>
        )}
        <div className={`d-flex justify-content-between`}>
          <Button
            outline={true}
            className={"right-arrow justify-content-center white-button"}
            text={"cancel"}
            direction={"ml-3"}
            righticon={"true"}
            onClick={(e) => {
              setBillingAddress({ ...initialAddressState });
              setShippingAddress({ ...initialAddressState });
              setUseSameAddress(false);
            }}
          />
          <Button
            disabled={loading || (loading === false && shouldChange === true)}
            className={`${!loading ? "right-arrow" : ""} orange-button`}
            direction={"ml-3"}
            children={`${
              loading && shouldChange === false ? "updating..." : "update"
            }`}
            righticon={"true"}
            icon={`${!loading ? "arrow-right" : ""}`}
            onClick={(e) => {
              triggerUpdate(
                billingAddress,
                shippingAddress,
                useSameAddress,
                setBillingAddress,
                setShippingAddress,
                setLoading,
                lock,
                setShouldChange,
                setBillingAddressOnCart,
                setShippingAddressOnCart,
                resetTouchedAndErrors,
                setShowPayment
              );
            }}
          />
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default CheckoutAddresses;
