import React from "react";
import { gql } from "graphql.macro";
import GraphQlQueryWrapper from "core/components/graphQlWrapper";
import ShopTheLookDataContainer from "./shopLookDetail-data-container";

const query = gql`
  query {
    getAllGalleryData {
      name
      image
      description
      category {
        category_id
        name
      }
      hotspot {
        image
        photo_by
        content
        name
      }
    }
  }
`;
const ShopLookDetailQueryLoader = (props) => {
  let url = props.location.pathname;
  let match = props.match.path;
  let newUrl = url.replace(match + "/", "");

  return (
    <>
      <GraphQlQueryWrapper
        query={query}
        variables={{ id: `shop-the-look-data-${newUrl}` }}
      >
        <ShopTheLookDataContainer url={newUrl} />
      </GraphQlQueryWrapper>
    </>
  );
};

export default ShopLookDetailQueryLoader;
