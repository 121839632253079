import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hotspot from "app/layout/hotspot";

const ShopLookSlider = props => {
  const settings = {
    dots: true,
    dotsClass: props.dotsClass,
    arrows: false,
    infinite: false,
    variableWidth: props.variableWidth,
    slidesToShow: props.slidesToShow,
    slidesToScroll: 1,
    customPaging: i => <div className={"slider-custom-dot"}>{i}</div>,
    appendDots: dots => (
      <div
        className={"test"}
        style={{
          padding: "15px 0",
          textAlign: props.DotsAlign,
          maxHeight: "5rem"
        }}
      >
        <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false
        }
      },
      {
        breakpoint: 970,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false
        }
      }
    ]
  };

  return (
    <Slider {...settings} className={"shop-look-slider-content"}>
      {typeof props.data !== "undefined" &&
        props.data.getHotspots.map(
          (hotspot, index) =>
            props.data.getHotspots[index].onslider === "true" && (
              <div key={`shopLookSliderDiv-` + index}>
                <Hotspot contentArray={hotspot} isHome={true}></Hotspot>
                <p>{props.data.getHotspots[index].photoby}</p>
              </div>
            )
        )}
    </Slider>
  );
};

export default ShopLookSlider;
