import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import CheckoutShippingAddressStateContainer from "./state-container";

const CheckoutShippingAddressReduxContainer = (props) => {
  return <CheckoutShippingAddressStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined"
      ? state.customer.data
      : {},
  shipping_address:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.shipping_addresses !== "undefined" &&
    state.customer.data.cart.shipping_addresses.length > 0 &&
    typeof state.customer.data.cart.shipping_addresses[0] !== "undefined"
      ? state.customer.data.cart.shipping_addresses[0]
      : {},
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    setShippingAddressOnCart: (shipping_address) => {
      dispatch(customerActions.setShippingAddressOnCart(shipping_address));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutShippingAddressReduxContainer);
