import React, { useState } from "react";
import CompareContributer from "core/components/compareContributer";
import {
  MDBContainer,
  MDBBox,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBLink,
} from "mdbreact";
import Image from "core/components/image";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import _ from "lodash";
import Price from "core/components/price";
import { CSSTransition } from "react-transition-group";

const transform = (node, index) => {
  if (node.type === "tag" && node.name === "p") {
    if (node.children.length > 0) {
      return node.children.map((child) => {
        return child.data;
      });
    }
  }

  return convertNodeToElement(node, index, transform);
};
const CompareListInner = (props) => {
  let { item, image, toggleOptions } = props;
  let compareUrl = "/" + props.item.url;
  let attributeCount = 6;
  let attributeArray = [];
  for (let index = 1; index <= attributeCount; index++) {
    let element = item[`attribute${index}`];
    attributeArray.push(element);
  }

  return (
    <MDBBox
      className="position-relative item p-3 w-100 text-center"
      style={{ flexBasis: "20%", flexGrow: "0" }}
    >
      <MDBBox className={"image-wrapper"} style={{ minHeight: "25rem" }}>
        <MDBLink className={"p-0"} to={compareUrl}>
          <Image
            source={image.url}
            imgContainer="product-image"
            image_alt={item.name + " image"}
          />
        </MDBLink>
      </MDBBox>
      <MDBBox
        className={
          "py-2 font-weight-bold compare-item-name two-rows-min-height"
        }
      >
        {item.name}
      </MDBBox>
      <MDBBox className={"py-2 compare-item-price"}>
        <Price type={"fixed"} price={item.productPrice} />
      </MDBBox>
      <MDBBox class="remove-compare position-absolute">
        <CompareContributer {...props} productId={item.id} />
        <MDBIcon far icon="times-circle" />
      </MDBBox>
      <CSSTransition
        key={"attribute-box"}
        classNames={"attribute-transition"}
        appear={true}
        in={toggleOptions}
        timeout={400}
      >
        <MDBBox className="slide attribute-box">
          {toggleOptions && (
            <MDBBox
              className={
                "attributes-list text-left d-flex justify-content-between flex-column"
              }
            >
              {attributeArray.map((attribute) => {
                if (_.isEmpty(attribute.label) && _.isEmpty(attribute.value)) {
                  return "";
                }
                return (
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol className="attributes-list-item py-2 px-0 d-flex justify-content-between">
                        <span>{attribute.label}</span>
                        <span>
                          {ReactHtmlParser(attribute.value, {
                            decodeEntities: true,
                            transform: transform,
                          })}
                        </span>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                );
              })}
            </MDBBox>
          )}
        </MDBBox>
      </CSSTransition>
    </MDBBox>
  );
};

export default CompareListInner;
