import React, { useState, memo } from "react";
import AccordionAssemblyComputed from "./accordionAssembly-computed";
import { MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";

import lodash from "lodash";

const AccordionAssemblyItem = memo(
  (props) => {
    let { item } = props;

    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
      setOpen(!open);
    };

    return (
      <MDBBox className={`accordion-item`}>
        {!lodash.isEmpty(item.getProducts) ? (
          <div className="accordion-section">
            <button className="accordion-title" onClick={toggleOpen}>
              <h3>{item.category_name}</h3>
              <span
                className={
                  (open && "accordion-icon rotate") || "accordion-icon"
                }
              >
                <Icon icon={"rightArrowOrange"} />
              </span>
            </button>

            <div className={`accordion-content ${open ? "open" : "closed"}`}>
              <AccordionAssemblyComputed items={item.getProducts} />
            </div>
          </div>
        ) : (
          ""
        )}
      </MDBBox>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps === nextProps)) {
      return true;
    }

    return false;
  }
);

export default AccordionAssemblyItem;
