import React, { useState } from "react";
import MobileOrderSummary from "./mobileOrderSummary";

const MobileOrderSummaryStateContainer = ({ cart }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MobileOrderSummary isOpen={isOpen} setIsOpen={setIsOpen} cart={cart} />
  );
};

export default MobileOrderSummaryStateContainer;
