import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import CheckoutLoginStateContainer from "./checkoutLogin-state-container";
import { messagesActions } from "core/state/redux/data/messages";

const CheckoutLoginReduxContainer = (props) => {
  return <CheckoutLoginStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  cart_email:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.email !== "undefined" &&
    state.customer.data.cart.email !== null
      ? state.customer.data.cart.email
      : "",
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    login: (username, password, cartToken, callbackFunction) => {
      dispatch(customerActions.loginCustomer(username, password, cartToken));
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutLoginReduxContainer);
