import React from "react";
import CompoundSlider from "app/layout/compoundSlider";
import { isEmpty } from "core/helpers/functions";
import Filter from "./filter";
import ReactLoader from "app/layout/loader";

export const generateFilterRender = (
  props,
  category,
  filtered_category,
  selected_category,
  addFilter,
  locked
) => {
  const { filters } = props;
  let filtersAggregations =
    typeof filters.data !== "undefined" &&
    typeof filters.data.aggregations !== "undefined"
      ? filters.data.aggregations
      : [];

  filtersAggregations.map(value => {
    if (
      value.attribute_code !== "category_id" &&
      value.attribute_code !== "price"
    ) {
      if (
        typeof category.initialFilters[value.attribute_code] !== "undefined"
      ) {
        return (category.initialFilters[value.attribute_code].options =
          value.options);
      }
    }
  });

  return Object.keys(category.initialFilters).map((item, key) => {
    let _filter = category.initialFilters[item];

    if (
      _filter.type === "category" ||
      _filter.type === "multi" ||
      _filter.type === "color"
    ) {
      let originalFilter = category.initialFilters[_filter.attribute_code];
      let values = _filter.values(originalFilter);
      if (
        typeof filtersAggregations.find(
          f => f.attribute_code === _filter.attribute_code
        ) === "undefined"
      ) {
        return "";
      }
      if (values.length === 1) {
        return "";
      }
      return (
        <div
          key={`key-${_filter.label}`}
          className={`filter-by-category ${Object.keys(_filter.values).length >=
            5 && "overflow-on"} filter-content `}
        >
          {isEmpty(values) && <ReactLoader />}
          {!isEmpty(values) && (
            <Filter
              key={`value-filter-${_filter.label}`}
              filter={_filter}
              filter_id={key}
              filters={values}
              title={`${_filter.label}`}
              listWithCloseButton={true}
              selected_category={selected_category}
              addFilter={addFilter}
              products={filtered_category.items}
              mainWrapper={true}
              allFilters={filters}
              removeFilter={props.removeFilter}
              resetPageNumber={props.resetPageNumber}
              toggleFilter={props.toggleFilter}
              setToggleFilter={props.setToggleFilter}
            />
          )}
        </div>
      );
    }
    if (_filter.type === "select") {
      return (
        <div
          key={_filter.attribute_code}
          className={`filter-${_filter.attribute_code}`}
        >
          <Filter
            filter={_filter}
            title={_filter.label}
            filters={_filter.values(props)}
            filter_id={"brand"}
            addFilter={addFilter}
            products={filtered_category.items}
            resetPageNumber={props.resetPageNumber}
            toggleFilter={props.toggleFilter}
            setToggleFilter={props.setToggleFilter}
          />
        </div>
      );
    }
    if (_filter.type === "range") {
      let currentAggregation = filtersAggregations.find(
        f => f.attribute_code === _filter.attribute_code
      );
      if (_filter.initialValues.min === _filter.initialValues.max) return <></>;
      return (
        <div
          key={_filter.attribute_code}
          className={`filter-${_filter.attribute_code}`}
        >
          <Filter
            title={`Price`}
            filter={_filter}
            id={`compound-slider-filter`}
            resetPageNumber={props.resetPageNumber}
            toggleFilter={props.toggleFilter}
            setToggleFilter={props.setToggleFilter}
          >
            <CompoundSlider
              {..._filter}
              items={filtered_category.items}
              step={1}
              useInputs={false}
              valueChangeHandler={_filter.valueChangeHandler}
              addFilter={addFilter}
              useTicks={false}
              showPriceRange={true}
              id={`price-filter`}
              symbol={`$`}
            />
          </Filter>
        </div>
      );
    }
    return "";
  });
};
