import React from "react";
import { useQuery } from "@apollo/react-hooks";
// import ReactLoader from "../loader";
import Component from "./dynamicGraphQlWrapper";
import ErrorPage from "app/pages/error";

const DynamicGraphQlWrapperQueryFetchContainer = props => {
  let LoadingComponent = props.loadingComponent;

  const { loading, error, data } = useQuery(props.query, {
    variables: props.variables
  });

  if (loading)
    return (
      <div>
        {typeof LoadingComponent !== "undefined" ? <LoadingComponent /> : <></>}
      </div>
    );

  if (error) return <div>Error: {error.message}</div>;

  const children = React.Children.map(props.children, child => {
    return React.cloneElement(child, { ...props, data: data });
  });

  if (!props.isCached && typeof data !== "undefined") {
    if ("urlResolver" in data) {
      if (data.urlResolver === null) {
        return <ErrorPage {...props} />;
      } else if (
        data.urlResolver.id &&
        data.urlResolver.type === "CMS_PAGE" &&
        typeof props.queries !== "undefined" &&
        typeof props.queries[props.variables.url] !== "undefined" &&
        typeof props.queries[props.variables.url].key !== "undefined"
      ) {
        props.addQueryData(
          data.urlResolver.data,
          props.variables.url,
          data.urlResolver.id
        );
      } else if (
        data.urlResolver.id &&
        data.urlResolver.type === "CATEGORY" &&
        typeof props.queries !== "undefined" &&
        typeof props.queries[props.variables.url] !== "undefined"
      ) {
        props.addQueryData(
          { url_key: data.urlResolver.relative_url },
          props.variables.url,
          data.urlResolver.id
        );
      }
    }
  }

  return <Component {...props}>{children}</Component>;
};

export default DynamicGraphQlWrapperQueryFetchContainer;
