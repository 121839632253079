import React, { useState } from "react";
import { MDBBox } from "mdbreact";
import CheckButton from "core/components/checkButton";
import Button from "core/components/button";
import Coupon from "app/layout/coupon";

const CheckoutProcess = (props) => {
  const [newsletter, setNewsletter] = useState(0);
  let { loading, setLoading, cart, lock, showPayment } = props;
  if (typeof cart.items === "undefined") {
    return <></>;
  }

  return (
    <>
      <Coupon />
      <MDBBox className="place-order-check">
        <CheckButton
          containerClass={
            "checkbox-container check-button multiselect-how-did-u"
          }
          wrapperClass={"checkmark"}
          label={"sign up for our newsletter"}
          onChange={() => setNewsletter(!newsletter)}
          checked={newsletter}
          type={"checkbox"}
        />
      </MDBBox>
      <MDBBox className="w-100">
        <Button
          className={"w-100 right-arrow orange-button"}
          direction={"ml-3"}
          righticon={"true"}
          icon={"arrow-right"}
          disabled={
            showPayment === false
              ? true
              : cart.selected_payment_method.title === ""
              ? true
              : loading
          }
          text={`${loading ? "processing your order..." : "place order"}`}
          onClick={(e) => {
            if (!loading) {
              setLoading(true);
              lock({
                always: () => {},
                success: () => {
                  setLoading(false);
                },
                fail: () => {
                  setLoading(false);
                },
              });
              props.placeOrder();
            }
          }}
        />
      </MDBBox>
    </>
  );
};

export default CheckoutProcess;
