import React from "react";
import Input from "core/components/input";
import Breadcrumbs from "app/layout/breadcrumbs/breadcrumbs";
import {
  MDBTypography,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBBox,
  MDBIcon
} from "mdbreact";
import Button from "core/components/button";
import CheckButton from "core/components/checkButton";
import Dropdown from "core/components/dropdown";

const Typography = props => {
  return (
    <MDBContainer fluid className="text-center mt-5 pt-5 text-md-left">
      <MDBRow>
        <MDBCol xl="12" sm="4" size="2" style={{ backgroundColor: "#aeaeae" }}>
          TEST 2,4,6,8,12
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="4" style={{ backgroundColor: "#aeaeae" }}>
          TEST SIZE=4
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="6" style={{ backgroundColor: "#aeaeae" }}>
          TEST SIZE=6
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="8" style={{ backgroundColor: "#aeaeae" }}>
          TEST SIZE=8
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="12">
          <MDBTypography tag="h1">Title H1 Gotham Medium</MDBTypography>
          <MDBTypography tag="h2">Title H1 Gotham Medium</MDBTypography>
          <MDBTypography tag="h3">Title H1 Gotham Book</MDBTypography>
          <MDBTypography tag="h4">Title H1 Gotham Book</MDBTypography>
          <MDBTypography tag="h5">Title H1 Gotham Book</MDBTypography>
          <MDBBox tag="p">Paragraph</MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="12">
          <MDBBox tag="p">Orange Color Variables</MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#ff6d2d"
          }}
        >
          Primary Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#ff5100"
          }}
        >
          Menu Links Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#ff521d"
          }}
        >
          Modal Border Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#f57811"
          }}
        >
          Footer Background Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#ff9b2e"
          }}
        >
          Text Color
        </MDBBox>
      </MDBRow>
      <MDBRow>
        <MDBCol md="12">
          <MDBBox tag="p">Gray Color Variabless</MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#858b8b"
          }}
        >
          Secondary Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#646b6b"
          }}
        >
          Links Above Footer Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#b3b9b9"
          }}
        >
          Paragraph Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#ced4d2"
          }}
        >
          Filter Title Background Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#ebebeb"
          }}
        >
          Bottom Line Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#e4edf3"
          }}
        >
          Footer Block Background Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#f1f4f6"
          }}
        >
          Block With Text Background Color
        </MDBBox>
      </MDBRow>
      <MDBRow>
        <MDBCol md="12">
          <MDBBox tag="p">Black Color Variables</MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#3a3a3a",
            color: "#ffffff"
          }}
        >
          Thirdy Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#00000029",
            color: "#ffffff"
          }}
        >
          Shadow Color
        </MDBBox>
      </MDBRow>
      <MDBRow>
        <MDBCol md="12">
          <MDBBox tag="p">Basic Color Variables</MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#ffffff"
          }}
        >
          White Color
        </MDBBox>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#e21a1a"
          }}
        >
          Danger Color
        </MDBBox>
      </MDBRow>
      <MDBRow>
        <MDBCol md="12">
          <MDBBox tag="p">Sand Color Variable</MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBBox
          tag="p"
          className="square m-2"
          style={{
            backgroundColor: "#ede6d3"
          }}
        >
          Sand Color
        </MDBBox>
      </MDBRow>
      <MDBRow>
        <MDBCol md="12">
          <Breadcrumbs />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <span style={{ marginRight: 10 }}>
            <Button
              className={"right-arrow orange-button"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
              text={"Primary"}
            />
          </span>
          <span style={{ marginRight: 10 }}>
            <Button
              color={"share-transparent"}
              direction={"mr-3"}
              leftIcon={true}
              icon={"share-alt"}
              text={"Share"}
            />
          </span>
          <span style={{ marginRight: 10 }}>
            <Button
              color={"share-orange orange-button"}
              direction={"mr-3"}
              leftIcon={true}
              icon={"share-alt"}
              text={"Share"}
            />
          </span>
          <span style={{ marginRight: 10 }}>
            <Button
              className={"right-arrow gray-button"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
              text={"Gray"}
            />
          </span>
          <span style={{ marginRight: 10 }}>
            <Button
              outline={true}
              className={"right-arrow white-button"}
              text={"White"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
            />
          </span>
          <span style={{ marginRight: 10 }}>
            <Button
              outline={true}
              className={"right-arrow black-button"}
              text={"Black"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
            />
          </span>
          <span style={{ marginRight: 10 }}>
            <Button
              color="primary"
              className={"basic-btn"}
              outline={true}
              direction={"mr-3"}
              leftIcon={true}
              icon={"share-alt"}
              text={"Basic"}
            />
          </span>
          <span style={{ marginRight: 10 }}>
            <Button
              color={"orange-button btn-default without-icon"}
              icon={"share-alt"}
              children={"Added"}
            />
          </span>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <div className={`chek-buttons`} style={{ marginBottom: 40 }}>
            <CheckButton
              containerClass={"radio-container check-button"}
              label={"radio"}
              type={"radio"}
              value={"no"}
              checked={"true"}
              wrapperClass={"radio"}
            />
            <CheckButton
              containerClass={"radio-container check-button"}
              label={"radio"}
              type={"radio"}
              value={"no"}
              wrapperClass={"radio"}
            />
            <CheckButton
              containerClass={"radio-container check-button"}
              label={"radio"}
              type={"radio"}
              value={"no"}
              checked={"true"}
              wrapperClass={"radio"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CheckButton
              containerClass={"checkbox-container check-button"}
              checked={"true"}
              wrapperClass={"checkmark"}
              label={"check"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"checkbox-container check-button"}
              wrapperClass={"checkmark"}
              label={"check"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"checkbox-container check-button"}
              checked={"true"}
              wrapperClass={"checkmark gray"}
              label={"check"}
              type={"checkbox"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CheckButton
              containerClass={"switch-container check-button"}
              checked={"true"}
              wrapperClass={"slider"}
              label={"check"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"switch-container check-button"}
              wrapperClass={"slider"}
              label={"check"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"switch-container check-button"}
              checked={"true"}
              wrapperClass={"slider"}
              label={"check"}
              type={"checkbox"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CheckButton
              outline={true}
              id="exampleInput"
              size={"lg"}
              label={"Large input"}
              type={"text"}
              value={"no"}
              wrapperClass={""}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <Input
              name={``}
              type={"number"}
              wrapperClass={"quantity-field"}
              inputClass={""}
              // onKeyPress={e => {
              //   onlyNumbers(e);
              // }}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <ul className="pagination page-pagination">
              <li className="page-item active-page-item">
                <a className="pagination-button" href={"/privacy-policy"}>
                  1
                </a>
              </li>
              <li className="page-item ">
                <a className="pagination-button" href={"/privacy-policy"}>
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="pagination-button" href={"/privacy-policy"}>
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="pagination-button" href={"/privacy-policy"}>
                  <MDBIcon icon="caret-right" />
                </a>
              </li>
              <li className="page-item view-all">
                <a className="pagination-button" href={"/privacy-policy"}>
                  View all
                </a>
              </li>
            </ul>
          </div>
          <div style={{ marginBottom: 40 }}>
            <Button className={"wishlist-btn"} color={"#FF5100"}>
              <MDBIcon far icon="heart" />
              <MDBIcon icon="caret-down" />
            </Button>
          </div>
          <div style={{ marginBottom: 40 }}>
            <Button className={"compare-btn"} color={"#FF5100"}>
              <MDBIcon far icon="clone" />
              <MDBIcon icon="plus" />
            </Button>
          </div>
          <div style={{ marginBottom: 40 }}>
            <Dropdown
              className={"filter-btn"}
              dropdownText={
                <>
                  kids
                  <MDBIcon icon="plus" />
                </>
              }
              dropdownItem={
                <CheckButton
                  containerClass={"checkbox-container check-button"}
                  wrapperClass={"checkmark gray"}
                  label={"check"}
                  type={"checkbox"}
                />
              }
            />
          </div>
          <div>
            <ul className={"custom-list"}>
              <li>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                similique aliquam, ut suscipit
              </li>
              <li>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                similique aliquam, ut suscipit
              </li>
              <li>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                similique aliquam, ut suscipit
              </li>
            </ul>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Typography;
