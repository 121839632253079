import React, { memo } from "react";
import { MDBContainer, MDBCol } from "mdbreact";
import SearchInput from "./searchInput";
import SearchResults from "./searchResults";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";

const Search = memo(
  ({ open, mobileonclick, showSearch }) => {
    const isMobile = useMediaQuery({ maxWidth: 992 });
    return (
      <MDBContainer
        fluid
        id={`search-container`}
        className={`${open ? "show" : "hide"}`}
      >
        <SearchInput />
        <MDBCol className={`w-100 p-0`}>
          {open ? <SearchResults mobileonclick={mobileonclick} /> : <></>}
          {isMobile && showSearch ? (
            <SearchResults mobileonclick={mobileonclick} />
          ) : (
            <></>
          )}
        </MDBCol>
      </MDBContainer>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Search;
