import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { isEmpty } from "core/helpers/functions";
import Dropdown from "./dropdown";
import ColorPicker from "./colorPicker";
import StoragePicker from "./storagePicker";
import Checkbox from "./checkbox";
import { isUndefined } from "core/helpers/functions";

const Options = (props) => {
  let sideOptions = [];
  let storageBasedColors = [];
  let availableColors = [];
  let shearlingPrice = props.data.shearling_price || 0;
  let specialityPrice = props.data.specialty_price || 0;

  if (!isEmpty(props.data) && props.data.options) {
    sideOptions = props.data.options.filter((option) => {
      if (
        (option.__typename === "CustomizableFieldOption" &&
          option.title.toLowerCase().includes("color") &&
          !(
            option.title.toLowerCase().includes("trundle") ||
            option.title.toLowerCase().includes("drawers")
          )) ||
        option.title.toLowerCase().includes("storage drawers") ||
        // if it's hardware we need to implement same look and feel as color selectors
        option.title.toLowerCase().includes("hardware")
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  if (!isEmpty(props.data) && props.data.options) {
    storageBasedColors = props.data.options.filter((option) => {
      if (
        option.__typename === "CustomizableFieldOption" &&
        option.title.toLowerCase().includes("color") &&
        (option.title.toLowerCase().includes("trundle") ||
          option.title.toLowerCase().includes("drawers"))
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  if (props.data.getColors) {
    let colorOptions = [...sideOptions, ...storageBasedColors];
    // Map color options to color customizable Options
    colorOptions.map((colorOption) => {
      // using global regex / /g to change each space
      let colorAttributeName = colorOption.title
        .toLowerCase()
        .replace(/ /g, "_");
      if (colorAttributeName === "drawers_color") {
        colorAttributeName = "storage_" + colorAttributeName;
      }
      if (!isUndefined(props.data.getColors)) {
        if (
          !colorAttributeName.includes("_color") &&
          colorAttributeName !== "hardware"
        ) {
          colorAttributeName = colorAttributeName + "_color";
        }
        if (!isUndefined(props.data.getColors[colorAttributeName])) {
          availableColors[colorOption.option_id] =
            props.data.getColors[colorAttributeName];
        }
      }
    });
  }

  const getOptionContent = (
    option,
    price,
    stateProps,
    changeSelection,
    selections,
    selectedSizeText
  ) => {
    let optionProps = {
      option,
      price,
      stateProps,
      changeSelection,
      selections,
      shearlingPrice,
      specialityPrice,
    };

    if (
      option.title === "Storage" &&
      option.__typename === "CustomizableDropDownOption"
    ) {
      return (
        <StoragePicker
          optionProps={optionProps}
          storageBasedColors={storageBasedColors}
          availableColors={availableColors}
          selectedSizeText={selectedSizeText}
        />
      );
    }

    if (option.__typename === "CustomizableCheckboxOption") {
      return <Checkbox optionProps={optionProps} option={option} />;
    }
    if (
      option.__typename === "CustomizableDropDownOption" ||
      (!option.title.toLowerCase().includes("color") &&
        // we need to remove hardware from ever being a dropdown
        !option.title.toLowerCase().includes("hardware"))
    ) {
      return (
        <Dropdown
          key={Math.random()}
          optionProps={optionProps}
          size_guide={props.data.size_guide}
        />
      );
    }
  };

  if (
    props.productOptions.options === undefined ||
    props.productOptions.options === []
  )
    return <></>;
  if (!isEmpty(props.data)) {
    let { options } = props.data;
    let selectedSizeText = "";
    let selectedSizeId = 0;
    let { productOptions } = props;

    options.map((option) => {
      if (option.title.toLowerCase() === "size") {
        selectedSizeId = option.option_id;
        return (selectedSizeText = option.value[0].title);
      }
    });

    if (productOptions) {
      if (
        typeof productOptions.options !== "undefined" &&
        typeof productOptions.options[selectedSizeId] !== "undefined"
      ) {
        selectedSizeText = productOptions.options[selectedSizeId].title;
      }
    }

    if (options) {
      return (
        <MDBCol
          size="12"
          className="customizable-options border-bottom px-lg-5 px-0 mt-4"
        >
          {options.map((option) => {
            return getOptionContent(
              option,
              props.selectedPrice,
              props.stateProps,
              props.changeSelection,
              props.productOptions.options,
              selectedSizeText
            );
          })}
          {!isEmpty(sideOptions) && (
            <MDBRow className={`side-options-container mt-4 option-container`}>
              {sideOptions.map((sideOption, key) => {
                let sizes;
                sizes = {
                  title: 6,
                  selector: 6,
                };
                return (
                  <ColorPicker
                    optionProps={{
                      option: sideOption,
                      availableColors: availableColors,
                      stateProps: props.stateProps,
                      price: props.selectedPrice,
                      changeSelection: props.changeSelection,
                      selections: props.productOptions.options,
                    }}
                    shearlingPrice={shearlingPrice}
                    specialtyPrice={specialityPrice}
                    sizes={sizes}
                    selectorClasses={"pl-0 pr-5"}
                  />
                );
              })}
            </MDBRow>
          )}
        </MDBCol>
      );
    }
  }
  return "";
};

export default Options;
