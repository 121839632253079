import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import FavoritesStateContainer from "./favorites-state-container";

const FavoritesReduxContainer = (props) => {
  return <FavoritesStateContainer wishlist={props.wishlist} {...props} />;
};

const mapStateToProps = (state) => ({
  wishlist: state.customer.data.wishlist,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavoritesReduxContainer);
