import React, { useState, useEffect } from "react";
import { MDBBox } from "mdbreact";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { transform } from "core/components/cmsBlock/Parser";

const Elements = (props) => {
  const [screenwidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );

  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);
  const [selected, setSelected] = useState(0);

  let { data } = props;
  let cmsBlocks = {};
  if (typeof data !== "undefined" && typeof data.cmsBlocks !== "undefined") {
    cmsBlocks = data.cmsBlocks.items;
  } else {
    return "";
  }
  let cmsBlockIdentifiers = [];
  let cmsBlockContents = [];

  cmsBlocks.map((value, index) => {
    let thisCmsBlock = cmsBlocks[index];
    cmsBlockIdentifiers.push(thisCmsBlock.title.split("[")[0]);
    cmsBlockContents.push(thisCmsBlock.content);
    return thisCmsBlock;
  });

  return (
    <>
      <MDBBox className={"links-wrapper"}>
        {cmsBlockIdentifiers.map((value, index) => {
          let thisIdentifier = cmsBlockIdentifiers[index];
          let lowercaseTitle = thisIdentifier.toLowerCase();
          return (
            <React.Fragment key={`link-key-wrapper-` + index}>
              {(screenwidth < 1025 && (
                <Link
                  key={`link-key-` + index}
                  className={"list-item"}
                  to={`/${lowercaseTitle}`}
                >
                  {thisIdentifier}
                </Link>
              )) || (
                <span
                  key={`link-key-span-` + index}
                  className={`${
                    (selected === index && "list-item-active") || "list-item"
                  }`}
                  onClick={(e) => {
                    setSelected(index);
                  }}
                >
                  {thisIdentifier}
                </span>
              )}
            </React.Fragment>
          );
        })}
      </MDBBox>
      <TransitionGroup className="whats-content" component={null}>
        {cmsBlockContents.map((value, index) => (
          <CSSTransition
            key={selected}
            // in={selected}
            timeout={250}
            classNames={`item`}
          >
            <MDBBox
              key={`tranisition-whats-content-box-` + index}
              className={`content-wrapper d-flex ${index} ${selected}`}
            >
              {ReactHtmlParser(cmsBlockContents[selected], {
                decodeEntities: true,
                transform: transform,
              })}
            </MDBBox>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </>
  );
};

export default Elements;
