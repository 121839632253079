import React from "react";
import _ from "lodash";
import CheckButton from "core/components/checkButton";

export const mapWishlistRender = (
  wishlist,
  wishlistArray,
  product,
  addFunction,
  removeFunction,
  options,
  lock,
  loading,
  setLoading,
  setIsRemoving
) => {
  let productItemsRelation = {};
  let itemsInSomeWishlists = [];
  wishlist.items.map((_item) => {
    if (
      productItemsRelation[_item.product.id] === undefined &&
      _item.product.id === product.id
    )
      productItemsRelation[_item.product.id] = [];
    if (_item.product.id === product.id) {
      productItemsRelation[_item.product.id].push({
        id: _item.id,
        wishlist: _item.multi_wishlist_id,
      });
    }
    return _item;
  });

  wishlist.multiwishlist_data.map((multiwishlist) => {
    multiwishlist.items.map((mItem) => {
      itemsInSomeWishlists.push(mItem);
    });
    return multiwishlist;
  });

  return wishlistArray.map((_wishlist) => {
    let inWishlist = false;
    if (typeof productItemsRelation[product.id] !== "undefined") {
      productItemsRelation[product.id].map((item) => {
        if (item.wishlist === _wishlist.id) {
          inWishlist = true;
        }
        if (item.wishlist === 0 && _wishlist.name === "main") {
          inWishlist = true;
        }
      });
    }

    return (
      <CheckButton
        containerClass={"checkbox-container check-button mt-5"}
        wrapperClass={"checkmark gray"}
        checked={inWishlist}
        onChange={(e) => {
          e.preventDefault();
          if (!loading) {
            setLoading(true);
            lock({
              always: (e) => {
                // setLoading(true);
              },
              fail: (e) => {
                setLoading(false);
              },
              success: (e) => {
                setLoading(false);
              },
            });
            let id = _wishlist.id;
            if (!inWishlist) {
              setIsRemoving(false);
              addFunction(id, product.id, options);
            } else {
              let itemForRemoval = productItemsRelation[product.id].find(
                (item) => {
                  if (_wishlist.name === "main") {
                    if (item.wishlist === 0) {
                      return { id: item.id };
                    }
                  }
                  return item.wishlist === _wishlist.id;
                }
              );
              if (typeof itemForRemoval !== "undefined") {
                setIsRemoving(true);
                removeFunction(itemForRemoval.id);
              }
            }
          }
        }}
        label={_wishlist.name === "main" ? "favorites" : _wishlist.name}
        type={"checkbox"}
      />
    );
  });
};
