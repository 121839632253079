// logic is inversed, true means that an error exists

export const validate = (
  firstname,
  lastname,
  email,
  confirm_email,
  password,
  confirm_password,
  terms
) => {
  return {
    firstname: firstname.length === 0,
    lastname: lastname.length === 0,
    email: email.length === 0 || ValidateEmail(email),
    confirm_email: email !== confirm_email,
    password: password.length < 8,
    confirm_password: password !== confirm_password,
    terms: terms === false,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return false;
  }
  return true;
}
