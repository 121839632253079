import React, { useEffect, useState } from "react";
import { MDBContainer, MDBLink, MDBCol, MDBRow } from "mdbreact";
/**Hovered dropdown is the dropdown that is currently hovered */
const HeaderDropdown = (props) => {
  let {
    menuItems,
    hoveredDropdown,
    position,
    setPosition,
    showDropdown,
    setShowDropdown,
  } = props;

  const [navigationPosition, setNavigationPosition] = useState(0);

  let itemsPerRow = 6;
  let itemNum = 0;
  if (menuItems[hoveredDropdown]) {
    itemNum = menuItems[hoveredDropdown].children.length;
    if (itemNum === itemsPerRow + 1) {
      itemsPerRow = itemsPerRow - 1;
    }
  }
  let extended = itemNum > itemsPerRow ? 2 : 1;
  let maxDropdownWidth = position + navigationPosition + 140 * extended;
  let height = 30.8;
  let maxDropdownHeight =
    itemNum >= itemsPerRow
      ? itemsPerRow * height
      : (itemNum % itemsPerRow) * height;

  useEffect(() => {
    if (
      hoveredDropdown !== false &&
      typeof document.getElementById("nav-dropdown-" + hoveredDropdown) !== null
    ) {
      let navigation = document
        .getElementById("main-navigation-header")
        .getBoundingClientRect();
      setNavigationPosition(navigation.left);

      let element = document.getElementById("nav-dropdown-" + hoveredDropdown);
      if (element) {
        element = element.getBoundingClientRect();
        setPosition(element.left);
      }
    }
  }, [position, hoveredDropdown, setNavigationPosition]);

  return (
    <MDBContainer
      id={`navigation-dropdown`}
      className={`flex-column justify-content-start align-items-start position-fixed ${
        (showDropdown && "show") || "hidden"
      } pl-5 `}
      style={{
        maxHeight: (showDropdown !== false && maxDropdownHeight + 15) || 0,
        maxWidth: maxDropdownWidth,
        padding: "40 0 23",
        left: navigationPosition - 28,
      }}
      onMouseEnter={(e) => {
        e.preventDefault();
        setShowDropdown(true);
      }}
      onMouseLeave={(e) => {
        setShowDropdown(false);
      }}
    >
      <MDBRow className={`align-items-start`}>
        {Object.keys(menuItems).map((value, index) => {
          if (index !== hoveredDropdown) return;
          let menuItem = menuItems[index];
          let children = menuItem.children;

          return (
            children.length > 0 && (
              <MDBCol
                key={`nav-col-${index}`}
                className={`nav-menu-items ${menuItem.name.toLowerCase()} align-items-start d-flex flex-column flex-wrap px-0 pt-0`}
                style={{
                  left: position - navigationPosition,
                  maxHeight: maxDropdownHeight,
                }}
              >
                {Object.keys(children).map((childIndex) => {
                  let childItem = children[childIndex];
                  return (
                    <div key={`nav-col-item-${childIndex}`} className="item">
                      <MDBLink
                        to={`/${childItem.url_path}`}
                        key={`menu-item-${childItem.id}`}
                        onClick={(e) => {
                          setShowDropdown(false);
                        }}
                        className={`p-0 py-2 pl-md-0 pr-md-0 pt-0 px-3 text-decoration-none`}
                      >
                        {childItem.name}
                      </MDBLink>
                    </div>
                  );
                })}
              </MDBCol>
            )
          );
        })}
      </MDBRow>
    </MDBContainer>
  );
};

export default HeaderDropdown;
