import React from "react";
import Controls from "./controls";

const ControlsStateContainer = (props) => {
  let product = props.data;
  let initialSelections = {
    selectedPrice: product.price_range.minimum_price.final_price.value,
    totalPrice: product.price_range.minimum_price.final_price.value,
  };
  let { options } = product;
  let dependencies = {};

  if (options) {
    options.map((option) => {
      if (
        (option.title.toLowerCase().includes("storage") ||
          option.title.toLowerCase().includes("trundle") ||
          option.title.toLowerCase().includes("drawers")) &&
        !option.title.toLowerCase().includes("color") &&
        !option.title.toLowerCase().includes("storage drawers")
      ) {
        option.value.map((optionValue) => {
          options.map((o) => {
            let optionValueFragments = optionValue.title.split(/\ /g);
            let finalOptionValueFragment = optionValueFragments[
              optionValueFragments.length - 1
            ].toLowerCase();

            if (o.title.toLowerCase().includes(finalOptionValueFragment)) {
              if (typeof dependencies[o.option_id] === "undefined") {
                dependencies[o.option_id] = {};
                dependencies[o.option_id][option.option_id] = [];
              }
              dependencies[o.option_id] = {
                [option.option_id]: [
                  ...dependencies[o.option_id][option.option_id],
                  optionValue.option_type_id,
                ],
              };
            }
          });
        });
      }
    });
  }

  // Fill initial state
  if (options) {
    if (props.editMode) {
      let cartItemOptions = props.cartItem.customizable_options;
      options.map((option) => {
        if (option.__typename == "CustomizableDropDownOption") {
          let foundOption = false;
          let foundOptionObject = {};
          if (typeof cartItemOptions !== "undefined") {
            foundOption = cartItemOptions.find((x) => x.label === option.title);
            foundOptionObject = {
              title: foundOption.values[0].label,
              option_type_id: parseInt(foundOption.values[0].value),
              price: foundOption.values[0].price.value,
              price_type: foundOption.values[0].price.type,
              sku: null,
              __typename: "CustomizableDropDownValue",
            };
          }

          if (foundOption) {
            initialSelections = {
              ...initialSelections,
              [option.option_id]: foundOptionObject,
              selectedPrice:
                initialSelections.selectedPrice +
                foundOption.values[0].price.value,
              totalPrice:
                initialSelections.totalPrice +
                foundOption.values[0].price.value,
            };
          } else {
            initialSelections = {
              ...initialSelections,
              [option.option_id]: option.value[0],
              selectedPrice:
                initialSelections.selectedPrice + option.value[0].price,
              totalPrice:
                initialSelections.totalPrice +
                foundOption.values[0].price.value,
            };
          }
        } else if (option.__typename === "CustomizableCheckboxOption") {
          let foundOption = false;
          let foundOptionObject = {};
          if (typeof cartItemOptions !== "undefined") {
            foundOption = cartItemOptions.find((x) => x.label === option.title);
            if (typeof foundOption !== "undefined" && foundOption) {
              foundOptionObject = {
                title: foundOption.values[0].label,
                option_type_id: parseInt(foundOption.values[0].value),
                price: foundOption.values[0].price.value,
                price_type: foundOption.values[0].price.type,
                sku: null,
                __typename: "CustomizableCheckboxOption",
              };
            }
          }
          if (typeof foundOption !== "undefined" && foundOption) {
            initialSelections = {
              ...initialSelections,
              [option.option_id]: foundOptionObject,
              selectedPrice:
                initialSelections.selectedPrice +
                foundOption.values[0].price.value,
              totalPrice:
                initialSelections.totalPrice +
                foundOption.values[0].price.value,
            };
          } else {
            initialSelections = {
              ...initialSelections,
              [option.option_id]: false,
              selectedPrice: initialSelections.selectedPrice,
              totalPrice: initialSelections.totalPrice,
            };
          }
        } else if (option.__typename == "CustomizableFieldOption") {
          let foundCartItemOption = cartItemOptions.find(
            (cartItemOption) => cartItemOption.label === option.title
          );
          if (typeof foundCartItemOption === "undefined") {
            initialSelections = {
              ...initialSelections,
              [option.option_id]: {
                color_hex: null,
                swatch_name: null,
                swatch_image: undefined,
                type: "classic",
              },
            };
          } else {
            let selectedValue = foundCartItemOption.values[0];
            let realColorKey = Object.keys(product.getColors).find((color) => {
              return color.includes(
                option.title.toLowerCase().replace(/\ /g, "_")
              );
            });
            let allRealColors = product.getColors[realColorKey];
            let selectedOption = allRealColors.find((realColor) => {
              return realColor.swatch_name === selectedValue.value;
            });

            initialSelections = {
              ...initialSelections,
              [option.option_id]: selectedOption,
            };
          }
        }
      });
    } else {
      options.map((option) => {
        if (option.__typename == "CustomizableDropDownOption") {
          initialSelections = {
            ...initialSelections,
            [option.option_id]: option.value[0],
            selectedPrice:
              initialSelections.selectedPrice + option.value[0].price,
            totalPrice: initialSelections.totalPrice + option.value[0].price,
          };
        } else if (option.__typename === "CustomizableCheckboxOption") {
          initialSelections = {
            ...initialSelections,
            [option.option_id]: false,
            selectedPrice: initialSelections.selectedPrice,
            totalPrice: initialSelections.totalPrice,
          };
        } else if (option.__typename == "CustomizableFieldOption") {
          let defaultAttributeCode =
            option.title.toLowerCase().replace(" ", "_") + "_default";
          if (
            product.getColors[defaultAttributeCode] &&
            product.getColors[defaultAttributeCode].length !== 0
          ) {
            let defaultColor = product.getColors[defaultAttributeCode];
            if (defaultColor) {
              defaultColor = defaultColor[0];
            }
            initialSelections = {
              ...initialSelections,
              [option.option_id]: { ...defaultColor },
            };
          } else {
            initialSelections = {
              ...initialSelections,
              [option.option_id]: {
                color_hex: "#fff",
                swatch_name: null,
                swatch_image: undefined,
                type: "classic",
              },
            };
          }
        }
      });
    }
  }

  if (typeof props.productOptions.options === "undefined") {
    props.reduceProductOptions({
      options: initialSelections,
      sku: props.data.sku,
    });
  } else if (
    product.sku !== props.productOptions.sku ||
    product.price_range.minimum_price.final_price.value !==
      props.productOptions.options.totalPrice
  ) {
    props.reduceProductOptions({
      options: initialSelections,
      sku: props.data.sku,
    });
  }

  return <Controls {...props} dependencies={dependencies} />;
};

export default ControlsStateContainer;
