import React from "react";
import { connect } from "react-redux";
import { commonActions } from "core/state/redux/data/common";
import messageActions from "core/state/redux/data/messages/actions";
import CreateStateContainer from "./create-state-container";
import { customerActions } from "core/state/redux/data/customer";

const CreateReduxContainer = props => {
  return (
    <CreateStateContainer
      activeWishlist={props.activeWishlist}
      wishlist={props.wishlist}
      lock={props.lock}
      createProject={props.createProject}
      deleteProject={props.deleteProject}
      addMessage={props.addMessage}
      setActiveWishlist={props.setActiveWishlist}
      wrapperClass={props.wrapperClass}
      target={props.target}
      text={props.text}
      loadingtext={props.loadingtext}
    />
  );
};

const mapStateToProps = state => ({
  wishlist: state.customer.data.wishlist
});

const mapDispatchToProps = dispatch => {
  return {
    lock: callback => {
      dispatch(commonActions.lock(callback));
    },
    addMessage: (text, type, target) => {
      dispatch(messageActions.addMessage(text, type, target));
    },
    createProject: name => {
      dispatch(customerActions.createWishlist(name));
    },
    deleteProject: id => {
      dispatch(customerActions.deleteWishlist(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReduxContainer);
