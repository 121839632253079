import React from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import Price from "core/components/price";
import Icon from "app/assets/icon/icon";
import { ModalConsumer } from "core/components/modal/ModalContext";
import OrderSummaryInformation from "./orderSummaryInformation";

const MobileOrderSummary = ({ cart }) => {
  let cartQuantity = 0;
  if (typeof cart.items === "undefined") return <></>;
  cart.items.map((item) => {
    return (cartQuantity = cartQuantity + item.quantity);
  });
  return (
    <MDBContainer id={`mobile-order-summary-container`} fluid>
      <ModalConsumer value={{ useWrapper: true }}>
        {({ showModal, hideModal }) => (
          <MDBRow
            onClick={(e) => {
              showModal(() => {
                return <OrderSummaryInformation hideModal={hideModal} />;
              });
            }}
          >
            <MDBCol className={`mobile-order-summary-order-total`}>
              <MDBRow>
                <MDBCol>order total</MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <Price price={cart.prices.grand_total.value} type={`fixed`} />
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol
              className={`mobile-order-summary-cart-items d-flex align-items-center`}
            >
              <MDBRow className={`w-100`}>
                <MDBCol className={`d-flex justify-content-end p-0`}>
                  <Icon icon={"cartOrange"} />
                  <div className={`span`}>{cartQuantity}</div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )}
      </ModalConsumer>
    </MDBContainer>
  );
};

export default MobileOrderSummary;
