import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ForgotPassword from "./forgotPassword";
import { customerActions } from "core/state/redux/data/customer";
import { isUndefined, isEmpty } from "core/helpers/functions";
import commonActions from "core/state/redux/data/common/actions";

const ForgotPasswordReduxContainer = props => {
  if (!isEmpty(props.customer)) {
    if (
      !isUndefined(props.customer) &&
      !isUndefined(props.customer.data.resetPassword) &&
      props.customer.data.resetPassword === true
    ) {
      props.resetPasswordSuccess(false);
      return <Redirect to={"/"} />;
    }
  }

  return <ForgotPassword {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer,
  loading: state.customer.loading
});

const mapDispatchToProps = dispatch => {
  return {
    onForgotPassword: email => {
      dispatch(customerActions.forgotCustomerPassword(email));
    },
    resetPassword: (token, password, confirmPassword) => {
      dispatch(commonActions.lock());
      dispatch(
        customerActions.resetCustomerPassword(token, password, confirmPassword)
      );
    },
    resetPasswordSuccess: value => {
      dispatch(customerActions.resetCustomerPasswordSuccess(value));
    },
    unlock: () => {
      dispatch(commonActions.unlock());
    },
    lock: callback => {
      dispatch(commonActions.lock(callback));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordReduxContainer);
