import React, { useState } from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import SubscribeStateContainer from "./subscribe-state-container";
import { messagesActions } from "core/state/redux/data/messages";
import gql from "graphql-tag";
import { isEmpty } from "core/helpers/functions";
import apolloClient from "core/graphql/apolloClient";

const IS_EMAIL_SUBSCRIBED = gql`
  query isEmailSubscribed($email: String!) {
    isEmailSubscribed(email: $email) {
      is_subscribed
    }
  }
`;
const SubscribeReduxContainer = props => {
  const [subscribe, setSubscribe] = useState(false);
  const [subscribe2, setSubscribe2] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [countryValue, setCountryValue] = useState({
    label: "United States",
    value: "US"
  });
  const [client, setClient] = useState({
    label: ["Client Type"],
    value: ["1"]
  });
  const [describe, setDescribe] = useState({
    label: "what best describes you?",
    value: "0"
  });
  const [stateValue, setStateValue] = useState("");
  const [company, setCompany] = useState("");
  let [zipcode, setZipcode] = useState("");
  const [error, setError] = useState(false);
  const state = {
    subscribe,
    setSubscribe,
    email,
    setEmail,
    subscribe2,
    setSubscribe2,
    firstname,
    setFirstName,
    lastname,
    setLastName,
    countryValue,
    setCountryValue,
    client,
    setClient,
    stateValue,
    setStateValue,
    company,
    setCompany,
    zipcode,
    setZipcode,
    describe,
    setDescribe,
    error,
    setError
  };

  const isEmailSubscribed = async () => {
    const { data } = await apolloClient.mutate({
      mutation: IS_EMAIL_SUBSCRIBED,
      variables: { email: state.email }
    });

    if (data === undefined) {
      return false;
    } else {
      if (data !== undefined && !isEmpty(data))
        return data.isEmailSubscribed.is_subscribed;
    }
  };

  const validateEmailField = () => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <SubscribeStateContainer
      newsletterCustomerSubscribe={props.newsletterCustomerSubscribe}
      validateEmailField={validateEmailField}
      isEmailSubscribed={isEmailSubscribed}
      {...props}
      state={state}
    />
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    newsletterCustomerSubscribe: (
      email,
      first_name,
      last_name,
      company,
      what_describes_you,
      country,
      state,
      zip
    ) => {
      dispatch(
        customerActions.newsletterCustomerSubscribe(
          email,
          first_name,
          last_name,
          company,
          what_describes_you,
          country,
          state,
          zip
        )
      );
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribeReduxContainer);
