export default {
  countries: [
    {
      id: "US",
      available_regions: [
        {
          id: 1,
          name: "Alabama",
          code: "AL",
          __typename: "Region",
        },
        {
          id: 4,
          name: "Arizona",
          code: "AZ",
          __typename: "Region",
        },
        {
          id: 5,
          name: "Arkansas",
          code: "AR",
          __typename: "Region",
        },
        {
          id: 12,
          name: "California",
          code: "CA",
          __typename: "Region",
        },
        {
          id: 13,
          name: "Colorado",
          code: "CO",
          __typename: "Region",
        },
        {
          id: 14,
          name: "Connecticut",
          code: "CT",
          __typename: "Region",
        },
        {
          id: 15,
          name: "Delaware",
          code: "DE",
          __typename: "Region",
        },
        {
          id: 16,
          name: "District of Columbia",
          code: "DC",
          __typename: "Region",
        },
        {
          id: 18,
          name: "Florida",
          code: "FL",
          __typename: "Region",
        },
        {
          id: 19,
          name: "Georgia",
          code: "GA",
          __typename: "Region",
        },
        {
          id: 22,
          name: "Idaho",
          code: "ID",
          __typename: "Region",
        },
        {
          id: 23,
          name: "Illinois",
          code: "IL",
          __typename: "Region",
        },
        {
          id: 24,
          name: "Indiana",
          code: "IN",
          __typename: "Region",
        },
        {
          id: 25,
          name: "Iowa",
          code: "IA",
          __typename: "Region",
        },
        {
          id: 26,
          name: "Kansas",
          code: "KS",
          __typename: "Region",
        },
        {
          id: 27,
          name: "Kentucky",
          code: "KY",
          __typename: "Region",
        },
        {
          id: 28,
          name: "Louisiana",
          code: "LA",
          __typename: "Region",
        },
        {
          id: 29,
          name: "Maine",
          code: "ME",
          __typename: "Region",
        },
        {
          id: 31,
          name: "Maryland",
          code: "MD",
          __typename: "Region",
        },
        {
          id: 32,
          name: "Massachusetts",
          code: "MA",
          __typename: "Region",
        },
        {
          id: 33,
          name: "Michigan",
          code: "MI",
          __typename: "Region",
        },
        {
          id: 34,
          name: "Minnesota",
          code: "MN",
          __typename: "Region",
        },
        {
          id: 35,
          name: "Mississippi",
          code: "MS",
          __typename: "Region",
        },
        {
          id: 36,
          name: "Missouri",
          code: "MO",
          __typename: "Region",
        },
        {
          id: 37,
          name: "Montana",
          code: "MT",
          __typename: "Region",
        },
        {
          id: 38,
          name: "Nebraska",
          code: "NE",
          __typename: "Region",
        },
        {
          id: 39,
          name: "Nevada",
          code: "NV",
          __typename: "Region",
        },
        {
          id: 40,
          name: "New Hampshire",
          code: "NH",
          __typename: "Region",
        },
        {
          id: 41,
          name: "New Jersey",
          code: "NJ",
          __typename: "Region",
        },
        {
          id: 42,
          name: "New Mexico",
          code: "NM",
          __typename: "Region",
        },
        {
          id: 43,
          name: "New York",
          code: "NY",
          __typename: "Region",
        },
        {
          id: 44,
          name: "North Carolina",
          code: "NC",
          __typename: "Region",
        },
        {
          id: 45,
          name: "North Dakota",
          code: "ND",
          __typename: "Region",
        },
        {
          id: 47,
          name: "Ohio",
          code: "OH",
          __typename: "Region",
        },
        {
          id: 48,
          name: "Oklahoma",
          code: "OK",
          __typename: "Region",
        },
        {
          id: 49,
          name: "Oregon",
          code: "OR",
          __typename: "Region",
        },
        {
          id: 51,
          name: "Pennsylvania",
          code: "PA",
          __typename: "Region",
        },
        {
          id: 53,
          name: "Rhode Island",
          code: "RI",
          __typename: "Region",
        },
        {
          id: 54,
          name: "South Carolina",
          code: "SC",
          __typename: "Region",
        },
        {
          id: 55,
          name: "South Dakota",
          code: "SD",
          __typename: "Region",
        },
        {
          id: 56,
          name: "Tennessee",
          code: "TN",
          __typename: "Region",
        },
        {
          id: 57,
          name: "Texas",
          code: "TX",
          __typename: "Region",
        },
        {
          id: 58,
          name: "Utah",
          code: "UT",
          __typename: "Region",
        },
        {
          id: 59,
          name: "Vermont",
          code: "VT",
          __typename: "Region",
        },
        {
          id: 61,
          name: "Virginia",
          code: "VA",
          __typename: "Region",
        },
        {
          id: 62,
          name: "Washington",
          code: "WA",
          __typename: "Region",
        },
        {
          id: 63,
          name: "West Virginia",
          code: "WV",
          __typename: "Region",
        },
        {
          id: 64,
          name: "Wisconsin",
          code: "WI",
          __typename: "Region",
        },
        {
          id: 65,
          name: "Wyoming",
          code: "WY",
          __typename: "Region",
        },
      ],
      full_name_locale: "United States",
      full_name_english: "United States",
      __typename: "Country",
    },
  ],
};
