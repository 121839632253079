// logic is inversed, true means that an error exists

export const validate = (
  firstname,
  lastname,
  phone,
  email,
  password,
  confirm_password,
  mask
) => {
  return {
    firstname: firstname.length === 0,
    lastname: lastname.length === 0,
    phone: phone.length === 0 || phone.length !== mask.length,
    email: email.length === 0 || ValidateEmail(email),
    password: password.length < 6 || password !== confirm_password
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return false;
  }
  return true;
}

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
