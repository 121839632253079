import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import CheckoutPaymentMethodStateContainer from "./state-container";

const CheckoutPaymentMethodReduxContainer = (props) => {
  return <CheckoutPaymentMethodStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  cart_grand_total:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.prices !== "undefined" &&
    typeof state.customer.data.cart.prices.grand_total !== "undefined" &&
    typeof state.customer.data.cart.prices.grand_total.value !== "undefined"
      ? state.customer.data.cart.prices.grand_total.value
      : {},
  shipping_address:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.shipping_addresses !== "undefined" &&
    state.customer.data.cart.shipping_addresses.length > 0 &&
    typeof state.customer.data.cart.shipping_addresses[0] !== "undefined"
      ? state.customer.data.cart.shipping_addresses[0]
      : {},
  available_payment_methods:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.available_payment_methods !== "undefined"
      ? state.customer.data.cart.available_payment_methods
      : [],
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    addMessage: (text, type) => {
      dispatch(messagesActions.addMessage(text, type));
    },
    setPaymentMethod: (payment_method) => {
      if (payment_method.code === "ebizcharge_ebizcharge") {
        dispatch(customerActions.setPaymentMethodAndPlaceOrder(payment_method));
      } else if (
        payment_method.method_code === "checkmo" ||
        payment_method.method_code === "terms"
      ) {
        dispatch(
          customerActions.setPaymentMethodOnCart({
            code: payment_method.method_code,
          })
        );
      } else {
        dispatch(
          customerActions.setPaymentMethodOnCart({
            code: payment_method.method_code,
            tokenbase_data: {
              cc_last4: payment_method.cc_last_4,
              acceptjs_key: payment_method.dataDescriptor,
              acceptjs_value: payment_method.dataValue,
            },
          })
        );
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutPaymentMethodReduxContainer);
