import React, { useState } from "react";
import Listing from "./listing";
const ListingStateContainer = props => {
  const [productPerPage, setProductPerPage] = useState({
    label: 10,
    value: 10
  });
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <Listing
      {...props}
      productPerPage={productPerPage}
      setProductPerPage={setProductPerPage}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
    />
  );
};

export default ListingStateContainer;
