import React, { memo } from "react";
import { Redirect } from "react-router-dom";
import ShopLookDetail from "./shopLookDetail";
import _ from "lodash";

const ShopTheLookDetailDataContainer = memo(
  ({ data, url }) => {
    if (_.isUndefined(data) || _.isUndefined(data.getAllGalleryData))
      return <></>;

    let allGalleryData = data.getAllGalleryData;
    let result = _.find(allGalleryData, (item, index) => {
      return (
        item.name
          .split(" ")
          .join("-")
          .toLowerCase()
          .replace(/\-$/, "") +
          "-" +
          index ===
        url
      );
    });
    if (_.isUndefined(result)) return <Redirect to={`/shop-the-look`} />;
    return <ShopLookDetail data={result} />;
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default ShopTheLookDetailDataContainer;
