import React, { memo } from "react";
import { MDBCol } from "mdbreact";
import { isEqual } from "lodash";
import CheckboxItem from "./checkboxItem";

const Checkbox = memo(
  ({
    sizes,
    option,
    state,
    setState,
    reduceProductOptions,
    product,
    optionProps,
  }) => {
    return (
      <MDBCol lg={sizes.selector} size="6" className="pl-0">
        {option.value.map((value) => {
          return (
            <CheckboxItem
              key={`checkbox-${option.title}-value-${value.option_type_id}`}
              value={value}
              option={option}
              state={state}
              setState={setState}
              reduceProductOptions={reduceProductOptions}
              product={product}
              optionProps={optionProps}
            />
          );
        })}
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Checkbox;
