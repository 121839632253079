export const determineIfInCartOrWishlist = (items, sku) => {
    if (typeof items === "undefined" || items.length === 0)
      return false;
  
    let foundFinishCartId = items.find(
      item => item.product.sku === sku
    );
  
    if (typeof foundFinishCartId === "undefined") return false;
  
    return foundFinishCartId.id ? true : false;
  };