import React, { useState } from "react";
import Cart from "./cart";

const CartStateContainer = props => {
  const [state, setState] = useState({
    items:
      typeof props.customer.data.cart !== "undefined"
        ? props.customer.data.cart.items.map(item => ({
            id: item.id,
            quantity: item.quantity
          }))
        : []
  });

  const [discountCode, setDiscountCode] = useState("");

  const setItems = id => e => {
    e.preventDefault();
    if (Number.parseInt(e.target.value) && e.target.value > 0) {
      setState({
        items: state.items.map(item => ({
          id: item.id,
          quantity:
            item.id === id ? Number.parseInt(e.target.value) : item.quantity
        }))
      });
    }
  };

  const increaseItems = id => {
    setState({
      items: state.items.map(item => ({
        id: item.id,
        quantity: item.id === id ? item.quantity + 1 : item.quantity
      }))
    });
  };

  const decreaseItems = id => {
    setState({
      items: state.items.map(item => ({
        id: item.id,
        quantity:
          item.id === id && item.quantity > 0
            ? item.quantity - 1
            : item.quantity
      }))
    });
  };
  let stateProps = {
    increaseItems,
    decreaseItems,
    discountCode,
    setDiscountCode,
    setItems
  };

  return <Cart {...props} itemQuantities={state.items} {...stateProps} />;
};

export default CartStateContainer;
