import React, { useState, useEffect } from "react";
import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBModalHeader,
  MDBModalBody,
  MDBBox,
  MDBModal,
} from "mdbreact";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import Select2 from "core/components/select";
import HeroImage from "app/layout/heroImage";
import MaskedInput from "react-text-mask";
import HeroImageSrc from "app/assets/images/register/ducduc-register-trade.jpg";
import HeroImageSrcMobile from "app/assets/images/register/ducduc-register-trade-mobile.png";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { findMaskByCountry } from "app/pages/customer/register/b2b/b2bStepThree/functions";
import { isEmpty } from "core/helpers/functions";
import TitleDescription from "app/layout/titleDescription";
import { validate, shouldMarkError } from "./validation";
import SessionMessages from "core/components/sessionMessages";
import SeoHelmet from "app/layout/seoHelmet";

let countriesData = [];
const ProductRegistration = (props) => {
  const generateSanitizedMaskArray = (object) => {
    return Object.keys(object).map((key) => {
      let regexChar = object[key];
      if (object[key] === "+") regexChar = "+";
      if (object[key] === "#") regexChar = /\d/;
      return regexChar;
    });
  };
  const [openModal, setOpenModal] = useState(false);

  const openModalFn = (e) => {
    setOpenModal(!openModal);
  };
  const toggle2 = () => {
    setOpenModal(false);
    setOpenModal(!openModal);
  };
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [modelName, setModelName] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [countrySelect, setCountrySelect] = useState({
    label: "United States",
    value: "US",
  });
  const [regionsData, setRegionsData] = useState(props.regionsDataObject);
  const [mask, setMask] = useState(findMaskByCountry("US"));
  const [manufactureDate, setManufactureDate] = useState("");
  const [selectState, setSelectState] = useState("");
  const [maskSanitizedArray, setMaskSanitizedArray] = useState(
    generateSanitizedMaskArray(findMaskByCountry("US").mask.split(""))
  );

  const updateRegion = (value, type) => {
    let data = getRegionData(countriesData, value);
    setRegionsData(data);
  };
  let errors = validate(
    modelName,
    modelNumber,
    manufactureDate,
    firstName,
    lastName,
    email,
    countrySelect,
    address1,
    city,
    selectState,
    zipCode
  );
  let { countries } = props;
  const [touched, setTouched] = useState({
    modelname: false,
    modelnumber: false,
    manufacturedate: false,
    firstname: false,
    lastname: false,
    email: false,
    countryselect: false,
    address1: false,
    city: false,
    selectstate: false,
    zipcode: false,
  });
  const validateInput = () => {
    let isTrue = true;
    setTouched({
      modelname: true,
      modelnumber: true,
      manufacturedate: true,
      firstname: true,
      lastname: true,
      email: true,
      countryselect: true,
      address1: true,
      city: true,
      selectstate: true,
      zipcode: true,
    });
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (
        item === "zipcode" &&
        !(countrySelect.value === "US" || countrySelect.value === "CA")
      ) {
        error = false;
      }
      if (
        item === "selectstate" &&
        !(countrySelect.value === "US" || countrySelect.value === "CA")
      ) {
        error = false;
      }
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    setZipCodeError(zipMask.length !== zipCode.length);
    if (zipCodeError) {
      isTrue = false;
    }

    if (isTrue) {
      if (countrySelect.value !== "") {
        setLoading(true);
        props.lock({
          always: (e) => {
            setLoading(true);
          },
          success: (e) => {
            setOpenModal(true);
            setLoading(false);
          },
          fail: (e) => {
            setLoading(false);
          },
        });
        props.sendEmail({
          input: {
            attributes: [
              { code: "modelname", value: modelName },
              { code: "modelnumber", value: modelNumber },
              { code: "manufactureDate", value: manufactureDate },
              { code: "firstName", value: firstName },
              { code: "lastName", value: lastName },
              { code: "phone", value: phone },
              { code: "email", value: email },
              { code: "countryselect", value: countrySelect.value },
              { code: "address1", value: address1 },
              { code: "address2", value: address2 },
              { code: "city", value: city },
              {
                code: "selectstate",
                value: !(
                  countrySelect.value === "US" || countrySelect.value === "CA"
                )
                  ? selectState
                  : selectState.value,
              },
              { code: "zipCode", value: zipCode },
            ],
            template: "15",
          },
        });
      }
    } else {
      props.addMessage("Please fill all required fields marked in red.");
    }
  };
  const setModelNameFn = (e) => {
    setModelName(e.target.value);
  };
  const setModelNumberFn = (e) => {
    setModelNumber(e.target.value);
  };
  const setManufactureDateFn = (e) => {
    setManufactureDate(e.target.value);
  };
  const setFirstNameFn = (e) => {
    setFirstName(e.target.value);
  };
  const setLastNameFn = (e) => {
    setLastName(e.target.value);
  };
  const setPhoneFn = (e) => {
    setPhone(e.target.value);
  };
  const setEmailFn = (e) => {
    setEmail(e.target.value);
  };
  const setAddress1Fn = (e) => {
    setAddress1(e.target.value);
  };
  const setAddress2Fn = (e) => {
    setAddress2(e.target.value);
  };
  const setCityFn = (e) => {
    setCity(e.target.value);
  };
  function setSelectStateFn(evt) {
    setSelectState(evt);
  }
  const setZipCodeFn = (e) => {
    if (e.target.value.length <= zipMask.length) setZipCode(e.target.value);
  };

  let countryDataObject = getCountries(countries);
  countriesData = Object.keys(countryDataObject).map((key) => {
    return countryDataObject[key];
  });
  const setCountrySelectHandler = (event, type) => {
    let value = event;
    let mask = [];
    mask = findMaskByCountry(value.value);
    setMask(mask);
    setCountrySelect(value);

    updateRegion(value, type);
    let maskSanitized = mask.mask.split("");
    setMaskSanitizedArray(generateSanitizedMaskArray(maskSanitized));
  };

  const [zipCodeError, setZipCodeError] = useState(false);

  const [zipMask, setZipMask] = useState([/\d/, /\d/, /\d/, /\d/, /\d/]);
  useEffect(() => {
    if (countrySelect.value === "CA")
      setZipMask([/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]);
    else setZipMask([/\d/, /\d/, /\d/, /\d/, /\d/]);
  }, [countrySelect.value]);

  return (
    <>
      <div className="img item d-lg-block d-none desktop-image">
        <HeroImage
          id={"product-registration-img"}
          className="b2b-banner"
          alt="Shop The Look Banner"
        />
      </div>
      <div className="img item d-lg-none d-block mobile-image">
        <HeroImage
          banner={HeroImageSrcMobile}
          className="b2b-banner"
          alt="Shop The Look Banner"
        />
      </div>

      <SeoHelmet
        url={"/product-registrations"}
        title={"product registration"}
        description={"product registration"}
        image={HeroImageSrc}
      />
      <MDBContainer
        id="trade-register-B2C"
        className={"page-main-content text-center w-100 product-register"}
      >
        <TitleDescription
          title="product registration"
          description={
            <>
              <span>
                PRODUCT REGISTRATION FOR SAFETY ALERT OR RECALL ONLY.{" "}
              </span>
              We will use the information provided on this page only to contact
              you if there is a safety alert or recall for this product. We will
              not sell, rent, or share your personal information. If you
              register on this Web site you do not need to fill out the card
              that came with your product.
            </>
          }
        ></TitleDescription>
        <MDBRow className={"create-account-form"}>
          <MDBCol lg="6">
            <div className="grey-text product-details">
              <span className="span-big"> product details </span>
              <InputField
                className={`custom-input ${
                  shouldMarkError("modelname", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("modelname", errors, touched)
                    ? "model name is required*"
                    : "model name*"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, modelname: true });
                }}
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={modelName !== undefined ? modelName : ""}
                onChange={setModelNameFn}
              />
              <InputField
                className={`custom-input ${
                  shouldMarkError("modelnumber", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("modelnumber", errors, touched)
                    ? "model number is required*"
                    : "model number*"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, modelnumber: true });
                }}
                group
                type="number"
                validate
                error="wrong"
                success="right"
                value={modelNumber !== undefined ? modelNumber : ""}
                onChange={setModelNumberFn}
              />
              <InputField
                label={`${
                  shouldMarkError("manufacturedate", errors, touched)
                    ? "Manufacture date is required*"
                    : "Manufacture date*"
                }`}
                group
                type="number"
                validate
                error="wrong"
                success="right"
                value={manufactureDate !== undefined ? manufactureDate : ""}
                onChange={setManufactureDateFn}
                onBlur={(e) => {
                  setTouched({ ...touched, manufacturedate: true });
                }}
                className={
                  shouldMarkError("manufacturedate", errors, touched)
                    ? "validation-error"
                    : ""
                }
              />
            </div>

            <div className="grey-text contact-info">
              <span className="span-big">contact info</span>
              <InputField
                className={`custom-input ${
                  shouldMarkError("firstname", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("firstname", errors, touched)
                    ? "first name is required*"
                    : "first name*"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, firstname: true });
                }}
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={firstName !== undefined ? firstName : ""}
                onChange={setFirstNameFn}
              />
              <InputField
                className={`custom-input ${
                  shouldMarkError("lastname", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("lastname", errors, touched)
                    ? "last name is required*"
                    : "last name*"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, lastname: true });
                }}
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={lastName !== undefined ? lastName : ""}
                onChange={setLastNameFn}
              />
              <div
                className={`md-form form-group input-mask default-input-field-container`}
              >
                <MaskedInput
                  id="mask-input"
                  type="text"
                  error="wrong"
                  success="right"
                  value={phone !== undefined ? props.phone : ""}
                  onChange={setPhoneFn}
                  mask={maskSanitizedArray}
                  guide={false}
                  placeholder={`phone`}
                  render={(ref, inputProps) => {
                    return (
                      <input
                        {...inputProps}
                        ref={ref}
                        label="phone"
                        className={`form-control validate default-input-field undefined`}
                      />
                    );
                  }}
                />
              </div>
              <InputField
                className={`custom-input ${
                  shouldMarkError("email", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("email", errors, touched)
                    ? email.length === 0
                      ? "email is required*"
                      : "email is invalid*"
                    : "email*"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, email: true });
                }}
                group
                type="email"
                validate
                error="wrong"
                success="right"
                value={email !== undefined ? email : ""}
                onChange={setEmailFn}
              />
            </div>
          </MDBCol>
          <MDBCol>
            <div className={`grey-text mailing-info`}>
              <span className="span-big"> mailing info </span>
              <div className={`state-selection-container`}>
                <Select2
                  name={"country"}
                  type={"text"}
                  icon="address"
                  value={countrySelect !== undefined ? countrySelect : ""}
                  onChange={(event) => {
                    setCountrySelectHandler(event);
                  }}
                  required={true}
                  wrapperClass="small"
                  placeholder="Country"
                  options={countriesData}
                  useCustom={true}
                  isSearchable={true}
                />
              </div>
              <InputField
                label={
                  shouldMarkError("address1", errors, touched)
                    ? "address 1 is required*"
                    : "address 1*"
                }
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={address1 !== undefined ? address1 : ""}
                onChange={setAddress1Fn}
                onBlur={(e) => {
                  setTouched({ ...touched, address1: true });
                }}
                className={
                  shouldMarkError("address1", errors, touched)
                    ? "validation-error"
                    : ""
                }
              />
              <InputField
                label="address 2"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={address2 !== undefined ? address2 : ""}
                onChange={setAddress2Fn}
              />
              <InputField
                label={
                  shouldMarkError("city", errors, touched)
                    ? "city is required*"
                    : "city*"
                }
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={city !== undefined ? city : ""}
                onChange={setCityFn}
                onBlur={(e) => {
                  setTouched({ ...touched, city: true });
                }}
                className={
                  shouldMarkError("city", errors, touched)
                    ? "validation-error"
                    : ""
                }
              />
              <div className={`state-selection-container`}>
                {(!isEmpty(regionsData) && (
                  <Select2
                    name={"state"}
                    type={"text"}
                    required={true}
                    value={selectState}
                    onChange={(event) => setSelectStateFn(event)}
                    wrapperClass="small"
                    placeholder={
                      countrySelect.value === "US"
                        ? "select state*"
                        : "select province*"
                    }
                    options={regionsData}
                    isSearchable={true}
                    onBlur={(e) => {
                      setTouched({ ...touched, selectstate: true });
                    }}
                    className={
                      shouldMarkError("selectstate", errors, touched)
                        ? "validation-error"
                        : ""
                    }
                  />
                )) || (
                  <InputField
                    name={"region_text"}
                    group
                    type={"text"}
                    value={selectState !== undefined ? selectState : ""}
                    onChange={(event) => setSelectStateFn(event.target.value)}
                    label="province"
                  />
                )}
              </div>
              <MDBBox className="md-form form-group default-input-field-container undefined">
                <MaskedInput
                  id="mask-input"
                  placeholder={
                    shouldMarkError("zipcode", errors, touched)
                      ? countrySelect.value === "US" ||
                        countrySelect.value === "CA"
                        ? countrySelect.value === "US" && zipCode === ""
                          ? "zip code is required*"
                          : countrySelect.value === "CA" &&
                            zipCode === "" &&
                            "postal code is required*"
                        : countrySelect.value === "US"
                        ? "zip code*"
                        : countrySelect.value === "CA"
                        ? "postal code*"
                        : "postal code"
                      : countrySelect.value === "US"
                      ? "zip code*"
                      : countrySelect.value === "CA"
                      ? "postal code*"
                      : "postal code"
                  }
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  value={zipCode !== undefined ? zipCode : ""}
                  mask={zipMask}
                  guide={false}
                  onChange={(e) => {
                    setZipCodeError(false);
                    setZipCodeFn(e);
                  }}
                  className={
                    shouldMarkError("zipcode", errors, touched) || zipCodeError
                      ? countrySelect.value === "US" ||
                        countrySelect.value === "CA"
                        ? "form-control validate default-input-field validation-error"
                        : "form-control validate default-input-field"
                      : "form-control validate default-input-field"
                  }
                  onBlur={(e) => {
                    setTouched({ ...touched, zipCode: true });
                  }}
                />
              </MDBBox>
            </div>
          </MDBCol>
          {openModal && (
            <MDBModal
              isOpen={openModal}
              toggle={toggle2}
              onClick={true}
              className={"modal-wrapper registration-submitted-modal"}
              centered
            >
              <MDBModalHeader onClick={() => openModalFn()}>
                <div className="close-btn">
                  <button type="button">
                    <i className="close-icon"></i>
                  </button>
                </div>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBBox tag="h2" className="text-center">
                  registration submitted
                </MDBBox>
                <MDBBox tag="p">
                  Thank you for submitting your registration of one of our
                  products!
                </MDBBox>
                <MDBBox className={"text-center"}>
                  <Button
                    outline={true}
                    className={"right-arrow white-button"}
                    children={"continue shopping"}
                    direction={"ml-3"}
                    righticon={"true"}
                    icon={"arrow-right"}
                    to={"/baby"}
                  />
                </MDBBox>
              </MDBModalBody>
            </MDBModal>
          )}
          <MDBCol lg="12">
            <div className="switch-and-required">
              <div className="text-right required-text-block">
                <span className="required-sign">*</span>
                <span className="required-text">= required</span>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="account-submit">
          <SessionMessages target={`register`} />
          <div className="submit-btn-container">
            <Button
              className={"right-arrow orange-button account-submit-btn"}
              direction={"ml-3"}
              righticon={"true"}
              disabled={loading}
              icon={loading ? "" : "arrow-right"}
              text={loading ? "submiting..." : "submit"}
              onClick={() => validateInput()}
            />
          </div>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default ProductRegistration;
