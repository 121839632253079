import React from "react";
import CountrySelect from "./countrySelect";
import { isUndefined } from "core/helpers/functions";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { filterByCountryId } from "core/helpers/customer";
import countries from "config/data/countries";

const CountrySelectStateContainer = props => {
  let countriesData = [];
  let regionsData = [];
  let countryDataObject = getCountries(countries.countries);
  countriesData = Object.keys(countryDataObject).map(key => {
    return countryDataObject[key];
  });
  let selectedCountry = filterByCountryId(
    props.countryValue.value,
    countriesData
  );
  regionsData = getRegionData(countriesData, selectedCountry);

  const setCountryValueHandler = event => {
    let value = event;
    props.setCountryValue(value);
    if (value.regions === null) {
      props.setStateValue(null);
    }
  };

  let isSearchable = isUndefined(props.isSearchable)
    ? true
    : props.isSearchable;
  let loader = isUndefined(props.loader) ? false : props.loader;
  // COUNTRY
  let countryValue = isUndefined(props.countryValue)
    ? {
        label: "United States",
        value: "US"
      }
    : props.countryValue;
  let countryRequired = isUndefined(props.countryRequired)
    ? false
    : props.countryRequired;
  let countryWrapperClass = isUndefined(props.countryWrapperClass)
    ? ""
    : props.countryWrapperClass;
  let countryPlaceholder = isUndefined(props.countryPlaceholder)
    ? "country"
    : props.countryPlaceholder;
  let countryName = isUndefined(props.countryName)
    ? "country"
    : props.countryName;
  // STATE
  let stateValue = isUndefined(props.stateValue) ? "" : props.stateValue;
  let stateRequired = isUndefined(props.stateRequired)
    ? false
    : props.stateRequired;
  let stateWrapperClass = isUndefined(props.stateWrapperClass)
    ? ""
    : props.stateWrapperClass;
  let statePlaceholder = isUndefined(props.statePlaceholder)
    ? "state"
    : props.statePlaceholder;
  let stateName = isUndefined(props.stateName) ? "state" : props.stateName;
  let stateLabel = isUndefined(props.stateLabel) ? "state" : props.stateLabel;
  let icon = isUndefined(props.icon) ? "carrot" : props.icon;

  return (
    <CountrySelect
      // Default
      isSearchable={isSearchable}
      loader={loader}
      icon={icon}
      // Country Passtrough
      countriesData={countriesData}
      setCountryValueHandler={setCountryValueHandler}
      countryValue={countryValue}
      countryRequired={countryRequired}
      countryWrapperClass={countryWrapperClass}
      countryPlaceholder={countryPlaceholder}
      // State Passtrough
      regionsData={regionsData}
      setStateValueHandler={props.setStateValue}
      stateValue={stateValue}
      stateRequired={stateRequired}
      stateWrapperClass={stateWrapperClass}
      statePlaceholder={statePlaceholder}
      stateName={stateName}
      stateLabel={stateLabel}
    />
  );
};

export default CountrySelectStateContainer;
