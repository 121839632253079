import { loader } from "graphql.macro";

export default (query, additionalMapping = []) => {
  if (query === "urlResolver") {
    return loader("core/graphql/queries/urlResolver.graphql");
  }

  throw new Error(
    "Unknown query, you probably forgot to define it in state/graphql/queryLoader.js."
  );
};
