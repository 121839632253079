import React from "react";
import { MDBRow } from "mdbreact";
import Create from "./create";
import Toolbar from "./toolbar";
import Listing from "./listing";
import ShareFavoritesBtn from "./shareFavoritesBtn";

const FavoritesComponents = (props) => {
  return (
    <>
      <MDBRow>
        <Create
          activeWishlist={props.activeWishlist}
          setActiveWishlist={props.setActiveWishlist}
        />
      </MDBRow>
      <MDBRow>
        <Toolbar
          activeWishlist={props.activeWishlist}
          setActiveWishlist={props.setActiveWishlist}
          projectName={props.projectName}
          setProjectName={props.setProjectName}
        />
      </MDBRow>
      <MDBRow>
        <Listing activeWishlist={props.activeWishlist} />
      </MDBRow>
      <MDBRow>
        <ShareFavoritesBtn
          {...props}
          activeWishlist={props.activeWishlist}
          mainWishlist={props.wishlist_id}
        />
      </MDBRow>
    </>
  );
};

export default FavoritesComponents;
