import React, { useState } from "react";
import Favorites from "./favorites";
import SeoHelmet from "app/layout/seoHelmet";

const FavoritesStateContainer = (props) => {
  const [activeWishlist, setActiveWishlist] = useState(0);
  const [projectName, setProjectName] = useState("favorites");
  return (
    <>
      <SeoHelmet title={`my favorites`} url={`customer/favorites`} />
      <Favorites
        activeWishlist={activeWishlist}
        setActiveWishlist={setActiveWishlist}
        projectName={projectName}
        setProjectName={setProjectName}
        wishlist_id={props.wishlist.id}
        {...props}
      />
    </>
  );
};

export default FavoritesStateContainer;
