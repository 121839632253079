import React from "react";
import { MDBRow, MDBCol, MDBBox, MDBContainer } from "mdbreact";
import HeroImage from "app/layout/heroImage";
import HeroImageSrc from "app/assets/images/register/ducduc-register-trade.jpg";
import Button from "core/components/button";

const thankYouPage = (props) => {
  return (
    <>
      <HeroImage
        id={"thank-you-img"}
        className="b2b-banner"
        alt="Shop The Look Banner"
      />
      <MDBContainer className={"page-main-content register-success"}>
        <MDBRow>
          <MDBCol md="12">
            <MDBBox tag="h3">thank you for submitting your application!</MDBBox>
            <MDBBox tag="h4">
              we look forward to working with you... a member of our sales team
              will be in touch shortly.
            </MDBBox>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="8" className={"register-succes-buttons"}>
            <Button
              outline={true}
              className={"right-arrow white-button"}
              children={"homepage"}
              direction={"ml-3"}
              to={"/"}
            />
            <Button
              className={"right-arrow orange-button shop-the-look-btn"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
              to={"/shop-the-look"}
              text={"shop the look"}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default thankYouPage;
