import React from "react";
import AssemblyInstructionSearch from "./assemblyInstructionSearch";
import lodash from "lodash";

const AssemblyInstructionSearchStateContainer = (props) => {
  let { search, data } = props;
  if (lodash.isUndefined(data) || lodash.isUndefined(data.getAssembly))
    return <></>;
  let categories = data.getAssembly;
  let results = [];
  let temporaryResults = [];
  categories.map((category) => {
    let filter = category.getProducts.filter((product) =>
      product.product_name.toLowerCase().includes(search)
    );

    if ({ filter }) {
      temporaryResults.push(filter);
    }
    return category;
  });
  temporaryResults.map((result) => {
    result.map((resultItem) => {
      results.push(resultItem);
    });
  });
  return <AssemblyInstructionSearch {...props} results={results} />;
};

export default AssemblyInstructionSearchStateContainer;
