const unlock = (store, action) => {
  const currentState = store.getState();
  if (currentState.common.locked === true) {
    return;
  }

  if (typeof currentState.common.afterUnlockCallback !== "undefined") {
    let { always, fail, success } = currentState.common.afterUnlockCallback;

    if (typeof always !== "undefined") {
      callCallbacks(always);
    }
    if (typeof fail !== "undefined" && typeof success !== "undefined") {
      if (action.flag === "fail") {
        callCallbacks(fail);
      } else {
        callCallbacks(success);
      }
    }
  }
};

const callCallbacks = (state) => {
  if (typeof state === "object") {
    state.map((callback) => {
      if (callback instanceof Function) {
        callback.call();
      }
    });
  } else {
    if (state instanceof Function) {
      state.call();
    }
  }
};

export default unlock;
