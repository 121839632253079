import React, { memo } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBIcon, MDBLink } from "mdbreact";
import Icon from "app/assets/icon/icon";
import lodash from "lodash";

const FooterTop = memo(
  (props) => {
    return (
      <MDBContainer
        fluid
        className="text-center text-md-left footer-top-content"
      >
        <MDBRow>
          <MDBCol md="4" className="footer-top-part">
            <MDBLink to="/find-a-retailer" link>
              <Icon icon={"findRetailerGray"} />
              Find a retailer
            </MDBLink>
          </MDBCol>
          <MDBCol md="4" className="footer-top-part">
            <MDBLink to="/about-us" link>
              <Icon icon={"aboutDucducGray"} />
              About ducduc
            </MDBLink>
          </MDBCol>
          <MDBCol md="4" className="footer-top-part">
            <MDBLink to="/shop-the-look" link>
              <MDBIcon icon="shopping-basket" />
              {/* <Icon icon={"shopTheLookGray"} /> */}
              Shop the look
            </MDBLink>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default FooterTop;
