import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { customerActions } from "core/state/redux/data/customer";
import { default as customerActionTypes } from "core/state/redux/data/customer/types";
import { default as commonActionTypes } from "core/state/redux/data/common/types";
import { pendingTask, begin, end } from "react-redux-spinner";
import { default as commonActions } from "core/state/redux/data/common/actions";
import RegisterB2C from "./RegisterB2C";
import { isUndefined } from "core/helpers/functions";

const REGISTER_B2C_CUSTOMER = customerActionTypes.REGISTER_B2C_CUSTOMER;
const REGISTER_CUSTOMER_SUCCESS = customerActionTypes.REGISTER_CUSTOMER_SUCCESS;
const UNLOCK = commonActionTypes.UNLOCK;

const RegisterB2CReduxContainer = props => {
  if (props.customer !== undefined) {
    if (props.status) {
      return <Redirect to={"/register/complete"} />;
    }
    if (props.customer.token === false || isUndefined(props.customer.token)) {
      return <RegisterB2C {...props} />;
    }

    if (props.status) {
      return <Redirect to={"/register/complete"} />;
    }
    if (
      !props.status &&
      props.customer.token !== false &&
      !isUndefined(props.customer.token)
    )
      return <Redirect to={"/"} />;
  }
};
const mapStateToProps = state => ({
  customer: state.customer.data,
  loading: state.customer.loading,
  status: state.customer.status,
  locked: state.common.locked
});

const mapDispatchToProps = dispatch => {
  return {
    registerB2CCustomer: (request, address) => {
      dispatch(commonActions.lock()).then(function(response) {
        dispatch({
          type: REGISTER_B2C_CUSTOMER,
          [pendingTask]: begin,
          request: request
        })
          .then(function(response) {
            if (response) {
              dispatch({
                type: REGISTER_CUSTOMER_SUCCESS,
                [pendingTask]: end,
                status: response
              }).then(function(response) {
                dispatch(commonActions.unlock());
              });
            }
          })
          .catch(function(reason) {
            dispatch({
              status: reason,
              type: UNLOCK,
              [pendingTask]: end
            });
          });
      });
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: callbackFunction => {
      dispatch(commonActions.lock(callbackFunction));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterB2CReduxContainer);
