import React, { memo, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import CheckoutProcess from "./checkoutProcess";
import CheckoutShippingMethod from "./checkoutShippingMethod";
import CheckoutPaymentMethod from "./checkoutPaymentMethod";
import CheckoutOrderReview from "./checkoutOrderReview";
import SeoHelmet from "app/layout/seoHelmet";
import _ from "lodash";
import Crossell from "./crossell";
import CheckoutAddresses from "./checkoutAddresses";
import CheckoutLogin from "./checkoutLogin";
import { MobileOrderSummary } from "./checkoutMobileComponents";

const Checkout = memo(
  ({
    cart,
    setCheckoutStep,
    customer,
    setGuestEmailOnCart,
    lock,
    empty,
    setShippingAddressOnCart,
    setBillingAddressOnCart,
    loggingIn,
    setLoggingIn,
    cart_grand_total,
    setPaymentMethod,
  }) => {
    let links = [
      { url: "/", title: "Home" },
      { url: "/checkout", title: "Checkout" },
    ];

    /**
     * this state will dictate if payment options should be shown
     * we determine this by checking if the billing information is selected or updated (not already in quote)
     **/
    const [showPayment, setShowPayment] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 992 });

    return (
      <>
        <SeoHelmet url={"/checkout"} title={"Checkout"} />

        {isMobile ? (
          <>
            <MobileOrderSummary />
          </>
        ) : (
          <></>
        )}
        <MDBContainer className="checkout page-main-content">
          {(typeof cart.items !== "undefined" && cart.items.length === 0) ||
          typeof cart.items === "undefined" ? (
            <>
              <Breadcrumbs links={links} />
              <h2>checkout</h2>
              <MDBRow className="content-container">
                <p className={`ml-4`}>
                  {(loggingIn &&
                    typeof cart.items !== "undefined" &&
                    cart.items.length === 0) ||
                  (loggingIn && typeof cart.items === "undefined")
                    ? "retrieving cart information..."
                    : "your shopping cart is empty"}
                </p>
              </MDBRow>
            </>
          ) : (
            <>
              {!isMobile ? (
                <>
                  <Breadcrumbs links={links} />
                  <h2>checkout</h2>
                </>
              ) : (
                <></>
              )}

              {typeof empty !== "undefined" ? (
                !empty ? (
                  <>
                    <MDBRow className="checkout-inner">
                      <MDBCol className={`step checkout-steps`}>
                        {!empty ? (
                          (typeof customer !== "undefined" &&
                            typeof customer.data !== "undefined" &&
                            typeof customer.data.token !== "undefined" &&
                            customer.data.token === false) ||
                          (typeof customer !== "undefined" &&
                            typeof customer.data !== "undefined" &&
                            typeof customer.data.token === "undefined") ? (
                            <CheckoutLogin
                              customer={customer}
                              setGuestEmailOnCart={setGuestEmailOnCart}
                              lock={lock}
                              loggingIn={loggingIn}
                              setLoggingIn={setLoggingIn}
                            />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                        {!empty ? (
                          <CheckoutAddresses
                            lock={lock}
                            setBillingAddressOnCart={setBillingAddressOnCart}
                            setShippingAddressOnCart={setShippingAddressOnCart}
                            setShowPayment={setShowPayment}
                            cart_grand_total={cart_grand_total}
                            setPaymentMethod={setPaymentMethod}
                          />
                        ) : (
                          <></>
                        )}
                      </MDBCol>
                      <MDBCol className={`step checkout-steps`}>
                        {!empty ? (
                          <>
                            <CheckoutShippingMethod
                              setCheckoutStep={setCheckoutStep}
                            />
                            <CheckoutPaymentMethod showPayment={showPayment} />
                          </>
                        ) : (
                          <></>
                        )}
                      </MDBCol>
                      <MDBCol className={`step checkout-steps`}>
                        {!empty ? (
                          <>
                            {!isMobile ? <CheckoutOrderReview /> : <></>}
                            <CheckoutProcess showPayment={showPayment} />
                          </>
                        ) : (
                          <></>
                        )}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <Crossell cart={cart} />
                      </MDBCol>
                    </MDBRow>
                  </>
                ) : (
                  <p className={`mb-5`}>
                    {loggingIn
                      ? "returning customer cart information..."
                      : "your shopping cart is empty"}
                  </p>
                )
              ) : (
                <p className={`mb-5`}>
                  {loggingIn
                    ? "returning customer cart information..."
                    : "retrieving information..."}
                </p>
              )}
            </>
          )}
        </MDBContainer>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Checkout;
