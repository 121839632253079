import React, { useEffect, useState, useRef } from "react";
import {
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBBox,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBBtn,
} from "mdbreact";
import { default as AccountLinks } from "app/config/routing/pages/customerPages";
import Icon from "app/assets/icon/icon";

import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";
import MiniCartLabel from "./MiniCartLabel";
import Search from "./search";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, toggleSearch, showSearch) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      event.target.tagName !== "svg" &&
      event.target.tagName !== "path"
    ) {
      if (showSearch) {
        toggleSearch(!showSearch);
      }
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}
const Right = (props) => {
  let { customer, token, createEmptyCart, getCartInformation } = props;
  let [showMiniCart, setShowMiniCart] = useState(false);
  let cartToken =
    typeof customer.data !== "undefined" &&
    typeof customer.data.cartToken !== "undefined"
      ? customer.data.cartToken
      : undefined;

  const toggleMiniCart = () => {
    setShowMiniCart(!showMiniCart);
  };

  const displayMiniCart = () => {
    setShowMiniCart(true);
  };

  const hideMiniCart = () => {
    setShowMiniCart(false);
  };

  const searchRef = useRef(null);
  useOutsideAlerter(searchRef, props.toggleSearch, props.showSearch);

  let wishlistCount =
    typeof customer !== "undefined" &&
    typeof customer.data !== "undefined" &&
    typeof customer.data.wishlist !== "undefined" &&
    typeof customer.data.wishlist.items !== "undefined"
      ? customer.data.wishlist.items.length
      : 0;
  return (
    <>
      <MDBNavbarNav
        onMouseLeave={hideMiniCart}
        right
        className="right align-items-center"
      >
        {token && !props.customer.hide ? (
          <>
            <MDBNavItem
              onMouseEnter={hideMiniCart}
              className="customer-name mr-5"
            >
              <MDBBox tag="p">Welcome, {customer.data.firstname}</MDBBox>
            </MDBNavItem>

            <MDBDropdown>
              <MDBDropdownToggle
                onMouseEnter={hideMiniCart}
                color="my-account"
                className={`btn-lg text-uppercase text-decoration-none p-0 pb-2 mt-2 my-account-btn`}
              >
                My Account
              </MDBDropdownToggle>
              <MDBDropdownMenu
                basic
                className={`pt-5 pb-4 mt-1 my-account-dropdown`}
              >
                {AccountLinks &&
                  Object.keys(AccountLinks).map((value, index) => {
                    let accountLink = AccountLinks[value];

                    return (
                      accountLink.inDropdown && (
                        <MDBDropdownItem
                          key={index}
                          className={`text-lowercase`}
                        >
                          {(accountLink.url !== "logout" && (
                            <MDBNavLink
                              className={`text-decoration-none`}
                              to={`${
                                (accountLink.url !== "logout" &&
                                  "/" + accountLink.url) ||
                                ""
                              }`}
                            >
                              {accountLink.dropdownText}
                            </MDBNavLink>
                          )) || (
                            <MDBBtn
                              onClick={(e) => {
                                props.logoutCustomer();
                              }}
                              className={`nav-link white`}
                            >
                              {accountLink.dropdownText}
                            </MDBBtn>
                          )}
                        </MDBDropdownItem>
                      )
                    );
                  })}
              </MDBDropdownMenu>
            </MDBDropdown>
          </>
        ) : (
          <ModalConsumer value={{ useWrapper: false }}>
            {({ showModal, hideModal }) => (
              <MDBNavItem
                onMouseEnter={hideMiniCart}
                className="sing-in"
                onClick={() => {
                  showModal(() => {
                    return <Login {...props} hideModal={hideModal} />;
                  });
                }}
              >
                <MDBNavLink to="#" className="text-decoration-none">
                  <MDBBox tag="p" className="text-uppercase">
                    SIGN IN
                  </MDBBox>
                </MDBNavLink>
              </MDBNavItem>
            )}
          </ModalConsumer>
        )}
        {token && !props.customer.hide && (
          <MDBNavItem onMouseEnter={hideMiniCart} className="wishlist">
            <ModalConsumer value={{ useWrapper: false }}>
              {({ showModal, hideModal }) => (
                <MDBNavLink
                  to={`/customer/favorites`}
                  className="align-items-center text-decoration-none"
                  onClick={(e) => {
                    if (!token) {
                      e.preventDefault();
                      showModal(() => {
                        return <Login {...props} hideModal={hideModal} />;
                      });
                    }
                  }}
                >
                  <Icon
                    id={`${
                      wishlistCount === 0 ? "heartEmptyOrange" : "heartFull"
                    }`}
                  />
                  <MDBBox tag="p" className="text-uppercase ">
                    {wishlistCount}
                  </MDBBox>
                </MDBNavLink>
              )}
            </ModalConsumer>
          </MDBNavItem>
        )}
        <MDBNavItem
          className={`cart show-minicart-${showMiniCart}`}
          onMouseEnter={displayMiniCart}
          // onMouseLeave={hideMiniCart}
        >
          <MDBNavLink
            to={"/cart"}
            className={`align-items-center text-decoration-none`}
          >
            <Icon icon={"cartOrange"} />
            <MDBBox tag="p" className="text-uppercase ">
              {typeof props.customer.data !== "undefined" &&
              typeof props.customer.data.cart !== "undefined" &&
              typeof props.customer.data.cart.items !== "undefined" &&
              props.customer.data.cart
                ? props.customer.data.cart.items.reduce(
                    (acc, curr) => acc + curr.quantity,
                    0
                  )
                : ""}
            </MDBBox>
          </MDBNavLink>
        </MDBNavItem>
        <MiniCartLabel
          id={"cart"}
          className="cart"
          showMiniCart={showMiniCart}
          toggleMiniCart={toggleMiniCart}
          hideMiniCart={hideMiniCart}
        />
        <div ref={searchRef}>
          <MDBNavItem onMouseEnter={hideMiniCart} className="search">
            <span
              onClick={(e) => {
                props.resetSearch();
                props.toggleSearch(!props.showSearch);
              }}
            >
              <Icon
                className="header-search-icon"
                icon={`${
                  typeof props.showSearch === "undefined"
                    ? "searchGray"
                    : props.showSearch === false
                    ? "searchGray"
                    : "removeFilterButtonGray"
                }`}
              />
            </span>
          </MDBNavItem>
        </div>
      </MDBNavbarNav>
      <div ref={searchRef}>
        <Search open={props.showSearch} />
      </div>
    </>
  );
};

export default Right;
