import apolloClient from "core/graphql/apolloClient";
import queryLoader from "core/graphql/queryLoader";
import { messagesActions } from "../../redux/data/messages";
import { customerActions } from "../../redux/data/customer";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useWishlist } from "./wishlistHookAddRemove";
import useLocking from "../commonHooks/useLocking";

function useWishlistAddToCart() {
  const wishlist = useSelector((state) => state.wishlist);
  const myWishlists = useSelector((state) => state.wishlist.data);
  const customer = useSelector((state) => state.customer);
  const [, , fetchWishlistData] = useWishlist();
  const [, unlock] = useLocking();

  let dispatch = useDispatch();

  const addToCartWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addItemToCart"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      fetchWishlistData(true);
      dispatch(customerActions.getCartInformation());
      dispatch(messagesActions.addMessage("Item added to cart", "success"));
      unlock("success");
    } catch (err) {
      unlock("fail");
      console.log(err.message);
      return;
    }
  };

  const addAllToCart = async (request) => {
    console.log(request);
    try {
      await apolloClient.mutate({
        mutation: queryLoader("addAllToCart"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      fetchWishlistData(true);
      dispatch(customerActions.getCartInformation());
      dispatch(
        messagesActions.addMessage(
          "Items successfully added to cart",
          "success"
        )
      );
      unlock("success");
    } catch (err) {
      unlock("fail");
      console.log(err);
      return;
    }
  };

  const deleteItemsFromWishlist = async (request) => {
    try {
      await apolloClient.mutate({
        mutation: queryLoader("deleteItemsFromWishlist"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      fetchWishlistData(true);
      dispatch(
        messagesActions.addMessage("Items successfully removed", "success")
      );
      unlock("success");
    } catch (err) {
      unlock("fail");
      console.log(err);
      return;
    }
  };

  return [addToCartWishlist, addAllToCart, deleteItemsFromWishlist];
}
export { useWishlistAddToCart };
