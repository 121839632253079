import CustomerAttributes from "config/customer/attributes";

const projectCustomerAttributes = {
  business_type: "business_type",
  retail_options: "retail_options",
  stores_count: "stores_count",
  specified: "specified",
  placed_order: "placed_order",
  is_trade: "is_trade",
  hear_about: "hear_about",
  tradeshow_specify: "tradeshow_specify",
  other_specify: "other_specify",
  resale_certificate: "resale_certificate",
  card_certificate: "card_certificate"
};
export default {
  ...CustomerAttributes,
  ...projectCustomerAttributes
};
