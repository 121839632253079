import React, { memo } from "react";
import FooterTop from "./top";
import FooterMiddle from "./middle";
import FooterBottom from "./bottom";
import { MDBFooter } from "mdbreact";
import lodash from "lodash";
import CompareList from "core/components/compareList";

const Footer = memo(
  (props) => {
    return (
      <div id={"footer"}>
        <MDBFooter>
          <FooterTop />
          <FooterMiddle />
          <FooterBottom />
          <CompareList />
        </MDBFooter>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Footer;
