import React from "react";

// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout definition for the website misc components
// This file should only be used to bootstrap new misc components and transfer props, it should not contain logic
// Keep in mind that this is loaded on every page of the website, so try to keep it and it's children light
// --------------------------------------------------------------------------------------------------------------------------------------------------------

const Misc = () => {
  return <></>;
};

export default Misc;
