import React, { memo, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import AccordionAssembly from "./accordionAssembly";
import AssemblyInstructionSearch from "./assemblyInstructionSearch";
import { isEqual } from "lodash";
import AssemblyTitleTab from "./assemblyTitleTab";

const AssemblyInstructionsStateContainer = memo(
  (props) => {
    const [active, setActive] = useState(0);
    const [search, setSearch] = useState("");
    return (
      <>
        <h3 className="testSize">Assembly Instructions</h3>
        <MDBRow className={"search-container"}>
          <MDBCol className={"search-wrapper"}>
            <AssemblyInstructionSearch
              {...props}
              // getters
              active={active}
              search={search}
              // setters
              setActive={setActive}
              setSearch={setSearch}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className={"assembly-row"}>
          <MDBCol className={"assembly-tab-title"}>
            <AssemblyTitleTab />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className={"assembly-wrapper text-center"}>
            <AccordionAssembly {...props} />
          </MDBCol>
        </MDBRow>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default AssemblyInstructionsStateContainer;
