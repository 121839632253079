import React from "react";
import GraphQlWrapper from "core/components/graphQlWrapper";
import { MDBBox, MDBCol, MDBRow } from "mdbreact";
import gql from "graphql-tag";
import Elements from "./elements";
import TitleDescription from "app/layout/titleDescription";

const CMSBLOCK_QUERY = gql`
  query cmsBlock($id: [String]) {
    cmsBlocks(identifiers: $id) {
      items {
        content
        identifier
        title
      }
    }
  }
`;
const WhatLookingBlock = (props) => {
  let { data } = props;
  return (
    <MDBRow>
      <MDBCol size="12" className="what-looking-block">
        <TitleDescription
          title={"what are you looking for?"}
          // description={"get the best designed furnitures for your child"}
        />
        <MDBBox id={`what-looking`}>
          {typeof data !== "undefined" && (
            <GraphQlWrapper
              query={CMSBLOCK_QUERY}
              variables={{ id: data }}
              saveQuery={true}
              noWrapper={true}
            >
              <Elements key={`some-content`} />
            </GraphQlWrapper>
          )}
        </MDBBox>
      </MDBCol>
    </MDBRow>
  );
};
export default WhatLookingBlock;
