import React from "react";
import { connect } from "react-redux";
import { default as queriesActions } from "core/state/redux/data/queries/actions";
import ShopLookDetailQueryLoader from "./shopLookDetail-query-container";

const ShopLookDetailReduxContainer = props => {
  return <ShopLookDetailQueryLoader {...props} />;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    addQueryData: (data, identifier, key) => {
      dispatch(queriesActions.addQueryData(data, identifier, key));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopLookDetailReduxContainer);
