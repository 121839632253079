import React, { useState, memo } from "react";
import CheckoutShippingMethod from "./checkoutShippingMethod";
import _ from "lodash";

const CheckoutShippingMethodStateContainer = memo(
  ({ cart_grand_total, cart, setShippingMethodsOnCart, lock }) => {
    const [inputs, setInputs] = useState({
      // shipping_method: {
      //   carrier_code: selected_shipping_method.carrier_code,
      //   method_code: selected_shipping_method.method_code
      // },
      // po_number: po_number,
      // order_comment: order_comment,
      // shipping_notes: shipping_notes
    });

    const [loading, setLoading] = useState(false);

    const handleInputChange = (event) => {
      if (event.target.name === "shipping_method") {
        setInputs({
          ...inputs,
          shipping_method: {
            carrier_code: event.target.getAttribute("carrier_code"),
            method_code: event.target.getAttribute("method_code"),
          },
        });
      } else {
        setInputs({
          ...inputs,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
        });
      }
    };

    return (
      <CheckoutShippingMethod
        inputs={inputs}
        handleInputChange={handleInputChange}
        setShippingMethodsOnCart={setShippingMethodsOnCart}
        cart={cart}
        loading={loading}
        setLoading={setLoading}
        lock={lock}
        cartGrandTotal={cart_grand_total}
      />
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default CheckoutShippingMethodStateContainer;
