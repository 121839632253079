import React from "react";
import Button from "core/components/button";
import { MDBBox } from "mdbreact";

const ShareFavoritesBtn = (props) => {
  let numberOfItems = 0;
  if (props.activeWishlist === 0) {
    props.wishlist.items.map((item) => {
      if (item.multi_wishlist_id === props.activeWishlist)
        numberOfItems = numberOfItems + 1;
    });
  } else {
    props.wishlist.multiwishlist_data.filter((wishlist) => {
      if (props.activeWishlist === wishlist.id)
        numberOfItems = wishlist.items.length;
    });
  }
  if (numberOfItems === 0) {
    return <></>;
  } else {
    return (
      <MDBBox className={"share-favorites-btn-wrapper"}>
        <Button
          outline={true}
          className={"right-arrow white-button"}
          text={"share"}
          direction={"ml-3"}
          righticon={"true"}
          icon={"arrow-right"}
          to={
            `/customer/favorites/share?main=${props.mainWishlist}` +
            `&active=` +
            `${props.activeWishlist}`
          }
        />
      </MDBBox>
    );
  }
};

export default ShareFavoritesBtn;
