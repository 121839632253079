import React, { useState } from "react";
import Button from "core/components/button";
import HeroImage from "app/layout/heroImage";
import HeroImageSrc from "app/assets/images/register/ducduc-register-trade.jpg";
import InputField from "core/components/inputField";
import { validate, shouldMarkError } from "./validation";
import { MDBRow, MDBCol, MDBBox, MDBContainer } from "mdbreact";
const ForgotPassword = (props) => {
  let { location } = props;
  const [loading, setLoading] = useState(false);
  let token =
    location.search !== "" && location.search.includes("token")
      ? location.search.split("=")[1]
      : "";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [touched, setTouched] = useState({ email: false, password: false });

  let errors = validate(email, password, confirmPassword);

  const setEmailFn = (e) => {
    setEmail(e.target.value);
  };
  const setPasswordFn = (e) => {
    setPassword(e.target.value);
  };
  const setConfirmPasswordFn = (e) => {
    setConfirmPassword(e.target.value);
  };
  const [passwordCheck, setPasswordCheck] = useState(false);
  const validateInput = () => {
    let isTrue = true;
    setTouched({
      password: true,
      confirm_password: true,
    });
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (item === "email") {
        error = false;
      }
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    let paswd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    // if (!password.match(paswd)) {
    //   isTrue = false;
    //   setPasswordCheck(true);
    // } else {
    //   setPasswordCheck(false);
    // }

    if (isTrue) {
      setLoading(true);
      props.lock({
        always: () => {
          setLoading(true);
        },
        success: () => {
          setLoading(false);
        },
        fail: () => {
          setLoading(false);
        },
      });
      props.resetPassword(token, password, confirmPassword);
    }
  };

  return (
    <>
      <HeroImage
        banner={HeroImageSrc}
        className="forgot-password-banner"
        alt="Forgot Password Banner"
      />
      {(!token && (
        <>
          <MDBContainer className={"page-main-content forgot-password first"}>
            <MDBRow>
              <MDBCol md="12">
                <MDBBox tag="h3">forgot password</MDBBox>
                <MDBBox tag="h4">
                  please enter your email address below to receive a password
                  reset link.
                </MDBBox>
              </MDBCol>
            </MDBRow>
            <MDBRow className="input-btn-content">
              <MDBCol className="input-field-content">
                <InputField
                  className={`custom-input ${
                    shouldMarkError("email", errors, touched)
                      ? "validation-error"
                      : ""
                  }`}
                  label={`${
                    shouldMarkError("email", errors, touched)
                      ? email.length === 0
                        ? "email is required*"
                        : "email is invalid*"
                      : "email*"
                  }`}
                  onBlur={(e) => {
                    setTouched({ ...touched, email: true });
                  }}
                  group
                  type="email"
                  validate
                  error="wrong"
                  success="right"
                  value={email !== undefined ? email : ""}
                  onChange={setEmailFn}
                />
              </MDBCol>
              <MDBCol className="clickable-button">
                <Button
                  outline={true}
                  className={"right-arrow white-button"}
                  children={`${loading ? "sending..." : "send"}`}
                  direction={"ml-3"}
                  disabled={loading}
                  righticon={"true"}
                  icon={loading ? "" : "arrow-right"}
                  onClick={(e) => {
                    setLoading(true);
                    props.lock({
                      always: () => {
                        setLoading(true);
                      },
                      success: () => {
                        setLoading(false);
                      },
                      fail: () => {
                        setLoading(false);
                      },
                    });
                    props.onForgotPassword(email);
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      )) || (
        <>
          <MDBContainer className={"page-main-content forgot-password second"}>
            <MDBRow>
              <MDBCol md="12">
                <MDBBox tag="h3">reset password</MDBBox>
                <MDBBox tag="h4">please enter your new password.</MDBBox>
              </MDBCol>
            </MDBRow>
            <MDBRow className="input-btn-content">
              <MDBCol className="input-field-content">
                <MDBBox tag="p" className="password-text">
                  password must include 3 different classes of characters: lower
                  case, upper case, digits, and/or special characters.
                </MDBBox>
                <InputField
                  className={`custom-input ${
                    shouldMarkError("password", errors, touched) ||
                    passwordCheck
                      ? "validation-error"
                      : ""
                  }`}
                  label={`${
                    shouldMarkError("password", errors, touched) ||
                    passwordCheck
                      ? "password is required*"
                      : "password*"
                  }`}
                  group
                  type="password"
                  validate
                  error="wrong"
                  success="right"
                  onChange={setPasswordFn}
                  onBlur={(e) => {
                    setTouched({ ...touched, password: true });
                  }}
                  value={password !== undefined ? password : ""}
                />
                <InputField
                  className={`custom-input ${
                    shouldMarkError("confirm_password", errors, touched)
                      ? "validation-error"
                      : ""
                  }`}
                  label={`${
                    shouldMarkError("confirm_password", errors, touched)
                      ? "confirm password is required*"
                      : "confirm password*"
                  }`}
                  onBlur={(e) => {
                    setTouched({ ...touched, confirm_password: true });
                  }}
                  group
                  type="password"
                  validate
                  error="wrong"
                  success="right"
                  value={confirmPassword !== undefined ? confirmPassword : ""}
                  onChange={setConfirmPasswordFn}
                />
              </MDBCol>
              <MDBCol className="clickable-button">
                <Button
                  outline={true}
                  className={"right-arrow white-button"}
                  children={`${loading ? "reseting..." : "reset password"}`}
                  direction={"ml-3"}
                  righticon={"true"}
                  icon={loading ? "" : "arrow-right"}
                  onClick={(e) => {
                    validateInput();
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
