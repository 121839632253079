import React from "react";
import Slider from "react-slick";
import CmsBlock from "core/components/cmsBlock";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const UspBlocks = (props) => {
  const settings = {
    dots: false,
    dotsClass: props.dotsClass,
    arrows: false,
    infinite: false,
    slidesToShow: 4,
    variableWidth: true,
    slidesToScroll: 1,
    customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
    appendDots: (dots) => (
      <div
        className={"test"}
        style={{
          padding: "15px",
          textAlign: "center",
          maxHeight: "5rem",
        }}
      >
        <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          variableWidth: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className={"usp-blocks-wrapper"}>
      <CmsBlock dangHtml={true} id='usp_made_here' />
      <CmsBlock dangHtml={true} id='usp_made_well' />
      <CmsBlock dangHtml={true} id='usp_made_safe' />
      <CmsBlock dangHtml={true} id='usp_made_smart' />
    </Slider>
  );
};

export default UspBlocks;
