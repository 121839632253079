import React from "react";
import ActiveFilter from "./activeFilter";
import { connect } from "react-redux";
import { filterActions } from "core/state/redux/data/filters";

const ActiveFilterReduxContainer = props => {
  return (
    <ActiveFilter
      removeFilter={props.removeFilter}
      clearFilters={props.clearFilters}
      filters={props.filters}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
  filters: state.filters
});

const mapDispatchToProps = dispatch => {
  return {
    clearFilters: () => {
      dispatch(filterActions.clearFilters());
    },
    removeFilter: filter => {
      dispatch(filterActions.removeFilter(filter));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveFilterReduxContainer);
