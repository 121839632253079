import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { customerActions } from "core/state/redux/data/customer";
import { default as customerActionTypes } from "core/state/redux/data/customer/types";
import { default as commonActionTypes } from "core/state/redux/data/common/types";
import { default as commonActions } from "core/state/redux/data/common/actions";
import { pendingTask, begin, end } from "react-redux-spinner";
import RegisterB2B from "./RegisterB2B";
import { messagesActions } from "core/state/redux/data/messages";

const REGISTER_B2B_CUSTOMER = customerActionTypes.REGISTER_B2B_CUSTOMER;
const REGISTER_CUSTOMER_SUCCESS = customerActionTypes.REGISTER_CUSTOMER_SUCCESS;
const UNLOCK = commonActionTypes.UNLOCK;
const RegisterB2BReduxContainer = (props) => {
  const [step, setStep] = useState(0);
  if (props.customer.data !== undefined) {
    if (props.status)
      return (
        <Redirect
          to={{
            pathname: "/register/success",
            state: { id: "hide" },
          }}
        />
      );
    if (
      step !== 2 &&
      props.customer.data.token !== false &&
      props.customer.data.token !== undefined
    )
      return <Redirect to="/" />;
    if (!props.status)
      return <RegisterB2B {...props} step={step} setStep={setStep} />;
  } else {
    return <RegisterB2B {...props} step={step} setStep={setStep} />;
  }
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  loading: state.customer.loading,
  status: state.customer.status,
  locked: state.common.locked,
});

const mapDispatchToProps = (dispatch) => {
  return {
    registerB2BCustomer: (request, address) => {
      dispatch(commonActions.lock()).then(function (response) {
        dispatch({
          type: REGISTER_B2B_CUSTOMER,
          [pendingTask]: begin,
          request: request,
          address: address,
        })
          .then(function (response) {
            if (response) {
              dispatch({
                type: REGISTER_CUSTOMER_SUCCESS,
                [pendingTask]: end,
                status: response,
              }).then(function (response) {
                dispatch(commonActions.unlock());
              });
            }
          })
          .catch(function (reason) {
            dispatch({
              status: reason,
              type: UNLOCK,
              [pendingTask]: end,
            });
          });
      });
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    addMessage: (messageText, type = "danger") => {
      dispatch(messagesActions.addMessage(messageText, type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterB2BReduxContainer);
