import React, { useEffect } from "react";
import ProductItem from "./productItem";
import FinishItem from "./finishItem";
import ReactLoader from "app/layout/loader";
import { isUndefined, isEmpty } from "core/helpers/functions";
import { findById } from "core/helpers/category";
import { filterConfiguration } from "config/category/filters";

export const findGetParameter = (parameterName) => {
  var result = null,
    tmp = [];
  var items = window.location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

const Listing = (props) => {
  let {
    data,
    allCategories,
    category_id,
    addFilteredData,
    addInitialCategoryData,
    category,
    reducePageData,
    changeCurrentPage,
    addFilter,
    search,
  } = props;
  let total_count = 0;
  let page_size = 1;
  let items_per_page = 0;

  useEffect(() => {
    if (!isUndefined(data) && !isUndefined(data.products)) {
      if (
        isUndefined(category) ||
        (!isUndefined(category) && category.id !== category_id)
      ) {
        let filters = {};
        Object.keys(filterConfiguration).map((key) => {
          let item = filterConfiguration[key];
          if (item.type === "range") {
            let values = item.values(data.products.items, item.attribute_code);
            return (filters[item.attribute_code] = {
              initialValues: item.values(
                data.products.items,
                item.attribute_code
              ),
              ...item,
            });
          }
          return (filters[item.attribute_code] = { ...item });
        });
        addInitialCategoryData({
          initialFilters: filters,
        });
        addFilteredData(data.products);

        changeCurrentPage(1);
        reducePageData({
          totalCount: data.products.total_count,
          pageInfo: data.products.page_info,
        });
        let brands = findGetParameter("brands");
        if (brands) {
          props.addFilter({
            brands: {
              filter_value: brands,
              filter_label: "Brands",
              filter_type: "multi",
            },
          });
        }
      } else {
        reducePageData({
          totalCount: data.products.total_count,
          pageInfo: data.products.page_info,
        });
      }
    }
  }, [
    data,
    addFilteredData,
    addInitialCategoryData,
    allCategories,
    category,
    category_id,
    reducePageData,
    search,
  ]);

  if (isUndefined(data)) return "";

  let items = [];
  let _items = {};

  if (!isUndefined(props.data)) {
    _items = props.data;
  }

  if (
    isUndefined(props.data) ||
    isUndefined(props.data) ||
    isUndefined(props.data.products)
  ) {
    items.push(<ReactLoader key={"loader-items"} />);
  } else {
    if (
      !isUndefined(_items) &&
      !isUndefined(_items.products) &&
      !isUndefined(_items.products.items)
    ) {
      Object.keys(_items.products.items).map((elem, index) => {
        let product = _items.products.items[index];
        if (window.location.pathname.includes("finishes")) {
          return items.push(
            <FinishItem
              key={`product-list-${product.id}`}
              productId={product.id}
              productName={product.name}
              productNumber={product.sku}
              productItemStock={product.only_x_left_in_stock}
              productPriceRegularValue={
                product.price_range.minimum_price.final_price.value
              }
              productPriceRegular={product.price_range}
              productRibbon={product.show_ribbon}
              productImage={product.image}
              productUrl={product.url_key}
              productSmallImage={product.small_image}
              productSearchUrl={false}
              product={product}
              customer={props.customer}
            />
          );
        } else {
          return items.push(
            <ProductItem
              key={`product-list-${product.id}`}
              productId={product.id}
              productName={product.name}
              productNumber={product.sku}
              productItemStock={product.only_x_left_in_stock}
              productPriceRegularValue={
                product.price_range.minimum_price.final_price.value
              }
              productPriceRegular={product.price_range.minimum_price}
              productRibbon={product.show_ribbon}
              productImage={product.image}
              productUrl={product.url_key}
              productSmallImage={product.small_image}
              productSearchUrl={false}
              productOptions={product.options}
              product={product}
              customer={props.customer}
            />
          );
        }
      });
      total_count = data.products.total_count;
      page_size = data.products.page_info.page_size;
    } else {
      items.push(<ReactLoader key={"loader-items"} />);
    }
  }

  if (
    !isUndefined(_items.products) &&
    isEmpty(_items.products.items) &&
    !isUndefined(_items.products.items)
  ) {
    items.push(
      <p key={"no-products-key"} className={`listing-no-products`}>
        There are no available products with these filters.
      </p>
    );
  }

  let current_page = 1;
  let total_pages = 1;
  let no_items = "";
  if (props.filters.data) {
    current_page = props.filters.data.page_info.current_page;
    total_pages = props.filters.data.page_info.total_pages;
  }
  if (total_pages === 1) {
    no_items = total_count + " item(s)";
  } else {
    if (total_count > current_page * page_size) {
      items_per_page = current_page * page_size;
    } else {
      items_per_page = total_count;
    }
    no_items = items_per_page + " per total " + total_count + " products";
  }
  return (
    <>
      <div className="listing-content">{items}</div>
    </>
  );
};

export default Listing;
