import React from "react";
import Image from "core/components/image/image";
import themeConfiguration from "config/themeConfiguration";
import { MDBCol, MDBRow, MDBBox } from "mdbreact";

const Lifestyle = (props) => {
  let lifestyle_items = props.data.media_gallery_entries;
  return (
    <div className="lifestyle-content">
      <MDBRow>
        <MDBCol size="12">
          {typeof lifestyle_items !== "undefined" &&
            lifestyle_items.map((value, index) => {
              return (
                lifestyle_items[index].image_pdp === true && (
                  <MDBBox
                    key={`lifestyle-item-${index}-${lifestyle_items[index].image_pdp}`}
                    tag="div"
                    className="lifestyle-item"
                  >
                    <Image
                      source={
                        themeConfiguration.magento_url +
                        themeConfiguration.media_url +
                        themeConfiguration.catalog_url +
                        lifestyle_items[index].file
                      }
                    />
                    <MDBRow className={`lifestyle-image-info`}>
                      <MDBCol md="6" sm="12">
                        {lifestyle_items[index].shown_in_finishes && (
                          <MDBBox tag="p">
                            shown in finish{" "}
                            {lifestyle_items[index].shown_in_finishes}
                          </MDBBox>
                        )}
                      </MDBCol>
                      <MDBCol md="6" sm="12">
                        {lifestyle_items[index].photo_by && (
                          <MDBBox tag="p" className={`photo-by`}>
                            {lifestyle_items[index].photo_by}
                          </MDBBox>
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBBox>
                )
              );
            })}
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default Lifestyle;
