import React, { memo } from "react";
import { MDBBox } from "mdbreact";
import lodash from "lodash";
const AssemblyTitleTab = memo(
  props => {
    return (
      <>
        <MDBBox tag="h1">unsure of the product name?</MDBBox>
        <MDBBox className={"assembly-title-content"}>
          <h2>find the specific product you’re looking for</h2>
          {/* <MDBBox tag="h4" className={"text-center"}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            luctus urna vitae quam eleifend gravida.
          </MDBBox> */}
        </MDBBox>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps === nextProps)) {
      return true;
    }

    return false;
  }
);
export default AssemblyTitleTab;
