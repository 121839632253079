import React, { memo, useMemo, useCallback } from "react";
import { renderItem } from "./render";
import _ from "lodash";
import { MDBCol } from "mdbreact";

const ShopTheLookContent = memo(
  ({ data, category }) => {
    const findByCategory = useCallback(
      (data, category) => {
        let items = [];

        // filter by category if used
        items = data.map((item, index) => {
          return renderItem(item, index, category);
        });
        return items;
      },
      [data, category]
    );

    const computeContent = useMemo(() => findByCategory(data, category), [
      data,
      category,
    ]);

    return (
      <MDBCol className={`d-flex flex-wrap w-100`}>{computeContent}</MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ShopTheLookContent;
