import React from "react";
import { connect } from "react-redux";
import { commonActions } from "core/state/redux/data/common";
import ToolbarDataContainer from "./toolbar-data-container";
import { customerActions } from "core/state/redux/data/customer";

const ToolbarReduxContainer = props => {
  return <ToolbarDataContainer {...props} />;
};

const mapStateToProps = state => ({
  wishlist: state.customer.data.wishlist
});
const mapDispatchToProps = dispatch => {
  return {
    lock: afterCallback => {
      dispatch(commonActions.lock(afterCallback));
    },
    renameProject: (id, name) => {
      dispatch(customerActions.renameWishlist(id, name));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolbarReduxContainer);
