import React from "react";
import ProductRegistrationReduxContainer from "./productRegistration-redux-container";
import countries from "config/data/countries";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { filterByCountryId } from "core/helpers/customer";
const ProductRegistrationDataContainer = props => {
  const _countries = countries.countries;
  let countryDataObject = getCountries(_countries);
  let countriesData;
  countriesData = Object.keys(countryDataObject).map(key => {
    return countryDataObject[key];
  });

  let selectedCountry = filterByCountryId("US", countriesData);
  let regionsDataObject = getRegionData(countriesData, selectedCountry);

  return (
    <ProductRegistrationReduxContainer
      countries={_countries}
      regionsDataObject={regionsDataObject}
    />
  );
};

export default ProductRegistrationDataContainer;
