import customerActions from "../actions";
import messagesActions from "core/state/redux/data/messages/actions";
import commonActions from "core/state/redux/data/common/actions";

export default async (store, action) => {
  try {
    store.dispatch(
      customerActions.getCustomerInformation(
        action.token,
        false,
        "",
        action.cartToken
      )
    );
    store.dispatch(commonActions.unlock());
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
