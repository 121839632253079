import React, { useState } from "react";
import ToolbarQueryContainer from "./toolbar-query-container";

const ToolbarStateContainer = (props) => {
  let { changeSortFilter } = props;
  const [sort, setSort] = useState(
    props.category?.default_sort_by !== null &&
      props.category?.default_sort_by !== "null"
      ? props.category.default_sort_by
      : "price"
  );
  const [sortOrder, setSortOrder] = useState("ASC");

  const changeSortFilterHandler = (data, sortOrderValue) => {
    setSort(data.value);
    changeSortFilter({ [data.value]: sortOrderValue });
  };

  return (
    <ToolbarQueryContainer
      {...props}
      sort={sort}
      setSort={setSort}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      changeSortFilterHandler={changeSortFilterHandler}
      changeSortFilter={changeSortFilter}
    />
  );
};

export default ToolbarStateContainer;
