import React, { useEffect } from "react";
import MobileStateContainer from "./mobile-state-container";

const MobileCartContainer = (props) => {
  let { getCartInformation, token } = props;
  useEffect(() => {
    getCartInformation();
  }, [getCartInformation, token]);
  return <MobileStateContainer {...props} />;
};

export default MobileCartContainer;
