import React, { useState } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBCollapse } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import Price from "core/components/price";
import Input from "core/components/input";
import Options from "./options";
import Button from "core/components/button";
import { isUndefined, isEmpty } from "core/helpers/functions";
import Usps from "./usps";
import { determineIfInWishlist } from "./wishlist-functions";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Wishlist from "./wishlist";
import Addons from "./addons";
import Login from "app/pages/customer/login";
import _ from "lodash";
import { Redirect } from "react-router-dom";

const ControlsDummyComponent = props => {
  let product = props.data;
  let { dependencies, wishlist, addProductToWishlist, lock } = props;
  let [amount, setAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [editAmount, setEditAmount] = useState(
    props.editMode
      ? typeof props.cartItem.quantity !== "undefined"
        ? props.cartItem.quantity
        : 0
      : 0
  );
  let [isSelectionsOpen, setIsSelectionsOpen] = useState(true);
  let [isAddonsOpen, setIsAddosOpen] = useState(false);
  let tmpAddonQtys = {};
  if (!isUndefined(props.data.addon_products)) {
    props.data.addon_products.map(addon => {
      tmpAddonQtys[addon.sku] = 0;
    });
  }

  let [addonQtys, setAddonQtys] = useState(tmpAddonQtys);

  const setAddonAmmount = (sku, qty) => {
    if (!isUndefined(addonQtys[sku])) {
      setAddonQtys({ ...addonQtys, [sku]: qty });
    }
  };

  let addonProps = {
    isAddonsOpen,
    setIsAddosOpen,
    addonQtys,
    setAddonQtys,
    addon_products: props.data.addon_products,
    setAddonAmmount
  };

  let hasOptions = Boolean(props.data.options);
  let hasAddons = !isEmpty(props.data.addon_products);

  let inWishlist = determineIfInWishlist(wishlist.items, product.sku);
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  if (redirect) {
    return <Redirect to={`/cart`} />;
  }

  return (
    <MDBRow className="sticky pdp-sticky-part">
      <MDBCol size="12">
        <Breadcrumbs />
      </MDBCol>
      <MDBCol size="12">
        <h3 className="pdp-main-title">{product.name}</h3>
      </MDBCol>
      {!isUndefined(product.description) && (
        <MDBCol size="12" className="description">
          <p dangerouslySetInnerHTML={{ __html: product.description.html }} />
        </MDBCol>
      )}
      {hasOptions && (
        <MDBCol size="12">
          <MDBCol
            size="12"
            className="selections-title border-bottom border-top py-4 mt-4"
            onClick={() => {
              setIsSelectionsOpen(!isSelectionsOpen);
            }}
          >
            <MDBIcon icon={`${isSelectionsOpen ? "minus" : "plus"}`} />
            <span>make your selections:</span>
          </MDBCol>
          <MDBCollapse
            isOpen={isSelectionsOpen}
            className="selections-content w-100"
          >
            <Options {...props} />
          </MDBCollapse>
        </MDBCol>
      )}

      <MDBCol size="12" className="mt-5 qty-price">
        <MDBRow>
          <MDBCol md="6" sm="12" className="qty-container">
            <span>QTY</span>
            {!props.editMode ? (
              <Input
                name={``}
                type={"number"}
                wrapperClass={"quantity-field"}
                className={"qty-control-wrapper"}
                inputClass={""}
                value={amount}
                plusClick={e => setAmount(amount + 1)}
                minusClick={e => {
                  setAmount(amount === 1 ? 1 : amount - 1);
                }}
                onChange={e => {
                  let value = parseInt(e.target.value);
                  if (value < 1 || value === "" || _.isNaN(value)) {
                    setAmount(1);
                  } else {
                    setAmount(value);
                  }
                }}
              />
            ) : (
              <Input
                name={``}
                type={"number"}
                wrapperClass={"quantity-field"}
                className={"qty-control-wrapper"}
                inputClass={""}
                value={editAmount}
                plusClick={e => setEditAmount(editAmount + 1)}
                minusClick={e => {
                  setEditAmount(editAmount === 1 ? 1 : editAmount - 1);
                }}
                onChange={e => {
                  let value = parseInt(e.target.value);
                  if (value < 1 || value === "" || _.isNaN(value)) {
                    setEditAmount(1);
                  } else {
                    setEditAmount(value);
                  }
                }}
              />
            )}
          </MDBCol>
          <MDBCol
            md="6"
            sm="12"
            className="d-flex price-container flex-row-reverse"
          >
            {!isUndefined(product.price_range) &&
            product.price_range.minimum_price.final_price.value !== "#" ? (
              <Price
                price={
                  typeof props.productOptions === "undefined" ||
                  typeof props.productOptions.options === "undefined" ||
                  typeof props.productOptions.options.selectedPrice ===
                    "undefined"
                    ? product.price_range.minimum_price.final_price.value
                    : props.productOptions.options.selectedPrice
                }
                currency={
                  product.price_range.minimum_price.final_price.currency
                }
                type={"regular"}
              />
            ) : (
              <></>
            )}
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol size="12" className="ctas mt-5">
        <Button
          disabled={typeof product.sku !== "undefined" ? loading : true}
          className={"right-arrow large orange-button"}
          direction={"ml-3"}
          righticon={"true"}
          id={"cartWhite"}
          children={`${
            typeof product.sku === "undefined"
              ? "loading"
              : props.editMode === true
              ? loading === false
                ? "edit item"
                : "editing..."
              : loading === false
              ? "add to cart"
              : "adding..."
          }`}
          onClick={() => {
            let options = [];
            setLoading(true);
            if (Object.keys(props.productOptions.options).length > 1) {
              Object.keys(props.productOptions.options).map(key => {
                let _option = props.productOptions.options[key];
                if (
                  _option === "" ||
                  key === "selectedPrice" ||
                  key === "value" ||
                  _option.title === "none"
                )
                  return;
                let customizableOptionFormat;
                if (_option.title) {
                  customizableOptionFormat = {
                    id: parseInt(key),
                    value_string: String(_option.option_type_id)
                  };
                } else {
                  customizableOptionFormat = {
                    id: parseInt(key),
                    value_string: _option.swatch_name
                  };
                }
                let addToOptions = true;
                if (dependencies[key]) {
                  let depKey = Object.keys(dependencies[key])[0];
                  addToOptions =
                    props.productOptions.options[depKey].option_type_id ===
                    dependencies[key][depKey];
                }
                if (addToOptions) {
                  return options.push(customizableOptionFormat);
                }
              });
            }
            if (props.editMode === false) {
              lock({
                always: e => {},
                fail: e => {
                  setLoading(false);
                },
                success: e => {
                  setLoading(false);
                }
              });
              props.addItemToCart(amount, product.sku, options);
            } else {
              lock({
                always: e => {
                  // setLoading(true);
                },
                fail: e => {
                  setLoading(false);
                },
                success: e => {
                  setLoading(false);
                  setRedirect(true);
                }
              });
              props.updateCart([
                {
                  cart_item_id: props.cartItem.id,
                  customizable_options: options,
                  quantity: props.editMode === false ? amount : editAmount
                }
              ]);
            }

            if (!isUndefined(addonQtys)) {
              Object.keys(addonQtys).map(sku => {
                if (addonQtys[sku] > 0) {
                  props.addItemToCart(addonQtys[sku], sku, []);
                }
              });
            }
          }}
        ></Button>
        <ModalConsumer value={{ useWrapper: true }}>
          {({ showModal, hideModal }) => (
            <Button
              className={"wishlist-btn"}
              color={"#FF5100"}
              onClick={e => {
                showModal(() => {
                  // options logic
                  let options = [];
                  if (Object.keys(props.productOptions.options).length > 1) {
                    Object.keys(props.productOptions.options).map(key => {
                      let _option = props.productOptions.options[key];
                      if (
                        _option === "" ||
                        key === "selectedPrice" ||
                        key === "value"
                      )
                        return;
                      let customizableOptionFormat;
                      if (_option.title) {
                        customizableOptionFormat = {
                          id: parseInt(key),
                          value_string: String(_option.option_type_id)
                        };
                      } else {
                        customizableOptionFormat = {
                          id: parseInt(key),
                          value_string: _option.swatch_name
                        };
                      }
                      let addToOptions = true;
                      if (dependencies[key]) {
                        let depKey = Object.keys(dependencies[key])[0];
                        addToOptions =
                          props.productOptions.options[depKey]
                            .option_type_id === dependencies[key][depKey];
                      }
                      if (addToOptions) {
                        return options.push(customizableOptionFormat);
                      }
                    });
                  }
                  if (!loggedIn) {
                    e.preventDefault();
                    return (
                      <Login
                        hideModal={hideModal}
                        component={
                          <Wishlist
                            inWishlist={inWishlist}
                            wishlist={wishlist}
                            hideModal={hideModal}
                            product={product}
                            options={options}
                          />
                        }
                      />
                    );
                  } else {
                    return (
                      <Wishlist
                        inWishlist={inWishlist}
                        wishlist={wishlist}
                        hideModal={hideModal}
                        product={product}
                        options={options}
                      />
                    );
                  }
                });
              }}
            >
              {!inWishlist ? (
                <MDBIcon far icon="heart" />
              ) : (
                <MDBIcon icon="heart" />
              )}
              <MDBIcon icon="caret-down" />
            </Button>
          )}
        </ModalConsumer>
      </MDBCol>

      <MDBCol size="12" className="usps mt-5 mb-5 ml-4 mr-4">
        <MDBRow className="d-flex justify-content-between">
          <Usps product={product} {...props} />
        </MDBRow>
      </MDBCol>

      {hasAddons && !isUndefined(props.data.addon_products) && (
        <MDBCol>
          <MDBRow>
            <Addons {...addonProps} addItemToCart={props.addItemToCart} />
          </MDBRow>
        </MDBCol>
      )}
    </MDBRow>
  );
};

export default ControlsDummyComponent;
