// logic is inversed, true means that an error exists

export const validate = (
  contact_name,
  street_billing,
  city,
  state,
  zipcode,
  contact_name_shipping,
  street_shipping,
  city_shipping,
  state_shipping,
  zipcode_shipping,
  email,
  email_shipping,
  is_same,
  terms,
  billing_phone,
  shipping_phone,
  country,
  shipping_country
) => {
  return {
    contact_name: contact_name.length === 0,
    street_billing: street_billing.length === 0,
    city: city.length === 0,
    state: country === "CA" || country === "US" ? state.length === 0 : false,
    zipcode: zipcode.length === 0,
    contact_name_shipping: is_same
      ? contact_name.length === 0
      : contact_name_shipping.length === 0,
    email: email.length === 0 || !ValidateEmail(email),
    street_shipping: is_same
      ? street_billing.length === 0
      : street_shipping.length === 0,
    city_shipping: is_same ? city.length === 0 : city_shipping.length === 0,
    state_shipping: is_same
      ? country === "CA" || country === "US"
        ? state.length === 0
        : false
      : shipping_country === "CA" || shipping_country === "US"
      ? state_shipping.length === 0
      : false,
    zipcode_shipping: is_same
      ? zipcode.length === 0
      : zipcode_shipping.length === 0,
    email_shipping: is_same
      ? email.length === 0 || !ValidateEmail(email)
      : email_shipping.length === 0 || !ValidateEmail(email_shipping),
    terms: terms === false,
    billing_phone: billing_phone.length === 0,
    shipping_phone: is_same
      ? billing_phone.length === 0
      : shipping_phone.length === 0,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};
function ValidateEmail(mail) {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
}
