import React, { useState } from "react";
import MiniCartItem from "./MiniCartItem";

const MiniCartItemStateContainer = props => {
  let { item } = props;
  const [quantity, setQuantity] = useState(item.quantity);
  const [loading, setLoading] = useState(false);
  return (
    <MiniCartItem
      {...props}
      quantity={quantity}
      setQuantity={setQuantity}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default MiniCartItemStateContainer;
