import React, { useState, useEffect, Fragment } from "react";
import { MDBDataTable, MDBCol, MDBRow } from "mdbreact";
const DataTable = ({ data, summary }) => {
  const [screenwidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);

  let columns = data.columns;
  let items = data.rows;

  return screenwidth > 768 ? (
    <>
      <MDBDataTable
        borderless
        data={data}
        paging={false}
        className={`order-information-table`}
        searching={false}
        sortable={false}
      />
      <MDBDataTable
        data={summary}
        borderless
        responsive
        paging={false}
        className={`order-information-table`}
        searching={false}
        sortable={false}
        id={`order-view-summary-footer`}
      />
    </>
  ) : (
    <>
      {items.map((item, index) => {
        return (
          <MDBRow
            className="order-cell mb-4 border-bottom"
            key={`mobile-order-view-item-${item.product_name}`}
          >
            <MDBCol lg="12">
              <MDBRow className="order-attribute mb-4">
                {columns.map((column) => {
                  return (
                    <Fragment key={`item-${item.product_name}-${column.label}`}>
                      <MDBCol size="6" className="order-attribute-name">
                        <p>{column.label}</p>
                      </MDBCol>
                      <MDBCol size="6" className="order-attribute-value">
                        {item[column.field]}
                      </MDBCol>
                    </Fragment>
                  );
                })}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        );
      })}
    </>
  );
};

export default DataTable;
