import React from "react";
import Pages from "./pages";
import customerPages, {
  ErrorPage,
} from "app/config/routing/pages/customerPages";
import Dashboard from "./dashboard";

const PagesStateContainer = (props) => {
  let { currentLocation } = props;
  let selectedPage = Dashboard;
  let filteredPage = customerPages.find((page) => {
    return (
      (page.exact && page.url.includes(currentLocation)) ||
      (page.exact === false && currentLocation.includes(page.url))
    );
  });
  if (typeof filteredPage !== "undefined") {
    selectedPage = filteredPage.component;
  }

  return <Pages {...props} selectedPage={selectedPage} />;
};

export default PagesStateContainer;
