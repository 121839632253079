import React from "react";
import { MDBRow, MDBCol, MDBLink } from "mdbreact";
import Price from "core/components/price";
import Input from "core/components/input";
import Icon from "app/assets/icon/icon";
import Button from "core/components/button";
import themeConfiguration from "config/themeConfiguration";
import _ from "lodash";
import Image from "core/components/image/image";

const AddonItem = ({
  addon,
  qty,
  setQty,
  addItemToCart,
  lock,
  loading,
  setLoading,
}) => {
  return (
    <MDBRow className={`addon-item-row`}>
      <MDBCol size={`3`} xl={`1`} lg={`1`} md={`1`} className={`p-0`}>
        <Image source={addon.image} />
      </MDBCol>
      <MDBCol
        size={`3`}
        xl={`6`}
        lg={`6`}
        md={`6`}
        className={`d-flex flex-column align-items-start p-2`}
      >
        <MDBRow>
          <MDBCol lg="12" size="12" className="addon-name">
            <MDBLink
              to={"/" + addon.url.split(themeConfiguration.magento_url)[1]}
              className={`p-0`}
            >
              {addon.name}
            </MDBLink>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12" size="12" className="addon-price">
            <Price price={addon.price} type={`fixed`} />
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol
        size="6"
        xl={`5`}
        lg="5"
        md={`5`}
        className="addon-control p-0 col-xs-12"
      >
        <MDBCol className={`${qty === 1 ? " disable-minus" : ""}`}>
          <Input
            name={``}
            type={"number"}
            wrapperClass={"quantity-field"}
            className={"qty-control-wrapper"}
            inputClass={""}
            value={qty}
            plusClick={(e) => {
              setQty(qty + 1);
            }}
            minusClick={(e) => {
              setQty(qty === 1 ? 1 : qty - 1);
            }}
            onChange={(e) => {
              let value = parseInt(e.target.value);
              if (value < 1 || value === "" || _.isNaN(value)) {
                setQty(1);
              } else {
                setQty(value);
              }
            }}
          />
        </MDBCol>

        <MDBCol>
          <MDBRow>
            <Button
              className={"large orange-button addon-add-to-cart"}
              children={<Icon icon={`addToCart`} />}
              disabled={loading}
              onClick={() => {
                let options = [];
                if (!loading) {
                  setLoading(true);
                  lock({
                    always: (e) => {
                      // setLoading(true);
                    },
                    fail: (e) => {
                      setLoading(false);
                    },
                    success: (e) => {
                      setLoading(false);
                    },
                  });

                  addItemToCart(qty, addon.sku, options);
                }
              }}
            />
          </MDBRow>
        </MDBCol>
      </MDBCol>
    </MDBRow>
  );
};

export default AddonItem;
