import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Link } from "react-router-dom";
import Price from "core/components/price";
import FormatDate from "core/components/date";

const OrderGrid = (props) => {
  const [screenwidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);
  let orders = null;
  if (typeof props.orders[0] !== "undefined") orders = props.orders[0].items;
  if (screenwidth > 768) {
    return (
      <MDBTable className="order-grid">
        <MDBTableHead>
          <tr>
            <th>order #</th>
            <th>date</th>
            <th>ship to</th>
            <th>order total</th>
            <th>status</th>
            <th>action</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {orders !== null ? (
            orders.map((order) => {
              let shipTo =
                order.addressInformation.shippingAddress.firstname +
                " " +
                order.addressInformation.shippingAddress.lastname;
              return (
                <>
                  <tr>
                    <td>{order.order_number}</td>
                    <td>
                      <FormatDate date={order.created_at} />
                    </td>
                    <td>{shipTo}</td>
                    <td>
                      <Price price={order.grand_total} />
                    </td>
                    <td>{order.status}</td>
                    <td>
                      <Link to={`/customer/orders/${order.order_number}`}>
                        view order
                      </Link>
                    </td>
                  </tr>
                </>
              );
            })
          ) : (
            <>
              <tr>
                <td>no orders found</td>
              </tr>
            </>
          )}
        </MDBTableBody>
      </MDBTable>
    );
  } else {
    return (
      <MDBRow>
        <MDBCol lg="12" className="mobile-order-grid">
          {orders !== null ? (
            orders.map((order) => {
              let shipTo =
                order.addressInformation.shippingAddress.firstname +
                " " +
                order.addressInformation.shippingAddress.lastname;
              return (
                <MDBRow className="order-cell mb-4 border-bottom">
                  <MDBCol lg="12">
                    <MDBRow className="order-attribute mb-4">
                      <MDBCol size="6" className="order-attribute-name">
                        order #
                      </MDBCol>
                      <MDBCol size="6" className="order-attribute-value">
                        {order.order_number}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="order-attribute mb-4">
                      <MDBCol size="6" className="order-attribute-name">
                        date
                      </MDBCol>
                      <MDBCol size="6" className="order-attribute-value">
                        <FormatDate date={order.created_at} />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="order-attribute mb-4">
                      <MDBCol size="6" className="order-attribute-name">
                        ship to
                      </MDBCol>
                      <MDBCol size="6" className="order-attribute-value">
                        {shipTo}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="order-attribute mb-4">
                      <MDBCol size="6" className="order-attribute-name">
                        order total
                      </MDBCol>
                      <MDBCol size="6" className="order-attribute-value">
                        <Price price={order.grand_total} />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="order-attribute mb-4">
                      <MDBCol size="6" className="order-attribute-name">
                        status
                      </MDBCol>
                      <MDBCol size="6" className="order-attribute-value">
                        {order.status}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="order-attribute mb-4">
                      <MDBCol size="6" className="order-attribute-name">
                        action
                      </MDBCol>
                      <MDBCol size="6" className="order-attribute-value">
                        <Link to={`/customer/orders/${order.order_number}`}>
                          view order
                        </Link>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              );
            })
          ) : (
            <>
              <tr>
                <td>no orders found</td>
              </tr>
            </>
          )}
        </MDBCol>
      </MDBRow>
    );
  }
};

export default OrderGrid;
