import apolloClient from "core/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "core/state/redux/data/common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";
import { gql } from "graphql.macro";

const SET_SHIPPING_ADDRESSES_ON_CART = gql`
  mutation SetShippingAddressesOnCart($input: SetShippingAddressesOnCartInput) {
    setShippingAddressesOnCart(input: $input) {
      cart {
        id
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              value
              currency
            }
            label
          }
        }
        available_payment_methods {
          code
          title
        }

        items {
          id
          prices {
            discounts {
              amount {
                currency
                value
              }
              label
            }
            price {
              currency
              value
            }
            row_total {
              currency
              value
            }
            row_total_including_tax {
              currency
              value
            }
            total_item_discount {
              currency
              value
            }
          }

          ... on SimpleCartItem {
            customizable_options {
              label
              values {
                label
                value
                price {
                  type
                  units
                  value
                }
              }
            }
          }
          product {
            url_key
            name
            sku
            small_image {
              url
            }
            lead_time
            crosssell_products {
              url_key
              name
              image {
                url
                label
              }
              price_range {
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    currency
                    value
                  }
                  fixed_product_taxes {
                    amount {
                      currency
                      value
                    }
                    label
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }
            }
            price_range {
              minimum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  currency
                  value
                }
                fixed_product_taxes {
                  amount {
                    currency
                    value
                  }
                  label
                }
                regular_price {
                  currency
                  value
                }
              }
              maximum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  currency
                  value
                }
                fixed_product_taxes {
                  amount {
                    currency
                    value
                  }
                  label
                }
                regular_price {
                  currency
                  value
                }
              }
            }
          }
          quantity
        }
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
        }
        shipping_addresses {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
          region {
            code
            label
          }
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              currency
              value
            }

            price_incl_tax {
              currency
              value
            }
          }
          selected_shipping_method {
            amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        selected_payment_method {
          title
          purchase_order_number
          code
        }
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
        }
        email
        applied_coupons {
          code
        }
      }
    }
  }
`;
export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cartToken;
    let input = {
      cart_id: cart_id,
      shipping_addresses: [{ ...action.shipping_address }],
    };

    const { data } = await apolloClient.mutate({
      mutation: SET_SHIPPING_ADDRESSES_ON_CART,
      variables: { input: input },
      fetchPolicy: "no-cache",
    });
    if (data) {
      store.dispatch(
        customerActions.setCartInformation(data.setShippingAddressesOnCart.cart)
      );
      store.dispatch(
        messagesActions.addMessage("updated cart information", "success")
      );
      store.dispatch(commonActions.unlock("success"));
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
