import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import CheckoutOrderReview from "../../../checkoutOrderReview";
import Icon from "app/assets/icon/icon";
const OrderSummaryInformation = (props) => {
  return (
    <MDBContainer fluid id={`order-summary-popup`}>
      <MDBRow className={`header position-relative`}>
        <MDBCol size={12}>
          <h4>order summary</h4>
        </MDBCol>
        <MDBCol
          size={3}
          onClick={props.hideModal}
          className={`position-absolute close-icon`}
        >
          <Icon icon={`closeBtnGrey`} onClick={props.hideModal} />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <CheckoutOrderReview />
      </MDBRow>
    </MDBContainer>
  );
};

export default OrderSummaryInformation;
