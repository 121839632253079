import React, { useState } from "react";
import { useCompare } from "core/state/hooks/compareHook";
import { default as themeConfiguration } from "config/themeConfiguration";
import CompareListInner from "./compareListInner/compareListInner";
import { MDBBox, MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { CSSTransition } from "react-transition-group";

const CompareList = (props) => {
  const [
    addCompare,
    removeCompare,
    getCompareData,
    getCompareItems,
    clearCompareList,
    checkIfProductIsInCompareById,
    getOpenState,
    setOpenState,
  ] = useCompare();

  let compareData = getCompareItems();
  const product_url =
    themeConfiguration.magento_url +
    themeConfiguration.media_url +
    themeConfiguration.catalog_url;

  const [toggleOptions, setToggleOptions] = useState(false);

  const compareToggle = () => {
    setToggle(!toggle);
  };
  const toggleAttr = () => {
    setToggleOptions(!toggleOptions);
  };

  // remapping functions for ease of use
  let toggle = getOpenState();
  let setToggle = setOpenState;

  return (
    <>
      {compareData.length > 0 && (
        <CSSTransition
          key={"compare-list-container"}
          classNames={"new-transition"}
          appear={true}
          in={getOpenState()}
          timeout={{
            appear: 400,
            enter: 400,
            exit: 400,
          }}
        >
          <MDBContainer
            className={`p-0 compare-list-container ${
              toggle ? "open" : "closed"
            } slide`}
            fluid
          >
            <MDBRow>
              <MDBCol className="text-center">
                <MDBContainer className={`container-btn ${toggle}`} fluid>
                  <MDBRow>
                    <MDBCol>
                      <MDBBox
                        onClick={(e) => {
                          compareToggle();
                        }}
                        className={`compare show-${toggle}`}
                      >
                        <MDBBox className={"circle-btn"}>
                          {(toggle && (
                            <MDBIcon
                              className="compare-icon"
                              icon="arrow-down"
                            />
                          )) || (
                            <MDBIcon className="compare-icon" icon="arrow-up" />
                          )}
                        </MDBBox>
                        <br />
                        <h5 className="text-capitalize font-weight-bold pb-4">
                          Compare List (
                          {compareData.length > 1
                            ? `${compareData.length} products`
                            : `${compareData.length} product`}
                          )
                        </h5>
                      </MDBBox>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <MDBContainer className={`compare show-${toggle}`}>
                  <MDBRow>
                    <MDBCol>
                      <MDBBox className="compare-inner-list  d-flex align-content-stretch align-content-center flex-wrap">
                        {compareData !== null &&
                          compareData.map((item) => {
                            let image = {
                              label: item.name,
                              url: product_url + item.thumbnail,
                            };

                            return (
                              <CompareListInner
                                item={item}
                                image={image}
                                toggleOptions={toggleOptions}
                              />
                            );
                          })}

                        <MDBBox className="w-100 options-btn slide py-4">
                          <button
                            className={`options slide options-${toggleOptions}`}
                            onClick={(e) => {
                              toggleAttr();
                            }}
                          >
                            {!toggleOptions
                              ? "Show Product Options"
                              : "Hide Product Options"}
                          </button>
                        </MDBBox>
                      </MDBBox>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </CSSTransition>
      )}
    </>
  );
};
export default CompareList;
