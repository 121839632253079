import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";

const Accountinformation = props => {
  let isNewsletter =
    typeof props.customer.newsletter !== "undefined" ||
    props.customer.newsletter === true;
  return (
    <MDBRow className="mb-md-5 mb-sm-5 mb-xs-5">
      <MDBCol lg="12">
        <MDBRow>
          <MDBCol lg="12" className="mb-3 mt-5">
            <h3 className="gray medium">account information</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="6" className="mb-4">
            <MDBRow>
              <MDBCol lg="12" className="mb-3">
                <h4 className="gray heavy large">contact information</h4>
              </MDBCol>
              <MDBCol lg="12">
                <p className="light-gray">
                  {props.customer.firstname} {props.customer.lastname}
                  <br />
                  {props.customer.email}
                </p>
              </MDBCol>
              <MDBCol lg="9" md="6" className="mt-4">
                <Link to="/customer/information">Edit</Link>
                <Link className="float-right" to="/customer/information">
                  change password
                </Link>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol lg="6">
            <MDBRow>
              <MDBCol lg="12" className="mb-lg-3 mt-xs-5 mt-sm-5 mt-md-0">
                <h4 className="gray heavy large">newsletter</h4>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol lg="12">
                <p className="light-gray">
                  {isNewsletter
                    ? "You are currently subscribed to our newsletter"
                    : "You are not currently subscribed to our newsletter"}
                  <br />
                  <br />
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol lg="6" className="mt-4">
                <Link to="/customer/newsletter">Edit</Link>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default Accountinformation;
