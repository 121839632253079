import React from "react";
import Popout from "../dropdown/poput";
import { MDBRow, MDBCol, MDBBox } from "mdbreact";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { truncateString } from "core/helpers/functions";

const ColorPicker = (props) => {
  let { option, selections, availableColors } = props.optionProps;
  let {
    parentSize,
    sizes,
    reduceProductOptions,
    product,
    specialtyPrice,
    shearlingPrice,
  } = props;
  let availableOptions = option.value;
  const changeOption = (value) => {
    let selectionTmp = {};

    if (selections.value === undefined) {
      let _value = { [option.option_id]: value };
      selectionTmp["value"] = _value;
    } else {
      let _value = { [option.option_id]: value };
      selectionTmp["value"] = { ...selections.value, ..._value };
    }

    let newSelection = {
      [option.option_id]: value,
      ...selectionTmp,
    };
    let newSelections = { ...selections, ...newSelection };

    let hasSpecialtyPrice = false;
    let hasShearlingPrice = false;
    Object.keys(newSelections).map((key) => {
      if (newSelections[key].type === "Shearling") {
        hasShearlingPrice = true;
      }
      if (newSelections[key].type === "Speciality") {
        hasSpecialtyPrice = true;
      }
    });

    if (hasSpecialtyPrice) {
      newSelections = { ...newSelections, specialtyPrice: specialtyPrice };
    } else {
      newSelections = { ...newSelections, specialtyPrice: 0 };
    }
    if (hasShearlingPrice) {
      newSelections = { ...newSelections, shearlingPrice: shearlingPrice };
    } else {
      newSelections = { ...newSelections, shearlingPrice: 0 };
    }

    newSelections = {
      ...newSelections,
      totalPrice:
        product.options.selectedPrice +
        newSelections.shearlingPrice +
        newSelections.specialtyPrice,
    };

    reduceProductOptions({ ...product, options: newSelections });
  };

  if (!availableOptions) {
    if (availableColors) {
      availableOptions = availableColors[option.option_id];
    }
  }
  if (!parentSize) {
    parentSize = 6;
  }
  if (!sizes) {
    sizes = {
      title: 4,
      selector: 8,
    };
  }
  if (!availableOptions) {
    availableOptions = [];
  }

  return (
    <MDBCol
      lg={parentSize}
      className={`color-option-container ${props.parentClasses}`}
    >
      <MDBRow>
        <MDBCol lg={sizes.title} size="6" className={props.titleClasses}>
          <span className="option-title">{option.title}</span>
        </MDBCol>
        <MDBCol lg={sizes.selector} size="6" className={props.selectorClasses}>
          <ModalConsumer>
            {({ showModal, hideModal }) => (
              <MDBBox
                onClick={() => {
                  showModal(
                    () => {
                      return (
                        <Popout
                          {...props}
                          option={option}
                          isCustom={true}
                          hideModal={hideModal}
                          contentClass={"color-selection"}
                          isColorPicker={true}
                          availableOptions={availableOptions}
                          changeOption={changeOption}
                        />
                      );
                    },
                    { withWrapper: false }
                  );
                }}
                className={`w-100 color-selection-button ${
                  product.options[option.option_id].swatch_name === null
                    ? "make-selection-validation"
                    : ""
                } `}
              >
                {product.options[option.option_id].swatch_name ? (
                  <div
                    style={{
                      backgroundImage: `url(${
                        product.options[option.option_id].swatch_image
                      })`,
                      backgroundColor: `${
                        product.options[option.option_id].color_hex
                      }`,
                      borderColor: "#fff",
                    }}
                    className={`color-swatch`}
                  ></div>
                ) : (
                  <></>
                )}
                {product.options[option.option_id].swatch_name !== null
                  ? truncateString(
                      product.options[option.option_id].swatch_name,
                      10
                    )
                  : "make selection"}
              </MDBBox>
            )}
          </ModalConsumer>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default ColorPicker;
