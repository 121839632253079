import React, { useState, useEffect } from "react";
import Sidebar from "./sidebar";

const SidebarStateContainer = props => {
  const [screenwidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);

  return <Sidebar {...props} screenwidth={screenwidth} />;
};

export default SidebarStateContainer;
