import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MDBRow, MDBCol } from "mdbreact";
import Toolbar from "./toolbar";
import Sidebar from "./sidebar";
import Listing from "./listing";

const CategoryComponentContainer = (props) => {
  let { allCategories, categoryFilter } = props;
  const [showFilters, setShowFilters] = useState(false);
  const isTabOrMob = useMediaQuery({ maxWidth: 992 });
  const [toggle, setToggle] = useState(true);
  const toggleDropdown = (props) => {
    let currentToggle = toggle;
    setToggle(!currentToggle);
  };
  const hideFilters = () => {
    setToggle(true);
  };
  return (
    <>
      {!isTabOrMob && (
        <MDBCol lg="3" className="sidebarcol">
          <Sidebar
            hideFilters={() => {}}
            key={"sidebar"}
            category_id={categoryFilter}
            allCategories={allCategories}
            variables={props.variables}
          />
        </MDBCol>
      )}
      {isTabOrMob && <></>}
      <MDBCol lg="9" className="listing-with-toolbar">
        <Toolbar
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          toggle={toggle}
          toggleDropdown={toggleDropdown}
        />
        <MDBRow>
          <MDBCol md="12" className="listing-wrapper">
            <Listing
              key={"listing"}
              category_id={categoryFilter}
              allCategories={allCategories}
            />
          </MDBCol>
        </MDBRow>
        <Toolbar
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          toggle={toggle}
          toggleDropdown={toggleDropdown}
          paginationOnly={true}
        />
      </MDBCol>
    </>
  );
};

export default CategoryComponentContainer;
