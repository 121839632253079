import React, { useState, Fragment } from "react";
import { MDBCol, MDBRow, MDBLink } from "mdbreact";
import Price from "core/components/price";
import Button from "core/components/button";
import { isEmpty } from "core/helpers/functions";
import Select2 from "core/components/select";
import { validate, shouldMarkError } from "./validator";
import MaskedInput from "react-text-mask";
import Coupon from "app/layout/coupon";
import DiscountSummary from "app/layout/coupon/discountSummary";
import _ from "lodash";

const Summary = ({
  prices,
  discountCode,
  setDiscountCode,
  setShippingAddress,
  lock,
  zip,
  setZip,
  state,
  setState,
  loading,
  setLoading,
  touched,
  setTouched,
  zipMask,
  addMessage,
  regionsDataObject,
  changed,
  setChanged,
  applyCouponToCart,
  removeCouponFromCart,
  couponLoading,
  setCouponLoading,
  items,
}) => {
  if (prices.length === 0) return <></>;
  let subtotal = prices.subtotal_excluding_tax.value - 0; // @TODO: replace the 0 with all taxes
  let grandTotal = prices.subtotal_including_tax.value;
  let shipping = 0; // @TODO: Replace this with shipping estimate
  let taxes = prices.applied_taxes;
  let taxes_price = 0;
  if (isEmpty(taxes)) {
    shipping = "not estimated";
    taxes = 0;
  } else {
    prices.applied_taxes.map((tax) => {
      taxes_price = taxes_price + tax.amount.value;
    });
  }

  let errors = validate(zip);
  let note = false;
  let highest_lead_time = 0;

  return (
    <>
      <MDBRow className="summary-container">
        <MDBCol size="12" className="summary border-bottom pb-2 border-gray">
          <span className="summary-title">Summary</span>
        </MDBCol>
        <MDBCol
          size="12"
          className="summary-items border-bottom pb-2 border-gray"
        >
          <MDBRow>
            <MDBCol size="12" className="summary-subtotal">
              <MDBRow className="my-3">
                <MDBCol
                  size="6"
                  className="summary-item-title d-flex justify-content-start"
                >
                  subtotal
                </MDBCol>
                <MDBCol
                  size="6"
                  className="summary-item-value d-flex justify-content-end"
                >
                  <Price price={subtotal} type={`fixed`} />
                </MDBCol>
              </MDBRow>
              <MDBRow className="summary-estimates my-3">
                <MDBCol
                  size="12"
                  className="summary-item-title d-flex justify-content-start"
                >
                  Estimate Tax
                </MDBCol>
                <MDBCol
                  size="12"
                  className="summary-item-value d-flex justify-content-end mt-3"
                >
                  {/* <InputField
                  className={`custom-input w-100`}
                  label={`zip code`}
                  type="text"
                  wrapperClass={"w-100"}
                  value={zip}
                  onChange={e => {
                    setZip(e.target.value);
                  }}
                /> */}
                  <div className={`md-form`}>
                    <MaskedInput
                      id="mask-input"
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      value={zip}
                      disabled={loading}
                      onChange={(e) => {
                        setZip(e.target.value);
                        if (e.target.value.length === 5) {
                          setChanged(false);
                        } else {
                          setChanged(true);
                        }
                      }}
                      className={`
                  form-control custom-input w-100 validate default-input-field mb-2
                    ${
                      shouldMarkError("zipcode", errors, touched)
                        ? "validation-error"
                        : ""
                    } `}
                      placeholder={
                        shouldMarkError("zipcode", errors, touched)
                          ? "zip code is required"
                          : "zip code"
                      }
                      onBlur={(e) => {
                        setTouched({ ...touched, zipcode: true });
                      }}
                      mask={zipMask}
                      guide={false}
                      render={(ref, inputProps) => {
                        return <input {...inputProps} ref={ref} />;
                      }}
                    />
                  </div>
                </MDBCol>
                <MDBCol>
                  <Button
                    disabled={changed}
                    onClick={(e) => {
                      e.preventDefault();
                      if (errors.zipcode === false) {
                        setLoading(true);
                        setChanged(true);
                        lock({
                          always: (e) => {
                            // setLoading(true);
                          },
                          fail: (e) => {
                            setLoading(false);
                          },
                          success: (e) => {
                            setLoading(false);
                          },
                        });
                        setShippingAddress({
                          address: {
                            firstname: "guest",
                            lastname: "guest",
                            country_code: "US",
                            street: ["street 1"],
                            telephone: "estimate",
                            region: "NY",
                            city: "city",
                            postcode: zip,
                          },
                        });
                      } else {
                        if (errors.zipcode === true) {
                          addMessage("please enter a valid zip code", "danger");
                        }
                      }
                    }}
                    className={`mb-4 w-100`}
                  >
                    {loading ? "Calculating..." : "Calculate"}
                  </Button>
                </MDBCol>
              </MDBRow>
              <MDBRow className="my-3">
                <MDBCol
                  size="6"
                  className="summary-item-title d-flex justify-content-start"
                >
                  tax
                </MDBCol>
                <MDBCol
                  size="6"
                  className="summary-item-value d-flex justify-content-end"
                >
                  {!loading ? (
                    <Price price={taxes_price} type={`fixed`} />
                  ) : (
                    "calculating..."
                  )}
                </MDBCol>
              </MDBRow>
              {/* <MDBRow className="my-3">
              <MDBCol
                size="6"
                className="summary-item-title d-flex justify-content-start"
              >
                shipping
              </MDBCol>
              <MDBCol
                size="6"
                className="summary-item-value d-flex justify-content-end"
              >
                {typeof shipping === "string" && shipping}
                {typeof shipping === "number" && <Price price={shipping} />}
              </MDBCol>
            </MDBRow> */}
              <DiscountSummary />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="12" className="summary-total">
          <MDBRow className="mt-4">
            <MDBCol
              size="6"
              className="summary-item-title d-flex justify-content-start"
            >
              order total
            </MDBCol>
            <MDBCol
              size="6"
              className="summary-item-value d-flex justify-content-end"
            >
              {!loading ? (
                typeof grandTotal === "number" && grandTotal !== 0 ? (
                  <Price price={grandTotal} type={`fixed`} />
                ) : (
                  "not estimated"
                )
              ) : (
                "calculating..."
              )}
            </MDBCol>
          </MDBRow>
        </MDBCol>
        {note && (
          <MDBCol size="12" className="summary-note">
            <MDBRow className="my-5">
              <MDBCol
                size="2"
                className="summary-item-title d-flex justify-content-start"
              >
                note:
              </MDBCol>
              <MDBCol
                size="10"
                className="summary-item-value d-flex justify-content-end"
              >
                <span className="note-text">{note}</span>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        )}
        <Coupon />

        <MDBCol size="12" className="summary-cta mt-5 px-0 px-lg-4">
          <MDBLink to={`/checkout`}>
            <Button
              color={"primary"}
              className={"right-arrow w-100 orange-button"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
              text={"secure checkout"}
            />
          </MDBLink>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Summary;
