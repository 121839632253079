import React, { useState, useEffect } from "react";
import Icon from "app/assets/icon/icon";
import Pagination from "../pagination";
import Select2 from "core/components/select";
import { isUndefined, isObject } from "core/helpers/functions";
import Overlay from "app/layout/overlay";
import { MDBRow, MDBCol, MDBBtn, MDBIcon, MDBBox } from "mdbreact";
import Sidebar from "app/pages/catalog/category/sidebar";
import { useMediaQuery } from "react-responsive";
import { getActiveFilters } from "app/pages/catalog/category/sidebar/activeFilter/functions";
import { isEmpty } from "core/helpers/functions";
import Button from "core/components/button";

const Toolbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [toggleFilter, setToggleFilter] = useState("filters");
  const [mobileFilterAllToggle, setMobileFilterAllToggle] = useState(false);
  const isTabOrMob = useMediaQuery({ maxWidth: 992 });
  let {
    data,
    sort,
    sortOrder,
    setSortOrder,
    changeSortFilterHandler,
    paginationOnly,
    allCategories,
    categoryFilter,
    hideFilters,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.pageNumber]);

  if (isUndefined(data)) return "";
  let active_filters = getActiveFilters(props.filters);
  let totalCount = props.pageInfo.totalCount;
  let sortByOptions = data.sort_fields.options;
  let totalPages = props.pageInfo.pageInfo.total_pages;

  sortByOptions = sortByOptions.filter((item) => {
    return item.value !== "position";
  });

  sortByOptions.find((item, index) => {
    if (item.value === "name") {
      sortByOptions[index].label = "Alphabetical";
    }
  });

  return (
    <MDBRow>
      <MDBCol md="12" id="toolbar">
        {(isUndefined(paginationOnly) && (
          <>
            <MDBRow>
              <MDBCol md="12">
                <h3 className="plp-main-title">{props.category.name}</h3>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol lg="6" md="12">
                <div className="sort-control">
                  {!isTabOrMob && totalCount !== 0 && (
                    <>
                      <div className="sort-options">
                        <div className="sort-options-content">
                          {totalCount > 1 && (
                            <>
                              <span className="sorter-label">Sort By</span>
                              <Select2
                                name={"sort"}
                                type={"text"}
                                value={sort}
                                onChange={(event) =>
                                  changeSortFilterHandler(event, sortOrder)
                                }
                                required={true}
                                wrapperClass="small main-select"
                                placeholder={"alphabetical"}
                                options={sortByOptions}
                                useCustom={true}
                                openIndicator="caret-up"
                                closedIndicator="caret-down"
                                isSearchable={false}
                              />
                              <span
                                className={`select-arrow ${
                                  sortOrder === "ASC" ? "ASC" : "DESC"
                                }`}
                                onClick={(e) => {
                                  let sortValue;
                                  let _sortOrder =
                                    sortOrder === "ASC" ? "DESC" : "ASC";
                                  if (isObject(sort)) {
                                    sortValue = sort;
                                  } else {
                                    sortValue = {
                                      label: "Alphabetical",
                                      value: sort,
                                      __typename: "SortField",
                                    };
                                  }

                                  changeSortFilterHandler(
                                    sortValue,
                                    _sortOrder
                                  );
                                  setSortOrder(_sortOrder);
                                }}
                              >
                                <Icon icon={`rightArrowGray`} />
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {isTabOrMob && (
                    <>
                      <MDBRow className="toolbar-mobile">
                        <MDBCol className="show-filters-mobile">
                          <div>
                            <MDBBtn
                              onClick={() => {
                                setIsOpen(true);
                              }}
                            >
                              Show filters
                            </MDBBtn>
                            <Overlay
                              isOpen={isOpen}
                              className={`overlay-${isOpen}`}
                            >
                              <MDBRow id="sidebar" className={`sidebar`}>
                                <MDBCol md="12" className="filter-title">
                                  <div
                                    md="12"
                                    className="border-bottom d-flex justify-content-center align-items-center title"
                                  >
                                    {(toggleFilter === "filters" &&
                                      toggleFilter) || (
                                      <>
                                        <MDBIcon
                                          className="filter-back"
                                          icon="caret-left"
                                          onClick={() => {
                                            setToggleFilter("filters");
                                            setMobileFilterAllToggle(
                                              !mobileFilterAllToggle
                                            );
                                          }}
                                        />
                                        {toggleFilter}
                                      </>
                                    )}
                                    <div className="close-btn">
                                      <MDBIcon
                                        onClick={(e) => {
                                          setIsOpen(false);
                                        }}
                                        far
                                        icon="times-circle"
                                      />
                                    </div>
                                  </div>
                                </MDBCol>
                                <MDBCol
                                  className={`filter-item-${toggleFilter}`}
                                >
                                  <Sidebar
                                    {...props}
                                    toggleFilter={toggleFilter}
                                    setToggleFilter={setToggleFilter}
                                    hideFilters={hideFilters}
                                    key={"sidebar"}
                                    category_id={categoryFilter}
                                    allCategories={allCategories}
                                    variables={props.variables}
                                  />
                                </MDBCol>
                              </MDBRow>
                              <MDBRow className="overlay-footer">
                                <div className="overlay-footer-inner">
                                  <MDBCol>
                                    <MDBBtn onClick={props.clearFilters}>
                                      clear filters
                                    </MDBBtn>
                                  </MDBCol>
                                  <MDBCol>
                                    <Button
                                      color={"primary"}
                                      onClick={() => {
                                        setIsOpen(false);
                                      }}
                                      className={
                                        "orange-button without-icon apply-btn"
                                      }
                                      children={"apply"}
                                    />
                                  </MDBCol>
                                </div>
                              </MDBRow>
                            </Overlay>
                          </div>
                        </MDBCol>
                        <MDBCol className="sort-options ">
                          <div className="sort-options-content">
                            <MDBBtn
                              className="sort-by-btn"
                              onClick={() => {
                                setIsOpenSort(true);
                              }}
                            >
                              Sort By
                            </MDBBtn>
                            <Overlay
                              isOpen={isOpenSort}
                              setIsOpen={setIsOpenSort}
                              className={`overlay-${isOpenSort}`}
                            >
                              <MDBRow id="sidebar" className={`sidebar`}>
                                <MDBCol md="12" className="filter-title">
                                  <MDBCol
                                    md="12"
                                    className="border-bottom d-flex justify-content-center align-items-center title"
                                  >
                                    Sort By
                                    <div className="close-btn">
                                      <MDBIcon
                                        onClick={() => {
                                          setIsOpenSort(false);
                                        }}
                                        far
                                        icon="times-circle"
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBCol>
                                <MDBCol className={"p-0"}>
                                  {Object.keys(sortByOptions).map((index) => {
                                    let sortElement = sortByOptions[index];
                                    return (
                                      <MDBBox className="sort-title">
                                        <h3
                                          key={`sort-${index}`}
                                          onClick={(e) => {
                                            let sortValue;
                                            setSortOrder(
                                              sortOrder === "ASC"
                                                ? "DESC"
                                                : "ASC"
                                            );
                                            if (isObject(sort)) {
                                              sortValue = sort;
                                            } else {
                                              sortValue = { value: sort };
                                            }
                                            changeSortFilterHandler(
                                              sortValue,
                                              sortOrder
                                            );
                                            setIsOpenSort(false);
                                          }}
                                        >
                                          {sortElement.label}
                                        </h3>
                                      </MDBBox>
                                    );
                                  })}
                                </MDBCol>
                              </MDBRow>
                            </Overlay>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      {isEmpty(active_filters) === false && (
                        <MDBRow className="toolbar-mobile-clear">
                          <MDBCol>
                            <span onClick={props.clearFilters}>
                              clear filters
                            </span>
                          </MDBCol>
                        </MDBRow>
                      )}
                    </>
                  )}

                  {totalCount > 1 && (
                    <div
                      className={`arrow-down ${
                        sortOrder === "ASC" && "arrow-up"
                      }`}
                      onClick={(e) => {
                        let sortValue;
                        let _sortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
                        if (isObject(sort)) {
                          sortValue = sort;
                        } else {
                          sortValue = {
                            label: "Alphabetical",
                            value: sort,
                            __typename: "SortField",
                          };
                        }

                        changeSortFilterHandler(sortValue, _sortOrder);
                        setSortOrder(_sortOrder);
                      }}
                    >
                      <Icon icon={"rightArrow"} />
                    </div>
                  )}
                </div>
              </MDBCol>
              <MDBCol
                lg="6"
                md="12"
                className={`d-flex mt-md-3 flex-lg-row-reverse`}
              >
                {(isTabOrMob && <></>) || (
                  <Pagination
                    changePageNumber={props.changePageNumber}
                    pageNumber={props.pageNumber}
                    totalPages={totalPages}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </>
        )) || (
          <>
            <MDBRow>
              <MDBCol
                lg="6"
                md="12"
                className={`d-flex mt-md-3 offset-lg-6 flex-lg-row-reverse`}
              >
                <Pagination
                  changePageNumber={props.changePageNumber}
                  pageNumber={props.pageNumber}
                  totalPages={totalPages}
                />
              </MDBCol>
            </MDBRow>
          </>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default Toolbar;
