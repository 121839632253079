import React, { useState } from "react";
import themeConfiguration from "config/themeConfiguration";
import Icon from "app/assets/icon/icon";
import ReactPlayer from "react-player";

import { MDBModalHeader, MDBModalBody, MDBBox, MDBModal } from "mdbreact";
const AssemblyModal = (props) => {
  const [assembly, setAssembly] = useState(false);
  const toggle = () => {
    setAssembly(!assembly);
  };
  let { video } = props;
  return (
    <>
      <Icon icon={"videoGray"} />
      <MDBBox className={"assembly-modal item"}>
        <MDBBox className="assembly-video-link" onClick={toggle}>
          assembly <br />
          video
        </MDBBox>
        {assembly && (
          <MDBModal
            isOpen={assembly}
            toggle={toggle}
            className={"modal-wrapper footer-newsletter assembly-modal"}
            centered
          >
            <MDBModalHeader onClick={toggle}>
              <div className="close-btn">
                <button type="button">
                  <i className="close-icon"></i>
                </button>
              </div>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBBox>
                <ReactPlayer
                  url={`${(themeConfiguration.magento_url = video.substring(
                    1
                  ))}`}
                  width={"100%"}
                />
              </MDBBox>
            </MDBModalBody>
          </MDBModal>
        )}
      </MDBBox>
    </>
  );
};

export default AssemblyModal;
