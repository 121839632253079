import React, { useRef, memo, useState } from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import ShopTheLookContent from "./shopTheLook-content";
import ShopTheLookTabs from "./shopTheLook-tabs";
import _ from "lodash";

const scrollToRef = ref => {
  ref.current.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest"
  });
  ref.current.scrollTop += 100;
};

const ShopTheLookDataContainer = memo(
  props => {
    let { data } = props;
    const [category, setCategory] = useState(0);
    const shopLookRef = useRef(null);

    if (_.isUndefined(data) || _.isUndefined(data.getAllGalleryData))
      return <></>;

    let allGalleryData = data.getAllGalleryData;
    let allCategories = [];

    allGalleryData.map(item => {
      allCategories.push(item.category[0]);
    });

    if (allCategories[0] === undefined) return <></>;

    let _uniqueCategories = _.uniqBy(allCategories, category => {
      if (typeof category !== "undefined") {
        return category.name;
      }
    });
    // split into categories

    let uniqueCategories = _uniqueCategories.filter(item => {
      return item !== undefined;
    });

    return (
      <>
        <MDBContainer fluid className={`shop-the-look-tabs-container`}>
          <MDBRow>
            <MDBContainer className={`shop-the-look-tabs`}>
              <MDBRow className={`d-flex flex-row flex-wrap`}>
                <ShopTheLookTabs
                  category={category}
                  setCategory={setCategory}
                  categories={uniqueCategories}
                  shopLookRef={shopLookRef}
                  scrollToRef={scrollToRef}
                />
              </MDBRow>
            </MDBContainer>
          </MDBRow>
        </MDBContainer>
        <div ref={shopLookRef}>
          <MDBContainer fluid className={`shop-the-look-content-container`}>
            <MDBRow>
              <MDBContainer className={`shop-the-look-content`}>
                <MDBRow className={`d-flex flex-row flex-wrap`}>
                  <ShopTheLookContent
                    shopRef={shopLookRef}
                    data={[...allGalleryData]}
                    category={category}
                  />
                </MDBRow>
              </MDBContainer>
            </MDBRow>
          </MDBContainer>
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ShopTheLookDataContainer;
