// customer attributes
export default {
  id: "id",
  email: "email",
  firstname: "firstname",
  lastname: "lastname",
  password: "password",
  dob: "dob",
  taxvat: "taxvat",
  gender: "gender",
  addresses: "addresses",
  contact_name: "contact_name",
  website: "website",
  telephone: "telephone",
  is_subscribed: "is_subscribed",
  is_trade: "is_trade"
};
