import Homepage from "app/pages/home";
import Dashboard from "app/pages/customer/account/pages/dashboard";
import Information from "app/pages/customer/account/pages/information";
import Addressbook from "app/pages/customer/account/pages/addressbook";
import Edit from "app/pages/customer/account/pages/addressbook/edit";
import Add from "app/pages/customer/account/pages/addressbook/add";
import Newsletter from "app/pages/customer/account/pages/newsletter";
import Orders from "app/pages/customer/account/pages/orders";
import Favorites from "app/pages/customer/account/pages/favorites";
import FavoritesShareWishlist from "app/pages/customer/account/pages/favorites/share";
import logout from "app/pages/customer/logout/logout";

export default [
  {
    url: "customer",
    component: Dashboard,
    exact: true,
    inSidebar: true,
    dropdownText: "dashboard",
    menuText: "account dashboard",
    inDropdown: true
  },
  {
    url: "customer/information",
    component: Information,
    exact: true,
    inSidebar: true,
    dropdownText: "information",
    menuText: "account information",
    inDropdown: false
  },
  {
    url: "customer/addresses",
    component: Addressbook,
    exact: true,
    inSidebar: true,
    menuText: "address book",
    inDropdown: false
  },
  {
    url: "customer/addresses/edit",
    component: Edit,
    exact: false,
    inSidebar: false,
    menuText: "address book",
    inDropdown: false
  },
  {
    url: "customer/addresses/add",
    component: Add,
    exact: true,
    inSidebar: false,
    menuText: "address book",
    inDropdown: false
  },
  {
    url: "customer/newsletter",
    component: Newsletter,
    exact: true,
    inSidebar: true,
    menuText: "newsletter subscription",
    inDropdown: false
  },
  {
    url: "customer/orders",
    component: Orders,
    separated: true,
    exact: false,
    inSidebar: true,
    dropdownText: "my orders",
    menuText: "my orders",
    inDropdown: true
  },
  {
    url: "customer/favorites/share",
    component: FavoritesShareWishlist,
    separated: true,
    exact: false,
    inSidebar: false,
    dropdownText: "share wishlist",
    menuText: "share wishlist",
    inDropdown: false,
    disabled: false
  },
  {
    url: "customer/favorites",
    component: Favorites,
    separated: true,
    exact: true,
    inSidebar: true,
    dropdownText: "my favorites",
    menuText: "my favorites",
    inDropdown: true,
    disabled: false
  },
  {
    url: "logout",
    component: logout,
    separated: true,
    exact: true,
    inSidebar: true,
    dropdownText: "sign out",
    menuText: "sign out",
    inDropdown: true,
    function: "logoutCustomer"
  }
];

export const pagesConfig = {
  sidebarTitle: "my account",
  mainTitle: "dashboard"
};

export { default as ErrorPage } from "app/pages/error";

export const DefaultPage = Homepage;
