import React from "react";
import "./productRibbon.scss";

const ProductRibbon = ({ val }) => {
  let name = "New";
  if (val === "5465") name = "Final Stock";
  if (val === "5467") name = "Sale";
  return <div className="productribbon">{name}</div>;
};

export default ProductRibbon;
