import React, { memo } from "react";

import AccordionItem from "./accordionItem";

const Accordion = memo(
  (props) => {
    let { active, tabsData, open, setOpen } = props;

    return tabsData.map((items, index) => {
      if (index === active) {
        return items.map((item) => {
          return (
            <AccordionItem
              key={`faq-accordion-item-${index}-${item.title}`}
              item={item}
              active={active}
              open={open}
              setOpen={setOpen}
            />
          );
        });
      } else {
        return <></>;
      }
    });
  },
  (prevProps, nextProps) => {
    if (prevProps === nextProps) {
      return true;
    }

    return false;
  }
);

export default Accordion;
