import React from "react";
import { connect } from "react-redux";
import { default as commonActions } from "core/state/redux/data/common/actions";
import LoginStateContainer from "./state-container";
import { customerActions } from "core/state/redux/data/customer";
import { withApollo } from "react-apollo";
import { Redirect } from "react-router-dom";

const LoginReduxContainer = props => {
  if (props.customer !== undefined) {
    if (props.data.hide) {
      props.reudceCustomer({ ...props.customer, hide: false });
    }
    if (props.customer.token !== undefined) {
      if (props.customer.token !== false) {
        let currUrl = window.location.href.substring(
          window.location.href.length - 17,
          window.location.href.length
        );
        if (currUrl === "register/complete") return <Redirect to={`/`} />;
      }
    }
    return <LoginStateContainer {...props} />;
  } else {
    return <></>;
  }
};

const mapStateToProps = state => ({
  data: state.customer,
  customer: state.customer.data,
  loading: state.customer.loading
});

const mapDispatchToProps = dispatch => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: callbackFunction => {
      dispatch(commonActions.lock(callbackFunction));
    },
    login: (username, password, cartToken, callbackFunction) => {
      dispatch(customerActions.loginCustomer(username, password, cartToken));
    },
    reudceCustomer: customer => {
      dispatch(customerActions._reduceCustomer({ ...customer, status: false }));
    }
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(LoginReduxContainer)
);
