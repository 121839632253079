import React, { memo } from "react";
import { useQuery } from "react-apollo-hooks";
import { FormComponent, FormContainer } from "react-authorize-net";
import { MDBBox } from "mdbreact";
import _ from "lodash";
import { authorizeNetConfiguration } from "./queries";
import AuthorizeNetRender from "./forms";

const AuthorizeNet = memo(
  ({ setPaymentMethod, amount, addMessage, method }) => {
    const { data, loading, error } = useQuery(authorizeNetConfiguration, {
      fetchPolicy: "cache-and-network",
    });
    if (loading) return <MDBBox className={"box-inner"}>loading...</MDBBox>;
    return (
      <MDBBox className={"box-inner authorize-net"}>
        <h5>credit card type</h5>
        <FormContainer
          clientKey={data.tokenBaseCheckoutConfig.clientKey}
          apiLoginId={data.tokenBaseCheckoutConfig.apiLoginId}
          environment={`${
            data.tokenBaseCheckoutConfig.sandbox === true
              ? "sandbox"
              : "production"
          }`}
          amount={amount}
          component={(innerProps) => (
            <AuthorizeNetRender
              {...innerProps}
              method={method}
              setPaymentMethod={setPaymentMethod}
              addMessage={addMessage}
              configuration={data.tokenBaseCheckoutConfig}
            />
          )}
        />
      </MDBBox>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default AuthorizeNet;
