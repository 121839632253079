import React, { useEffect } from "react";
import { MDBRow, MDBCol, MDBBox, MDBContainer } from "mdbreact";
import HeroImage from "app/layout/heroImage";
import HeroImageSrc from "app/assets/images/register/ducduc-register-trade.jpg";
import HeroImageSrcMobile from "app/assets/images/register/ducduc-register-trade-mobile.png";
import Button from "core/components/button";
import { Redirect } from "react-router-dom";
import SeoHelmet from "app/layout/seoHelmet";

const OrderSuccess = ({ orderNumber, placeOrderSuccess, customer }) => {
  useEffect(() => {
    placeOrderSuccess(null);
  }, [orderNumber, placeOrderSuccess]);
  if (orderNumber === null) {
    return <Redirect to={"/"} />;
  }
  return (
    <>
      <div className="img item d-lg-block d-none desktop-image">
        <HeroImage
          id={"order-success-img"}
          className="b2b-banner"
          alt="Shop The Look Banner"
        />
      </div>
      <div className="img item d-lg-none d-block mobile-image">
        <HeroImage
          banner={HeroImageSrcMobile}
          className="b2b-banner"
          alt="Shop The Look Banner"
          background={true}
        />
      </div>

      <SeoHelmet
        url={"/thank-you-for-your-purchase"}
        title={"thank you for your purchase"}
        description={"thank you for your purchase"}
        image={HeroImageSrc}
      />
      <MDBContainer
        className={"page-main-content register-success order-success"}
      >
        <MDBRow>
          <MDBCol md="12">
            <MDBBox tag="h3">Thank you for your purchase!!</MDBBox>
            <MDBBox tag="h4">
              <span>
                Your Order Number is:
                <span className={`font-weight-bold pl-2`}>{orderNumber}</span>
              </span>
              <br />
              We’ll email you an order confirmation with details and tracking
              info.
            </MDBBox>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="8" className={"register-succes-buttons"}>
            <Button
              outline={true}
              className={"right-arrow white-button"}
              children={"continue shopping"}
              direction={"ml-3"}
              to={"/"}
            />
            {customer.data.token === false ? (
              <Button
                outline={true}
                className={"right-arrow create-account"}
                children={"create an account"}
                direction={"ml-3"}
                to={"/register"}
                style={{
                  backgroundColor: "#ff6d2d !important",
                  color: "#fff !important",
                  border: "1px solid #ff6d2d !important",
                }}
              />
            ) : (
                <></>
              )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default OrderSuccess;
