import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import CouponStateContainer from "./coupon-state-container";

const CouponReduxContainer = (props) => {
  return <CouponStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  cart:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined"
      ? state.customer.data.cart
      : {},
});
const mapDispatchToProps = (dispatch) => {
  return {
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    applyCouponOnCart: (coupon) => {
      dispatch(customerActions.applyCouponToCart(coupon));
    },
    removeCouponFromCart: () => {
      dispatch(customerActions.removeCouponFromCart());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponReduxContainer);
