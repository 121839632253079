import React, { useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";
import AuthorizeNetRender from "./paymentMethods/authorizeNet";
import CheckButton from "core/components/checkButton";

const CheckoutPaymentMethod = (props) => {
  const {
    inputs,
    handleInputChange,
    addMessage,
    shipping_address,
    available_payment_methods,
    showPayment,
    cart_grand_total,
  } = props;

  return (
    <div id="checkoutpaymentmethod">
      <MDBContainer fluid className={"payment-method-container"}>
        <MDBBox>
          <h3>payment method</h3>
        </MDBBox>
        {showPayment && typeof shipping_address !== "undefined" ? (
          <MDBRow>
            <MDBCol>
              {cart_grand_total === 0
                ? available_payment_methods
                    .filter((method) => method.code === "free")
                    .map((method) => {
                      return (
                        <CheckButton
                          key={`payment-method-${method.code}`}
                          containerClass={
                            "radio-container check-button radio-yes-no"
                          }
                          wrapperClass={"radio"}
                          name="paymentMethod"
                          payment_method={method.code}
                          type="radio"
                          label={method.title}
                          checked={inputs.paymentMethod === method.code}
                          onChange={() => {
                            handleInputChange("paymentMethod", method.code);
                          }}
                        />
                      );
                    })
                : available_payment_methods.map((method) => {
                    return (
                      <CheckButton
                        key={`payment-method-${method.code}`}
                        containerClass={
                          "radio-container check-button radio-yes-no"
                        }
                        wrapperClass={"radio"}
                        name="paymentMethod"
                        payment_method={method.code}
                        type="radio"
                        label={method.title}
                        checked={inputs.paymentMethod === method.code}
                        onChange={() => {
                          handleInputChange("paymentMethod", method.code);
                        }}
                      />
                    );
                  })}
            </MDBCol>
          </MDBRow>
        ) : (
          <p>
            no payment methods are available
            <br />
            please update your billing information
          </p>
        )}
        {/* payment methods need to be rendered, so we check if the payment method matches and return appropritate render*/}
        {showPayment ? (
          (cart_grand_total > 0 &&
            inputs.paymentMethod === "tnw_authorize_cim") ||
          inputs.paymentMethod === "authorizenet_directpost" ||
          inputs.paymentMethod === "anet_creditcard" ||
          inputs.paymentMethod === "authorizenet_acceptjs" ||
          inputs.paymentMethod === "authnetcim" ? (
            <AuthorizeNetRender
              setPaymentMethod={props.setPaymentMethod}
              amount={cart_grand_total}
              addMessage={addMessage}
              method={inputs.paymentMethod}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </MDBContainer>
    </div>
  );
};

CheckoutPaymentMethod.whyDidYouRender = true;
export default CheckoutPaymentMethod;
