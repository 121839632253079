import React, { useState } from "react";
import RegisterB2BReduxContainer from "./RegisterB2B-redux-container";
import countries from "config/data/countries";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { filterByCountryId } from "core/helpers/customer";
const RegisterDataContainer = (props) => {
  const [isRegistered, setIsRegistered] = useState(false);
  const _countries = countries.countries;
  let countryDataObject = getCountries(_countries);
  let countriesData;
  countriesData = Object.keys(countryDataObject).map((key) => {
    return countryDataObject[key];
  });

  let selectedCountry = filterByCountryId("US", countriesData);
  let regionsDataObject = getRegionData(countriesData, selectedCountry);

  return (
    <RegisterB2BReduxContainer
      isRegistered={isRegistered}
      setIsRegistered={setIsRegistered}
      countries={_countries}
      regionsDataObject={regionsDataObject}
    />
  );
};

export default RegisterDataContainer;
