import React, { useState } from "react";
import {
  MDBMask,
  MDBView,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBox,
} from "mdbreact";
import themeConfiguration from "config/themeConfiguration";
import { determineCurrency } from "config/currency";
import { isUndefined } from "core/helpers/functions";
import { Link } from "react-router-dom";

// This is just test image - on dev its slider with hotspots
const CardComp = (props) => {
  const [isVisible, setVisible] = useState(false);
  const [isHome] = useState(props.isHome); // Passing page that called hotspot (PDP or HomePage)

  return (
    <>
      {isHome ? (
        <>
          <MDBMask
            style={{
              top: props.dot.top + "%",
              left: props.dot.left + "%",
            }}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
            className={`hotSpot ${
              (isVisible && "hotspot-active") || "hotspot-inactive"
            }`}
          >
            {props.dot.url ? (
              <Link to={"/" + props.dot.url}>
                {isVisible && (
                  <MDBCard className="cardContainer">
                    {props.dot.display_type == "product" && (
                      <MDBCardImage
                        className="img-hotspotdetail"
                        src={props.dot.image}
                        alt="hot spot picture"
                      />
                    )}
                    <div className={`position-relative`}>
                      <MDBCardBody>
                        {props.dot.display_type == "product" && (
                          <>
                            <MDBCardTitle className="product-name">
                              {props.dot.name}
                            </MDBCardTitle>
                            <MDBCardText className="product-detail">
                              starting at
                              <span className="product-price">
                                {determineCurrency(props.dot.priceCurrency)}
                                {props.dot.price}
                              </span>
                            </MDBCardText>
                          </>
                        )}
                        {props.dot.display_type == "text" && (
                          <>{props.dot.addInfo}</>
                        )}
                      </MDBCardBody>
                      <div className="dots"></div>
                    </div>
                  </MDBCard>
                )}
              </Link>
            ) : (
              <>
                {isVisible && (
                  <MDBCard className="cardContainer">
                    {props.dot.display_type == "product" && (
                      <MDBCardImage
                        className="img-hotspotdetail"
                        src={props.dot.image}
                        alt="hot spot picture"
                      />
                    )}
                    <MDBCardBody>
                      {props.dot.display_type == "product" && (
                        <>
                          <MDBCardTitle className="product-name">
                            {props.dot.name}
                          </MDBCardTitle>
                          <MDBCardText className="product-detail">
                            starting at
                            <span className="product-price">
                              {determineCurrency(props.dot.priceCurrency)}
                              {props.dot.price}
                            </span>
                          </MDBCardText>
                        </>
                      )}
                      {props.dot.display_type == "text" && (
                        <>{props.dot.addInfo}</>
                      )}
                    </MDBCardBody>
                    <div className="dots"></div>
                  </MDBCard>
                )}
              </>
            )}

            <MDBBox className={`hotspot-circle`} />
          </MDBMask>
        </>
      ) : (
        <MDBMask className="detailContainer">
          <MDBCardText>{props.dot.content}</MDBCardText>
        </MDBMask>
      )}
    </>
  );
  // cardTitle and cardText are passed with GraphQL if its homepage
  // span is filled with price
};
const Hotspot = (props) => {
  let image = isUndefined(props.contentArray.image)
    ? props.contentArray.hotspot_image
    : props.contentArray.image;
  const [hotspotBackground] = useState(themeConfiguration.magento_url + image);
  return (
    <div className="hotspotContentContainer">
      <MDBView>
        <img src={hotspotBackground} className="lazyload img-slider" alt="" />
        {props.dots.map((dot, index) => {
          return (
            <CardComp
              key={`card-comp-` + index}
              dot={dot}
              isHome={props.isHome}
            />
          );
        })}
      </MDBView>
    </div>
  );
};

export default Hotspot;
