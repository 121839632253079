import React from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { Track, Handle, Tick } from "./functions";
import Input from "core/components/input/input";
import { MDBRow, MDBCol } from "mdbreact";
import { isEqual, isEmpty } from "lodash";

const CompoundSlider = (props) => {
  let CurrentValues = props.currentValues;
  let { previousValues, setPreviousValues, previousValuesInitial } = props;
  let { symbol } = props;

  if (CurrentValues === {}) return;

  if (typeof symbol === "undefined") {
    symbol = "";
  }
  return (
    <>
      {props.showPriceRange && (
        <MDBRow>
          <MDBCol>{symbol + CurrentValues.min}</MDBCol>
          <MDBCol>{symbol + CurrentValues.max}</MDBCol>
        </MDBRow>
      )}
      <Slider
        className={`compound-slider`}
        domain={[props.min, props.max]}
        step={props.step}
        mode={2}
        values={[CurrentValues.min, CurrentValues.max]}
        onChange={(e) => {
          let minValue = e[0];
          let maxValue = e[1];
          if (!isEqual(previousValues, previousValuesInitial)) {
            setPreviousValues({ min: props.min, max: props.max });
            props.setCurrentValues({ min: props.min, max: props.max });
          } else {
            props.valueChangeHandler(
              { min: minValue, max: maxValue },
              props.addFilter
            );
            props.setCurrentValues({ min: minValue, max: maxValue });
          }
        }}
      >
        <Rail>
          {({ getRailProps }) => <div className={`rail`} {...getRailProps()} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                  useDecimal={props.useDecimal}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        {props.useTicks && (
          <Ticks
            count={
              props.ticksCount /* generate approximately props.ticksCount ticks within the domain */
            }
          >
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map((tick) => (
                  <Tick key={tick.id} tick={tick} count={ticks.length} />
                ))}
              </div>
            )}
          </Ticks>
        )}
      </Slider>

      {props.useInputs && (
        <div className="rangefield-content">
          <Input
            id={props.minId}
            name={props.minId}
            type={"number"}
            wrapperClass={"range-field-wrapper"}
            className={"range-field"}
            inputClass={`range-slider range-slider-min range-slider-${props.minId}`}
            value={CurrentValues.min}
            onChange={(e) => {
              let thisValue = e.target.value;
              if (
                isNaN(thisValue) ||
                thisValue === "" ||
                thisValue < CurrentValues.min
              ) {
                thisValue = props.min;
              }
              props.setCurrentValues({
                min: parseFloat(thisValue),
                max: parseFloat(CurrentValues.max),
              });
            }}
          />
          <span>To</span>
          <Input
            id={props.maxId}
            name={props.maxId}
            type={"number"}
            wrapperClass={"range-field-wrapper"}
            className={"range-field"}
            inputClass={`range-slider range-slider-min range-slider-${props.minId}`}
            value={CurrentValues.max}
            onChange={(e) => {
              let thisValue = e.target.value;
              if (
                isNaN(thisValue) ||
                thisValue === "" ||
                thisValue < CurrentValues.min
              ) {
                thisValue = CurrentValues.min;
              }
              props.setCurrentValues({
                min: parseFloat(CurrentValues.min),
                max: parseFloat(thisValue),
              });
            }}
          />
        </div>
      )}
    </>
  );
};

export default CompoundSlider;
