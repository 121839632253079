import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { MDBLink, MDBIcon } from "mdbreact";
import { default as CoreImage } from "core/components/image";
import { isUndefined } from "core/helpers/functions";
import Icon from "app/assets/icon/icon";
import ProductRibbon from "app/layout/productRibbon/productRibbon";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";
import Wishlist from "app/pages/catalog/product/info/controls/wishlist";
import { determineIfInWishlist } from "app/pages/catalog/product/info/controls/wishlist-functions";
import Price from "core/components/price";
import CompareContributer from "core/components/compareContributer";
import _ from "lodash";
import { useCompare } from "core/state/hooks/compareHook";
import themeConfiguration from "config/themeConfiguration";

const productImageUrl = (productUrl) => {
  return (
    themeConfiguration.magento_url +
    themeConfiguration.media_url +
    themeConfiguration.catalog_url +
    "/" +
    productUrl
  );
};
const ProductItem = (props) => {
  let { wishlist } = props;
  const [image, setImage] = useState(props.productImage.url);
  const [hoverImage] = useState(() => {
    if (
      typeof props.productMedia === "undefined" ||
      typeof props.productMedia[1] === "undefined"
    ) {
      return false;
    }
    let thisImage = props.productMedia[1];
    new Image().src = productImageUrl(thisImage.file);
    return productImageUrl(thisImage.file);
  });
  const [hovered, setHovered] = useState(false);

  let location = useLocation();
  let productSearchUrl = props.productSearchUrl;
  let productRelatedUrl = props.isRelatedOrUpsell;
  let productUrl = "/" + props.productUrl;
  if (!productSearchUrl && typeof props.useOrigin === "undefined") {
    productUrl = location.pathname + productUrl;
  }
  if (productSearchUrl === false && productRelatedUrl === true) {
    productUrl = props.productUrl;
  }
  let compareRequest = { productId: props.productId };
  const [
    addCompare,
    removeCompare,
    checkIfProductIsInCompareById,
  ] = useCompare();
  let isProductInCompare = checkIfProductIsInCompareById(props.productId);
  const [isInWishList, setIsInWishList] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleWishlist = () => setIsInWishList(!isInWishList);
  //const isTabOrMob = useMediaQuery({ maxWidth: 767 });
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }

  let dependencies = {};
  let inWishlist = determineIfInWishlist(wishlist.items, props.productNumber);
  // let inWishlist = false;
  if (loggedIn) {
    if (
      typeof props.wishlist !== "undefined" &&
      typeof props.wishlist.items !== "undefined"
    ) {
      let isInWishlistFinder = Object.keys(props.wishlist.items).find((x) => {
        return props.wishlist.items[x].product.id === props.productId;
      });

      if (typeof isInWishlistFinder !== "undefined") {
        if (isInWishList === false) {
          setIsInWishList(true);
        }
      } else {
        if (isInWishList === true) {
          setIsInWishList(false);
        }
      }
    }
    let options = props.productOptions;

    if (options) {
      options.map((option) => {
        if (option.title.toLowerCase() === "storage") {
          option.value.map((optionValue) => {
            options.map((o) => {
              if (
                o.title.toLowerCase().includes(optionValue.title.toLowerCase())
              ) {
                dependencies[o.option_id] = {
                  [option.option_id]: optionValue.option_type_id,
                };
              }
            });
          });
        }
      });
    }
  }

  return (
    <div
      className="product-item-content"
      id={props.value}
      key={props.index}
      onClick={(e) => {
        if (!isUndefined(props.onClick)) {
          props.onClick();
        }
      }}
    >
      <div
        onMouseEnter={(e) => {
          if (typeof props.productMedia !== "undefined")
            if (typeof props.productMedia[1] !== "undefined") setHovered(true);
        }}
        onMouseLeave={(e) => {
          if (typeof props.productMedia !== "undefined") {
            if (typeof props.productMedia[1] !== "undefined") setHovered(false);
          }
        }}
        className="product-item-image product-item-part"
      >
        {props.productRibbon && loggedIn && (
          <ProductRibbon val={props.productRibbon} />
        )}

        <MDBLink to={productUrl}>
          {!isUndefined(props.productImage) && (
            <>
              <CoreImage
                source={image}
                imgContainer={`product-image ${!hovered ? "show" : "hide"}`}
                image_alt={props.productName + " " + props.productImage.label}
              />
              {hoverImage ? (
                <CoreImage
                  source={hoverImage}
                  imgContainer={`product-image ${hovered ? "show" : "hide"}`}
                  image_alt={props.productName + " additional"}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </MDBLink>
      </div>
      <div className="product-item-info product-item-part">
        <p
          className="product-name"
          alt={props.productName}
          title={props.productName}
        >
          {props.productName.length > 30
            ? props.productName.substr(0, 28) + "..."
            : props.productName}
        </p>
      </div>
      <div className="product-item-additional-info product-item-part">
        <p className="product-price">
          <span className="starting-at">
            {_.isNull(props.productOptions) ? "" : "starting at "}
          </span>
          <Price price={props.productPriceRegularValue} type={`fixed`} />
        </p>
        {/* COMPARE */}
        <div className="product-item-compare">
          {isProductInCompare && (
            <>
              <MDBIcon
                onClick={() => {
                  removeCompare(compareRequest);
                }}
                icon="clone"
              />
            </>
          )}
          {!isProductInCompare && (
            <>
              <MDBIcon
                onClick={() => {
                  if (props.compareList.length !== 5) {
                    addCompare(compareRequest);
                  } else {
                    props.addMessage(
                      "Compare is limited to 5 products at a time",
                      "danger"
                    );
                  }
                }}
                far
                icon="clone"
              />
            </>
          )}
        </div>
        <ModalConsumer value={{ useWrapper: false }}>
          {({ showModal, hideModal }) => (
            <p
              className="product-wishlist"
              onClick={(e) => {
                showModal(() => {
                  // options logic
                  let options = [];
                  if (Object.keys(options).length > 1) {
                    Object.keys(options).map((key) => {
                      let _option = options[key];
                      if (
                        _option === "" ||
                        key === "selectedPrice" ||
                        key === "value"
                      )
                        return;
                      let customizableOptionFormat;
                      if (_option.title) {
                        customizableOptionFormat = {
                          id: parseInt(key),
                          value_string: String(_option.option_type_id),
                        };
                      } else {
                        customizableOptionFormat = {
                          id: parseInt(key),
                          value_string: _option.swatch_name,
                        };
                      }
                      let addToOptions = true;
                      if (dependencies[key]) {
                        let depKey = Object.keys(dependencies[key])[0];
                        addToOptions =
                          options[depKey].option_type_id ===
                          dependencies[key][depKey];
                      }
                      if (addToOptions) {
                        return options.push(customizableOptionFormat);
                      }
                    });
                  }
                  if (!loggedIn) {
                    e.preventDefault();
                    return (
                      <Login
                        hideModal={hideModal}
                        component={
                          <Wishlist
                            inWishlist={inWishlist}
                            wishlist={wishlist}
                            hideModal={hideModal}
                            product={props.product}
                            options={options}
                          />
                        }
                      />
                    );
                  } else {
                    return (
                      <Wishlist
                        inWishlist={inWishlist}
                        wishlist={wishlist}
                        hideModal={hideModal}
                        product={props.product}
                        options={options}
                      />
                    );
                  }
                });
              }}
            >
              {(isInWishList && (
                <Icon
                  id={"heartFull"}
                  className={`${loading ? "active" : "inactive"}`}
                />
              )) || (
                <Icon
                  id={"heartEmptyGray"}
                  className={`${loading ? "active" : "inactive"}`}
                />
              )}
            </p>
          )}
        </ModalConsumer>
      </div>
      <CompareContributer
        productId={props.productId}
        compareList={props.compareList}
        addMessage={props.addMessage}
      />
    </div>
  );
};
export default ProductItem;
