import React from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import Info from "./info";
import Details from "./details";
import Assembly from "./assembly";
import Related from "./related";
import Reviews from "./reviews";
import Upsells from "./upsells";
import SeoHelmet from "app/layout/seoHelmet";
import Helmet from "react-helmet";
import { ProductSchema } from "core/schemas/productSchema";

const Product = (props) => {
  let { assemblySize, relatedSize, showAssemblyRelated } = props.stateProps;
  let { data } = props;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <script type="application/ld+json">
          {typeof data !== "undefined" && ProductSchema(data)}
        </script>
      </Helmet>
      <SeoHelmet
        url={props.variables.url}
        title={data.meta_title}
        description={data.meta_description}
        image={
          typeof data.image !== "undefined" &&
          typeof data.image.url !== "undefined" &&
          data.image.url !== null
            ? data.image.url
            : ""
        }
      />
      <MDBContainer className="product-page-container mb-5" fluid>
        <MDBRow>
          <MDBCol size="12">
            {/* Prod Info */}
            <Info {...props} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Product;
