// logic is inversed, true means that an error exists

export const validate = zipcode => {
  return {
    zipcode: zipcode.length === 0 || zipcode.length < 5
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};
