import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import ListingQueryContainer from "./listing-query-container";
import { filterActions } from "core/state/redux/data/filters";
import { default as commonActions } from "core/state/redux/data/common/actions";
import SeoHelmet from "app/layout/seoHelmet";
import { findById } from "core/helpers/category";

const ListingReduxContainer = (props) => {
  let allCategories;
  let category;
  if (props.allCategories) {
    if (lodash.isUndefined(props.allCategories[2])) {
      if (lodash.isUndefined(props.allCategoriesTemp[2])) {
        return <></>;
      }
      allCategories = props.allCategoriesTemp[2].data.categoryList[0];
      category = findById(props.category_id, allCategories);
    } else {
      allCategories = props.allCategories[2].data.categoryList[0];
      category = findById(props.category_id, allCategories);
    }

    return (
      <>
        {!lodash.isUndefined(category) && (
          <SeoHelmet
            url={category.url_path}
            title={category.name}
            description={category.meta_description}
            image={typeof category.image !== "undefined" ? category.image : ""}
          />
        )}
        <ListingQueryContainer {...props} allCategories={allCategories} />
      </>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  product_filters: state.filters.filter,
  category: state.filters.category,
  locked: state.common.locked,
  customer: state.customer.data,
  pageNumber: state.filters.pageNumber,
  allCategories: state.queries.data,
  allCategoriesTemp: state.queries.temp,
});

const mapDispatchToProps = (dispatch) => {
  return {
    lock: () => {
      dispatch(commonActions.lock());
    },
    unlock: () => {
      dispatch(commonActions.unlock());
    },
    addFilteredData: (data) => {
      dispatch(filterActions.addFilteredData(data));
    },
    addInitialCategoryData: (data) => {
      dispatch(filterActions.addInitialCategoryData(data));
    },
    changeCurrentPage: (page) => {
      dispatch(filterActions.changePageNumber(page));
    },
    reducePageData: (pageData) => {
      dispatch(filterActions.reducePageData(pageData));
    },
    removeAllFilters: () => {
      dispatch(filterActions.removeAllFilters());
    },
    clearFilters: () => {
      dispatch(filterActions.clearFilters());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingReduxContainer);
