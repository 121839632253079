import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import CheckButton from "core/components/checkButton";
import ColorPicker from "../colorPicker";
const StoragePicker = (props) => {
  let {
    product,
    reduceProductOptions,
    selectedSizeText,
    storageBasedColors,
    availableColors,
  } = props;
  let { option, selections } = props.optionProps;

  const changeOption = (value) => {
    let selectionTmp = {};
    let previousPrice = product.options[option.option_id].price;

    let newPrice = product.options.selectedPrice - previousPrice + value.price;

    if (selections.value === undefined) {
      let _value = { [option.option_id]: value };
      selectionTmp["value"] = _value;
    } else {
      let _value = { [option.option_id]: value };
      selectionTmp["value"] = { ...selections.value, ..._value };
    }
    let newSelection = {
      [option.option_id]: value,
      ...selectionTmp,
      selectedPrice: newPrice,
    };
    let newSelections = { ...selections, ...newSelection };

    reduceProductOptions({ ...product, options: newSelections });
  };

  // this line will check if you load another product, and if the options are missing
  // if selections has a length of 1, it is only for the selected price
  if (Object.keys(selections).length === 1) return <></>;

  // count if we should separate checkboxes based on size
  let count = 0;
  let isXL = false;
  return (
    <MDBRow
      className={`${option.title.toLowerCase()}-container justify-content-center pb-4 option-container`}
    >
      {option.value.map((value, key) => {
        if (
          value.title.toLowerCase().includes(selectedSizeText.toLowerCase())
        ) {
          count++;
        }
      })}
      {option.value.map((value, key) => {
        let removePadding = !(key % 2);
        let isChecked =
          typeof selections !== "undefined" &&
          typeof selections[option.option_id] !== "undefined"
            ? selections[option.option_id].option_type_id ===
              value.option_type_id
            : false;

        if (selectedSizeText.includes("XL")) {
          isXL = true;
        }

        return (
          <>
            {count === 0 ? (
              <MDBCol
                size="6"
                lg="3"
                className={`d-flex justify-content-center ${
                  removePadding ? "pl-lg-0" : ""
                }`.trim()}
              >
                <CheckButton
                  containerClass={`switch-container ${
                    isChecked ? "checked" : ""
                  } check-button`}
                  checked={isChecked}
                  onChange={(e) => {
                    if (isChecked) {
                      changeOption({
                        title: "none",
                        price: 0,
                        option_type_id: null,
                      });
                    } else {
                      changeOption(value);
                    }
                  }}
                  wrapperClass={"slider"}
                  label={value.title.replace(selectedSizeText + " ", "")}
                  type={"checkbox"}
                />
              </MDBCol>
            ) : isXL ? (
              value.title.includes("XL") &&
              value.title
                .toLowerCase()
                .includes(selectedSizeText.toLowerCase()) ? (
                <MDBCol
                  size="6"
                  lg="3"
                  className={`d-flex justify-content-center ${
                    removePadding ? "pl-lg-0" : ""
                  }`.trim()}
                >
                  <CheckButton
                    containerClass={`switch-container ${
                      isChecked ? "checked" : ""
                    } check-button`}
                    checked={isChecked}
                    onChange={(e) => {
                      if (isChecked) {
                        changeOption({
                          title: "none",
                          price: 0,
                          option_type_id: null,
                        });
                      } else {
                        changeOption(value);
                      }
                    }}
                    wrapperClass={"slider"}
                    label={value.title.replace(selectedSizeText + " ", "")}
                    type={"checkbox"}
                  />
                </MDBCol>
              ) : (
                <></>
              )
            ) : isXL === false &&
              value.title.includes(selectedSizeText) &&
              !value.title.includes("XL") ? (
              <MDBCol
                size="6"
                lg="3"
                className={`d-flex justify-content-center ${
                  removePadding ? "pl-lg-0" : ""
                }`.trim()}
              >
                <CheckButton
                  containerClass={`switch-container ${
                    isChecked ? "checked" : ""
                  } check-button`}
                  checked={isChecked}
                  onChange={(e) => {
                    if (isChecked) {
                      changeOption({
                        title: "none",
                        price: 0,
                        option_type_id: null,
                      });
                    } else {
                      changeOption(value);
                    }
                  }}
                  wrapperClass={"slider"}
                  label={value.title.replace(selectedSizeText + " ", "")}
                  type={"checkbox"}
                />
              </MDBCol>
            ) : (
              <></>
            )}
          </>
        );
      })}
      {storageBasedColors.map((colorOption) => {
        if (
          typeof selections[option.option_id] !== "undefined" &&
          selections[option.option_id].title
            .toLowerCase()
            .includes(colorOption.title.toLowerCase().replace(" color", ""))
        ) {
          let optionProps = {
            option: colorOption,
            availableColors: availableColors,
            selections,
          };
          return (
            <MDBCol
              size="12"
              className="d-flex mt-3 justify-content-center align-items-center"
            >
              <ColorPicker
                parentSize="6"
                optionProps={optionProps}
                shearlingPrice={props.optionProps.shearlingPrice}
                specialtyPrice={props.optionProps.specialityPrice}
                parentClasses={"pl-lg-0"}
                titleClasses={"px-lg-3 px-0 pb-0"}
                selectorClasses={"left-indent pr-lg-3 pb-0 pl-1 pr-4"}
                sizes={{
                  title: 6,
                  selector: 6,
                }}
              />
            </MDBCol>
          );
        }
      })}
    </MDBRow>
  );
};

export default StoragePicker;
