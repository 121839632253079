import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import FormStateContainer from "./state-container";
import { customerActions } from "core/state/redux/data/customer";
import { messagesActions } from "core/state/redux/data/messages";

const FormReduxContainer = (props) => {
  return <FormStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    addMessage: (text, type, target) => {
      dispatch(messagesActions.addMessage(text, type, target));
    },
    getCustomerInformation: (token) => {
      dispatch(customerActions.getCustomerInformation(token, true));
    },
    editCustomerAddress: (request) => {
      dispatch(customerActions.editCustomerAddress(request));
    },
    createCustomerAddress: (request) => {
      dispatch(customerActions.createCustomerAddress(request));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormReduxContainer);
