// Middleware
import types from "./types";
import addFilteredData from "./middleware/addFilteredData";

const filterMiddleware = store => {
  return next => {
    return async action => {
      next(action);
      switch (action.type) {
        default:
          return store;
        // case types.ADD_FILTERED_DATA:
        //   return addFilteredData(store, action);
      }
    };
  };
};

export default filterMiddleware;
