import React, { memo } from "react";
import Button from "core/components/button";
import _ from "lodash";
import MaskedInput from "react-text-mask";
import ReactLoader from "app/layout/loader";
import { MDBBox, MDBCol, MDBRow } from "mdbreact";
import {
  maskForAmericanExpressCard,
  maskForVisaAndMasterCard,
} from "./mask-array";
import * as R from "ramda";

const AuthorizeNetRender = memo(
  ({
    ccNumber,
    setCcNumber,
    ccExpirationDate,
    setCcExpirationDate,
    ccExpirationYear,
    setCcExpirationYear,
    ccValidationNumber,
    setCcValidationNumber,
    inputs,
    saveInfo,
    setSaveInfo,
    loadingOrder,
    formValid,
    determineCardType,
    errors,
    shouldUpdate,
    setShouldUpdate,
    handleChange,
    handleFocus,
    handleBlur,
    handleSubmit,
    setPaymentMethod,
    loading,
    setLoading,
    setErrors,
    apiErrors,
    addMessage,
    preventUpdate,
    setPreventUpdate,
    values,
  }) => {
    let creditCardError = _.find(errors, (item, index) => {
      return item.text === "Please provide valid credit card number.";
    });
    let creditCardYearError =
      _.find(errors, (item, index) => {
        return item.text === "Please provide valid expiration year.";
      }) ||
      _.find(errors, (item, index) => {
        return item.text === "Expiration date must be in the future.";
      });
    let creditCardDateError =
      _.find(errors, (item, index) => {
        return item.text === "Please provide valid expiration month.";
      }) ||
      _.find(errors, (item, index) => {
        return item.text === "Expiration date must be in the future.";
      });

    let cardType = determineCardType(ccNumber);
    let cardMask =
      cardType === "VI" || cardType === "MC"
        ? maskForVisaAndMasterCard
        : cardType === "AE"
        ? [...maskForAmericanExpressCard]
        : maskForVisaAndMasterCard;

    /*
     *   please note the document.getElementById functions
     *   they are used to set values once update is triggered for a field, since the container resets local state
     */
    return (
      <>
        <MDBBox className={"credit-cards"}>
          <span
            className={
              ccNumber && determineCardType(ccNumber) === "VI"
                ? "active"
                : "nonactive"
            }
          >
            visa
          </span>
          <span
            className={
              ccNumber && determineCardType(ccNumber) === "MC"
                ? "active"
                : "nonactive"
            }
          >
            mc
          </span>
          <span
            className={
              ccNumber && determineCardType(ccNumber) === "AE"
                ? "active"
                : "nonactive"
            }
          >
            amex
          </span>
          <span
            className={
              ccNumber && determineCardType(ccNumber) === "DI"
                ? "active"
                : "nonactive"
            }
          >
            discover
          </span>
        </MDBBox>
        <MDBBox>
          <>
            <p>credit Card Number</p>
            <div className="md-form default-input-field-container">
              <MaskedInput
                id={`cardNumberId`}
                mask={cardMask}
                placeholderChar={"\u2002"}
                guide={true}
                value={ccNumber}
                name={`cardNumber`}
                onFocus={R.curry(handleFocus)("cardNumber")}
                onChange={(e) => {
                  e.persist();
                  setCcNumber(e.target.value);
                  handleChange("cardNumber", e);
                  setPreventUpdate(false);
                }}
                onClick={(e) => {
                  e.persist();
                  setCcNumber(e.target.value);
                  handleChange("cardNumber", e);
                }}
                onBlur={(e) => {
                  let element = document.getElementById("expDateReal");
                  let secondElement = document.getElementById("cardCodeId");
                  element.click();
                  secondElement.click();
                }}
                render={(ref, inputProps) => {
                  return (
                    <input
                      {...inputProps}
                      ref={ref}
                      className={`form-control default-input-field ${
                        creditCardError ? "validation-error" : ""
                      }`}
                      placeholder={`card number*`}
                    />
                  );
                }}
              />
            </div>
            <p>Expiration Date</p>
            <MDBRow className="datecc">
              <MDBCol size="6">
                {/* ccExpDate and ccExpYear are formated into "expDate" invisible input, which is used by authorize.net form container */}
                <div className="md-form default-input-field-container">
                  <MaskedInput
                    mask={[/\d/, /\d/]}
                    placeholderChar={"\u2002"}
                    guide={false}
                    value={ccExpirationDate}
                    onChange={(e) => {
                      e.persist();
                      setCcExpirationDate(e.target.value);
                      setPreventUpdate(false);
                    }}
                    onBlur={(e) => {
                      let element = document.getElementById("expDateReal");
                      let secondElement = document.getElementById(
                        "cardNumberId"
                      );
                      let thirdElement = document.getElementById("cardCodeId");
                      element.click();
                      secondElement.click();
                      thirdElement.click();
                    }}
                    render={(ref, inputProps) => {
                      return (
                        <input
                          {...inputProps}
                          ref={ref}
                          className={`form-control default-input-field ${
                            creditCardDateError ? "validation-error" : ""
                          }`}
                          placeholder={`Month*`}
                        />
                      );
                    }}
                  />
                </div>
              </MDBCol>
              <MDBCol size="6">
                <div class="md-form default-input-field-container">
                  <MaskedInput
                    mask={[/\d/, /\d/]}
                    placeholderChar={"\u2002"}
                    guide={false}
                    value={ccExpirationYear}
                    onChange={(e) => {
                      setCcExpirationYear(e.target.value);
                      setPreventUpdate(false);
                    }}
                    onBlur={(e) => {
                      let element = document.getElementById("expDateReal");
                      let secondElement = document.getElementById(
                        "cardNumberId"
                      );
                      let thirdElement = document.getElementById("cardCodeId");
                      element.click();
                      secondElement.click();
                      thirdElement.click();
                    }}
                    render={(ref, inputProps) => {
                      return (
                        <input
                          {...inputProps}
                          ref={ref}
                          className={`form-control default-input-field ${
                            creditCardYearError ? "validation-error" : ""
                          }`}
                          placeholder={`Year*`}
                        />
                      );
                    }}
                  />
                </div>
                <input
                  // hidden={true}
                  id="expDateReal"
                  value={ccExpirationDate + "/" + ccExpirationYear}
                  hidden={true}
                  onClick={(e) => {
                    e.persist();
                    handleChange("expDate", e);
                  }}
                  onBlur={(e) => {
                    let secondElement = document.getElementById("cardNumberId");
                    let thirdElement = document.getElementById("cardCodeId");
                    secondElement.click();
                    thirdElement.click();
                  }}
                />
              </MDBCol>
              <MDBCol size="6"></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="5">
                <p>validation number</p>
                <div class="md-form default-input-field-container">
                  <MaskedInput
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    placeholderChar={"\u2002"}
                    guide={false}
                    value={ccValidationNumber}
                    onChange={(e) => {
                      e.persist();
                      setCcValidationNumber(e.target.value);
                      handleChange("cardCode", e);
                      setPreventUpdate(false);
                    }}
                    onClick={(e) => {
                      e.persist();
                      setCcValidationNumber(e.target.value);
                      handleChange("cardCode", e);
                    }}
                    onBlur={(e) => {
                      let element = document.getElementById("expDateReal");
                      let secondElement = document.getElementById(
                        "cardNumberId"
                      );
                      element.click();
                      secondElement.click();
                    }}
                    render={(ref, inputProps) => {
                      return (
                        <input
                          {...inputProps}
                          id={`cardCodeId`}
                          ref={ref}
                          className={`form-control default-input-field`}
                          placeholder={`ex: 999*`}
                        />
                      );
                    }}
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                {/* <CheckButton
                containerClass={"checkbox-container check-button use-later"}
                wrapperClass={"checkmark"}
                label={"save for use later"}
                onChange={() => setSaveInfo(!saveInfo)}
                checked={saveInfo}
                type={"checkbox"}
              /> */}
              </MDBCol>
            </MDBRow>
          </>
          {loadingOrder ? (
            <ReactLoader />
          ) : (
            <>
              <MDBBox>
                {apiErrors.map((error) => {
                  return (
                    <p key={`credit-card-error-${error}`} className={`error`}>
                      *{error}
                    </p>
                  );
                })}
              </MDBBox>
              <MDBBox className="second-column checkout-buttons">
                <Button
                  outline={true}
                  className={"right-arrow justify-content-center white-button"}
                  text={"cancel"}
                  direction={"ml-3"}
                  righticon={"true"}
                  onClick={(e) => {
                    e.persist();
                    e.target.value = "";
                    setCcNumber("");
                    setCcExpirationDate("");
                    setCcExpirationYear("");
                    setCcValidationNumber("");
                    handleChange("cardCode", e);
                    handleChange("expDate", e);
                    handleChange("cardNumber", e);
                    setPreventUpdate(false);
                  }}
                />

                <Button
                  disabled={preventUpdate}
                  onClick={async (e) => {
                    setPreventUpdate(true);
                    try {
                      e.persist();
                      setLoading(true);
                      let result = await handleSubmit();
                      if (result.messages.resultCode.toLowerCase() === "ok") {
                        setLoading(true);
                        setPaymentMethod({
                          method_code: "authnetcim",
                          cc_last_4: ccNumber.substr(ccNumber.length - 4),
                          ...result.opaqueData,
                        });
                        addMessage("payment information is set", "success");
                        setLoading(false);
                        setErrors([]);
                      } else {
                        setLoading(false);
                      }
                    } catch (error) {
                      setErrors([...error.messages.message]);
                      setLoading(false);
                      setPreventUpdate(false);
                    }
                  }}
                  text={
                    shouldUpdate !== "authorizenet_acceptjs"
                      ? "continue"
                      : "update"
                  }
                  className={"right-arrow orange-button"}
                  direction={"ml-3"}
                  righticon={"true"}
                  icon={"arrow-right"}
                />
              </MDBBox>
            </>
          )}
        </MDBBox>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default AuthorizeNetRender;
