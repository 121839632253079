import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import ListingQueryContainer from "./listing-query-container";
import { filterActions } from "core/state/redux/data/filters";
import { default as commonActions } from "core/state/redux/data/common/actions";
import SeoHelmet from "app/layout/seoHelmet";
import { findById } from "core/helpers/category";

const ListingReduxContainer = (props) => {
  return (
    <>
      <ListingQueryContainer {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  product_filters: state.filters.filter,
  category: state.filters.category,
  locked: state.common.locked,
  customer: state.customer.data,
  pageNumber: state.filters.pageNumber,
  allCategories: state.queries.data,
  allCategoriesTemp: state.queries.temp,
  search: state.search.search,
});

const mapDispatchToProps = (dispatch) => {
  return {
    lock: () => {
      dispatch(commonActions.lock());
    },
    unlock: () => {
      dispatch(commonActions.unlock());
    },
    addFilter: (filter) => {
      dispatch(filterActions.addFilter(filter));
    },
    addFilteredData: (data) => {
      dispatch(filterActions.addFilteredData(data));
    },
    addInitialCategoryData: (data) => {
      dispatch(filterActions.addInitialCategoryData(data));
    },
    changeCurrentPage: (page) => {
      dispatch(filterActions.changePageNumber(page));
    },
    reducePageData: (pageData) => {
      dispatch(filterActions.reducePageData(pageData));
    },
    removeAllFilters: () => {
      dispatch(filterActions.removeAllFilters());
    },
    clearFilters: () => {
      dispatch(filterActions.clearFilters());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingReduxContainer);
