import React from "react";
import { MDBContainer } from "mdbreact";
import TitleDescription from "app/layout/titleDescription/titleDescription";
import Helmet from "react-helmet";
import themeConfiguration from "config/themeConfiguration";
import HeroImage from "app/layout/heroImage";
import Breadcrumbs from "app/layout/breadcrumbs";
import HeroImageSrcMobile from "app/assets/images/register/ducduc-register-trade-mobile.png";
import HeroImageSrc from "app/assets/images/register/soho-crib.jpg";
import RegisterB2BStepsContainer from "./RegisterB2B-steps-container";
import CmsBlock from "core/components/cmsBlock";

const RegisterB2B = (props) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trade Registration - {themeConfiguration.name}</title>
        <link
          rel="canonical"
          href={`${themeConfiguration.app_url}/register/trade`}
        />
      </Helmet>
      <div className="img item d-lg-block d-none desktop-image">
        <HeroImage
          id={"register-img"}
          className="b2b-banner "
          alt="Shop The Look Banner"
        />
      </div>
      <div className="img item d-lg-none d-block mobile-image">
        <HeroImage
          banner={HeroImageSrcMobile}
          className="b2b-banner"
          alt="Shop The Look Banner"
        />
      </div>
      <MDBContainer
        id="trade-register-B2B"
        fluid
        className={"page-main-content"}
      >
        <Breadcrumbs />
        <TitleDescription
          title="trade program application"
          description={
            <CmsBlock dangHtml="true" id={"register_trade_program_text"} />
          }
          // description2={}
        />
        <RegisterB2BStepsContainer {...props} />
      </MDBContainer>
    </>
  );
};

export default RegisterB2B;
