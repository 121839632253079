import React, { useState } from "react";
import { MDBInput, MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";
import AccordionAssemblyItem from "../accordionAssembly/accordionAssemblyItem";
import lodash from "lodash";
import CmsBlock from "core/components/cmsBlock";

const AssemblyInstructionSearch = (props) => {
  let { search, setSearch, results } = props;

  let items = [];
  items["getProducts"] = results;
  items["category_name"] = `${
    results.length
      ? `search results found ${results.length}`
      : `no-results found ${results.length}`
  } `;
  const [value, setValue] = useState(0);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <MDBBox>
      <MDBInput
        className=" custom-input form-control search-input"
        label="search by product name"
        group
        type="text"
        validate
        error="wrong"
        success="right"
        value={search}
        onChange={handleChange}
      >
        <Icon icon={"searchOrange"} />
      </MDBInput>
      <MDBBox className={"search-results"}>
        {!lodash.isEmpty(results.length) && !lodash.isEmpty(search) && (
          <MDBBox className="search-results-inner">
            {search.length > 2 ? (
              <>
                {results.length > 0 ? (
                  <>Search Results for: "{search}"</>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </MDBBox>
        )}
        {search.length > 2 ? (
          results.length > 0 ? (
            <MDBBox className={"search-box"}>
              <>Search Results for: "{search}"</>
              <AccordionAssemblyItem item={items} />
            </MDBBox>
          ) : (
            <>
              {results.length === 0 ? (
                <>
                  <MDBBox className={"search-box"}>
                    Search Results for: "{search}"
                  </MDBBox>
                  <MDBBox className={"no-results"}>
                    <h3>{value} results found</h3>
                  </MDBBox>
                </>
              ) : (
                ""
              )}
            </>
          )
        ) : (
          <></>
        )}
      </MDBBox>
      <MDBBox tag="h4" className={"text-center"}>
        <CmsBlock dangHtml={"true"} id={"assembly_parenting_text"} />
      </MDBBox>
    </MDBBox>
  );
};

export default AssemblyInstructionSearch;
