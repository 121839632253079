import { getMinMax, getOptions } from "core/helpers/category";
import { priceChangeHandler } from "./filter_change_handlers";

const MULTI_FILTER_TYPE = "multi";
const COLOR_FILTER_TYPE = "color";

export const filterConfiguration = {
  // category: {
  //   is_filter: true,
  //   attribute_code: "category",
  //   label: "Category",
  //   type: "category",
  //   values: getCategoryFilter
  // },
  // brand: {
  //   is_filter: true,
  //   attribute_code: "brand",
  //   label: "Brands",
  //   type: "select",
  //   values: getBrands
  // },

  brands: {
    is_filter: true,
    attribute_code: "brands",
    label: "Brands",
    type: MULTI_FILTER_TYPE,
    values: getOptions,
    valueChangeHandler: ""
  },
  lead_time: {
    is_filter: true,
    attribute_code: "lead_time",
    label: "Lead Time",
    type: MULTI_FILTER_TYPE,
    values: getOptions,
    valueChangeHandler: ""
  },
  price: {
    is_filter: true,
    attribute_code: "price",
    label: "Price Range",
    type: "range",
    values: getMinMax,
    valueChangeHandler: priceChangeHandler
  },
  // has_size: {
  //   is_filter: true,
  //   attribute_code: "has_size",
  //   label: "Size",
  //   type: MULTI_FILTER_TYPE,
  //   values: getOptions,
  //   valueChangeHandler: ""
  // },
  // has_storage: {
  //   is_filter: true,
  //   attribute_code: "has_storage",
  //   label: "Storage",
  //   type: MULTI_FILTER_TYPE,
  //   values: getOptions,
  //   valueChangeHandler: ""
  // },
  product_collection: {
    is_filter: true,
    attribute_code: "product_collection",
    label: "Collection",
    type: MULTI_FILTER_TYPE,
    values: getOptions,
    valueChangeHandler: ""
  },
  color: {
    is_filter: true,
    attribute_code: "color",
    label: "Color",
    type: MULTI_FILTER_TYPE,
    frontend_type: COLOR_FILTER_TYPE,
    values: getOptions,
    valueChangeHandler: ""
  },
  finish_color: {
    is_filter: true,
    attribute_code: "finish_color",
    label: "Color",
    type: MULTI_FILTER_TYPE,
    frontend_type: COLOR_FILTER_TYPE,
    values: getOptions,
    valueChangeHandler: ""
  }
};
