import React from "react";
import CmsBlock from "core/components/cmsBlock";

const HeroImage = (props) => {
  let { background, banner, alt, id, style } = props;

  background = typeof background === "undefined" ? false : true;

  return (
    <>
      {(!background && (
        <div className="main-banner">
          <CmsBlock dangHtml={true} id={id} alt={alt} />;
        </div>
      )) || (
        <div
          className="main-banner main-banner-background"
          style={{ ...style, backgroundImage: `url(${banner})` }}
        />
      )}
    </>
  );
};

export default HeroImage;
