import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MDBBtn, MDBRow, MDBCol, MDBContainer, MDBIcon } from "mdbreact";
import Overlay from "app/layout/overlay";
import {
  default as Pages,
  pagesConfig
} from "app/config/routing/pages/customerPages";

const CustomerSidebar = props => {
  let { selectedLink, setSelectedLink, screenwidth, logoutCustomer } = props;
  const [isOpen, setIsOpen] = useState(false);

  if (screenwidth < 992) {
    return (
      <>
        <MDBContainer className="mobile-sidebar mt-5 mb-5">
          <MDBRow>
            <MDBCol md="12">
              <MDBBtn
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                my account
                <MDBIcon icon="caret-down" />
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
          <MDBRow className="mobile-account-menu">
            <MDBCol
              lg="12"
              className="mobile-account-menu-item border-bottom d-flex justify-content-center align-items-center title"
            >
              my account
              <div className="close-btn">
                <MDBIcon
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  far
                  icon="times-circle"
                />
              </div>
            </MDBCol>
            {Pages.map(page => {
              if (page.inSidebar) {
                return (
                  <Link
                    className={`mobile-account-menu-item d-flex ${
                      page.disabled ? "disabled" : ""
                    } border-bottom align-items-center justify-content-start pl-5 ${
                      page.url === selectedLink ? "active" : ""
                    }`}
                    key={page.url}
                    to={"/" + page.url}
                    onClick={e => {
                      if (typeof page.function !== "undefined") {
                        e.preventDefault();
                        if (page.function === "logoutCustomer") {
                          logoutCustomer();
                        }
                      } else {
                        setSelectedLink(page.url);
                      }
                      setIsOpen(false);
                    }}
                  >
                    <MDBCol
                      className={`account-link text-decoration-none pl-5`}
                    >
                      {page.menuText}
                    </MDBCol>
                  </Link>
                );
              }
            })}
          </MDBRow>
        </Overlay>
      </>
    );
  }

  return (
    <div className="customer-sidebar">
      <h3 className="gray light">{pagesConfig.sidebarTitle}</h3>
      <ul className="list-group main-account-group pb-3 account-group">
        {Pages.map(page => {
          if (!page.separated && page.inSidebar) {
            return (
              <Link
                className={`list-group-item  ${
                  page.disabled ? "disabled" : ""
                } account-link mb-1 ${
                  page.url === selectedLink ? "active" : ""
                }`}
                key={page.url}
                to={"/" + page.url}
                onClick={e => {
                  if (typeof page.function !== "undefined") {
                    e.preventDefault();
                    if (page.function === "logoutCustomer") {
                      logoutCustomer();
                    }
                  } else {
                    setSelectedLink(page.url);
                  }
                }}
              >
                {page.menuText}
              </Link>
            );
          }
        })}
      </ul>
      <ul className="list-group secondary-account-group mt-3 account-group">
        {Pages.map(page => {
          if (page.separated && page.inSidebar) {
            return (
              <Link
                className={`list-group-item ${
                  page.disabled ? "disabled" : ""
                } account-link mt-1 ${
                  page.url === selectedLink ? "active" : ""
                }`}
                key={page.url}
                to={"/" + page.url}
                onClick={e => {
                  if (typeof page.function !== "undefined") {
                    e.preventDefault();
                    if (page.function === "logoutCustomer") {
                      logoutCustomer();
                    }
                  } else {
                    setSelectedLink(page.url);
                  }
                }}
              >
                {page.menuText}
              </Link>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default CustomerSidebar;
