import React from "react";
import Icon from "app/assets/icon/icon";
import Button from "core/components/button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const UspContact = (props) => {
  const settings = {
    dots: false,
    dotsClass: props.dotsClass,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 1,
    customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
    appendDots: (dots) => (
      <div
        className={"test"}
        style={{
          padding: "15px 0",
          textAlign: props.DotsAlign,
          maxHeight: "5rem",
        }}
      >
        <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          variableWidth: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
        },
      },
    ],
  };

  const showChat = () => {
    if (!window.LC_API.chat_window_maximized()) {
      window.LC_API.open_chat_window();
    }
  };
  return (
    <Slider {...settings} className={"usp-contact-wrapper"}>
      <div>
        <h3>in person</h3>
        <Icon icon={"contactIcon1"} />
        <p>
          Want to see ducduc products in person? Stop by our NYDC design studio
          and showroom to say hello (and snack at our candy bar!)
        </p>
        <p className={"text-orange"}>
          new york design center 200 lexington avenue, no. 701 new york, ny
          10016
        </p>
      </div>
      <div>
        <h3>on phone</h3>
        <Icon icon={"contactIcon2"} />
        <p>
          Want to talk through a design project or genius custom idea? Our
          client services team can be reached at the number below.
        </p>
        <p className={"text-orange"}>
          212.226.1868 9am to 6pm eastern time Give us a ring!
        </p>
      </div>
      <div>
        <h3>live chat</h3>
        <Icon icon={"contactIcon3"} />
        <p>
          Can’t wait to talk? Click on the button below or find the message box
          in the bottom right to chat with us live in real time!
        </p>
        <Button
          outline={true}
          className={"right-arrow w-100 white-button"}
          text={"start live chat"}
          direction={"ml-3"}
          righticon={"true"}
          icon={"arrow-right"}
          onClick={() => showChat()}
        />
      </div>
    </Slider>
  );
};

export default UspContact;
