import React, { useState } from "react";
import { MDBRow, MDBCol, MDBBox } from "mdbreact";
import { Alert } from "reactstrap";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import CheckButton from "core/components/checkButton";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { Link } from "react-router-dom";
import { findMaskByCountry } from "./functions";
import Select2 from "core/components/select";
import { isEmpty } from "core/helpers/functions";
import MaskedInput from "react-text-mask";
import { validate, shouldMarkError } from "./validator";

let countriesData = [];
const RegisterStepThree = (props) => {
  const generateSanitizedMaskArray = (object) => {
    return Object.keys(object).map((key) => {
      let regexChar = object[key];
      if (object[key] === "+") regexChar = "+";
      if (object[key] === "#") regexChar = /\d/;
      return regexChar;
    });
  };

  let errors = validate(
    props.billingContactName,
    props.billingAddress1,
    props.billingCity,
    props.billingState,
    props.billingZipCode,
    props.shippingContactName,
    props.shippingAddress1,
    props.shippingCity,
    props.shippingState,
    props.shippingZipCode,
    props.billingEmail,
    props.shippingEmail,
    props.isSame,
    props.terms,
    props.billingPhone,
    props.shippingPhone,
    props.countrySelect.value,
    props.countrySelectShipping.value
  );

  let {
    loader,
    isSame,
    countries,
    countrySelect,
    countrySelectShipping,
    setCountrySelect,
    setCountrySelectShipping,
    regionsData,
    setRegionsData,
    regionsDataShipping,
    setRegionsDataShipping,
  } = props;

  const [termsAccepted, setTermsAccepted] = useState("");
  const [, setMask] = useState(findMaskByCountry("US"));
  const [, setMaskShipping] = useState(findMaskByCountry("US"));
  const [maskSanitizedArray, setMaskSanitizedArray] = useState(
    generateSanitizedMaskArray(findMaskByCountry("US").mask.split(""))
  );
  const [maskSanitizedArrayShipping, setMaskSanitizedArrayShipping] = useState(
    generateSanitizedMaskArray(findMaskByCountry("US").mask.split(""))
  );
  const [zipMask, setZipMask] = useState([/\d/, /\d/, /\d/, /\d/, /\d/]);
  const [zipMaskShipping, setZipMaskShipping] = useState([
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ]);
  const [touched, setTouched] = useState({});

  let countryDataObject = getCountries(countries);
  countriesData = Object.keys(countryDataObject).map((key) => {
    return countryDataObject[key];
  });

  const setCountrySelectHandler = (event, type, isSame = false) => {
    let value = event;
    let mask = [];
    let maskShipping = [];

    if (type === "billing") {
      mask = findMaskByCountry(value.value);
      setMask(mask);
      setCountrySelect(value);
    } else {
      maskShipping = findMaskByCountry(value.value);
      setMaskShipping(mask);
      setCountrySelectShipping(value);
    }

    updateRegion(value, type);

    if (value.regions === null && type === "billing") {
      setBillingState("");
    } else if (value.regions === null && type === "shipping") {
      setShippingState("");
    }
    if (type === "billing") {
      let maskSanitized = mask.mask.split("");
      setMaskSanitizedArray(generateSanitizedMaskArray(maskSanitized));
      if (isSame) {
        setMaskSanitizedArrayShipping(
          generateSanitizedMaskArray(maskSanitized)
        );
      }
    } else if (type === "shipping") {
      let maskSanitized = maskShipping.mask.split("");
      setMaskSanitizedArrayShipping(generateSanitizedMaskArray(maskSanitized));
    }

    if (value.value === "US") {
      if (!isSame) {
        if (type === "billing") {
          setZipMask([/\d/, /\d/, /\d/, /\d/, /\d/]);
        }
        if (type === "shipping") {
          setZipMaskShipping([/\d/, /\d/, /\d/, /\d/, /\d/]);
        }
      } else {
        setZipMask([/\d/, /\d/, /\d/, /\d/, /\d/]);
        setZipMaskShipping([/\d/, /\d/, /\d/, /\d/, /\d/]);
      }
    } else if (value.value === "CA") {
      if (!isSame) {
        if (type === "billing") {
          setZipMask([/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]);
        }
        if (type === "shipping") {
          setZipMaskShipping([
            /[A-Z]/i,
            /\d/,
            /[A-Z]/i,
            "-",
            /\d/,
            /[A-Z]/i,
            /\d/,
          ]);
        }
      } else {
        setZipMask([/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]);
        setZipMaskShipping([
          /[A-Z]/i,
          /\d/,
          /[A-Z]/i,
          " ",
          /\d/,
          /[A-Z]/i,
          /\d/,
        ]);
      }
    } else {
      if (isSame) {
        setZipMask([]);
        setZipMaskShipping([]);
      } else {
        if (type === "billing") {
          setZipMask([]);
        }
        if (type === "shipping") {
          setZipMaskShipping([]);
        }
      }
    }
  };

  const updateRegion = (value, type) => {
    let data = getRegionData(countriesData, value);

    if (type === "billing") {
      setRegionsData(data);
    } else {
      setRegionsDataShipping(data);
    }
  };

  const [zipCodeShippingError, setZipCodeShippingError] = useState(false);
  const [zipCodeBillingError, setZipCodeBillingError] = useState(false);

  const validateInputStepThree = () => {
    if (isSame) {
      setZipCodeShippingError(zipMask.length !== props.billingZipCode.length);
    } else {
      setZipCodeShippingError(
        zipMaskShipping.length !== props.shippingZipCode.length
      );
    }
    setZipCodeBillingError(zipMask.length !== props.billingZipCode.length);
    setTouched({
      contact_name: true,
      street_billing: true,
      city: true,
      state: true,
      zipcode: true,
      contact_name_shipping: true,
      street_shipping: true,
      city_shipping: true,
      state_shipping: true,
      zipcode_shipping: true,
      terms: true,
      billing_phone: true,
      shipping_phone: true,
      email_shipping: true,
      email: true,
    });
    let isTrue = true;
    if (!props.terms) {
      setTermsAccepted("please accept Terms + Conditions");
    }
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (
        item === "zipcode" &&
        !(
          props.countrySelect.value === "US" ||
          props.countrySelect.value === "CA"
        )
      ) {
        error = false;
      }
      if (
        item === "zipcode" &&
        zipMask.length !== props.billingZipCode.length
      ) {
        if (zipMask.length === 0) error = false;
        else error = true;
      }
      if (
        item === "zipcode_shipping" &&
        !(
          props.countrySelectShipping.value === "US" ||
          props.countrySelectShipping.value === "CA"
        )
      ) {
        error = false;
      }
      if (
        item === "zipcode_shipping" &&
        zipMaskShipping.length !== props.shippingZipCode.length
      ) {
        if (isSame) {
          if (
            item === "zipcode" &&
            zipMask.length !== props.billingZipCode.length
          ) {
            if (zipMask.length === 0) error = false;
            else error = true;
          }
        } else {
          if (zipMaskShipping.length === 0) {
            error = false;
          } else {
            error = true;
          }
        }
      }
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });

    if (isTrue) {
      props.reudceCustomer({ ...props.customer, hide: true });
      props.submitRegister();
    } else {
      props.addMessage("Please fill all required fields marked in red.");
    }
  };

  const upperCasePipe = (conformedValue) => {
    return conformedValue.toUpperCase();
  };

  //billing
  const setBillingContactName = (e) => {
    props.setBillingContactName(e.target.value);
  };
  const setBillingAddress1 = (e) => {
    props.setBillingAddress1(e.target.value);
  };
  const setBillingAddress2 = (e) => {
    props.setBillingAddress2(e.target.value);
  };
  const setBillingCity = (e) => {
    props.setBillingCity(e.target.value);
  };
  const setBillingZipCode = (e) => {
    props.setBillingZipCode(e.target.value);
  };
  const setBillingEmail = (e) => {
    props.setBillingEmail(e.target.value);
  };
  const setBillingPhone = (e) => {
    props.setBillingPhone(e.target.value);
  };
  //shopping
  const setShippingContactName = (e) => {
    props.setShippingContactName(e.target.value);
  };
  const setShippingAddress1 = (e) => {
    props.setShippingAddress1(e.target.value);
  };
  const setShippingAddress2 = (e) => {
    props.setShippingAddress2(e.target.value);
  };
  const setShippingCity = (e) => {
    props.setShippingCity(e.target.value);
  };
  const setShippingZipCode = (e) => {
    props.setShippingZipCode(e.target.value);
  };
  const setShippingEmail = (e) => {
    props.setShippingEmail(e.target.value);
  };
  const setShippingPhone = (e) => {
    props.setShippingPhone(e.target.value);
  };

  function setBillingState(evt) {
    props.setBillingState(evt);
  }
  function setShippingState(evt) {
    props.setShippingState(evt);
  }

  const sameAsBilling = () => {
    if (isSame) {
      setCountrySelectHandler(props.countrySelect, "shipping", true);
    } else {
      setCountrySelectHandler(props.countrySelect, "shipping", false);
    }
    props.sameAsBilling();
  };
  return (
    <MDBBox id={`trade-register-step-three`}>
      <MDBRow>
        <MDBCol lg="6">
          <div className="grey-text">
            <span>billing</span>
            <InputField
              label={
                shouldMarkError("contact_name", errors, touched)
                  ? "contact name is required*"
                  : "contact name*"
              }
              group
              disabled={loader}
              type="text"
              validate
              error="wrong"
              success="right"
              value={
                props.billingContactName !== undefined
                  ? props.billingContactName
                  : ""
              }
              onChange={setBillingContactName}
              className={
                shouldMarkError("contact_name", errors, touched)
                  ? "validation-error"
                  : ""
              }
              onBlur={(e) => {
                setTouched({ ...touched, contact_name: true });
              }}
            />
            <div className={`state-selection-container`}>
              <Select2
                disabled={loader}
                name={"country"}
                type={"text"}
                icon="address"
                value={countrySelect}
                onChange={(event) =>
                  setCountrySelectHandler(event, "billing", isSame)
                }
                required={true}
                wrapperClass="small"
                placeholder="country"
                options={countriesData}
                useCustom={true}
                isSearchable={true}
                // menuIsOpen={true}
              />
            </div>
            <InputField
              label={
                shouldMarkError("street_billing", errors, touched)
                  ? "address 1 is required*"
                  : "address 1*"
              }
              group
              disabled={loader}
              type="text"
              validate
              error="wrong"
              success="right"
              value={
                props.billingAddress1 !== undefined ? props.billingAddress1 : ""
              }
              onChange={setBillingAddress1}
              onBlur={(e) => {
                setTouched({ ...touched, street_billing: true });
              }}
              className={
                shouldMarkError("street_billing", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <InputField
              label="address 2"
              group
              disabled={loader}
              type="text"
              validate
              error="wrong"
              success="right"
              value={
                props.billingAddress2 !== undefined ? props.billingAddress2 : ""
              }
              onChange={setBillingAddress2}
            />
            <InputField
              label={
                shouldMarkError("city", errors, touched)
                  ? "city is required*"
                  : "city*"
              }
              group
              disabled={loader}
              type="text"
              validate
              error="wrong"
              success="right"
              value={props.billingCity !== undefined ? props.billingCity : ""}
              onChange={setBillingCity}
              onBlur={(e) => {
                setTouched({ ...touched, city: true });
              }}
              className={
                shouldMarkError("city", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <div className={`state-selection-container`}>
              {(!isEmpty(regionsData) && (
                <Select2
                  disabled={loader}
                  name={"state"}
                  type={"text"}
                  icon="address"
                  required={true}
                  value={props.billingState}
                  onChange={(event) => setBillingState(event)}
                  wrapperClass="small"
                  placeholder={
                    props.countrySelect.value === "US"
                      ? "select state*"
                      : "select province*"
                  }
                  options={regionsData}
                  isSearchable={true}
                  onBlur={(e) => {
                    setTouched({ ...touched, state: true });
                  }}
                  className={
                    shouldMarkError("state", errors, touched)
                      ? "validation-error"
                      : ""
                  }
                />
              )) || (
                <InputField
                  name={"region_text"}
                  group
                  type={"text"}
                  wrapperClass={"small"}
                  required={true}
                  value={props.billingState}
                  onChange={(event) => setBillingState(event.target.value)}
                  icon="address"
                  label="province"
                />
              )}
            </div>
            {zipMask.length === 0 ? (
              <InputField
                label={
                  shouldMarkError("zipcode", errors, touched)
                    ? props.countrySelect.value === "US"
                      ? "zip code is required*"
                      : props.countrySelect.value === "CA"
                      ? "postal code is required*"
                      : "postal code"
                    : props.countrySelect.value === "US"
                    ? "zip code*"
                    : props.countrySelect.value === "CA"
                    ? "postal code*"
                    : "postal code"
                }
                group
                disabled={loader}
                type="text"
                validate
                error="wrong"
                success="right"
                value={
                  props.billingZipCode !== undefined ? props.billingZipCode : ""
                }
                onChange={setBillingZipCode}
                onBlur={(e) => {
                  setTouched({ ...touched, zipcode: true });
                }}
                className={
                  shouldMarkError("zipcode", errors, touched) ||
                  zipCodeBillingError
                    ? props.countrySelect.value === "US" ||
                      props.countrySelect.value === "CA"
                      ? "validation-error"
                      : ""
                    : ""
                }
              />
            ) : (
              <div
                className={`md-form form-group default-input-field-container`}
              >
                <MaskedInput
                  id="mask-input"
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  value={props.billingZipCode}
                  onChange={setBillingZipCode}
                  className={`
                  form-control validate default-input-field
                    ${
                      shouldMarkError("zipcode", errors, touched) ||
                      zipCodeBillingError
                        ? props.countrySelect.value === "US" ||
                          props.countrySelect.value === "CA"
                          ? "validation-error"
                          : ""
                        : ""
                    }`}
                  placeholder={
                    shouldMarkError("zipcode", errors, touched)
                      ? props.countrySelect.value === "US"
                        ? "zip code is required*"
                        : props.countrySelect.value === "CA"
                        ? "postal code is required*"
                        : "postal code"
                      : props.countrySelect.value === "US"
                      ? "zip code*"
                      : props.countrySelect.value === "CA"
                      ? "postal code*"
                      : "postal code"
                  }
                  onBlur={(e) => {
                    setTouched({ ...touched, zipcode: true });
                  }}
                  mask={zipMask}
                  guide={false}
                  pipe={upperCasePipe}
                  render={(ref, inputProps) => {
                    return <input {...inputProps} ref={ref} />;
                  }}
                />
              </div>
            )}
            <InputField
              label={
                shouldMarkError("email", errors, touched)
                  ? props.billingEmail.length === 0
                    ? "email is required*"
                    : "email is invalid"
                  : "email*"
              }
              group
              disabled={loader}
              type="email"
              validate
              error="wrong"
              success="right"
              value={props.billingEmail !== undefined ? props.billingEmail : ""}
              onChange={setBillingEmail}
              onBlur={(e) => {
                setTouched({ ...touched, email: true });
              }}
              className={
                shouldMarkError("email", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <div className={`md-form form-group default-input-field-container`}>
              <MaskedInput
                id="mask-input"
                type="text"
                error="wrong"
                success="right"
                id="mask-input"
                value={
                  props.billingPhone !== undefined ? props.billingPhone : ""
                }
                onChange={setBillingPhone}
                mask={maskSanitizedArray}
                guide={false}
                placeholder={`phone*`}
                placeholder={
                  shouldMarkError("billing_phone", errors, touched)
                    ? "phone is required*"
                    : "phone*"
                }
                className={
                  shouldMarkError("billing_phone", errors, touched)
                    ? "validation-error"
                    : ""
                }
                render={(ref, inputProps) => {
                  return (
                    <input
                      {...inputProps}
                      ref={ref}
                      className={`form-control validate default-input-field ${
                        shouldMarkError("billing_phone", errors, touched)
                          ? "validation-error"
                          : ""
                      }`}
                    />
                  );
                }}
              />
            </div>
          </div>
        </MDBCol>
        <MDBCol lg="6">
          <div className="grey-text">
            <span>shipping</span>
            <div className="float-right">
              <CheckButton
                containerClass={"checkbox-container check-button"}
                wrapperClass={"checkmark"}
                label={"same as billing"}
                onChange={() => {
                  sameAsBilling();
                  props.setIsSame(!isSame);
                }}
                checked={isSame}
                type={"checkbox"}
              />
            </div>

            <InputField
              label="contact name*"
              group
              disabled={isSame}
              type="text"
              validate
              error="wrong"
              success="right"
              value={
                !isSame ? props.shippingContactName : props.billingContactName
              }
              onChange={setShippingContactName}
              onBlur={(e) => {
                setTouched({ ...touched, contact_name_shipping: true });
              }}
              className={
                shouldMarkError("contact_name_shipping", errors, touched)
                  ? "validation-error"
                  : ""
              }
              label={
                shouldMarkError("contact_name_shipping", errors, touched)
                  ? "contact name is required*"
                  : "contact name*"
              }
            />
            <div className={`state-selection-container`}>
              <Select2
                disabled={loader}
                name={"country"}
                type={"text"}
                icon="address"
                value={!isSame ? countrySelectShipping : countrySelect}
                onChange={(event) => {
                  setCountrySelectHandler(event, "shipping");
                }}
                required={true}
                wrapperClass="small"
                placeholder="Country"
                options={countriesData}
                useCustom={true}
                isSearchable={true}
                isDisabled={isSame}
                // menuIsOpen={true}
              />
            </div>
            <InputField
              label={
                shouldMarkError("street_shipping", errors, touched)
                  ? "address 1 is required*"
                  : "address 1*"
              }
              group
              disabled={isSame}
              type="text"
              validate
              error="wrong"
              success="right"
              value={!isSame ? props.shippingAddress1 : props.billingAddress1}
              onChange={setShippingAddress1}
              onBlur={(e) => {
                setTouched({ ...touched, street_shipping: true });
              }}
              className={
                shouldMarkError("street_shipping", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <InputField
              label="address 2"
              group
              disabled={isSame}
              type="text"
              validate
              error="wrong"
              success="right"
              value={!isSame ? props.shippingAddress2 : props.billingAddress2}
              onChange={setShippingAddress2}
            />
            <InputField
              label={
                shouldMarkError("city_shipping", errors, touched)
                  ? "city is required*"
                  : "city*"
              }
              group
              disabled={isSame}
              type="text"
              validate
              error="wrong"
              success="right"
              value={!isSame ? props.shippingCity : props.billingCity}
              onChange={setShippingCity}
              onBlur={(e) => {
                setTouched({ ...touched, city_shipping: true });
              }}
              className={
                shouldMarkError("city_shipping", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <div className={`state-selection-container`}>
              {!isSame && !isEmpty(regionsDataShipping) ? (
                <Select2
                  disabled={loader}
                  name={"state-2"}
                  type={"text"}
                  icon="address"
                  required={true}
                  value={props.shippingState}
                  onChange={(event) => setShippingState(event)}
                  wrapperClass="small"
                  placeholder={
                    props.countrySelectShipping.value === "CA"
                      ? "select province*"
                      : "select state*"
                  }
                  options={regionsDataShipping}
                  isSearchable={true}
                  isDisabled={isSame}
                  onBlur={(e) => {
                    setTouched({ ...touched, state_shipping: true });
                  }}
                  className={
                    shouldMarkError("state_shipping", errors, touched)
                      ? "validation-error"
                      : ""
                  }
                />
              ) : !isSame && isEmpty(regionsDataShipping) ? (
                <InputField
                  name={"region_text"}
                  group
                  type={"text"}
                  wrapperClass={"small"}
                  required={true}
                  value={props.shippingState}
                  onChange={(event) => setShippingState(event.target.value)}
                  icon="address"
                  label="province"
                />
              ) : isSame ? (
                !isEmpty(regionsData) ? (
                  <Select2
                    name={"state-2"}
                    type={"text"}
                    icon="address"
                    required={true}
                    value={props.billingState}
                    onChange={(event) => setShippingState(event)}
                    wrapperClass="small"
                    placeholder={
                      props.countrySelect.value === "US"
                        ? "select state*"
                        : "select province*"
                    }
                    options={regionsData}
                    isSearchable={true}
                    isDisabled={isSame}
                    onBlur={(e) => {
                      setTouched({ ...touched, state_shipping: true });
                    }}
                    className={
                      shouldMarkError("state", errors, touched)
                        ? "validation-error"
                        : ""
                    }
                  />
                ) : (
                  <InputField
                    name={"region_text"}
                    group
                    type={"text"}
                    wrapperClass={"small"}
                    required={true}
                    value={props.billingState}
                    onChange={(event) => setShippingState(event.target.value)}
                    icon="address"
                    label="province"
                    disabled={isSame}
                  />
                )
              ) : (
                <></>
              )}
            </div>
            {zipMaskShipping.length === 0 ||
            (isSame && zipMask.length === 0) ? (
              <InputField
                label={
                  isSame
                    ? shouldMarkError("zipcode", errors, touched)
                      ? props.countrySelect.value === "US"
                        ? "zip code is required*"
                        : props.countrySelect.value === "CA"
                        ? "postal code is required*"
                        : "postal code"
                      : props.countrySelect.value === "US"
                      ? "zip code*"
                      : props.countrySelect.value === "CA"
                      ? "postal code"
                      : "postal code"
                    : shouldMarkError("zipcode_shipping", errors, touched)
                    ? props.countrySelectShipping.value === "US"
                      ? "zip code is required*"
                      : props.countrySelectShipping.value === "CA"
                      ? "postal code is required*"
                      : "postal code"
                    : props.countrySelectShipping.value === "US"
                    ? "zip code*"
                    : props.countrySelectShipping.value === "CA"
                    ? "postal code"
                    : "postal code"
                }
                group
                disabled={isSame}
                type="text"
                validate
                error="wrong"
                success="right"
                value={!isSame ? props.shippingZipCode : props.billingZipCode}
                onChange={setShippingZipCode}
                className={
                  shouldMarkError("zipcode_shipping", errors, touched)
                    ? countrySelectShipping.value === "US" ||
                      countrySelectShipping.value === "CA"
                      ? "validation-error"
                      : ""
                    : ""
                }
                onBlur={(e) => {
                  setTouched({ ...touched, zipcode_shipping: true });
                }}
              />
            ) : (
              <div
                className={`md-form form-group default-input-field-container`}
              >
                <MaskedInput
                  id="mask-input"
                  label={
                    shouldMarkError("zipcode_shipping", errors, touched)
                      ? props.countrySelectShipping.value === "US"
                        ? "zip code is required*"
                        : props.countrySelectShipping.value === "CA"
                        ? "postal code is required*"
                        : "postal code"
                      : props.countrySelectShipping.value === "US"
                      ? "zip code*"
                      : props.countrySelectShipping.value === "CA"
                      ? "postal code*"
                      : "postal code"
                  }
                  group
                  disabled={isSame}
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  value={!isSame ? props.shippingZipCode : props.billingZipCode}
                  onChange={setShippingZipCode}
                  className={`
                  form-control validate default-input-field
                    ${
                      shouldMarkError("zipcode_shipping", errors, touched) ||
                      zipCodeShippingError
                        ? countrySelectShipping.value === "US" ||
                          countrySelectShipping.value === "CA"
                          ? "validation-error"
                          : ""
                        : ""
                    }`}
                  placeholder={
                    shouldMarkError("zipcode_shipping", errors, touched)
                      ? props.countrySelectShipping.value === "US"
                        ? "zip code is required*"
                        : props.countrySelectShipping.value === "CA"
                        ? "postal code is required*"
                        : "postal code"
                      : props.countrySelectShipping.value === "US"
                      ? "zip code*"
                      : props.countrySelectShipping.value === "CA"
                      ? "postal code*"
                      : "postal code"
                  }
                  onBlur={(e) => {
                    setTouched({ ...touched, zipcode_shipping: true });
                  }}
                  mask={zipMaskShipping}
                  guide={false}
                  pipe={upperCasePipe}
                  render={(ref, inputProps) => {
                    return <input {...inputProps} ref={ref} />;
                  }}
                />
              </div>
            )}
            <InputField
              label={
                isSame
                  ? shouldMarkError("email", errors, touched)
                    ? props.billingEmail.length === 0
                      ? "email is required*"
                      : "email is invalid"
                    : "email*"
                  : shouldMarkError("email_shipping", errors, touched)
                  ? props.shippingEmail.length === 0
                    ? "email is required*"
                    : "email is invalid"
                  : "email*"
              }
              group
              disabled={isSame}
              type="email"
              validate
              error="wrong"
              success="right"
              value={!isSame ? props.shippingEmail : props.billingEmail}
              onChange={setShippingEmail}
              onBlur={(e) => {
                setTouched({ ...touched, email_shipping: true });
              }}
              className={
                isSame
                  ? shouldMarkError("email", errors, touched)
                    ? "validation-error"
                    : ""
                  : shouldMarkError("email_shipping", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <div className={`md-form form-group default-input-field-container`}>
              <MaskedInput
                id="mask-input"
                disabled={isSame}
                type="text"
                error="wrong"
                success="right"
                value={!isSame ? props.shippingPhone : props.billingPhone}
                onChange={setShippingPhone}
                mask={maskSanitizedArrayShipping}
                guide={false}
                placeholder={
                  shouldMarkError("shipping_phone", errors, touched)
                    ? "phone is required*"
                    : "phone*"
                }
                className={
                  shouldMarkError("shipping_phone", errors, touched)
                    ? "validation-error"
                    : ""
                }
                render={(ref, inputProps) => {
                  return (
                    <input
                      {...inputProps}
                      ref={ref}
                      className={`form-control validate default-input-field ${
                        shouldMarkError("shipping_phone", errors, touched)
                          ? "validation-error"
                          : ""
                      } `}
                    />
                  );
                }}
              />
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="step-three-check-block">
        <MDBCol lg={"6"} className={`offset-md-6`}>
          <CheckButton
            containerClass={"switch-container check-button"}
            wrapperClass={"slider"}
            label={"join our mailing list"}
            value="Join our mailing list"
            checked={props.joinOurMailingList}
            onChange={() =>
              props.setJoinOurMailingList(!props.joinOurMailingList)
            }
            type={"checkbox"}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="step-three-check-block">
        <MDBCol lg="6" className={`offset-md-6 terms-block`}>
          <CheckButton
            containerClass={"switch-container check-button"}
            wrapperClass={"slider"}
            className={
              shouldMarkError("terms", errors, touched)
                ? "validation-error"
                : ""
            }
            label={
              <>
                I have read the
                <Link to={"/terms-and-conditions"} target="_blank">
                  terms + conditions
                </Link>
              </>
            }
            value="I have read the Terms + Conditions"
            checked={props.terms}
            onChange={() => props.setTerms(!props.terms)}
            type={"checkbox"}
          />
          <div className="text-right required-text-block">
            <span className="required-sign">*</span>
            <span className="required-text">= required</span>
          </div>
        </MDBCol>
      </MDBRow>
      {termsAccepted !== "" && !props.terms && (
        <Alert color="danger" isOpen={true} toggle={() => setTermsAccepted("")}>
          {termsAccepted}
        </Alert>
      )}
      <MDBRow className="step-three-buttons">
        <MDBCol md={"6"}>
          <div className="text-left py-4 mt-3">
            <Button
              outline={true}
              className={"right-arrow white-button"}
              children={"back"}
              direction={"ml-3"}
              onClick={props.setPreviousStep}
            />
          </div>
        </MDBCol>
        <MDBCol md={"6"}>
          <div className="text-right py-4 mt-3">
            <Button
              className={"right-arrow orange-button step-three-submit"}
              direction={"ml-3"}
              disabled={loader}
              righticon={"true"}
              icon={"arrow-right"}
              text={`${(loader && "sending") || "submit"}`}
              onClick={() => validateInputStepThree()}
            />
          </div>
        </MDBCol>
      </MDBRow>
    </MDBBox>
  );
};

export default RegisterStepThree;
