import React from "react";
import Category from "./category";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";

const MENU_QUERY = gql`
  query Category($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      id
      name
      include_in_menu
      default_sort_by
      url_path
      position
      description
      products {
        total_count
      }
      mobile_image
      children {
        id
        name
        default_sort_by
        image
        url_key
        url_path
        include_in_menu
        position
        description
        products {
          total_count
        }
        mobile_image
        children {
          id
          name
          default_sort_by
          image
          url_key
          url_path
          include_in_menu
          position
          description
          products {
            total_count
          }
          mobile_image
        }
      }
    }
  }
`;

const CategoryQueryContainer = (props) => {
  return (
    <>
      <GraphQlWrapper
        query={MENU_QUERY}
        variables={{
          id: "2",
        }}
        saveQuery={true}
      >
        <Category
          {...props}
          setFilters={props.setFilters}
          filters={props.filters}
        />
      </GraphQlWrapper>
    </>
  );
};

export default CategoryQueryContainer;
