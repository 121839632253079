import React from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from "mdbreact";
const Dropdown = props => {
  return (
    <MDBDropdown>
      <MDBDropdownToggle {...props}>{props.dropdownText}</MDBDropdownToggle>
      <MDBDropdownMenu {...props}>
        <MDBDropdownItem>{props.dropdownItem}</MDBDropdownItem>
        <MDBDropdownItem>{props.dropdownItem}</MDBDropdownItem>
        <MDBDropdownItem>{props.dropdownItem}</MDBDropdownItem>
        <MDBDropdownItem>{props.dropdownSeparatedLink}</MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  );
};
export default Dropdown;
