import React from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import { commonActions } from "core/state/redux/data/common";
import WishlistDataContainer from "./wishlist-data-container";

const WishlistReduxContainer = props => {
  if (typeof props.product !== "undefined")
    return <WishlistDataContainer {...props} />;
  return <></>;
};

const mapStateToProps = state => ({
  wishlist: state.customer.data.wishlist
});

const mapDispatchToProps = dispatch => {
  return {
    addProductToWishlist: (wishlist_id, product_id, options = []) => {
      dispatch(
        customerActions.addProductToWishlist(wishlist_id, product_id, options)
      );
    },
    removeItemFromWishlist: wishlist_item_id => {
      dispatch(customerActions.removeItemFromWishlist(wishlist_item_id));
    },
    lock: callback => {
      dispatch(commonActions.lock(callback));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishlistReduxContainer);
