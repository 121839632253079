import React, { memo } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { isEqual } from "lodash";
import { truncateString } from "core/helpers/functions";

const Listing = memo(
  ({
    wishlists,
    activeWishlist,
    setActiveWishlist,
    setProjectName,
    setIsEditing
  }) => {
    return (
      <MDBCol id={`wishlist-toolbar-listing`}>
        <MDBRow>
          <MDBCol className={`d-flex flex-row`}>
            {wishlists.map(({ id, name }) => {
              return (
                <MDBCol
                  key={`multiwishlist-${id}-${name}`}
                  onClick={e => {
                    setActiveWishlist(
                      name.toLowerCase() !== "favorites" ? id : 0
                    );
                    setProjectName(name);
                    setIsEditing(false);
                  }}
                  className={`item ${
                    activeWishlist === 0 && name.toLowerCase() === "favorites"
                      ? "active"
                      : activeWishlist === id &&
                        name.toLowerCase() !== "favorites"
                      ? "active"
                      : "inactive"
                  }`}
                >
                  {truncateString(name, 16)}
                </MDBCol>
              );
            })}
          </MDBCol>
        </MDBRow>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps) ? true : false;
  }
);

export default Listing;
