import React from "react";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";
import WhatLookingBlockDataContainer from "./whatLookingBlock-data-container";
/**
 * Refactor GraphQl Wrapper to use this approach? addQuery middleware
 */
const BLOCK_GROUP = gql`
  query Category($id: Int!) {
    getGroupBlocks(id: $id) {
      id
      content
      name
    }
  }
`;

const WhatLookingBlockQueryContainer = props => {
  return (
    <GraphQlWrapper
      query={BLOCK_GROUP}
      variables={{ id: 2, identifier: "whats_looking_for" }}
      saveQuery={true}
    >
      <WhatLookingBlockDataContainer />
    </GraphQlWrapper>
  );
};

export default WhatLookingBlockQueryContainer;
