import React from "react";
import AssemblyInstructionsStateContainer from "./assemblyInstructions-state-container";
import GraphQlWrapper from "core/components/graphQlWrapper/";
import { gql } from "graphql.macro";
const ASSEMBLY_QUERY = gql`
  query getAssembly {
    getAssembly {
      category_id
      category_name
      getProducts {
        instructions
        product_id
        product_name
        sku
        video
      }
    }
  }
`;

const AssemblyInstructionsQueryContainer = props => {
  return (
    <GraphQlWrapper
      query={ASSEMBLY_QUERY}
      variables={{
        id: `assembly-instructions-data` + props.id,
        url: props.url
      }}
      loader={true}
    >
      <AssemblyInstructionsStateContainer {...props} />
    </GraphQlWrapper>
  );
};

export default AssemblyInstructionsQueryContainer;
