import React from "react";
import { connect } from "react-redux";
import { default as queriesActions } from "core/state/redux/data/queries/actions";
import { filterActions } from "core/state/redux/data/filters";
import { default as commonActions } from "core/state/redux/data/common/actions";
import WishlistShareFilterQueryContainer from "./wishlistShare-filter-query-container";

const WishlistShareFilterReduxContainer = (props) => {
  return <WishlistShareFilterQueryContainer {...props} />;
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  product_filters: state.filters.filter,
  pageNumber: state.filters.pageNumber,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addQueryData: (data, identifier, key) => {
      dispatch(queriesActions.addQueryData(data, identifier, key));
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
    unlock: () => {
      dispatch(commonActions.unlock());
    },
    addFilteredData: (data) => {
      dispatch(filterActions.addFilteredData(data));
    },
    addInitialCategoryData: (data) => {
      dispatch(filterActions.addInitialCategoryData(data));
    },
    changeCurrentPage: (page) => {
      dispatch(filterActions.changeCurrentPage(page));
    },
    reducePageData: (pageData) => {
      dispatch(filterActions.reducePageData(pageData));
    },
    removeAllFilters: () => {
      dispatch(filterActions.removeAllFilters());
    },
    clearFilters: () => {
      dispatch(filterActions.clearFilters());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishlistShareFilterReduxContainer);
