import React, { useState } from "react";
import Header from "./header";

const HeaderStateContainer = props => {
  let { data, toggleSearch } = props;
  const [menu, setMenu] = useState(data.categoryList[0]);
  const [toggle, setToggle] = useState(false);
  const [hoveredDropdown, setHoveredDropdown] = useState(0);
  const [position, setPosition] = useState(0);
  const [firstPosition, setFirstPosition] = useState(0);

  const changeToggle = () => {
    setToggle(!toggle);
  };

  return (
    <Header
      menu={menu}
      setMenu={setMenu}
      changeToggle={changeToggle}
      toggle={toggle}
      hoveredDropdown={hoveredDropdown}
      setHoveredDropdown={setHoveredDropdown}
      position={position}
      setPosition={setPosition}
      firstPosition={firstPosition}
      setFirstPosition={setFirstPosition}
      toggleSearch={toggleSearch}
    />
  );
};

export default HeaderStateContainer;
