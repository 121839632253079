import React, { useState } from "react";
import Form from "./form";
import { getCountryById } from "core/helpers/functions";
import { isUndefined } from "lodash";

const FormStateContainer = (props) => {
  let { address } = props;
  const [countryValue, setCountryValue] = useState(
    isUndefined(address)
      ? {
          label: "United States",
          value: "US",
        }
      : {
          label: getCountryById(address.country_id).full_name_english,
          value: address.country_id,
        }
  );

  const [stateValue, setStateValue] = useState(
    isUndefined(address)
      ? ""
      : address.region.region_id === 0
      ? address.region.region
      : {
          label: address.region.region,
          value: address.region.region_id,
        }
  );
  let [firstname, setFirstname] = useState(
    isUndefined(address) ? "" : address.firstname
  );
  let [lastname, setLastname] = useState(
    isUndefined(address) ? "" : address.lastname
  );
  let [companyname, setCompanyname] = useState(
    isUndefined(address) ? "" : address.company
  );
  let [phone, setPhone] = useState(
    isUndefined(address) ? "" : address.telephone
  );
  let [address1, setAddress1] = useState(
    isUndefined(address) ? "" : address.street[0]
  );
  let [address2, setAddress2] = useState(
    isUndefined(address) ? "" : address.street[1]
  );
  let [city, setCity] = useState(isUndefined(address) ? "" : address.city);
  let [zipcode, setZipcode] = useState(
    isUndefined(address) ? "" : address.postcode
  );

  const [defaultBilling, setDefaultBilling] = useState(
    isUndefined(address) ? false : address.default_billing
  );
  const [defaultShipping, setDefaultShipping] = useState(
    isUndefined(address) ? false : address.default_shipping
  );
  const [loading, setLoading] = useState(false);
  return (
    <Form
      {...props}
      countryValue={countryValue}
      setCountryValue={setCountryValue}
      stateValue={stateValue}
      setStateValue={setStateValue}
      firstname={firstname}
      setFirstname={setFirstname}
      lastname={lastname}
      setLastname={setLastname}
      companyname={companyname}
      setCompanyname={setCompanyname}
      setPhone={setPhone}
      phone={phone}
      address1={address1}
      setAddress1={setAddress1}
      address2={address2}
      setAddress2={setAddress2}
      city={city}
      setCity={setCity}
      zipcode={zipcode}
      setZipcode={setZipcode}
      defaultBilling={defaultBilling}
      defaultShipping={defaultShipping}
      setDefaultBilling={setDefaultBilling}
      setDefaultShipping={setDefaultShipping}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default FormStateContainer;
