let color = "#e88602";

export default {
  magento_url: "https://magento.studioducnyc.com/",
  graphql_url: "graphql",
  media_url: "/pub/media",
  catalog_url: "/catalog/product",
  category_url: "/catalog/category",
  app_url: "https://magento.studioducnyc.com/",
  name: "ducduc",
  name_homepage: "ducduc - children's furniture | youth beds | bunk beds",
  root_category_id: 2,
  product_category_id: 2,
  sessionMessageTime: 7000,
  colors: {
    primary: color,
  },
  loader: {
    color: "#ff6d2d",
    height: 100,
    width: 100,
    type: "MutatingDots",
  },
  mediaGallery: {
    nav: "thumbs",
    desktop: {
      width: 800,
      height: 600,
    },
    mobile: {
      width: "auto",
      height: "auto",
    },
  },
  pricing: {
    defaultCurrency: "USD",
  },
};

export const storage = {
  local_storage_key: "ducduc",
  throttle_local_storage_time: 200,
};
