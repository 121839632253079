import React from "react";
import { MDBRow, MDBCol, MDBBox, MDBContainer } from "mdbreact";
import HeroImage from "app/layout/heroImage";
import HeroImageSrc from "app/assets/images/register/ducduc-register-trade.jpg";
import Button from "core/components/button";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";

const cThankYouPage = (props) => {
  return (
    <>
      <HeroImage
        id={"thank-you-img"}
        className="b2b-banner"
        alt="Shop The Look Banner"
      />
      <MDBContainer className={"page-main-content register-success"}>
        <MDBRow>
          <MDBCol md="12">
            <MDBBox tag="h3">thank you for registering!</MDBBox>
            <MDBBox tag="h4">
              A confirmation email has been sent. Click the button below to
              login
            </MDBBox>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="8" className={"register-succes-buttons"}>
            <Button
              outline={true}
              className={"right-arrow white-button"}
              children={"homepage"}
              direction={"ml-3"}
              to={"/"}
            />
            <ModalConsumer value={{ useWrapper: false }}>
              {({ showModal, hideModal }) => (
                <Button
                  className={"right-arrow orange-button shop-the-look-btn"}
                  direction={"ml-3"}
                  righticon={"true"}
                  icon={"arrow-right"}
                  text={"login"}
                  onClick={() => {
                    showModal(() => {
                      return <Login hideModal={hideModal} />;
                    });
                  }}
                />
              )}
            </ModalConsumer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default cThankYouPage;
