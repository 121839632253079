import React from "react";
import Breadcrumbs from "app/layout/breadcrumbs";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import lodash from "lodash";
import CategoryComponentContainer from "./category-component-container";
import ParentCategory from "./parentCategory";
import SeoHelmet from "app/layout/seoHelmet";
import { findCategoryByAttribute } from "core/helpers/category";

const Category = (props) => {
  const { categoryFilter, data } = props;

  let url = window.location.pathname.substring(1);
  let category = undefined;

  if (typeof data === "undefined") return <></>;
  if (typeof data.categoryList[0].children !== "undefined") {
    category = findCategoryByAttribute(
      url,
      data.categoryList[0].children,
      "url_path"
    );
  }

  return (
    <>
      <SeoHelmet
        url={category.url_path}
        title={category.name}
        description={category.meta_description}
        image={typeof category.image !== "undefined" ? category.image : ""}
      />
      <div id={`category-${categoryFilter}`} className={`category-container`}>
        <MDBContainer className="plp-main-container">
          {/* BREADCRUMBS CONTAINER */}
          <MDBRow>
            <MDBCol lg="9" className="offset-lg-3">
              <Breadcrumbs />
            </MDBCol>
          </MDBRow>
          {(typeof category !== "undefined" &&
            typeof category.children === "undefined") ||
          (typeof category !== "undefined" &&
            lodash.isEmpty(category.children)) ? (
            <MDBRow className="main-plp-container">
              <CategoryComponentContainer
                parentCategoryData={data}
                categoryFilter={categoryFilter}
              />
            </MDBRow>
          ) : (
            <>
              {typeof category !== "undefined" &&
              typeof category.children === "undefined" ? (
                <MDBRow className="main-plp-container">
                  <CategoryComponentContainer categoryFilter={category.id} />
                </MDBRow>
              ) : (
                <ParentCategory data={category} />
              )}
            </>
          )}
        </MDBContainer>
      </div>
    </>
  );
};

export default Category;
