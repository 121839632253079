import React, { Fragment } from "react";
import {
  MDBNavbar,
  MDBCol,
  MDBContainer,
  MDBBox,
  MDBHamburgerToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavLink,
  MDBLink,
  MDBNavbarBrand,
} from "mdbreact";
import ToggleMenu from "./toggle-menu";
import Login from "app/pages/customer/login";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { UncontrolledDropdown } from "reactstrap";
import { toggleHamburger } from "./functions";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { default as StaticLinks } from "./data";
import { default as AccountLinks } from "app/config/routing/pages/customerPages";
import Icon from "app/assets/icon/icon";
import Search from "../right/search";

const Mobile = (props) => {
  let {
    menuItems,
    setOpen,
    open,
    token,
    menuToggle,
    setMenuToggle,
    logoutCustomer,
    toggleSearch,
  } = props;
  const showChat = () => {
    if (!window.LC_API.chat_window_maximized()) {
      window.LC_API.open_chat_window();
    }
  };

  const closeBurger = () => {
    if (props.open) document.getElementById("hamburger1").click();
  };

  const navigation = [
    <>
      <MDBBox>
        <MDBNavbarNav left>
          {Object.keys(menuItems).map((element, index) => {
            let currentMenuItem = menuItems[index];
            if (menuItems[index].include_in_menu === 0) {
              return <Fragment key={`menu-fragment-${index}`}></Fragment>;
            }
            return (
              (currentMenuItem.children.length > 0 && (
                <UncontrolledDropdown
                  key={`uncontrolled-nav-dropdown-${index}`}
                >
                  <MDBBox
                    className=" dropdown-toggle nav-link p-0 "
                    id={`nav-dropdown-${index}`}
                  >
                    <MDBNavbarNav
                      className={`mobile-dropdown`}
                      id={`dropdown-visible-${index}`}
                    >
                      <MDBBox display={`flex`} flex={`column`}>
                        <ToggleMenu
                          items={currentMenuItem.children}
                          menuItem={currentMenuItem}
                          toggleHamburger={toggleHamburger}
                          setOpen={setOpen}
                          open={open}
                        />
                      </MDBBox>
                    </MDBNavbarNav>
                  </MDBBox>
                </UncontrolledDropdown>
              )) || (
                <UncontrolledDropdown
                  key={`uncontrolled-nav-dropdown-${index}`}
                >
                  <MDBBox className="dropdown-toggle nav-link static-link">
                    <MDBNavLink
                      to={`/${currentMenuItem.url_path}`}
                      className={`p-0 text-decoration-none text-uppercase`}
                      key={`nav-item-not-uncontrolled-nav-${index}`}
                      onClick={(e) => {
                        toggleHamburger(props);
                        closeBurger();
                      }}
                    >
                      {currentMenuItem.name}
                    </MDBNavLink>
                  </MDBBox>
                </UncontrolledDropdown>
              )
            );
          })}
          {StaticLinks.map((value, index) => {
            return (
              <UncontrolledDropdown key={`uncontrolled-nav-dropdown-${index}`}>
                <MDBBox className="dropdown-toggle nav-link static-link">
                  <MDBNavLink
                    to={value.url}
                    className={`mr-5 text-uppercase p-0 text-decoration-none`}
                    onClick={(e) => {
                      toggleHamburger(props);
                      closeBurger();
                    }}
                  >
                    {value.name}
                  </MDBNavLink>
                </MDBBox>
              </UncontrolledDropdown>
            );
          })}
        </MDBNavbarNav>
      </MDBBox>
    </>,
    <div>
      {AccountLinks &&
        Object.keys(AccountLinks).map((value, index) => {
          let accountLink = AccountLinks[index];
          return (
            accountLink.inDropdown &&
            accountLink.url !== "logout" && (
              <UncontrolledDropdown
                key={`uncontrolled-nav-dropdown-account-${index}`}
              >
                <MDBBox className="dropdown-toggle nav-link static-link mobile-account-links">
                  <MDBNavLink
                    to={`${
                      (accountLink.url !== "logout" && "/" + accountLink.url) ||
                      ""
                    }`}
                    className={`mr-5 text-uppercase p-0 text-decoration-none`}
                    onClick={(e) => {
                      toggleHamburger(props);
                      closeBurger();
                    }}
                  >
                    {accountLink.menuText}
                  </MDBNavLink>
                </MDBBox>
              </UncontrolledDropdown>
            )
          );
        })}
    </div>,
  ];
  return (
    <MDBNavbar
      id={`mobile-header`}
      expand="lg"
      scrolling
      fixed="top"
      className={`white align-items-start`}
    >
      <MDBContainer fluid className={`h-100 px-0`}>
        <MDBBox
          display={`flex`}
          flex={`row`}
          justifyContent={`between`}
          id={`mobile-navigation-container`}
          className={`w-100 h-100`}
          alignItems={`center`}
        >
          <MDBBox className={`hamburger-menu`}>
            <MDBHamburgerToggler
              color="#ff6d2d"
              id="hamburger1"
              onClick={() => toggleHamburger(props)}
            />
          </MDBBox>
          <MDBBox id={`logo-container`}>
            <MDBCol id={`duc-duc-letter-logo`} className={`position-relative`}>
              <MDBNavbarBrand href="/">
                <Icon icon={`ducducLetterLogo`} />
              </MDBNavbarBrand>
            </MDBCol>
            <MDBCol id={`duc-duc-logo`} className={`position-relative`}>
              <MDBNavbarBrand href="/">
                <Icon icon={"ducducLogo"} />
              </MDBNavbarBrand>
              <MDBBox id={`logo-shape`} className={`position-absolute`}>
                <Icon icon={`logoShape`} />
              </MDBBox>
            </MDBCol>
          </MDBBox>
          <MDBBox id={`mini-cart-icon`} flex={`column`} display={`flex`}>
            <MDBBox className="cart ml-auto text-right" alignSelf={`end`}>
              <MDBLink
                onClick={() => closeBurger()}
                to={"/cart"}
                className="ml-auto text-decoration-none"
              >
                <Icon icon={"cartOrange"} />
              </MDBLink>
            </MDBBox>
          </MDBBox>
        </MDBBox>

        <MDBContainer
          fluid
          id={`mobile-navigation`}
          className={`align-items-start ${
            (open && `is-open`) || `is-closed`
          } px-0`}
        >
          <MDBCollapse isOpen={open} navbar>
            {/*Menu toggle tabs*/}
            <MDBBox
              id={`navigation-menu-toggles`}
              display={`flex`}
              flex={`row`}
              justifyContent={`between`}
              className={`${(token && "signed-in") || "signed-out"}`}
            >
              <MDBBox
                className={`menu-toggle ${
                  (menuToggle === 0 && "active") || `not-active`
                }`}
                onClick={(e) => {
                  setMenuToggle(0);
                }}
              >
                Menu
              </MDBBox>
              {(token && (
                <>
                  <MDBBox
                    className={`menu-toggle ${
                      (menuToggle === 1 && "active") || `not-active`
                    }`}
                    onClick={(e) => {
                      setMenuToggle(1);
                    }}
                  >
                    My Account
                  </MDBBox>
                  <MDBBox
                    className={`menu-toggle ${
                      (menuToggle === 2 && "active") || `not-active`
                    }`}
                    onClick={(e) => {
                      logoutCustomer();
                      // setMenuToggle(menuToggle);
                      toggleHamburger(props);
                      closeBurger();
                      setMenuToggle(0);
                    }}
                  >
                    Sign Out
                  </MDBBox>
                </>
              )) || (
                <ModalConsumer value={{ useWrapper: false }}>
                  {({ showModal, hideModal }) => (
                    <MDBBox
                      className="menu-toggle"
                      onClick={() => {
                        showModal(() => {
                          return (
                            <Login
                              hideModal={hideModal}
                              closeBurger={closeBurger}
                            />
                          );
                        });
                      }}
                    >
                      <MDBBox tag="p">SIGN IN</MDBBox>
                    </MDBBox>
                  )}
                </ModalConsumer>
              )}
            </MDBBox>
            <Search
              mobileonclick={(e) => {
                toggleHamburger(props);
                closeBurger();
              }}
              open={props.showSearch}
              toggleSearch={toggleSearch}
            />
            <TransitionGroup component={null}>
              {navigation.map((value, index) => (
                <CSSTransition
                  key={menuToggle}
                  // in={menuToggle}
                  classNames={`toggle`}
                  appear={600}
                  timeout={600}
                >
                  {navigation[menuToggle]}
                </CSSTransition>
              ))}
            </TransitionGroup>
            <div className="text-center text-md-left dropdown-bottom-links">
              <MDBBox
                display={`flex`}
                flex={`row`}
                alignItems={`end`}
                justifyContent={`center`}
                className="dropdown-bottom-links-content"
              >
                <MDBBox className="bottom-links-part">
                  <MDBLink
                    to="/about-us"
                    link
                    className={`text-decoration-none d-flex flex-column align-items-center`}
                    onClick={(e) => {
                      toggleHamburger(props);
                      closeBurger();
                    }}
                  >
                    <Icon icon={"aboutDucDucIcon"} />
                    <MDBBox tag={`span`}>About</MDBBox>
                  </MDBLink>
                </MDBBox>
                <MDBBox className="bottom-links-part">
                  <MDBLink
                    to="/find-a-retailer"
                    link
                    className={`text-decoration-none d-flex flex-column align-items-center`}
                    onClick={(e) => {
                      toggleHamburger(props);
                      closeBurger();
                    }}
                  >
                    <Icon icon={"inPersonIcon"} />
                    <MDBBox tag={`span`}>Find</MDBBox>
                  </MDBLink>
                </MDBBox>
                <MDBBox className="bottom-links-part">
                  <MDBLink
                    to="#"
                    link
                    className={`text-decoration-none d-flex flex-column align-items-center justify-content-end`}
                    onClick={(e) => {
                      showChat();
                      toggleHamburger(props);
                      closeBurger();
                    }}
                  >
                    <Icon icon={"liveChatIcon"} />
                    <MDBBox tag={`span`}>Chat</MDBBox>
                  </MDBLink>
                </MDBBox>
              </MDBBox>
            </div>
            <div className="text-center text-md-left dropdown-menu-footer-links">
              <MDBBox
                display={`flex`}
                flex={`row`}
                alignItems={`end`}
                justifyContent={`center`}
                className="dropdown-menu-footer-links-content"
              >
                <div className="col">
                  {/* <MDBBox className="menu-footer-links-part">
                    <MDBLink
                      to="/shipping"
                      link
                      className={`text-decoration-none d-flex flex-column align-items-center`}
                      onClick={e => {
                        toggleHamburger(props);
                        closeBurger();
                      }}
                    >
                      <MDBBox tag={`span`}>Shipping</MDBBox>
                    </MDBLink>
                  </MDBBox> */}
                  <MDBBox className="menu-footer-links-part">
                    <MDBLink
                      to="/terms-and-conditions"
                      link
                      className={`text-decoration-none d-flex flex-column align-items-center`}
                      onClick={(e) => {
                        toggleHamburger(props);
                        closeBurger();
                      }}
                    >
                      <MDBBox tag={`span`}>Terms & Conditions</MDBBox>
                    </MDBLink>
                  </MDBBox>
                </div>
                <div className="col">
                  {/* <MDBBox className="menu-footer-links-part">
                    <MDBLink
                      to="/return-policy"
                      link
                      className={`text-decoration-none d-flex flex-column align-items-center justify-content-end`}
                      onClick={e => {
                        toggleHamburger(props);
                        closeBurger();
                      }}
                    >
                      <MDBBox tag={`span`}>Return Policy</MDBBox>
                    </MDBLink>
                  </MDBBox> */}
                  <MDBBox className="menu-footer-links-part">
                    <MDBLink
                      to="/privacy-policy"
                      link
                      className={`text-decoration-none d-flex flex-column align-items-center justify-content-end`}
                      onClick={(e) => {
                        toggleHamburger(props);
                        closeBurger();
                      }}
                    >
                      <MDBBox tag={`span`}>Privacy Policy</MDBBox>
                    </MDBLink>
                  </MDBBox>
                </div>
              </MDBBox>
            </div>
          </MDBCollapse>
        </MDBContainer>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default Mobile;
