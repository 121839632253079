import React from "react";
import CustomerDataContainer from "./customer-data-container";
import gql from "graphql-tag";
import DynamicGraphQlWrapper from "core/components/dynamicGraphQlWrapper";

const CUSTOMER_ORDERS = gql`
  query CustomerOrders {
    customerOrders {
      items {
        order_number
        created_at
        grand_total
        status
        shippingPrice
        shippingMethod
        subtotal
        productInformation {
          product_name
          quantity
          row_total
          sku
          unit_price
        }
        addressInformation {
          billingAddress {
            city
            company
            country_code
            firstname
            lastname
          }
          shippingAddress {
            city
            company
            country_code
            firstname
            lastname
          }
        }
      }
    }
  }
`;
const CustomerQueryContainer = props => {
  return (
    <DynamicGraphQlWrapper
      {...props}
      query={CUSTOMER_ORDERS}
      variables={{}}
      saveQuery={true}
    >
      <CustomerDataContainer />
    </DynamicGraphQlWrapper>
  );
};

export default CustomerQueryContainer;
