import React from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import StoragePicker from "./storagePicker";
import { productActions } from "core/state/redux/data/product";

const StoragePickerReduxContainer = props => {
  return <StoragePicker {...props} />;
};

const mapStateToProps = state => ({
  product: state.product
});

const mapDispatchToProps = dispatch => {
  return {
    lock: () => {
      dispatch(customerActions.lock());
    },
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    reduceProductOptions: options => {
      dispatch(productActions._reduceOptions(options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoragePickerReduxContainer);
