import React, { memo } from "react";
import InputField from "core/components/inputField";
import { MDBCol, MDBRow } from "mdbreact";
import Button from "core/components/button";
import _ from "lodash";

const Create = memo(
  ({
    project,
    setProject,
    addMessage,
    wishlist,
    activeWishlist,
    setActiveWishlist,
    createProject,
    deleteProject,
    lock,
    loading,
    setLoading,
    wrapperClass,
    target,
    text,
    loadingtext,
  }) => {
    let wishlistNames = wishlist.multiwishlist_data.map((wishlist) => {
      return wishlist.name;
    });
    wishlistNames.push("favorites");
    return (
      <MDBCol lg={"12"} md={"12"} className={`${wrapperClass}`.trim()}>
        <MDBRow className="create-project-block">
          <MDBCol className="enter-name">
            <InputField
              label={`${
                project.length === 0 ? "enter project name" : "project name"
              }`}
              group
              type="text"
              validate
              error="wrong"
              success="right"
              value={project}
              onChange={(e) => {
                if (e.target.value.length <= 24) setProject(e.target.value);
              }}
            />
          </MDBCol>
          <MDBCol className={`md-form add-button`}>
            <Button
              className={"right-arrow orange-button w-100"}
              direction={"ml-3"}
              color={"primary"}
              righticon={"true"}
              icon={"arrow-right"}
              text={
                !loading
                  ? typeof text === "undefined"
                    ? "create a new project"
                    : text
                  : typeof loadingtext === "undefined"
                  ? "creating..."
                  : loadingtext
              }
              disabled={loading}
              onClick={(e) => {
                if (project.trim().length > 0) {
                  if (wishlistNames.includes(project.toLowerCase())) {
                    addMessage(
                      "you already have a project with this name",
                      "danger",
                      typeof target === "undefined" ? "global" : target
                    );
                  } else {
                    setLoading(true);
                    lock({
                      always: (e) => {
                        // setLoading(true);
                      },
                      fail: (e) => {
                        setLoading(false);
                      },
                      success: (e) => {
                        setLoading(false);
                        setProject("");
                      },
                    });
                    createProject(project);
                  }
                } else {
                  addMessage("please enter name for new project", "danger");
                }
              }}
            />
          </MDBCol>
          <MDBCol></MDBCol>
        </MDBRow>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Create;
