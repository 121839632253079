import React, { useState } from "react";
import CartItem from "./cartItem";

const CartItemStateContainer = props => {
  let [detailsExpanded, setDetailsExpanded] = useState(false);
  let [additionalOptionsExpanded, setAdditionalOptionsExpanded] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(props.item.quantity);
  let stateProps = {
    detailsExpanded,
    setDetailsExpanded,
    additionalOptionsExpanded,
    setAdditionalOptionsExpanded
  };

  return (
    <CartItem
      {...props}
      {...stateProps}
      loading={loading}
      setLoading={setLoading}
      quantity={quantity}
      setQuantity={setQuantity}
    />
  );
};

export default CartItemStateContainer;
