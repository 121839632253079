import React from "react";
import { gql } from "graphql.macro";
import DynamicGraphQlWrapper from "core/components/dynamicGraphQlWrapper";
import Usps from "./usps";

const CMSBLOCK_CONTROLS = gql`
  query cmsBlock($id: [String]) {
    cmsBlocks(identifiers: $id) {
      items {
        content
        identifier
        title
      }
    }
  }
`;
const UspsQueryContainer = props => {
  return (
    <>
      <DynamicGraphQlWrapper
        query={CMSBLOCK_CONTROLS}
        variables={{ id: [`ship-method`, `lead-time`] }}
      >
        <Usps {...props} />
      </DynamicGraphQlWrapper>
    </>
  );
};

export default UspsQueryContainer;
