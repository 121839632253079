import React, { useState } from "react";
import { MDBCol, MDBRow, MDBContainer } from "mdbreact";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import CheckButton from "core/components/checkButton";
import { Alert } from "reactstrap";
import { validate, shouldMarkError } from "./validation";
import { isUndefined } from "core/helpers/functions";

const Information = (props) => {
  let {
    // Getters
    changeEmail,
    changePassword,
    firstname,
    lastname,
    email,
    password,
    newpassword,
    newpasswordconf,
    loading,
    //Setters
    setChangeEmail,
    setChangePassword,
    setFirstname,
    setLastname,
    setEmail,
    setPassword,
    setNewpassword,
    setNewpasswordconf,
    setLoading,
  } = props;
  let { customer } = props;

  const [touched, setTouched] = useState({});
  let errors = validate(
    firstname,
    lastname,
    email,
    password,
    newpassword,
    newpasswordconf
  );
  const validateInput = () => {
    let isTrue = true;
    setTouched({
      firstname: true,
      lastname: true,
      email: true,
      password: true,
      new_password: true,
      confirm_password: true,
    });

    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    // if (isTrue) {
    //   props.addMessage("to je to ", "success");
    // }
    return isTrue;
  };
  let [alert, setAlert] = useState("");

  const setFirstNameFn = (e) => {
    setFirstname(e.target.value);
  };
  const setLastNameFn = (e) => {
    setLastname(e.target.value);
  };
  const setChangeEmailFn = (e) => {
    setChangeEmail(e.target.value);
  };

  const setPasswordFn = (e) => {
    setPassword(e.target.value);
  };
  const setNewPasswordFn = (e) => {
    setNewpassword(e.target.value);
  };
  const setConfirmPasswordFn = (e) => {
    setNewpasswordconf(e.target.value);
  };

  return (
    <MDBContainer className="account-information-container mb-5">
      <MDBRow className="account-information border-bottom">
        <MDBCol lg="12" className="mb-5">
          <h3 className="large">edit account information</h3>
        </MDBCol>
        <MDBCol lg="6" md="12">
          <h4 className="gray heavy large mb-5">account information</h4>

          <InputField
            className={`custom-input ${
              shouldMarkError("firstname", errors, touched)
                ? "validation-error"
                : ""
            }`}
            label={`${
              shouldMarkError("firstname", errors, touched)
                ? "first name is required*"
                : "first name*"
            }`}
            onBlur={(e) => {
              setTouched({ ...touched, firstname: true });
            }}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={firstname !== undefined ? firstname : ""}
            onChange={setFirstNameFn}
          />
          <InputField
            className={`custom-input ${
              shouldMarkError("lastname", errors, touched)
                ? "validation-error"
                : ""
            }`}
            label={`${
              shouldMarkError("lastname", errors, touched)
                ? "last name is required*"
                : "last name*"
            }`}
            onBlur={(e) => {
              setTouched({ ...touched, lastname: true });
            }}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={lastname !== undefined ? lastname : ""}
            onChange={setLastNameFn}
          />

          <CheckButton
            containerClass={"switch-container check-button mt-5"}
            wrapperClass={"slider"}
            checked={changeEmail}
            onChange={() => {
              setChangeEmail(!changeEmail);
            }}
            label={"change email"}
            type={"checkbox"}
          />
          <CheckButton
            containerClass={"switch-container check-button mt-5"}
            wrapperClass={"slider"}
            checked={changePassword}
            onChange={() => {
              setChangePassword(!changePassword);
            }}
            label={"change password"}
            type={"checkbox"}
          />
        </MDBCol>
        <MDBCol lg="6" md="12">
          {(changeEmail || changePassword) && (
            <h4 className="gray heavy large mb-5">{props.secondTitle}</h4>
          )}
          {changeEmail && (
            <>
              <InputField
                className={`custom-input ${
                  shouldMarkError("changeEmail", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("changeEmail", errors, touched)
                    ? "change email"
                    : "email address*"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, email: true });
                }}
                group
                type="email"
                validate
                error="wrong"
                success="right"
                value={email !== undefined ? email : ""}
                onChange={(e) => setEmail(e.target.value)}
              />
            </>
          )}
          {changePassword && (
            <InputField
              className={`custom-input ${
                shouldMarkError("password", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("password", errors, touched)
                  ? "password is required*"
                  : "password*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, password: true });
              }}
              group
              type="password"
              validate
              error="wrong"
              success="right"
              value={password !== undefined ? password : ""}
              onChange={setPasswordFn}
            />
          )}
          {changePassword && (
            <>
              <InputField
                className={`custom-input ${
                  shouldMarkError("new_password", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("new_password", errors, touched)
                    ? "newpassword is required*"
                    : "newpassword*"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, new_password: true });
                }}
                group
                type="password"
                validate
                error="wrong"
                success="right"
                value={newpassword !== undefined ? newpassword : ""}
                onChange={setNewPasswordFn}
              />
              <InputField
                className={`custom-input ${
                  shouldMarkError("confirm_password", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("confirm_password", errors, touched)
                    ? "confirm password"
                    : "confirm password matched"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, confirm_password: true });
                }}
                group
                type="password"
                validate
                error="wrong"
                success="right"
                value={newpasswordconf !== undefined ? newpasswordconf : ""}
                onChange={setConfirmPasswordFn}
              />
            </>
          )}
        </MDBCol>
      </MDBRow>
      {/* <MDBRow>
        <MDBCol lg="3" className="offset-lg-9" md="12">
          <p>* = required</p>
        </MDBCol>
      </MDBRow> */}
      {alert !== "" && (
        <Alert color="danger" isOpen={true} toggle={() => setAlert("")}>
          {alert}
        </Alert>
      )}

      <MDBRow>
        <MDBCol lg="4" className="offset-lg-8" md="12">
          <Button
            color={"primary"}
            className={"right-arrow orange-button mt-5 w-100"}
            direction={"ml-3"}
            righticon={"true"}
            icon={"arrow-right"}
            disabled={loading}
            text={loading ? "sending" : "submit"}
            onClick={(e) => {
              e.preventDefault();
              let email_req = {};
              let validate = undefined;
              let password_request = {};
              let valid = validateInput();

              if (changeEmail) {
                email_req = {
                  email: props.email,
                  password: password,
                };
              }
              if (changePassword) {
                if (newpasswordconf === newpassword) validate = true;
                else validate = false;
                password_request = {
                  currentPassword: password,
                  newPassword: newpassword,
                };
              }
              if (valid) {
                props.onSubmit({
                  firstname: firstname,
                  lastname: lastname,
                  ...email_req,
                  ...password_request,
                });
              }
            }}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Information;
