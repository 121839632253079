import React from "react";
import { getCountryById } from "core/helpers/functions";

const Address = props => {
  let { address } = props;
  if (address !== undefined) {
    return (
      <>
        <span>
          {address.firstname} {address.lastname}
        </span>
        <br />
        <span>{address.street.join(" ")}</span>
        <br />
        <span>
          {address.city}, {address.region.region} {address.postcode}
        </span>
        <br />
        <span>{getCountryById(address.country_id).full_name_english}</span>
      </>
    );
  } else {
    return <> </>;
  }
};

export default Address;
