import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "app/app";
import swConfig from "./swConfig";

// Activated trigger
let activated = false;
const MAX_LOAD_TIMEOUT = 3000;

if ('serviceWorker' in navigator) {
  // Render App
  if(!activated) {
      ReactDOM.render(<App />, document.getElementById("root"));
      activated = true;
  }
} else {
  // Listen for activated event
  document.addEventListener('sw:activated', (evt) => {
    // Render App
    if(!activated) {
      console.log("Customer Service worker activated - injecting");
      ReactDOM.render(<App />, document.getElementById("root"));
      activated = true;
    }
  });
}

// Manually activate after 2 seconds
setTimeout(function() {
  if(!activated) {
    console.log(`Timeout activation (${MAX_LOAD_TIMEOUT}) - manually injecting`);
    ReactDOM.render(<App />, document.getElementById("root"));
    activated = true;
  }
}, MAX_LOAD_TIMEOUT);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig);
