import React from "react";
import { ReactComponent as AboutDucducGray } from "./aboutDucducGray.svg";
import { ReactComponent as AboutDucducOrange } from "./aboutDucducOrange.svg";
import { ReactComponent as AddToCart } from "./addToCart.svg";
import { ReactComponent as AddQty } from "./addQty.svg";
import { ReactComponent as AssmblyInstructionsGray } from "./assmblyInstructionsGray.svg";
import { ReactComponent as CartOrange } from "./cartOrange.svg";
import { ReactComponent as CartWhite } from "./cartWhite.svg";
import { ReactComponent as CartPlus } from "./cartPlus.svg";
import { ReactComponent as CartPlusGray } from "./cartPlusGray.svg";
import { ReactComponent as ChatOrange } from "./chatOrange.svg";
import { ReactComponent as ContactIcon1 } from "./contactIcon1.svg";
import { ReactComponent as ContactIcon2 } from "./contactIcon2.svg";
import { ReactComponent as ContactIcon3 } from "./contactIcon3.svg";
import { ReactComponent as CloseBtnGrey } from "./closeBtnGrey.svg";
import { ReactComponent as DownloadIcon } from "./downloadIcon.svg";
import { ReactComponent as DucducLogo } from "./ducducLogo.svg";
import { ReactComponent as FindOrange } from "./findOrange.svg";
import { ReactComponent as FindPin } from "./findPin.svg";
import { ReactComponent as FindRetailerGray } from "./findRetailerGray.svg";
import { ReactComponent as FindRetailerOrange } from "./findRetailerOrange.svg";
import { ReactComponent as HeartEmptyGray } from "./heartEmptyGray.svg";
import { ReactComponent as HeartEmptyOrange } from "./heartEmptyOrange.svg";
import { ReactComponent as HeartFull } from "./heartFull.svg";
import { ReactComponent as MadeHere } from "./madeHere.svg";
import { ReactComponent as MadeSafe } from "./madeSafe.svg";
import { ReactComponent as MadeSmart } from "./madeSmart.svg";
import { ReactComponent as MadeWell } from "./madeWell.svg";
import { ReactComponent as MinusQty } from "./minusQty.svg";
import { ReactComponent as MoveFavorite } from "./moveFavorite.svg";
import { ReactComponent as PlusGray } from "./plusGray.svg";
import { ReactComponent as PdfIconGray } from "./pdfIconGray.svg";
import { ReactComponent as PlayIcon } from "./playIcon.svg";
import { ReactComponent as RightArrowGray } from "./rightArrowGray.svg";
import { ReactComponent as RightArrowOrange } from "./rightArrowOrange.svg";
import { ReactComponent as RightArrowCircle } from "./rightArrowCircle.svg";
import { ReactComponent as SearchGray } from "./searchGray.svg";
import { ReactComponent as SearchOrange } from "./searchOrange.svg";
import { ReactComponent as ShopTheLookOrange } from "./shopTheLookOrange.svg";
import { ReactComponent as ShopTheLookGray } from "./shopTheLookGray.svg";
import { ReactComponent as LogoShape } from "./logo-shape.svg";
import { ReactComponent as LeadTimeGray } from "./leadTimeGray.svg";
import { ReactComponent as ducducLetterLogo } from "./ducducLetterLogo.svg";
import { ReactComponent as UspOne } from "./uspOne.svg";
import { ReactComponent as UspTwo } from "./uspTwo.svg";
import { ReactComponent as UspThree } from "./uspThree.svg";
import { ReactComponent as UspFour } from "./uspFour.svg";
import { ReactComponent as Instagram } from "./instagramGrey.svg";
import { ReactComponent as InstagramWhite } from "./instagramWhite.svg";
import { ReactComponent as InstagramOrange } from "./instagramOrange.svg";
import { ReactComponent as SearchRound } from "./searchIconRoundOrange.svg";
import { ReactComponent as ShipMethodGray } from "./shipMethodGray.svg";
import { ReactComponent as TearSheetGray } from "./tearSheetGray.svg";
import { ReactComponent as AboutDucDucIcon } from "./about-ducduc.svg";
import { ReactComponent as InPersonIcon } from "./in-person-icon.svg";
import { ReactComponent as LiveChatIcon } from "./live-chat-icon.svg";
import { ReactComponent as LogoWhite } from "./ducducWhiteLogo.svg";
import { ReactComponent as LeftArrowCircle } from "./leftArrowCircle.svg";
import { ReactComponent as VideoGray } from "./videoGray.svg";
import { ReactComponent as AssemblyPdf } from "./assemblyPdf.svg";
import { ReactComponent as RemoveFilterButtonBlack } from "./close_black.svg";
import { ReactComponent as RemoveFilterButtonGray } from "./close_gray.svg";
import { ReactComponent as DucducLogoWoLetters } from "./ducducLogoWoLetters.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as TrashCanOrange } from "./trash-can-orange.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Zoom } from "./zoom.svg";
import { ReactComponent as matressIcon } from "./matressIcon.svg";
import { ReactComponent as matressIconHover } from "./matressIconHover.svg";

const icons = {
  aboutDucducGray: AboutDucducGray,
  aboutDucducOrange: AboutDucducOrange,
  addToCart: AddToCart,
  addQty: AddQty,
  assmblyInstructionsGray: AssmblyInstructionsGray,
  cartOrange: CartOrange,
  cartWhite: CartWhite,
  cartPlus: CartPlus,
  cartPlusGray: CartPlusGray,
  chatOrange: ChatOrange,
  closeBtnGrey: CloseBtnGrey,
  ducducLogo: DucducLogo,
  downloadIcon: DownloadIcon,
  findOrange: FindOrange,
  findPin: FindPin,
  findRetailerGray: FindRetailerGray,
  findRetailerOrange: FindRetailerOrange,
  heartEmptyGray: HeartEmptyGray,
  heartEmptyOrange: HeartEmptyOrange,
  heartFull: HeartFull,
  madeHere: MadeHere,
  madeSafe: MadeSafe,
  madeSmart: MadeSmart,
  madeWell: MadeWell,
  minusQty: MinusQty,
  moveFavorite: MoveFavorite,
  plusGray: PlusGray,
  playIcon: PlayIcon,
  rightArrowGray: RightArrowGray,
  rightArrowOrange: RightArrowOrange,
  rightArrowCircle: RightArrowCircle,
  removeFilterButtonBlack: RemoveFilterButtonBlack,
  removeFilterButtonGray: RemoveFilterButtonGray,
  searchGray: SearchGray,
  searchOrange: SearchOrange,
  shipMethodGray: ShipMethodGray,
  shopTheLookGray: ShopTheLookGray,
  shopTheLookOrange: ShopTheLookOrange,
  tearSheetGray: TearSheetGray,
  logoShape: LogoShape,
  leadTimeGray: LeadTimeGray,
  ducducLetterLogo: ducducLetterLogo,
  uspOne: UspOne,
  uspTwo: UspTwo,
  uspThree: UspThree,
  uspFour: UspFour,
  instagramGrey: Instagram,
  instagramWhite: InstagramWhite,
  instagramOrange: InstagramOrange,
  searchIconOrangeRound: SearchRound,
  contactIcon1: ContactIcon1,
  contactIcon2: ContactIcon2,
  contactIcon3: ContactIcon3,
  aboutDucDucIcon: AboutDucDucIcon,
  inPersonIcon: InPersonIcon,
  liveChatIcon: LiveChatIcon,
  ducducWhiteLogo: LogoWhite,
  leftArrowCircle: LeftArrowCircle,
  videoGray: VideoGray,
  assemblyPdf: AssemblyPdf,
  ducducLogoWoLetters: DucducLogoWoLetters,
  pdfIconGray: PdfIconGray,
  location: Location,
  trashCanOrange: TrashCanOrange,
  edit: Edit,
  zoom: Zoom,
  matressIcon: matressIcon,
  matressIconHover: matressIconHover,
};
const Icon = (props) => {
  const ReturnIcon = icons[props.icon];

  return icons.hasOwnProperty(props.icon) ? <ReturnIcon {...props} /> : "";
};

export default Icon;
