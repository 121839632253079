import React from "react";
import { connect } from "react-redux";
import ParentCategory from "./parent-category";
import { isUndefined } from "lodash";
import { findCategoryByAttribute } from "core/helpers/category";

const ParentCategoryReduxContainer = props => {
  if (props.allCategories) {
    if (isUndefined(props.allCategories[2])) {
      if (isUndefined(props.allCategoriesTemp[2])) {
        return <></>;
      }
      let allCategories = props.allCategoriesTemp[2].data.categoryList[0];
      let data = findCategoryByAttribute(
        window.location.pathname.substring(1),
        allCategories,
        "url_path"
      );

      return (
        <ParentCategory
          {...props}
          data={data}
          allCategories={props.allCategoriesTemp[2].data.categoryList[0]}
        />
      );
    }
    let allCategories = props.allCategories[2].data.categoryList[0];
    return <ParentCategory {...props} allCategories={allCategories} />;
  } else {
    return <></>;
  }
};

const mapStateToProps = state => ({
  allCategories: state.queries.data,
  allCategoriesTemp: state.queries.temp
});

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentCategoryReduxContainer);
