import React from "react";
import banner from "./../../../assets/images/custom/banner.png";
import bannerMobile from "./../../../assets/images/custom/banner-mobile.png";
import Breadcrumbs from "../../../layout/breadcrumbs";
import HeroImage from "app/layout/heroImage/heroImage";
import CustomTop from "../custom/customTop";
// import CustomGallery from "../custom/customGallery";
import CustomCards from "../custom/customCards";
import CustomShopTheLook from "../custom/customShopTheLook";
import CustomForm from "../custom/customForm";
import SeoHelmet from "app/layout/seoHelmet";

const Custom = (props) => {
  return (
    <div className="custom-page">
      <div className="img item d-lg-block d-none desktop-image">
        <HeroImage id={"custom-img"} alt="Custom Banner" />
      </div>
      <div className="img item d-lg-none d-block mobile-image">
        <HeroImage banner={bannerMobile} alt="Custom Banner" />
      </div>

      <SeoHelmet
        url={"/custom"}
        title={"custom"}
        description={
          "not every company is as lucky as we are! having our own factory in Connecticut means having control over the production of every part of every piece of furniture that lands in your kid’s room."
        }
        image={banner}
      />
      <div className="custom-page-content">
        <CustomTop />
        {/* <CustomGallery /> */}
        <CustomCards />
        <CustomShopTheLook />
        <CustomForm />
      </div>
    </div>
  );
};

export default Custom;
