import React from "react";
import { connect } from "react-redux";
import { commonActions } from "core/state/redux/data/common";
import messageActions from "core/state/redux/data/messages/actions";
import ListingDataContainer from "./listing-data-container";
import { customerActions } from "core/state/redux/data/customer";

const ListingReduxContainer = props => {
  return <ListingDataContainer {...props} />;
};

const mapStateToProps = state => ({
  wishlist: state.customer.data.wishlist
});

const mapDispatchToProps = dispatch => {
  return {
    lock: callback => {
      dispatch(commonActions.lock(callback));
    },
    addMessage: (text, type) => {
      dispatch(messageActions.addMessage(text, type));
    },
    deleteItem: wishlist_item_id => {
      dispatch(customerActions.removeItemFromWishlist(wishlist_item_id));
    },
    addItemToCart: (amount, sku, specialOptions) => {
      dispatch(
        customerActions.addProductsToCart([
          {
            data: {
              quantity: amount,
              sku: sku
            },
            customizable_options: specialOptions
          }
        ])
      );
    },
    copyWishlistItem: (wishlist_id, wishlist_item_id) => {
      dispatch(customerActions.copyWishlistItem(wishlist_item_id, wishlist_id));
    },
    addItemToWishlist: (wishlist_id, product_id, options = []) => {
      dispatch(
        customerActions.addProductToWishlist(wishlist_id, product_id, options)
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingReduxContainer);
