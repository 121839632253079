// Action Creators
import types from "./types";

const _reduceOptions = options => {
  return {
    type: types._REDUCE_OPTIONS,
    options: options
  };
};

export default {
  _reduceOptions
};
