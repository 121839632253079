import React, { memo } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBLink } from "mdbreact";
import _ from "lodash";
import Image from "core/components/image/image";
import { determineCurrency } from "config/currency";
import Icon from "app/assets/icon/icon";
import Price from "core/components/price";
import { findCategoryByAttribute } from "core/helpers/category";

const SearchResults = memo(
  ({
    data,
    allCategories,
    closeSearch,
    search,
    loading,
    mobileonclick,
    changeSearch,
  }) => {
    let searchResults = data.products;
    let { items, getSuggested } = searchResults;
    let categories = [];
    let brands = [];

    items.map((item) => {
      let label = "";
      if (item.brands === "5884") label = "studio duc";
      else label = "ducduc";
      const found = brands.some((el) => el.value === item.brands);
      if (!found && item.brands !== null)
        return brands.push({ value: item.brands, label: label });
    });

    items.map((item) => {
      return item.categories.map((category) => {
        return categories.push(
          findCategoryByAttribute(category.id, allCategories.children, "id")
        );
      });
    });
    let searchCategory = findCategoryByAttribute(
      search,
      allCategories.children,
      "name"
    );
    if (!_.isUndefined(searchCategory)) {
      categories.push(searchCategory);
    }
    let uniqueCategories = _.uniqBy(categories, (category) => {
      return typeof category !== "undefined" ? category.id : [];
    });
    if (loading) {
      return (
        <MDBContainer
          fluid
          id={`search-results-container`}
          className={`no-results`}
        >
          <MDBRow className={`d-flex w-100 flex-column`}>
            <MDBCol>
              <Icon icon={`ducducLogoWoLetters`} />
            </MDBCol>
            <MDBCol>Loading...</MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    }

    if (items.length === 0 && categories.length === 0 && !loading)
      return (
        <MDBContainer
          fluid
          id={`search-results-container`}
          className={`no-results`}
        >
          <MDBRow className={`d-flex w-100 flex-column`}>
            <MDBCol>
              <Icon icon={`ducducLogoWoLetters`} />
            </MDBCol>
            <MDBCol>No Results Found</MDBCol>
          </MDBRow>
        </MDBContainer>
      );

    return (
      <MDBContainer fluid id={`search-results-container`}>
        <MDBRow className={`w-100`}>
          {brands.length > 0 && (
            <MDBCol lg={"1"} className="suggestions-container">
              <MDBRow>
                <MDBCol>
                  <span>brands</span>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                {brands.length > 0 ? (
                  brands.map((brand) => {
                    return (
                      <MDBCol>
                        <MDBLink
                          to={
                            `/search-results?search=` +
                            encodeURI(search) +
                            "&brands=" +
                            brand.value
                          }
                          onClick={(e) => {
                            closeSearch();
                            if (document.documentElement.clientWidth < 999) {
                              mobileonclick();
                            }
                          }}
                          className={`view-brands`}
                        >
                          {brand.label}
                        </MDBLink>
                      </MDBCol>
                    );
                  })
                ) : (
                  <></>
                )}
              </MDBRow>
            </MDBCol>
          )}
          <MDBCol lg={"2"} className={`offset-lg-1`}>
            <MDBRow>
              <MDBCol>
                <span>Category</span>
              </MDBCol>
            </MDBRow>
            <MDBRow id={`search-results-categories`} className={`d-flex`}>
              {uniqueCategories.map((category, key) => {
                if (typeof category === "undefined") return <></>;
                if (key > 5) return <></>;
                return (
                  <MDBCol
                    className={`search-results-columns`}
                    key={`search-results-category-${category.id}-${category.name}`}
                  >
                    <MDBLink
                      to={`/` + category.url_path}
                      onClick={(e) => {
                        closeSearch();
                        if (document.documentElement.clientWidth < 999) {
                          mobileonclick();
                        }
                      }}
                    >
                      {category.name}
                    </MDBLink>
                  </MDBCol>
                );
              })}
              {uniqueCategories.length > 6 && (
                <MDBCol className={`text-left`}>
                  <MDBLink
                    to={`/search-results?search=` + encodeURI(search)}
                    onClick={(e) => {
                      closeSearch();
                      if (document.documentElement.clientWidth < 999) {
                        mobileonclick();
                      }
                    }}
                    className={`view-all`}
                  >
                    View All
                  </MDBLink>
                </MDBCol>
              )}
            </MDBRow>
          </MDBCol>
          <MDBCol lg={"8"}>
            <MDBRow>
              <MDBCol>
                <span>Products</span>
              </MDBCol>
              {items.length > 0 ? (
                <MDBCol className={`text-right`}>
                  <MDBLink
                    to={`/search-results?search=` + encodeURI(search)}
                    onClick={(e) => {
                      closeSearch();
                      if (document.documentElement.clientWidth < 999) {
                        mobileonclick();
                      }
                    }}
                    className={`view-all`}
                  >
                    View All
                  </MDBLink>
                </MDBCol>
              ) : (
                <></>
              )}
            </MDBRow>
            <MDBRow className="px-4">
              {items.length > 0 ? (
                items.map((item, index) => {
                  if (index > 5) return <></>;
                  return (
                    <MDBCol
                      key={`search-results-header-${index}-${item.name}`}
                      className={`d-flex search-item p-0`}
                      lg={"4"}
                    >
                      <MDBCol className={`p-0`} lg={"5"} md={"4"} xs={"4"}>
                        <MDBLink
                          to={`/` + item.url_key}
                          onClick={(e) => {
                            closeSearch();
                            if (document.documentElement.clientWidth < 999) {
                              mobileonclick();
                            }
                          }}
                        >
                          <Image source={item.image.url} />
                        </MDBLink>
                      </MDBCol>
                      <MDBCol
                        className={`d-flex flex-column p-0 pl-4`}
                        lg={"6"}
                        md={"8"}
                        xs={"8"}
                      >
                        <MDBCol
                          className={`search-item-name p-0 d-flex align-items-end`}
                        >
                          <MDBLink
                            to={`/` + item.url_key}
                            onClick={(e) => {
                              closeSearch();
                              if (document.documentElement.clientWidth < 999) {
                                mobileonclick();
                              }
                            }}
                          >
                            <span>{item.name}</span>
                          </MDBLink>
                        </MDBCol>
                        <MDBCol className={`search-item-price d-flex p-0`}>
                          {_.isNull(item.options) ? (
                            ""
                          ) : (
                            <MDBCol className={`p-0`}>
                              <span className={`search-item-starting-label`}>
                                starting at
                              </span>
                            </MDBCol>
                          )}
                          <MDBCol className={`p-0`}>
                            <span className={`search-item-starting-price`}>
                              <Price
                                price={
                                  item.price_range.minimum_price.final_price
                                    .value
                                }
                                type={"fixed"}
                              />
                            </span>
                          </MDBCol>
                        </MDBCol>
                      </MDBCol>
                    </MDBCol>
                  );
                })
              ) : (
                <></>
              )}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default SearchResults;
