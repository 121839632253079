import { validate } from "./validation";

export const triggerUpdate = (
  billingAddress,
  shippingAddress,
  useSameAddress,
  setBillingAddress,
  setShippingAddress,
  setLoading,
  lock,
  setShouldChange,
  setBillingAddressOnCart,
  setShippingAddressOnCart,
  resetTouchedAndErrors,
  setShowPayment
) => {
  let isTrue = true;
  setBillingAddress({
    ...billingAddress,
    touched: {
      firstname: true,
      lastname: true,
      telephone: true,
      address1: true,
      city: true,
      selectstate: true,
      zipcode: true,
      phone: true,
    },
  });
  setShippingAddress({
    ...shippingAddress,
    touched: {
      firstname: true,
      lastname: true,
      telephone: true,
      address1: true,
      city: true,
      selectstate: true,
      zipcode: true,
      phone: true,
    },
  });
  let errors = validate(
    billingAddress.firstname,
    billingAddress.lastname,
    billingAddress.countrySelect,
    billingAddress.address1,
    billingAddress.city,
    billingAddress.selectState,
    billingAddress.zipcode,
    billingAddress.phone
  );
  let shippingErrors = validate(
    shippingAddress.firstname,
    shippingAddress.lastname,
    shippingAddress.countrySelect,
    shippingAddress.address1,
    shippingAddress.city,
    shippingAddress.selectState,
    shippingAddress.zipcode,
    shippingAddress.phone
  );
  if (
    billingAddress.customer_address_id === null ||
    billingAddress.customer_address_id === "new-address"
  ) {
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
  }

  if (
    (!useSameAddress && shippingAddress.customer_address_id === null) ||
    shippingAddress.customer_address_id === "new-address"
  ) {
    Object.keys(shippingErrors).map((item) => {
      let error = shippingErrors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
  }
  if (isTrue) {
    setLoading(true);
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setShouldChange(true);
        setShowPayment(true);
      },
      fail: () => {
        setLoading(false);
      },
    });
    if (useSameAddress) {
      if (
        billingAddress.customer_address_id === null ||
        billingAddress.customer_address_id === "new-address"
      ) {
        setBillingAddressOnCart({
          address: {
            city: billingAddress.city,
            company: "",
            country_code: billingAddress.countrySelect.value,
            firstname: billingAddress.firstname,
            lastname: billingAddress.lastname,
            postcode: billingAddress.zipcode,
            region:
              typeof billingAddress.selectState === "object"
                ? billingAddress.selectState.code.toString()
                : billingAddress.selectState.toString(),
            save_in_address_book: false,
            street: [billingAddress.address1, billingAddress.address2],
            telephone: billingAddress.phone,
          },
          same_as_shipping: true,
        });
        resetTouchedAndErrors(true, true);
      } else {
        setBillingAddressOnCart({
          customer_address_id: billingAddress.customer_address_id,
          same_as_shipping: true,
        });
        resetTouchedAndErrors(true, true);
      }
    } else {
      if (
        billingAddress.customer_address_id === null ||
        billingAddress.customer_address_id === "new-address"
      ) {
        setBillingAddressOnCart({
          address: {
            city: billingAddress.city,
            company: "",
            country_code: billingAddress.countrySelect.value,
            firstname: billingAddress.firstname,
            lastname: billingAddress.lastname,
            postcode: billingAddress.zipcode,
            region:
              typeof billingAddress.selectState === "object"
                ? billingAddress.selectState.code.toString()
                : billingAddress.selectState.toString(),
            save_in_address_book: false,
            street: [billingAddress.address1, billingAddress.address2],
            telephone: billingAddress.phone,
          },
          same_as_shipping: false,
        });
        resetTouchedAndErrors(true);
      } else {
        setBillingAddressOnCart({
          customer_address_id: billingAddress.customer_address_id,
          same_as_shipping: false,
        });
        resetTouchedAndErrors(true);
      }

      if (
        shippingAddress.customer_address_id === null ||
        shippingAddress.customer_address_id === "new-address"
      ) {
        setShippingAddressOnCart({
          address: {
            city: shippingAddress.city,
            company: "",
            country_code: shippingAddress.countrySelect.value,
            firstname: shippingAddress.firstname,
            lastname: shippingAddress.lastname,
            postcode: shippingAddress.zipcode,
            region:
              typeof shippingAddress.selectState === "object"
                ? shippingAddress.selectState.code.toString()
                : shippingAddress.selectState.toString(),
            save_in_address_book: false,
            street: [shippingAddress.address1, shippingAddress.address2],
            telephone: shippingAddress.phone,
          },
        });
        resetTouchedAndErrors(false, true);
      } else {
        setShippingAddressOnCart({
          customer_address_id: shippingAddress.customer_address_id,
        });
        resetTouchedAndErrors(false, true);
      }
    }
  }
};
