import React, { useState, useEffect } from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import Images from "./images";
import Lifestyle from "./lifestyle";
import Controls from "./controls";

const Info = props => {
  const [screenwidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  let lifestyle_images = props.data.media_gallery_entries;
  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);

  return (
    <MDBContainer className="product-information px-0">
      {screenwidth > 992 ? (
        <MDBRow>
          <MDBCol lg="6" md="6">
            <Images {...props} />
            {typeof lifestyle_images !== "undefined" && (
              <Lifestyle {...props} />
            )}
          </MDBCol>
          <MDBCol lg="6" md="6" className="pl-5">
            <Controls {...props} />
          </MDBCol>
        </MDBRow>
      ) : (
        <>
          <MDBCol size="12">
            <Images {...props} />
          </MDBCol>
          <MDBCol size="12">
            <Controls {...props} />
          </MDBCol>
          <MDBCol size="12">
            <Lifestyle {...props} />
          </MDBCol>
        </>
      )}
    </MDBContainer>
  );
};

export default Info;
