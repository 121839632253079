import React from "react";
import banner from "app/assets/images/assembly/assembly.png";
import bannerMobile from "app/assets/images/assembly/assembly-mobile.png";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs/breadcrumbs";
import HeroImage from "app/layout/heroImage/heroImage";
import AssemblyInstructionsQueryContainer from "./assemblyInstructions-query-container";
import SeoHelmet from "app/layout/seoHelmet";

const AssemblyInstructions = (props) => {
  return (
    <MDBContainer fluid className="assembly-page">
      <MDBRow>
        <MDBCol md="12" className={"p-0"}>
          <div className="img item d-lg-block d-none desktop-image">
            <HeroImage id={"assembly-img"} alt="Assembly Instructions banner" />
          </div>
          <div className="img item d-lg-none d-block mobile-image">
            <HeroImage
              banner={bannerMobile}
              alt="Assembly Instructions banner"
            />
          </div>
          <SeoHelmet
            url={"/assembly-instructions"}
            title={"assembly instructions"}
            description={"assembly instructions for our line of products"}
            image={banner}
          />
        </MDBCol>
        <MDBCol md="12">
          <Breadcrumbs />
        </MDBCol>
      </MDBRow>
      <AssemblyInstructionsQueryContainer />
    </MDBContainer>
  );
};

export default AssemblyInstructions;
