import React, { useState } from "react";
import Share from "./share";

const ShareStateContainer = (props) => {
  const [shareEmails, setShareEmails] = useState("");
  const [messages, setMessages] = useState("");
  const [multwishlist, setMultiwishlist] = useState("");

  return (
    <>
      <Share
        {...props}
        shareEmails={shareEmails}
        setShareEmails={setShareEmails}
        messages={messages}
        setMessages={setMessages}
      />
    </>
  );
};

export default ShareStateContainer;
