import React from "react";
import Breadcrumbs from "app/layout/breadcrumbs";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import WishlistShareComponentContainer from "./wishlistShare-component-container";

const WishlistShare = props => {
  return (
    <div>
      <MDBContainer className="plp-main-container">
        <MDBRow>
          <MDBCol lg="9" className="offset-lg-3">
            <Breadcrumbs />
          </MDBCol>
        </MDBRow>
        <MDBRow className="main-plp-container">
          <WishlistShareComponentContainer {...props} />
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default WishlistShare;
