import React, { useState, memo } from "react";
import InputField from "core/components/inputField";
import Button from "core/components/button";
import { MDBBox, MDBCol, MDBRow } from "mdbreact";
import Select2 from "core/components/select";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { findMaskByCountry } from "app/pages/customer/register/b2b/b2bStepThree/functions";
import { validate, shouldMarkError } from "./validation";
import MaskedInput from "react-text-mask";
import _ from "lodash";

import { isEmpty } from "core/helpers/functions";
let countriesData = [];

const CheckoutBillingAddress = memo(
  ({
    defaultShippingAddress,
    customerAddresses,
    setShippingAddressOnCart,
    regionsDataObject,
    countries,
    shippingAddress: stateShippingAddress,
    setShippingAddress: setStateShippingAddress,
    setShouldChange,
  }) => {
    let countryDataObject = getCountries(countries);
    countriesData = Object.keys(countryDataObject).map((key) => {
      return countryDataObject[key];
    });

    const setCountrySelectHandler = (event, type) => {
      let value = event;
      let mask = [];
      mask = findMaskByCountry(value.value);
      setMask(mask);
      handleStateChange(value, "countrySelect");

      updateRegion(value, type);
      let maskSanitized = mask.mask.split("");
      setMaskSanitizedArray(generateSanitizedMaskArray(maskSanitized));

      if (value.value === "US") {
        setZipMask([/\d/, /\d/, /\d/, /\d/, /\d/]);
      } else if (value.value === "CA") {
        setZipMask([/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]);
      } else {
        setZipMask([]);
      }
    };

    const generateSanitizedMaskArray = (object) => {
      return Object.keys(object).map((key) => {
        let regexChar = object[key];
        if (object[key] === "+") regexChar = "+";
        if (object[key] === "#") regexChar = /\d/;
        return regexChar;
      });
    };

    const [shippingAddress, setShippingAddress] = useState(
      defaultShippingAddress
    );

    let {
      firstname,
      lastname,
      phone,
      address1,
      address2,
      city,
      zipcode,
      countrySelect,
      selectState,
      touched,
    } = stateShippingAddress;

    const [regionsData, setRegionsData] = useState(regionsDataObject);
    const [mask, setMask] = useState(findMaskByCountry("US"));
    const [maskSanitizedArray, setMaskSanitizedArray] = useState(
      generateSanitizedMaskArray(findMaskByCountry("US").mask.split(""))
    );
    const [zipMask, setZipMask] = useState([/\d/, /\d/, /\d/, /\d/, /\d/]);
    const [loading, setLoading] = useState(false);

    const updateRegion = (value, type) => {
      let data = getRegionData(countriesData, value);
      setRegionsData(data);
    };
    const handleStateChange = (value, field) => {
      setStateShippingAddress({
        ...stateShippingAddress,
        [field]: value,
        touched: {
          ...stateShippingAddress.touched,
          [field]: true,
        },
      });
      setShouldChange(false);
    };

    let errors = validate(
      firstname,
      lastname,
      countrySelect,
      address1,
      city,
      selectState,
      zipcode,
      phone
    );

    const upperCasePipe = (conformedValue) => {
      return conformedValue.toUpperCase();
    };

    if (customerAddresses.length > 1) {
      if (stateShippingAddress.customer_address_id === null) {
        handleStateChange(shippingAddress.value, "customer_address_id");
      }
    }

    return (
      <MDBBox className={"checkout-column one"}>
        <h3>shipping</h3>
        <MDBRow>
          {customerAddresses.length === 1 ||
          customerAddresses.length === 0 ||
          shippingAddress.value === "new-address" ? (
            <MDBCol lg="12" className={`customer-info`}>
              <div className="grey-text">
                <div
                  className={`md-form form-group default-input-field-container space-divider`}
                >
                  {customerAddresses.length > 1 ? (
                    <Select2
                      name={"country"}
                      type={"text"}
                      icon="address"
                      value={shippingAddress}
                      onChange={(event) => {
                        if (event.value !== "new-address") {
                          handleStateChange(event.value, "customer_address_id");
                        } else {
                          handleStateChange(null, "customer_address_id");
                        }
                        setShippingAddress(event);
                      }}
                      required={true}
                      wrapperClass="small"
                      placeholder="shipping address"
                      options={customerAddresses}
                      useCustom={true}
                      isSearchable={true}
                      wrapperClass={`space-divider`}
                      // menuIsOpen={true}
                    />
                  ) : (
                    <></>
                  )}
                  <InputField
                    label={`${
                      shouldMarkError("firstname", errors, touched)
                        ? "first name is required*"
                        : "first name*"
                    }`}
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    value={firstname !== undefined ? firstname : ""}
                    onChange={(e) => {
                      handleStateChange(e.target.value, "firstname");
                    }}
                    className={
                      shouldMarkError("firstname", errors, touched)
                        ? "validation-error"
                        : ""
                    }
                  />
                  <InputField
                    label={`${
                      shouldMarkError("lastname", errors, touched)
                        ? "last name is required*"
                        : "last name*"
                    }`}
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    value={lastname !== undefined ? lastname : ""}
                    onChange={(e) => {
                      handleStateChange(e.target.value, "lastname");
                    }}
                    className={
                      shouldMarkError("lastname", errors, touched)
                        ? "validation-error"
                        : ""
                    }
                  />
                  <MaskedInput
                    id="mask-input"
                    type="text"
                    error="wrong"
                    success="right"
                    value={phone}
                    onChange={(e) => {
                      handleStateChange(e.target.value, "phone");
                    }}
                    mask={maskSanitizedArray}
                    guide={false}
                    placeholder={`${
                      shouldMarkError("phone", errors, touched)
                        ? "phone is required*"
                        : "phone*"
                    }`}
                    className={`form-control validate default-input-field ${
                      shouldMarkError("phone", errors, touched)
                        ? "validation-error"
                        : ""
                    }`}
                    render={(ref, inputProps) => {
                      return <input {...inputProps} ref={ref} />;
                    }}
                  />
                </div>
                <div className={`state-selection-container`}>
                  <Select2
                    name={"country"}
                    type={"text"}
                    icon="address"
                    value={countrySelect}
                    onChange={(event) =>
                      setCountrySelectHandler(event, "billing", false)
                    }
                    required={true}
                    wrapperClass="small"
                    placeholder="country"
                    options={countriesData}
                    useCustom={true}
                    isSearchable={true}
                    // menuIsOpen={true}
                  />
                </div>
                <InputField
                  label={
                    shouldMarkError("address1", errors, touched)
                      ? "address 1 is required*"
                      : "address 1*"
                  }
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  value={address1 !== undefined ? address1 : ""}
                  onChange={(e) => {
                    handleStateChange(e.target.value, "address1");
                  }}
                  className={
                    shouldMarkError("address1", errors, touched)
                      ? "validation-error"
                      : ""
                  }
                />
                <InputField
                  label="address 2"
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  value={address2 !== undefined ? address2 : ""}
                  onChange={(e) => {
                    handleStateChange(e.target.value, "address2");
                  }}
                />
                <InputField
                  label={
                    shouldMarkError("city", errors, touched)
                      ? "city is required*"
                      : "city*"
                  }
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  value={city !== undefined ? city : ""}
                  onChange={(e) => {
                    handleStateChange(e.target.value, "city");
                  }}
                  className={
                    shouldMarkError("city", errors, touched)
                      ? "validation-error"
                      : ""
                  }
                />
                <div className={`state-selection-container`}>
                  {(!isEmpty(regionsData) && (
                    <Select2
                      name={"state"}
                      type={"text"}
                      icon="address"
                      required={true}
                      value={selectState}
                      onChange={(e) => {
                        handleStateChange(e, "selectState");
                      }}
                      wrapperClass="small"
                      placeholder={
                        countrySelect.value === "US"
                          ? "select state*"
                          : "select province*"
                      }
                      options={regionsData}
                      isSearchable={true}
                      className={
                        shouldMarkError("state", errors, touched)
                          ? "validation-error"
                          : ""
                      }
                    />
                  )) || (
                    <InputField
                      name={"region_text"}
                      group
                      type={"text"}
                      wrapperClass={"small"}
                      required={true}
                      value={selectState}
                      onChange={(e) => {
                        handleStateChange(e.target.value, "selectState");
                      }}
                      icon="address"
                      label="province"
                    />
                  )}
                </div>
                {zipMask.length === 0 ? (
                  <InputField
                    label={
                      shouldMarkError("zipcode", errors, touched)
                        ? countrySelect.value === "US"
                          ? "zip code is required*"
                          : countrySelect.value === "CA"
                          ? "postal code is required*"
                          : "postal code"
                        : countrySelect.value === "US"
                        ? "zip code*"
                        : countrySelect.value === "CA"
                        ? "postal code*"
                        : "postal code"
                    }
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    value={zipcode !== undefined ? zipcode : ""}
                    onChange={(e) => {
                      handleStateChange(e.target.value, "zipcode");
                    }}
                    className={
                      shouldMarkError("zipcode", errors, touched)
                        ? countrySelect.value === "US" ||
                          countrySelect.value === "CA"
                          ? "validation-error"
                          : ""
                        : ""
                    }
                  />
                ) : (
                  <div
                    className={`md-form form-group default-input-field-container`}
                  >
                    <MaskedInput
                      id="mask-input"
                      type="text"
                      error="wrong"
                      success="right"
                      value={zipcode}
                      onChange={(e) => {
                        handleStateChange(e.target.value, "zipcode");
                      }}
                      className={`
                  form-control validate default-input-field
                    ${
                      shouldMarkError("zipcode", errors, touched)
                        ? countrySelect.value === "US" ||
                          countrySelect.value === "CA"
                          ? "validation-error"
                          : ""
                        : ""
                    }`}
                      placeholder={
                        shouldMarkError("zipcode", errors, touched)
                          ? countrySelect.value === "US"
                            ? "zip code is required*"
                            : countrySelect.value === "CA"
                            ? "postal code is required*"
                            : "postal code"
                          : countrySelect.value === "US"
                          ? "zip code*"
                          : countrySelect.value === "CA"
                          ? "postal code*"
                          : "postal code"
                      }
                      mask={zipMask}
                      guide={false}
                      pipe={upperCasePipe}
                      render={(ref, inputProps) => {
                        return (
                          <input {...inputProps} ref={ref} label="phone" />
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </MDBCol>
          ) : (
            <MDBCol className={`customer-info`}>
              <Select2
                name={"country"}
                type={"text"}
                icon="address"
                value={shippingAddress}
                onChange={(event) => {
                  if (event.value !== "new-address") {
                    handleStateChange(event.value, "customer_address_id");
                  } else {
                    handleStateChange(null, "customer_address_id");
                  }
                  setShippingAddress(event);
                }}
                required={true}
                wrapperClass="small"
                placeholder="shipping address"
                options={customerAddresses}
                useCustom={true}
                isSearchable={true}
                // menuIsOpen={true}
              />
            </MDBCol>
          )}
        </MDBRow>
      </MDBBox>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default CheckoutBillingAddress;
