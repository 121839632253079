import React from "react";
import OrderInformation from "./orderInformation";

const OrderInformationDataContainer = ({ order }) => {
  let { addressInformation, shippingMethod, payment } = order;
  let { billingAddress, shippingAddress } = addressInformation;

  if (
    typeof billingAddress === "undefined" ||
    typeof shippingAddress === "undefined"
  )
    return <></>;

  return (
    <OrderInformation
      billingAddress={billingAddress}
      shippingAddress={shippingAddress}
      shippingMethod={shippingMethod}
      payment={payment}
    />
  );
};

export default OrderInformationDataContainer;
