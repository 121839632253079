import React from "react";
import { MDBRow, MDBCol, MDBAlert } from "mdbreact";
import { Link } from "react-router-dom";
import OrderGrid from "core/components/orderGrid";
import { isUndefined } from "core/helpers/functions";

const Recentorders = props => {
  let hasOrders;
  let orders;
  if (isUndefined(props.orderData)) {
    hasOrders = false;
  } else {
    hasOrders = true;
    orders = props.orderData.slice(0, 3);
  }
  return (
    <MDBRow className="mb-md-5 mb-sm-5 mb-xs-5">
      <MDBCol lg="12">
        <MDBRow>
          <MDBCol lg="12" className="mb-3 mt-5 border-bottom">
            <h3 className="d-inline-block gray medium pb-3 mr-5">
              recent orders
            </h3>
            <Link className="d-inline" to="/customer/orders">
              view all
            </Link>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12">
            {hasOrders ? (
              <>
                <OrderGrid orders={orders} />
              </>
            ) : (
              <>You have placed no orders</>
            )}
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default Recentorders;
