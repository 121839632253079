export const maskForVisaAndMasterCard = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "\u2000",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "\u2000",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "\u2000",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const maskForAmericanExpressCard = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "\u2000",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "\u2000",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
