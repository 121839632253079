import { default as countries } from "config/data/countries";
export function truncateString(str, num) {
  if (str) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num).trim() + "...";
  }
  return "";
}
export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}
export function isNull(variable) {
  if (variable === "null") return true;
  return false;
}

export function isUndefined(variable) {
  if (typeof variable === "undefined") return true;
  return false;
}

export function isObject(element) {
  return typeof element === "object" ? true : false;
}

export function isFunction(variable) {
  if (typeof variable === "function") return true;
  return false;
}

export function getCountryById(id) {
  let country = countries.countries.filter((country) => {
    if (country.id === id) {
      return country;
    }
  });
  if (country.length) {
    return country[0];
  }
  return {};
}

export function onlyNumbers(event) {
  if (
    (event.which !== 8 && event.which !== 0 && event.which < 48) ||
    event.which > 57
  ) {
    event.preventDefault();
  }
}
