import React from "react";
import { MDBContainer, MDBCol } from "mdbreact";
import FavoritesComponents from "./favorites-components";

const Favorites = (props) => {
  return (
    <MDBContainer id="favorites">
      <MDBCol lg="12" className={"p-0"}>
        <h3>my favorites</h3>
      </MDBCol>
      <FavoritesComponents {...props} />
    </MDBContainer>
  );
};

export default Favorites;
