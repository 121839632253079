import React, { Suspense } from "react";
import Layout from "./layout";
import apolloClient from "core/graphql/apolloClient";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import Cache from "core/components/cache";
import store from "core/state/redux/store";
import history from "core/state/redux/history";
import "./main.scss";
import ScrollToTop from "react-router-scroll-top";
import Helmet from "react-helmet";
import { OrganizationSchema } from "core/schemas/organizationSchema";

const App = () => {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{OrganizationSchema()}</script>
      </Helmet>
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <ReduxProvider store={store}>
            <Cache>
              <Router history={history}>
                <ScrollToTop>
                  <Layout />
                </ScrollToTop>
              </Router>
            </Cache>
          </ReduxProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
