import React from "react";
import { MDBContainer, MDBRow, MDBBox, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";

const TitleDescription = (props) => {
  let { title, description, description2, link, to } = props;
  return (
    <MDBContainer id={`title-holder`}>
      <MDBRow className="title-decription-block">
        <MDBCol className="d-flex flex-column">
          {title ? <MDBBox tag="h2">{title}</MDBBox> : <></>}
          <MDBBox tag={"div"} className={`description`}>
            <MDBBox tag="p">{description}</MDBBox>
            {description2 && <MDBBox tag="p">{description2}</MDBBox>}
            {(to !== undefined && <Link to={to}>{link}</Link>) || <></>}
          </MDBBox>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default TitleDescription;
