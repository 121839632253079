import React, { useState } from "react";
import Checkout from "./checkout";

const CheckoutStateContainer = (props) => {
  const [loggingIn, setLoggingIn] = useState(false);
  return (
    <Checkout {...props} loggingIn={loggingIn} setLoggingIn={setLoggingIn} />
  );
};

export default CheckoutStateContainer;
