import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";
import banner from "app/assets/images/contact/contact.png";
import bannerMobile from "app/assets/images/contact/banner-mobile.png";
import Breadcrumbs from "app/layout/breadcrumbs";
import ContactText from "./contactText";
import UspContact from "./uspContact";
import ContactInfo from "./contactInfo/";
import SeoHelmet from "app/layout/seoHelmet";
import HeroImage from "app/layout/heroImage";

const Contact = (props) => {
  return (
    <>
      <SeoHelmet title={"Contact"} url={"contact"} />
      <MDBContainer fluid className={"contact-us-container"}>
        <MDBRow>
          <MDBCol className={"p-0"} md="12">
            <div className="img item d-lg-block d-none desktop-image">
              <HeroImage id={"contact-img"} alt="Shop The Look Banner" />
            </div>
            <div className="img item d-lg-none d-block mobile-image">
              <HeroImage banner={bannerMobile} alt="Shop The Look Banner" />
            </div>
          </MDBCol>
          <MDBCol className={"contact-page"} md="12">
            <MDBBox
              className={"contact-upper-text w-100 text-center mx-auto my-5"}
            >
              <ContactText />
            </MDBBox>
            <MDBBox className={"usp-contact"}>
              <UspContact {...props} />
            </MDBBox>
          </MDBCol>
          <MDBCol size="12">
            <MDBBox className={"contact-info"}>
              <ContactInfo />
            </MDBBox>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Contact;
