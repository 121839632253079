import React from "react";
import { isUndefined } from "core/helpers/functions";

const FormatDate = props => {
  let { date, type } = props;
  let formattedDate;
  if (isUndefined(type)) {
    type = "short";
  }
  if (type === "short") {
    formattedDate = new Date(date);
    formattedDate =
      formattedDate.getMonth() +
      "/" +
      formattedDate.getDay() +
      "/" +
      formattedDate
        .getFullYear()
        .toString()
        .slice(-2);
  }
  return <>{formattedDate}</>;
};

export default FormatDate;
