import React from "react";
import { connect } from "react-redux";
import DiscountSummary from "./discountSummary";

const DiscountSummaryReduxContainer = (props) => {
  return <DiscountSummary {...props} />;
};

const mapStateToProps = (state) => ({
  cart:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined"
      ? state.customer.data.cart
      : {},
});
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscountSummaryReduxContainer);
