import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import ViewStateContainer from "./state-container";

const ViewReduxContainer = props => {
  return <ViewStateContainer {...props} />;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewReduxContainer);
