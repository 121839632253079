import React from "react";
import WhatLookingBlock from "./whatLookingBlock";

const WhatLookingBlockDataContainer = props => {
  let { data } = props;

  if (
    typeof data === "undefined" ||
    typeof data.getGroupBlocks === "undefined" ||
    data.getGroupBlocks.id === null
  )
    return "";

  let cmsBlockGroups = data.getGroupBlocks.content.split(",");
  return <WhatLookingBlock data={cmsBlockGroups} />;
};

export default WhatLookingBlockDataContainer;
