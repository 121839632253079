import React, { memo } from "react";
import {
  MDBCol,
  MDBRow,
  MDBIcon
} from "mdbreact";
import DataTable from "./dataTable";
import OrderInformation from "./orderInformation";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";

const View = memo(
  ({ order }) => {
    let dateSplit = order.created_at.split(" ");
    let date = dateSplit[0].split(/-/g);
    let time = dateSplit[1].split(/:/g);
    const utcDate = new Date(
      Date.UTC(date[0], date[1] - 1, date[2], time[0], time[1], time[2])
    );

    return (
      <MDBRow className="view-order">
        <MDBCol size="12" className="d-lg-none">
          <Link to="/customer/orders" className="back-button">
            <MDBIcon icon="arrow-left" /> back
          </Link>
        </MDBCol>
        <MDBCol size="12" className="order-id mt-5">
          <h2>order #{order.order_number}</h2>
        </MDBCol>
        <MDBCol size="12" className="order-status">
          <div className="status-container">{order.status}</div>
        </MDBCol>
        <MDBCol size="12" className="order-date mt-5">
          <MDBRow>
            <MDBCol size="6">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(utcDate)}
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="12" className="order-items mt-5">
          <MDBRow>
            <MDBCol size={`12`}>
              <h3>items ordered</h3>
            </MDBCol>
            <MDBCol size={`12`}>
              <DataTable products={order.productInformation} order={order} />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="12" className="order-information mt-5">
          <MDBRow>
            <MDBCol size={`12`}>
              <h3>order information</h3>
            </MDBCol>
            <MDBCol size={`12`}>
              <MDBRow>
                <OrderInformation order={order} />
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default View;
