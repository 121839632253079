import React, { useState } from "react";
import { MDBCol, MDBRow, MDBContainer } from "mdbreact";
import Button from "core/components/button";
import CheckButton from "core/components/checkButton";
import { isUndefined } from "core/helpers/functions";

const Newsletter = props => {
  let [newsletter, setNewsletter] = useState(props.customer.is_subscribed);

  return (
    <MDBContainer className="account-information-container mb-5">
      <MDBRow className="account-information border-bottom pb-5 mb-3">
        <MDBCol lg="12" className="mb-5">
          <h3 className="large">newsletter subscription</h3>
        </MDBCol>
        <MDBCol lg="6" md="12">
          <h4 className="gray heavy large mb-5">subscription option</h4>
          <CheckButton
            containerClass={"switch-container check-button mt-5"}
            wrapperClass={"slider"}
            checked={newsletter}
            onChange={() => {
              setNewsletter(!newsletter);
            }}
            label={"General subscription"}
            type={"checkbox"}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg="4" className="offset-lg-8" md="12">
          <Button
            color={"primary"}
            className={"right-arrow orange-button mt-5 w-100"}
            direction={"ml-3"}
            righticon={"true"}
            icon={"arrow-right"}
            disabled={props.locked}
            text={props.locked ? "Saving..." : "Save"}
            onClick={e => {
              e.preventDefault();
              if (isUndefined(props.locked) || props.locked === false) {
                props.onSubmit({
                  is_subscribed: newsletter
                });
              }
            }}
          ></Button>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Newsletter;
