import React from "react";
import { MDBCol, MDBRow, MDBContainer } from "mdbreact";
import DefaultAddresses from "app/pages/customer/account/pages/dashboard/addressbook";
import Button from "core/components/button";
import Address from "app/layout/address";
import { Link } from "react-router-dom";

const Addressbook = props => {
  return (
    <MDBContainer className="account-addressbook-container mb-5">
      <MDBRow className="account-addressbook">
        <MDBCol lg="12" className="">
          <h3 className="large">address book</h3>
        </MDBCol>
        <MDBCol lg="12">
          <DefaultAddresses {...props} />
        </MDBCol>
        <MDBCol lg="12">
          <h3 className="gray medium mb-5">additional address entries</h3>
          {props.hasAdditionalAddresses ? (
            <MDBRow>
              {props.additionalAddresses.map(address => {
                return (
                  <MDBCol lg="4" md="6" sm="12" className="mb-5">
                    <MDBRow>
                      <MDBCol lg="12" className="mb-3">
                        <h4 className="gray large heavy">
                          {address.street[0]}
                        </h4>
                      </MDBCol>
                      <MDBCol lg="12">
                        <p className="light-gray">
                          <Address address={address} />
                        </p>
                      </MDBCol>
                      <MDBCol lg="12" className="mt-4">
                        <Link to={`/customer/addresses/edit/${address.id}`}>
                          Edit
                        </Link>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                );
              })}
            </MDBRow>
          ) : (
            <p className="light-gray">
              you have no other address entries in your address book
            </p>
          )}
        </MDBCol>
        <MDBCol lg="4">
          <Button
            color={"primary"}
            to="/customer/addresses/add"
            className={"right-arrow orange-button mt-5 w-100"}
            direction={"ml-3"}
            righticon={"true"}
            icon={"arrow-right"}
            text={"add new address"}
          ></Button>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Addressbook;
