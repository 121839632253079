import { useDispatch, useSelector } from "react-redux";
import apolloClient from "core/graphql/apolloClient";
import compareActions from "../redux/data/compare/actions";
import queryLoader from "core/graphql/queryLoader";
import { messagesActions } from "../redux/data/messages";
import _ from "lodash";

function useCompare() {
  // return compare from state
  const compare = useSelector((state) => state.compare);
  const dispatch = useDispatch();

  /**
   * Adds product with id to compare list based on guestIndex
   * @param {productId: String} request
   */
  const addCompare = async (request) => {
    try {
      let checkIfexists = checkIfProductIsInCompareById(request.productId);
      if (checkIfexists) {
        return;
      }
      let guestIndex = determineGuestIndex();
      let variables = { productId: request.productId };
      if (guestIndex !== null) {
        variables["guestIndex"] = guestIndex;
      }
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addToCompare"),
        variables: variables,
        fetchPolicy: "no-cache",
      });

      if (data.addProductToCompare !== null) {
        dispatch(compareActions.setCompare(data.addProductToCompare));
        setOpenState(true);
        dispatch(
          messagesActions.addMessage(
            "Item successfully added to compare",
            "success"
          )
        );
      }
    } catch (err) {
      console.log(err.message);
      return;
    }
  };

  const getCompareData = () => {
    return compare;
  };

  const getCompareItems = () => {
    return compare.data.compareItems.items;
  };

  /**
   * Remove product with id from compare list based on guestIndex
   * @param {productId: String} request
   */
  const removeCompare = async (request) => {
    try {
      let guestIndex = determineGuestIndex();
      let checkIfexists = checkIfProductIsInCompareById(request.productId);
      console.log(checkIfexists);
      if (!checkIfexists) {
        return;
      }
      let variables = {
        productId: request.productId,
        guestIndex: guestIndex,
      };

      const { data } = await apolloClient.mutate({
        mutation: queryLoader("removeCompare"),
        variables: variables,
        fetchPolicy: "no-cache",
      });
      if (data.guestIndex !== null) {
        dispatch(compareActions.setCompare(data.removeProductToCompare));
        setOpenState(false);
        dispatch(
          messagesActions.addMessage(
            "item successfully removed from compared list",
            "success"
          )
        );
      }
      return data;
    } catch (error) {
      dispatch(messagesActions.addMessage(error.toString(), "danger"));
      return;
    }
  };

  const determineGuestIndex = () => {
    let guestIndex =
      typeof compare.data.compareItems !== "undefined" &&
      typeof compare.data.compareItems.guestIndex !== `undefined`
        ? compare.data.compareItems.guestIndex
        : null;
    let customerId =
      typeof compare.data.compareItems !== "undefined" &&
      typeof compare.data.compareItems.customerId !== `undefined`
        ? compare.data.compareItems.customerId
        : null;

    if (
      !_.isNull(customerId) &&
      typeof customerId !== `undefined` &&
      customerId !== "0"
    ) {
      return customerId;
    }
    return guestIndex;
  };

  const clearCompareList = async () => {
    let guestIndex = determineGuestIndex();

    let variables = {
      guestIndex: guestIndex,
    };

    const { data } = await apolloClient.mutate({
      mutation: queryLoader("clearCompare"),
      variables: variables,
      fetchPolicy: "no-cache",
    });
  };

  const checkIfProductIsInCompareById = (product_id) => {
    if (
      typeof compare.data.compareItems === "undefined" ||
      typeof compare.data.compareItems.items === "undefined" ||
      compare.data.compareItems.items === null ||
      compare.data.compareItems.items.length === 0
    ) {
      return false;
    }

    let findIfItemExistsInCompare = compare.data.compareItems.items.filter(
      (item) => {
        return parseInt(item.id) === parseInt(product_id);
      }
    );
    return findIfItemExistsInCompare.length === 1 ? true : false;
  };

  const getOpenState = () => {
    return compare.open;
  };

  const setOpenState = (newState) => {
    dispatch(compareActions.setOpenState(newState));
    return newState;
  };

  return [
    addCompare,
    removeCompare,
    getCompareData,
    getCompareItems,
    clearCompareList,
    checkIfProductIsInCompareById,
    getOpenState,
    setOpenState,
  ];
}
export { useCompare };
