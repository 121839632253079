import React from "react";
import { connect } from "react-redux";
import { customerActions } from "core/state/redux/data/customer";
import RegisterStepThree from "./registerStepThree";

const ThankYouPageReduxContainer = props => {
  return <RegisterStepThree {...props} />;
};
const mapStateToProps = state => ({
  customer: state.customer
});

const mapDispatchToProps = dispatch => {
  return {
    reudceCustomer: customer => {
      dispatch(customerActions._reduceCustomer({ ...customer, status: false }));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYouPageReduxContainer);
