import React, { memo, useState } from "react";
import ImageContainer from "./imageContainer";
import themeConfiguration from "config/themeConfiguration";
import lodash from "lodash";
import ImageLoader from "react-load-image";
import ReactLoader from "app/layout/loader";

function Preloader(props) {
  return (
    <div style={{ width: "100%" }}>
      <ReactLoader />
    </div>
  );
}

const Image = memo(
  (props) => {
    let source = "";
    if (typeof props.source === "undefined") return "";
    if (!lodash.isUndefined(props.backend) && props.backend === true) {
      if (props.backend_public) {
        source =
          themeConfiguration.magento_url +
          themeConfiguration.media_url +
          props.source;
      } else {
        source = themeConfiguration.magento_url + props.source;
      }
    } else {
      source = props.source.includes("http") ? props.source : props.source;
    }

    // Convert to cached optimized image
    const cachedImageUrl = `https://atlas-avant-garde.web.app/cache/avant-garde/ducducnyc?fileUrl=${encodeURIComponent(source)}`;

    return (
      <ImageContainer {...props}>
        <img
          data-src={cachedImageUrl}
          className={`${props.className} lazyload`}
        />
        {/*<ImageLoader src={cachedImageUrl}>
          <img className={props.className} />
          <div>Error!</div>
          <Preloader />
        </ImageLoader>*/}
      </ImageContainer>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Image;
