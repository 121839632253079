import React, { useEffect } from "react";
import RightStateContainer from "./right-state-container";

const RightCartContainer = (props) => {
  let { getCartInformation, token } = props;
  useEffect(() => {
    getCartInformation();
  }, [getCartInformation, token]);
  return <RightStateContainer {...props} />;
};

export default RightCartContainer;
