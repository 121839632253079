import apolloClient from "core/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "core/state/redux/data/common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";
import queryLoader from "app/graphql/queryLoader";
export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cart.id;
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("placeOrder"),
      variables: {
        input: {
          cart_id: cart_id,
        },
      },
    });

    store.dispatch(
      messagesActions.addMessage(
        `YOUR ORDER HAS BEEN RECEIVED.
    THANK YOU FOR YOUR PURCHASE!
    Your order # is: ${data.placeOrder.order.order_id}.
    You will receive an order confirmation email with details of your order.`,
        "success"
      )
    );
    store.dispatch(
      customerActions.placeOrderSuccess(data.placeOrder.order.order_id)
    );
    store.dispatch(customerActions.createEmptyCart());
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
