import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Address from "app/layout/address";
import { Link } from "react-router-dom";

const DefaultAddresses = props => {
  let { defaultBilling, defaultShipping, doesHaveAddesses, isTrade } = props;
  return (
    <MDBRow className="mb-md-5 mb-sm-5 mb-xs-5">
      <MDBCol lg="12">
        <MDBRow>
          <MDBCol lg="12" className="mb-3 mt-5">
            <h3 className="gray medium">address book</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="6" className="mb-4">
            <MDBRow>
              <MDBCol lg="12" className="mb-3">
                <h4 className="gray heavy large">default billing address</h4>
              </MDBCol>
              <MDBCol lg="12">
                <p className="address light-gray">
                  {doesHaveAddesses ? (
                    <Address address={defaultBilling} />
                  ) : (
                    <>You have not set a default billing address.</>
                  )}
                </p>
              </MDBCol>
              {!isTrade ? (
                <>
                  {typeof defaultBilling !== "undefined" && (
                    <MDBCol lg="6" className="mt-4">
                      <Link
                        to={`/customer/addresses/edit/${defaultBilling.id}`}
                      >
                        Edit
                      </Link>
                    </MDBCol>
                  )}
                </>
              ) : (
                <MDBCol lg="12" className="mt-4">
                  <p className="light-gray">
                    NOTE: to change this info, please contact Customer Service
                  </p>
                </MDBCol>
              )}
            </MDBRow>
          </MDBCol>

          <MDBCol lg="6">
            <MDBRow>
              <MDBCol lg="12" className="mb-3">
                <h4 className="gray large heavy">default shipping address</h4>
              </MDBCol>
              <MDBCol lg="12">
                <p className="light-gray">
                  {doesHaveAddesses ? (
                    <>
                      <Address address={defaultShipping} />
                    </>
                  ) : (
                    <>You have not set a default shipping address.</>
                  )}
                </p>
              </MDBCol>
              {!isTrade && (
                <>
                  {typeof defaultShipping !== "undefined" && (
                    <MDBCol lg="6" className="mt-4">
                      <Link
                        to={`/customer/addresses/edit/${defaultShipping.id}`}
                      >
                        Edit
                      </Link>
                    </MDBCol>
                  )}
                </>
              )}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default DefaultAddresses;
