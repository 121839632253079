import React, { useState, useEffect } from "react";
import Filter from "./filter";
import { MDBBox, MDBIcon } from "mdbreact";
import { CSSTransition } from "react-transition-group";

const FilterStateContainer = props => {
  let { setToggleFilter, toggleFilter } = props;
  /*For mobile start*/
  const [screenwidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    if (toggleFilter === "filters") {
      setMobileFilterToggle(false);
    }
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, [toggleFilter]);
  /*For mobile end*/

  const [mobileFilterToggle, setMobileFilterToggle] = useState(false);
  const [toggle, setToggle] = useState(false);

  if (screenwidth < 992) {
    return (
      <MDBBox className={`filter w-100`}>
        {toggleFilter === "filters" && (
          <MDBBox
            onClick={e => {
              setToggleFilter(props.title);
              setMobileFilterToggle(true);
            }}
            className={`filter-toggle row show-${mobileFilterToggle} ${
              !mobileFilterToggle
                ? "fadeInDown animated"
                : "invisible fadeOutUp"
            }`}
          >
            <h3>
              {props.title}
              {(mobileFilterToggle && (
                <MDBIcon className="filter-icon" icon="minus" />
              )) || <MDBIcon className="filter-icon" icon="plus" />}
            </h3>
          </MDBBox>
        )}
        {toggleFilter !== "filters" ? (
          <CSSTransition
            in={mobileFilterToggle}
            appear={true}
            timeout={100}
            classNames={`filter`}
          >
            <Filter isMobile={true} {...props} />
          </CSSTransition>
        ) : (
          <></>
        )}
      </MDBBox>
    );
  }

  return (
    <MDBBox className={`filter w-100`}>
      <MDBBox
        onClick={e => {
          setToggle(!toggle);
        }}
        className={`filter-toggle row show-${toggle}`}
      >
        <h3>
          {props.title}
          {(toggle && <MDBIcon className="filter-icon" icon="minus" />) || (
            <MDBIcon className="filter-icon" icon="plus" />
          )}
        </h3>
      </MDBBox>
      <CSSTransition
        in={toggle}
        appear={true}
        timeout={100}
        classNames={`filter`}
      >
        <Filter {...props} />
      </CSSTransition>
    </MDBBox>
  );
};

export default FilterStateContainer;
