import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import WishlistShare from "./wishlistShare";

const SKU_WISHLIST = gql`
  query getWishlistItems($share_code: String!, $multiwishlist_id: String!) {
    getWishlistItems(
      share_code: $share_code
      multiwishlist_id: $multiwishlist_id
    ) {
      sku
    }
  }
`;
const WishlistShareQueryContainer = (props) => {
  let resultsWishlistShare = null;
  let search = new URLSearchParams(props.location.search);
  let code = search.get("code").toString();
  let multiwishlistId = search.get("multiwishlist_id").toString();
  let arrayOfSku = [];
  resultsWishlistShare = useQuery(SKU_WISHLIST, {
    variables: { share_code: code, multiwishlist_id: multiwishlistId },
  });

  if (!resultsWishlistShare.loading) {
    resultsWishlistShare.data.getWishlistItems.map((item) => {
      return arrayOfSku.push(item.sku);
    });
  }
  if (arrayOfSku.length === 0) {
    arrayOfSku.push(null);
  }

  return <WishlistShare {...props} wishlistskus={arrayOfSku} />;
};

export default WishlistShareQueryContainer;
