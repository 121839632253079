import React, { useState } from "react";
import Button from "core/components/button";
import { Redirect } from "react-router-dom";
import Wishlist from "app/pages/catalog/product/info/controls/wishlist/wishlist";
import { validate, shouldMarkError } from "./../validation";

const ShareBtn = (props) => {
  let {
    wishlistShare,
    messages,
    shareWishlist,
    setTouched,
    touched,
    shareEmails,
    lock,
    setEmailError,
    setMessageError,
  } = props;
  const [loading, setLoading] = useState(false);

  let errors = validate(shareEmails, messages);
  Object.keys(errors).map((item) => {
    let error = errors[item];

    if (item === "shareEmails") {
      if (touched.shareEmails) setEmailError(error);
    }

    if (item === "messages") {
      if (touched.messages) setMessageError(error);
    }
  });
  const validateInput = () => {
    let isTrue = true;
    setTouched({
      shareEmails: true,
      messages: true,
    });
    Object.keys(errors).map((item) => {
      let error = errors[item];

      if (item === "shareEmails") {
        setEmailError(error);
      }
      if (item === "messages") {
        setMessageError(error);
      }
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      } else {
        error = false;
      }
    });
    if (isTrue) {
      if (loading) {
        setLoading(!loading);
      } else {
        setLoading(true);
        lock({
          always: (e) => {
            // setLoading(true);
          },
          fail: (e) => {
            setLoading(false);
          },
          success: (e) => {
            setLoading(false);
          },
        });
        shareWishlist(
          props.shareEmails,
          props.messages,
          props.mainWishlist,
          props.activeWishlist
        );
      }
    } else {
      props.addMessage(
        "Please fill all required fields marked in red.",
        "danger"
      );
    }
  };

  let id = parseInt(window.location.search.split("=")[1]);

  if (typeof id === "undefined" || (typeof id === "int" && id.length === 0)) {
    return <Redirect to="/customer/favorites" />;
  }
  return (
    <div className={"share-btn-wrapper"}>
      <Button
        className={"right-arrow orange-button share-btn"}
        direction={"ml-3"}
        disabled={loading}
        righticon={"true"}
        validate
        icon={"arrow-right"}
        text={`${!loading ? "share wishlist" : "sharing wishlist..."}`}
        onClick={(e) => {
          e.preventDefault();
          validateInput();
        }}
      />
    </div>
  );
};

export default ShareBtn;
