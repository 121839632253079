import React from "react";
import HeaderStateContainer from "./header-state-container";
import _ from "lodash";

const HeaderDataContainer = props => {
  if (
    !_.isUndefined(props.data) &&
    !_.isUndefined(props.data.categoryList) &&
    !_.isUndefined(props.data.categoryList[0])
  ) {
    let sortedCategories = props.data.categoryList[0].children.sort((a, b) => {
      return a.position - b.position;
    });
    props.data.categoryList[0].children = sortedCategories;
  } else {
    return <></>;
  }

  return <HeaderStateContainer {...props} />;
};

export default HeaderDataContainer;
