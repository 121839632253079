import React from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import { default as newCustomerActions } from "app/state/redux/data/customer/actions";
import searchActions from "core/state/redux/data/search/actions";
import ControlsStateContainer from "./controls-state-container";
import ControlsDummyComponent from "./controls-dummy-component";
import { isEmpty } from "core/helpers/functions";
import { productActions } from "core/state/redux/data/product";
import { commonActions } from "core/state/redux/data/common";
import { messagesActions } from "core/state/redux/data/messages";
import { Redirect } from "react-router-dom";

const ControlsReduxContainer = (props) => {
  if (
    (!props.editMode && !isEmpty(props.data)) ||
    (props.editMode && props.cartItem)
  ) {
    return <ControlsStateContainer {...props} />;
  } else if (
    props.editMode &&
    props.cartItem === false &&
    props.data.name !== "loading..."
  ) {
    return <Redirect to={`/cart`} />;
  } else {
    return <ControlsDummyComponent {...props} />;
  }
};
const mapStateToProps = (state) => ({
  items:
    state.customer.data !== undefined
      ? state.customer.data.cart !== undefined
        ? state.customer.data.cart
          ? state.customer.data.cart.items
          : []
        : []
      : [],
  productOptions: state.product,
  wishlist:
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.wishlist !== "undefined"
      ? state.customer.data.wishlist
      : [],
  customer: state.customer.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToCart: (amount, sku, specialOptions) => {
      dispatch(
        customerActions.addProductsToCart([
          {
            data: {
              quantity: amount,
              sku: sku,
            },
            customizable_options: specialOptions,
          },
        ])
      );
    },
    addConfigurableItemToCart: (
      amount,
      sku,
      specialOptions,
      variant_sku = false,
      type = "simple"
    ) => {
      let buyRequest = [
        {
          data: {
            quantity: amount,
            sku: variant_sku,
          },
          customizable_options: specialOptions,
        },
      ];
      if (type !== "simple") {
        buyRequest[0]["variant_sku"] = variant_sku;
        buyRequest[0]["parent_sku"] = sku;
      }

      dispatch(
        newCustomerActions.addConfigurableProductToCart([...buyRequest])
      );
    },
    updateCart: (items) => {
      dispatch(customerActions.updateCart(items));
    },
    showLogin: () => {
      window.scrollTo(0, 0);
      dispatch(searchActions.toggleSearch(false));
      dispatch(customerActions.toggleLogin(true));
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    reduceProductOptions: (options) => {
      dispatch(productActions._reduceOptions(options));
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlsReduxContainer);
