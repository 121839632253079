import React from "react";
import Breadcrumbs from "app/layout/breadcrumbs";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import CategoryComponentContainer from "./category-component-container";

const Category = (props) => {
  const { categoryFilter } = props;

  return (
    <>
      <div id={`category-${categoryFilter}`} className={`category-container`}>
        <MDBContainer className="plp-main-container">
          {/* BREADCRUMBS CONTAINER */}
          <MDBRow>
            <MDBCol lg="9" className="offset-lg-3">
              <Breadcrumbs />
            </MDBCol>
          </MDBRow>
          <>
            <MDBRow className="main-plp-container">
              <CategoryComponentContainer />
            </MDBRow>
          </>
        </MDBContainer>
      </div>
    </>
  );
};

export default Category;
