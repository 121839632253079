import React from "react";
import FindRetailer from "./findRetailer";

const FindRetailerDataContainer = (props) => {
  let stores = [];
  let etailers = [];
  if (props.data !== undefined) {
    props.data.getStoreLocator
      .filter((store) => {
        return store.store_type === "Store";
      })
      .map((store) =>
        stores.push({
          store_id: store.store_id,
          name: store.name,
          state: store.state,
          city: store.city,
          street: store.street,
          lat: parseFloat(store.latitude),
          lng: parseFloat(store.longitude),
          store_type: store.store_type,
          website: store.website,
          showroom: store.showroom,
        })
      );
    props.data.getStoreLocator
      .filter((store) => {
        return store.store_type !== "Store";
      })
      .map((store) =>
        etailers.push({
          store_id: store.store_id,
          name: store.name,
          state: store.state,
          city: store.city,
          street: store.street,
          lat: parseFloat(store.latitude),
          lng: parseFloat(store.longitude),
          store_type: store.store_type,
          website: store.website,
          showroom: store.showroom,
        })
      );
  }
  return <FindRetailer {...props} stores={stores} etailers={etailers} />;
};

export default FindRetailerDataContainer;
