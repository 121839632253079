import React from "react";
import Slider from "./slider";
import GraphQlWrapper from "core/components/graphQlWrapper";
import { gql } from "graphql.macro";

const QUERY = gql`
  query {
    slider(name: "Homepage slider") {
      autoplay
      name
      slide_animation
      slide_list
      slides {
        has_overlay
        media
        mobile_media
        name
        overlay_text
        overlay_title
        overlay_cta_link
        overlay_cta_label
      }
    }
  }
`;
const HomepageSliderQuery = (props) => {
  return (
    <GraphQlWrapper
      query={QUERY}
      variables={{ id: "homepage", identifier: "slider" }}
      saveQuery={true}
    >
      <Slider />
    </GraphQlWrapper>
  );
};

export default HomepageSliderQuery;
