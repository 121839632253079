import React from "react";
import Toolbar from "./toolbar";

const ToolbarQueryContainer = props => {
  return (
    <>
      <Toolbar {...props} />
    </>
  );
};

export default ToolbarQueryContainer;
