import React, { useState } from "react";
import CategoryQueryContainer from "./category-query-container";
import SeoHelmet from "app/layout/seoHelmet";

const CategoryStateContainer = (props) => {
  const [categoryFilter, setCategoryFilter] = useState(props.variables.id);
  const [filters, setFilters] = useState(props.filters);

  if (props.variables.id !== categoryFilter) {
    setCategoryFilter(props.variables.id);
  }
  if (props.filters !== filters) {
    setFilters(props.filters);
  }

  if (typeof props.category !== "undefined") {
    return (
      <>
        <SeoHelmet title={props.category.name} url={props.category.url_path} />
        <CategoryQueryContainer
          {...props}
          allCategories={props.allCategories}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          data={props.category}
          setFilters={props.setFilters}
          filters={props.filters}
        />
      </>
    );
  }
  return (
    <CategoryQueryContainer
      allCategories={props.allCategories}
      categoryFilter={categoryFilter}
      setCategoryFilter={setCategoryFilter}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

export default CategoryStateContainer;
