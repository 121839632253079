import React, { memo } from "react";
import {
  MDBCol,
  MDBMask,
  MDBView,
  MDBContainer,
  MDBRow,
  MDBLink,
} from "mdbreact";
import _ from "lodash";
import Button from "core/components/button";
import Image from "core/components/image";
import history from "core/state/redux/history";

const Slide = memo(
  (props) => {
    let {
      image,
      imgClass,
      className,
      overlay_title,
      overlay_text,
      overlay_cta_label,
      overlay_cta_link,
      has_overlay,
    } = props;

    return (
      <div
        onClick={() => {
          if (has_overlay === 0) history.push(overlay_cta_link);
        }}
        className="slide-main-wrapper"
      >
        <MDBView className={className}>
          <div className={`d-lg-block d-none`}>
            <Image
              source={image}
              className={imgClass}
              alt={props.alt}
              placeholderimage={props.placeholderimage}
            />
          </div>
          <div className={`d-lg-none d-block`}>
            <Image
              source={props.mobileImage}
              className={imgClass}
              alt={props.alt}
              placeholderimage={props.placeholderimage}
            />
          </div>
          <MDBMask overlay={`black-light`} className={`flex-center`}>
            <MDBContainer>
              <MDBRow>
                <MDBCol className={`text-white text-center`}>
                  <h1
                    tag={`h1`}
                    variant={`display-1`}
                    className={`display-1 text-white`}
                  >
                    {overlay_title}
                  </h1>
                  <p tag={`p`} className={`text-white`}>
                    {overlay_text}
                  </p>
                  {has_overlay === 1 && overlay_cta_link && overlay_cta_label && (
                    <MDBLink
                      to={overlay_cta_link}
                      className={`d-inline-block button-main-slider`}
                    >
                      <Button
                        className={"right-arrow orange-button"}
                        direction={"ml-3"}
                        righticon={"true"}
                        icon={"arrow-right"}
                        text={"Primary"}
                      >
                        {overlay_cta_label}
                      </Button>
                    </MDBLink>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Slide;
