import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import { customerActions } from "core/state/redux/data/customer";
import CartItemDataContainer from "./data-container";

const CartItemReduxContainer = props => {
  return <CartItemDataContainer {...props} />;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: callback => {
      dispatch(commonActions.lock(callback));
    },
    updateCart: items => {
      dispatch(customerActions.updateCart(items));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartItemReduxContainer);
