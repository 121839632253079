import types from "./types";
import { uniqBy } from "lodash";

// Add a new message
const addMessageReducer = (state, action) => {
  let currentMessages = state.items ? state.items : [];
  let newMessages = [...currentMessages];

  newMessages.push({
    text: action.messageText,
    type: action.messageType,
    target: action.messageTarget,
    closeTime: action.messageCloseTime
  });

  let uniqueMessages = uniqBy(newMessages, message => {
    return [message.text, message.target].join();
  });
  return Object.assign({}, state, { items: uniqueMessages });
};

// Remove an existing message
const removeMessageReducer = (state, action) => {
  let currentMessages = state.items ? state.items : [];
  let newMessages = [...currentMessages];
  for (let i = 0; i < newMessages.length; i++) {
    if (newMessages[i].text === action.messageText) {
      newMessages.splice(i, 1);
      break;
    }
  }

  return Object.assign({}, state, { items: newMessages });
};

// Main reducer
const messagesReducer = (state, action) => {
  if (state === undefined) return {};
  if (action.type === types.ADD_MESSAGE)
    return addMessageReducer(state, action);
  if (action.type === types.REMOVE_MESSAGE)
    return removeMessageReducer(state, action);
  return state;
};

export default messagesReducer;
