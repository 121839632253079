import React from "react";
import Forms from "./forms";
import { gql } from "graphql.macro";
import GraphQlQueryWrapper from "core/components/graphQlWrapper";

const FORM = gql`
  query forms {
    forms {
      form {
        document
        image
        title
      }
    }
  }
`;

const FormsQueryContainer = props => {
  return (
    <>
      <GraphQlQueryWrapper query={FORM} variables={{ id: `forms-content` }}>
        <Forms />
      </GraphQlQueryWrapper>
    </>
  );
};

export default FormsQueryContainer;
