import React, { memo } from "react";
import { MDBBox } from "mdbreact";
import themeConfiguration from "config/themeConfiguration";
import lodash from "lodash";
import Icon from "../../../../../../assets/icon/icon";

const AssemblyPDF = memo(
  (props) => {
    return (
      <>
        <Icon icon={"assemblyPdf"} />
        <MDBBox className={"assembly-pdf item"}>
          <a
            href={`
          ${themeConfiguration.magento_url.substring(
            0,
            themeConfiguration.magento_url.length - 1
          )}${props.instructions}
        `}
            style={{ display: "inline-block" }}
            download={props.name}
            target={`_blank`}
          >
            assembly <br /> instructions
          </a>
        </MDBBox>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default AssemblyPDF;
