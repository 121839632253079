import React, { memo } from "react";
import AccordionAssemblyItem from "./accordionAssemblyItem";
import { MDBBox } from "mdbreact";
import { isEqual } from "lodash";

const AccordionAssembly = memo(
  props => {
    if (typeof props.data === "undefined") return <></>;
    let data = props.data.getAssembly;

    let halfLength = Math.floor(data.length / 2);
    let leftSide = data.slice(0, halfLength);
    let rightSide = data.slice(halfLength, data.length);
    return (
      <>
        <MDBBox className={"assembly-tab column-one"}>
          {Object.keys(leftSide).map((items, index) => {
            return (
              <AccordionAssemblyItem
                key={`assembly-one-${index}`}
                item={leftSide[index]}
              />
            );
          })}
        </MDBBox>
        <MDBBox className={"assembly-tab column-two"}>
          {Object.keys(rightSide).map((items, index) => {
            return (
              <AccordionAssemblyItem
                key={`assembly-two-${index}`}
                item={rightSide[index]}
              />
            );
          })}
        </MDBBox>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default AccordionAssembly;
