import React, { useState } from "react";
import CompoundSlider from "./compoundSlider";
import { isEmpty } from "lodash";

const CompoundSliderState = (props) => {
  let { initialValues, showPriceRange, symbol } = props;
  let previousValuesInitial = {};
  const { useInputs, valueChangeHandler, addFilter, attribute_code } = props;
  const [currentValues, setCurrentValues] = useState({});
  const [previousValues, setPreviousValues] = useState({});

  if (typeof initialValues.min === "string") {
    initialValues = {
      min: parseInt(initialValues.min),
      max: parseInt(initialValues.max),
    };
    previousValuesInitial = {
      min: parseInt(initialValues.min),
      max: parseInt(initialValues.max),
    };
  }
  if (isEmpty(previousValuesInitial)) {
    previousValuesInitial = {
      min: parseInt(initialValues.min),
      max: parseInt(initialValues.max),
    };
  }

  if (initialValues.min === initialValues.max) return "";

  return (
    <CompoundSlider
      min={initialValues.min}
      max={initialValues.max}
      step={1}
      minId={`min-${attribute_code}-slider`}
      maxId={`max-${attribute_code}-slider`}
      useInputs={useInputs}
      valueChangeHandler={valueChangeHandler}
      currentValues={currentValues}
      setCurrentValues={setCurrentValues}
      addFilter={addFilter}
      showPriceRange={showPriceRange}
      symbol={symbol}
      initialLoad={props.initialLoad}
      setInitalLoad={props.setInitalLoad}
      previousValuesInitial={previousValuesInitial}
      previousValues={previousValues}
      setPreviousValues={setPreviousValues}
    />
  );
};

export default CompoundSliderState;
