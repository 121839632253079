import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import ProductStateContainer from "./state-container";

const ProductReduxContainer = (props) => {
  return <ProductStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  items:
    state.customer.data !== undefined
      ? state.customer.data.cart !== undefined
        ? state.customer.data.cart
          ? state.customer.data.cart.items
          : []
        : []
      : [],
  customer: state.customer.data?.token,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductReduxContainer);
