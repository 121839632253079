import React, { useState } from "react";
import InputField from "core/components/inputField/inputField";
import { MDBInput, MDBBox, MDBCol, MDBRow } from "mdbreact";
import CheckButton from "core/components/checkButton/checkButton";
import Button from "core/components/button";
import Select2 from "core/components/select/select";
import CountrySelect from "core/components/countrySelect";
import { validate, shouldMarkError } from "./validation";

const ContactInfo = (props) => {
  const [magazine, setMagazine] = useState(false);
  const [tradeshow, setTradeshow] = useState(false);
  const [online, setOnline] = useState(false);
  const [retail, setRetail] = useState(false);
  const [family, setFamily] = useState(false);
  const [other, setOther] = useState(false);
  const [otherSpecify, setOtherSpecify] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [message, setMessage] = useState("");
  const [countryValue, setCountryValue] = useState({
    label: "United States",
    value: "US",
  });
  const [client, setClient] = useState({
    label: "client type*",
    value: 0,
  });
  const [stateValue, setStateValue] = useState("");

  const setFirstNameFn = (e) => {
    setFirstName(e.target.value);
  };
  const setLastNameFn = (e) => {
    setLastName(e.target.value);
  };
  const setEmailFn = (e) => {
    setEmail(e.target.value);
  };
  const setCityFn = (e) => {
    setCity(e.target.value);
  };
  const setMessageFn = (e) => {
    setMessage(e.target.value);
  };
  const setOtherSpecifyFn = (e) => {
    setOtherSpecify(e.target.value);
  };

  const state = {
    firstName,
    lastName,
    email,
    city,
    message,
    magazine,
    tradeshow,
    online,
    retail,
    family,
    other,
    countryValue,
    client,
    stateValue,
  };

  let errors = validate(firstName, lastName, email, city, message);
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    city: false,
    message: false,
    client: false,
    stateValue: false,
  });

  const [loading, setLoading] = useState(false);

  const _handleSendEmail = (e) => {
    let isTrue = true;
    setTouched({
      firstName: true,
      lastName: true,
      email: true,
      city: true,
      message: true,
      client: true,
      stateValue: true,
    });

    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });

    if (isTrue) {
      if (client.value !== 0) {
        if (stateValue !== "") {
          setLoading(true);
          props.lock({
            always: () => {
              setLoading(true);
            },
            success: () => {
              setLoading(false);
              setFirstName("");
              setLastName("");
              setEmail("");
              setCity("");
              setMessage("");
              setCountryValue({
                label: "United States",
                value: "US",
              });
              setClient({
                label: "client type*",
                value: 0,
              });
              setStateValue("");
              setMagazine(false);
              setTradeshow(false);
              setOnline(false);
              setRetail(false);
              setFamily(false);
              setOther(false);
              setOtherSpecify("");
              setTouched({
                firstName: false,
                lastName: false,
                email: false,
                city: false,
                message: false,
                client: false,
                stateValue: false,
              });
            },
            fail: () => {
              setLoading(false);
            },
          });
          let attributesUsed = [];
          if (magazine) {
            attributesUsed.push({
              code: "magazine",
              value: "<li>magazine</li>",
            });
          }
          if (tradeshow) {
            attributesUsed.push({
              code: "tradeshow",
              value: "<li>tradeshow</li>",
            });
          }
          if (online) {
            attributesUsed.push({ code: "online", value: "<li>online</li>" });
          }
          if (retail) {
            attributesUsed.push({
              code: "retail",
              value: "<li>retail store</li>",
            });
          }

          if (family) {
            attributesUsed.push({
              code: "family",
              value: "<li>family friend</li>",
            });
          }
          if (other) {
            attributesUsed.push({
              code: "other",
              value: "<li>" + otherSpecify + "</li>",
            });
          }

          props.sendEmail({
            input: {
              attributes: [
                { code: "email", value: email },
                { code: "firstname", value: firstName },
                { code: "lastname", value: lastName },
                { code: "city", value: city },
                { code: "message", value: message },
                { code: "countryValue", value: countryValue.label },
                { code: "stateValue", value: stateValue.label },
                { code: "clientType", value: client.label },
                ...attributesUsed,
              ],
              template: "10",
            },
          });
        } else {
          props.addMessage("Please fill all required fields marked in red.");
        }
      }
    }
  };

  return (
    <>
      <MDBBox className={"contact-inputs"}>
        <div className={"contact-inputs-inner one"}>
          <h4>contact info</h4>
          <InputField
            className={`custom-input ${shouldMarkError("firstName", errors, touched)
                ? "validation-error"
                : ""
              }`}
            label={`${shouldMarkError("firstName", errors, touched)
                ? "first name is required*"
                : "first name*"
              }`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={firstName !== undefined ? firstName : ""}
            onChange={setFirstNameFn}
          />
          <InputField
            className={`custom-input ${shouldMarkError("lastName", errors, touched)
                ? "validation-error"
                : ""
              }`}
            label={`${shouldMarkError("lastName", errors, touched)
                ? "last name is required*"
                : "last name*"
              }`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={lastName !== undefined ? lastName : ""}
            onChange={setLastNameFn}
          />
          <InputField
            className={`custom-input ${shouldMarkError("email", errors, touched)
                ? "validation-error"
                : ""
              }`}
            label={`${shouldMarkError("email", errors, touched)
                ? "email address is required*"
                : "email address*"
              }`}
            group
            type="email"
            validate
            error="wrong"
            success="right"
            value={email !== undefined ? email : ""}
            onChange={setEmailFn}
          />
          <InputField
            className={`custom-input ${shouldMarkError("city", errors, touched) ? "validation-error" : ""
              }`}
            label={`${shouldMarkError("city", errors, touched)
                ? "city is required*"
                : "city*"
              }`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={city !== undefined ? city : ""}
            onChange={setCityFn}
          />
          <MDBBox className={"select-div-container"}>
            <CountrySelect
              isSearchable={true}
              setStateValue={setStateValue}
              stateValue={stateValue}
              countryValue={countryValue}
              setCountryValue={setCountryValue}
              statePlaceholder={
                countryValue.value === "US" || countryValue.value === "CA"
                  ? countryValue.value === "US"
                    ? "state*"
                    : countryValue.value === "CA"
                      ? "province*"
                      : "province"
                  : "province"
              }
              stateLabel={"region"}
              stateWrapperClass={
                (stateValue === "" || stateValue === null) && touched.stateValue
                  ? "main-select dark validation-error"
                  : "main-select dark"
              }
            />
          </MDBBox>
        </div>
        <div className={"contact-inputs-inner two"}>
          <h4>details</h4>
          <MDBBox>
            <div className="select-div-container">
              <Select2
                className={`browser-default custom-Select2 ${client.value === 0 && touched.client ? "validation-error" : ""
                  }`}
                onChange={(e) => {
                  setClient(e);
                }}
                options={[
                  { label: "direct", value: 1 },
                  { label: "designer", value: 2 },
                  { label: "retailer", value: 3 },
                  { label: "press", value: 4 },
                  { label: "other", value: 5 },
                ]}
                value={client}
                openIndicator="caret-up"
                closedIndicator="caret-down"
                useCustom={true}
              />
            </div>
          </MDBBox>
          <h4 className={"how-did-title"}> how did you hear about us?</h4>
          <MDBBox>
            <CheckButton
              containerClass={
                "checkbox-container check-button multiselect-how-did-u"
              }
              wrapperClass={"checkmark"}
              label={"magazine"}
              onChange={() => setMagazine(!magazine)}
              checked={magazine}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"checkbox-container check-button"}
              checked={tradeshow}
              wrapperClass={"checkmark"}
              onChange={() => setTradeshow(!tradeshow)}
              checked={tradeshow}
              label={"tradeshow"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"checkbox-container check-button"}
              wrapperClass={"checkmark"}
              onChange={() => setOnline(!online)}
              checked={online}
              label={"online"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"checkbox-container check-button"}
              checked={retail}
              wrapperClass={"checkmark"}
              onChange={() => setRetail(!retail)}
              label={"retail store"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"checkbox-container check-button"}
              checked={family}
              wrapperClass={"checkmark"}
              onChange={() => setFamily(!family)}
              label={"family friend"}
              type={"checkbox"}
            />
            <div className={"other"}>
              <CheckButton
                containerClass={"checkbox-container check-button"}
                checked={other}
                onChange={() => {
                  setOther(!other);
                  if (other) {
                    setOtherSpecify(undefined);
                  }
                }}
                wrapperClass={"checkmark"}
                label={"other"}
                type={"checkbox"}
              />
              <MDBInput
                containerClass="specify-input-container"
                className="specify-input-field"
                disabled={!other}
                labelClass="please-specify"
                label="please specify"
                group
                type="text"
                value={otherSpecify !== undefined ? otherSpecify : ""}
                onChange={setOtherSpecifyFn}
              />
            </div>
          </MDBBox>
        </div>
        <MDBBox className={"w-100 textarea"}>
          <InputField
            className={`custom-input ${shouldMarkError("message", errors, touched)
                ? "validation-error"
                : ""
              }`}
            label={`${shouldMarkError("message", errors, touched)
                ? "message is required*"
                : "message*"
              }`}
            type="textarea"
            id="exampleFormControlTextarea1"
            validate
            error="wrong"
            success="right"
            value={message !== undefined ? message : ""}
            onChange={setMessageFn}
          />
        </MDBBox>
        <MDBRow className="w-100 m-0">
          <MDBCol className="contact-required-block p-0">
            <div className="required-text-block">
              <span className="required-sign">*</span>
              <span className="required-text">= required</span>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBBox>
      <MDBBox className={"contact-submit-btn w-100"}>
        <Button
          className={"right-arrow orange-button"}
          direction={"ml-3"}
          righticon={"true"}
          disabled={loading}
          icon={loading ? "" : "arrow-right"}
          text={loading ? "Submiting..." : "Submit"}
          onClick={() => _handleSendEmail()}
        />
      </MDBBox>
    </>
  );
};

export default ContactInfo;
