import React from "react";
import { MDBBox } from "mdbreact";
import Button from "core/components/button";

const ShopLook = () => {
  return (
    <>
      <div className={"shop-look-container"}>
        <MDBBox display={"flex"} className={"box-wrapper"}>
          <div className={"shop-look-text"}>
            <h1>shop the look</h1>
            <p>Get inspired by some our favorite rooms!</p>
          </div>
          <div className={"shop-look-btn"}>
            <Button
              outline={true}
              className={"right-arrow white-button"}
              text={"view shop the look"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
              to={"/shop-the-look"}
            />
          </div>
        </MDBBox>
      </div>
    </>
  );
};
export default ShopLook;
