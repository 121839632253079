import React, { useState } from "react";
import CustomerQueryContainer from "./customer-query-container";

const CustomerStateContainer = props => {
  let currentLocation = props.location.pathname;
  let currentUrl = currentLocation.substring(1);
  const [selectedLink, setSelectedLink] = useState(currentUrl);

  return (
    <CustomerQueryContainer
      {...props}
      currentLocation={currentUrl}
      selectedLink={selectedLink}
      setSelectedLink={setSelectedLink}
    />
  );
};

export default CustomerStateContainer;
