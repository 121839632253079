import React from "react";
import CustomerSidebar from "./sidebar";
import CustomerPages from "./pages";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

const Customer = props => {
  return (
    <MDBContainer className="customer-account page-main-content mt-4">
      <MDBRow>
        <MDBCol lg="3">
          <CustomerSidebar {...props} />
        </MDBCol>
        <MDBCol lg="9">
          <CustomerPages {...props} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Customer;
