import React, { useEffect } from "react";
import ProductItem from "./productItem";
import { isUndefined } from "core/helpers/functions";
import { findById } from "core/helpers/category";
import { filterConfiguration } from "config/category/filters";

const WishlistShareListing = (props) => {
  let items = [];
  let _items = {};

  let { data, addFilteredData, addInitialCategoryData, reducePageData } = props;

  useEffect(() => {
    if (!isUndefined(data) && !isUndefined(data.products)) {
      let filters = {};
      Object.keys(filterConfiguration).map((key) => {
        let item = filterConfiguration[key];
        if (item.type === "range") {
          let values = item.values(data.products.items, item.attribute_code);
          return (filters[item.attribute_code] = {
            initialValues: item.values(
              data.products.items,
              item.attribute_code
            ),
            ...item,
          });
        }
        return (filters[item.attribute_code] = { ...item });
      });
      addInitialCategoryData({
        initialFilters: filters,
      });
      addFilteredData(data.products);

      reducePageData({
        totalCount: data.products.total_count,
        pageInfo: data.products.page_info,
      });
    } else {
      reducePageData({
        totalCount: data.products.total_count,
        pageInfo: data.products.page_info,
      });
    }
  }, [data, addFilteredData]);

  if (!isUndefined(props.data)) {
    _items = props.data;
  }

  {
    Object.keys(_items.products.items).map((elem, index) => {
      let product = _items.products.items[index];
      return items.push(
        <ProductItem
          key={`product-list-${product.id}`}
          productName={product.name}
          productNumber={product.sku}
          productItemStock={product.only_x_left_in_stock}
          productPriceRegularValue={
            product.price_range.minimum_price.final_price.value
          }
          productPriceRegular={product.price_range.minimum_price}
          productRibbon={product.show_ribbon}
          productImage={product.image}
          productUrl={product.url_key}
          productSmallImage={product.small_image}
          productSearchUrl={false}
          customer={props.customer}
        />
      );
    });
  }
  return (
    <>
      <div className="listing-content">{items}</div>
    </>
  );
};

export default WishlistShareListing;
