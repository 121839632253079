import React from "react";
import CartStateContainer from "./state-container";

const CartDataContainer = props => {
  const headers = [
    {
      title: "item",
      size: 2
    },
    {
      title: "",
      size: 4
    },
    {
      title: "price",
      size: 2
    },
    {
      title: "qty",
      size: 3
    },
    {
      title: "subtotal",
      size: 3
    }
  ];

  return <CartStateContainer {...props} headers={headers} />;
};

export default CartDataContainer;
