import React, { memo, useCallback, useState } from "react";
import { isUndefined } from "lodash";
import { MDBBox, MDBLink, MDBIcon } from "mdbreact";
import lodash from "lodash";
import { useMemo } from "react";
const SidebarItem = memo(
  ({ open, category }) => {
    const [toggle, setToggle] = useState(false);
    let hasChildren =
      !isUndefined(category.children) && category.children.length > 0
        ? true
        : false;

    const computeChildren = useCallback(
      (category, hasChildren) => {
        if (hasChildren) {
          return category.children.map(child => (
            <SidebarItem key={child.name + "-" + child.id} category={child} />
          ));
        } else {
          return <></>;
        }
      },
      [category, hasChildren]
    );

    const computedChildren = useMemo(
      () => computeChildren(category, hasChildren),
      [category, hasChildren, computeChildren]
    );
    if (category.include_in_menu === 0) {
      return <></>;
    }
    return (
      <div className={`sidebar-item`}>
        <MDBBox
          className={`${
            hasChildren ? "sidebar-item-toggle" : "sidebar-item-link"
          } ${open === category.id && toggle === false ? "open" : "closed"}`}
        >
          {hasChildren ? (
            <>
              <MDBLink
                to={category.url_path}
                onClick={e => {
                  if (window.location.pathname.includes(category.url_path)) {
                    e.preventDefault();
                    setToggle(!toggle);
                  }
                }}
              >
                {category.name}
                {open === category.id && toggle === false ? (
                  <MDBIcon className="filter-icon" icon="minus" />
                ) : (
                  <MDBIcon className="filter-icon" icon="plus" />
                )}
              </MDBLink>
            </>
          ) : (
            <MDBLink to={category.url_path}>{category.name}</MDBLink>
          )}
        </MDBBox>
        {hasChildren ? (
          <div
            className={`sidebar-item-children-container ${
              open === category.id && toggle === false ? "open" : "closed"
            }`}
          >
            {computedChildren}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default SidebarItem;
