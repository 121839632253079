export const determineState = (value, object) => {
  let regionObject;

  if (typeof object[value.value] !== "undefined") {
    regionObject = {
      region_code: object[value.value].code,
      region: "",
      region_id: object[value.value].value
    };
  } else {
    regionObject = {
      region_code: "",
      region: value,
      region_id: 0
    };
  }
  return regionObject;
};
