import React from "react";
import Wishlist from "./wishlist";

const WishlistDataContainer = props => {
  let { wishlist } = props;
  if (typeof wishlist === "undefined") {
    return <></>;
  }
  let wishlistArray = [
    { id: parseInt(wishlist.id), name: "main", items: wishlist.items },
    ...wishlist.multiwishlist_data
  ];

  return <Wishlist {...props} wishlistArray={wishlistArray} />;
};

export default WishlistDataContainer;
