import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import CheckoutShippingMethodStateContainer from "./state-container";

const CheckoutShippingMethodReduxContainer = (props) => {
  return <CheckoutShippingMethodStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  cart:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined"
      ? state.customer.data.cart
      : {},
  cart_grand_total:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.prices !== "undefined" &&
    typeof state.customer.data.cart.prices.grand_total !== "undefined" &&
    typeof state.customer.data.cart.prices.grand_total.value !== "undefined"
      ? state.customer.data.cart.prices.grand_total.value
      : {},
});

const mapDispatchToProps = (dispatch) => {
  return {
    setShippingMethodsOnCart: (shipping_methods) => {
      dispatch(customerActions.setShippingMethodsOnCart(shipping_methods));
    },
    setPONumber: (po_number) => {
      dispatch(customerActions.savePONumber(po_number));
    },
    setOrderNote: (order_comment) => {
      dispatch(customerActions.saveOrderNote(order_comment));
    },
    setShippingNote: (shipping_notes) => {
      dispatch(customerActions.saveShippingNote(shipping_notes));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutShippingMethodReduxContainer);
