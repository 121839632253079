import React, { useState } from "react";
import FilterItem from "./filterItem";
import FilterWrapper from "./filterItem/filterWrapper";
import { useMediaQuery } from "react-responsive";

const Filter = (props) => {
  let {
    filter,
    filters,
    addFilter,
    allFilters,
    removeFilter,
    resetPageNumber,
  } = props;
  const isTabOrMob = useMediaQuery({ maxWidth: 767 });
  const [toggle, setToggle] = useState(isTabOrMob ? false : true);

  const items = [];

  if (typeof filters !== "undefined") {
    return (
      <div
        className={`filter-container ${
          props.isMobile ? "filter-container-mobile" : ""
        }
          ${filter.frontend_type === "color" ? "color-filter-container" : ""}`}
      >
        {filters.map((currentFilterValue) => {
          let isActive =
            typeof allFilters.items !== "undefined" &&
            typeof allFilters.items[filter.attribute_code] !== "undefined"
              ? allFilters.items[filter.attribute_code].filter_value.includes(
                  currentFilterValue.value
                )
              : false;

          return (
            <FilterItem
              key={`filter-item-${currentFilterValue.label}-${currentFilterValue}`}
              categoryName={currentFilterValue.label}
              selectedCategory={true}
              openDropdown={true}
              option_id={filter.value}
              type={filter.type}
              frontend_type={filter.frontend_type}
              onClick={(e) => {
                if (!isActive) {
                  resetPageNumber();
                  addFilter({
                    [filter.attribute_code]: {
                      filter_value: currentFilterValue.value,
                      filter_label: currentFilterValue.label,
                      filter_type: filter.type,
                    },
                  });
                } else {
                  resetPageNumber();
                  removeFilter({
                    filter_code: filter.attribute_code,
                    filter_value: currentFilterValue.value,
                  });
                }
              }}
              isActive={isActive}
            />
          );
        })}
      </div>
    );
  }
  return (
    <div
      id={props.id}
      className={`filter-container rotate-${!toggle && "arrow"} col`}
    >
      <div
        className={`filter-inner-content ${!toggle && "hidden"} ${
          Object.keys(items).length >= 5 && "overflow-on"
        }`}
      >
        {props.mainWrapper && (
          <FilterWrapper wrapper={true}>{items && <>{items}</>}</FilterWrapper>
        )}
        {!props.mainWrapper && items && <>{items}</>}

        {props.children && props.children}
      </div>
    </div>
  );
};

export default Filter;
