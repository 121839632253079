import React, { useState } from "react";
import {
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBBox,
} from "mdbreact";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import CheckButton from "core/components/checkButton";
import Icon from "app/assets/icon/icon";
import themeConfiguration from "config/themeConfiguration";

const Usps = (props) => {
  const [shipModal, setShipModal] = useState(false);
  const [leadModal, setLeadModal] = useState(false);
  const [tearSheetModal, setTearSheetModal] = useState(false);
  const [tearIcon, setTearIcon] = useState(false);
  const [assemblyIcon, setAssemblyIcon] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState("hide-pricing");
  const [markupPrice, setMarkupPrice] = useState(0);
  const [customPrice, setCustomPrice] = useState(0);

  const setDescriptionFn = (e) => {
    setDescription(e.target.value);
  };
  const toggleShip = () => {
    setShipModal(!shipModal);
  };
  const toggleLead = () => {
    setLeadModal(!leadModal);
  };
  const toggleTearSheet = () => {
    setTearSheetModal(!tearSheetModal);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const generateDownloadLink = () => {
    let downloadLink =
      themeConfiguration.magento_url +
      `productpdf/download/file/1?id=${props.product.id}&price_select=wholesale`;
    if (props.customer.is_trade) {
      if (selectedOption === "hide-pricing")
        // price_select=hidde
        downloadLink =
          themeConfiguration.magento_url +
          `productpdf/download/file/1?id=${props.product.id}&price_select=hidde`;
      if (selectedOption === "display-wholesale-pricing")
        // price_select=wholesale
        downloadLink =
          themeConfiguration.magento_url +
          `productpdf/download/file/1?id=${props.product.id}&price_select=wholesale`;
      if (selectedOption === "markup-price")
        // price_select=mark_up&mark_up_value=12
        downloadLink =
          themeConfiguration.magento_url +
          `productpdf/download/file/1?id=${props.product.id}&price_select=mark_up&mark_up_value=${markupPrice}`;
      if (selectedOption === "custom-price")
        // price_select=custom&custom_price=123
        downloadLink =
          themeConfiguration.magento_url +
          `productpdf/download/file/1?id=${props.product.id}&price_select=custom&custom_price=${customPrice}`;
      if (description !== "") {
        downloadLink = downloadLink + `&notes=${description}`;
      }
    }
    window.open(downloadLink, "_blank");
  };

  return (
    <>
      <MDBCol
        onClick={() => toggleShip()}
        size="3"
        className="d-flex p-0 flex-lg-row flex-column justify-items-center align-items-center usp-item"
      >
        <Icon icon={"shipMethodGray"} />
        <span>
          ship
          <br />
          method
        </span>
      </MDBCol>
      <MDBCol
        onClick={() => toggleLead()}
        size="3"
        className="d-flex p-0 flex-lg-row flex-column justify-items-center align-items-center usp-item"
      >
        <Icon icon={"leadTimeGray"} />
        <span>
          lead
          <br />
          time
        </span>
      </MDBCol>
      <MDBCol
        onMouseEnter={() => setTearIcon(true)}
        onMouseLeave={() => setTearIcon(false)}
        onClick={() => {
          if (props.customer.is_trade) {
            // toggleTearSheet();
            generateDownloadLink();
          } else {
            generateDownloadLink();
          }
        }}
        size="3"
        className={`d-flex p-0 flex-lg-row flex-column justify-items-center align-items-center usp-item hover-tear-${tearIcon}`}
      >
        {(tearIcon && <Icon className="orange-icon" id={"downloadIcon"} />) || (
          <Icon icon={"tearSheetGray"} />
        )}
        <span>
          tear
          <br />
          sheet
        </span>
      </MDBCol>
      {props.product.assembly_informations !== null ? (
        <MDBCol
          onMouseEnter={() => setAssemblyIcon(true)}
          onMouseLeave={() => setAssemblyIcon(false)}
          size="3"
          className={`d-flex p-0 flex-lg-row flex-column justify-items-center align-items-center usp-item hover-assembly-${assemblyIcon}`}
        >
          {(assemblyIcon && (
            <Icon className="orange-icon" id={"downloadIcon"} />
          )) || <Icon icon={"assemblyPdf"} />}
          <span>
            <a
              href={
                props.product.assembly_informations !== null
                  ? `
          ${
            themeConfiguration.magento_url.substring(
              0,
              themeConfiguration.magento_url.length - 1
            ) +
            "/pub/media/catalog/product/assembly/instructions/" +
            props.product.assembly_informations
          }
        `
                  : `#`
              }
              style={{ display: "inline-block" }}
              download={props.name}
              target={
                props.product.assembly_informations !== null ? `_blank` : ``
              }
            >
              assembly
              <br />
              instructions
            </a>
          </span>
        </MDBCol>
      ) : (
        <MDBCol
          size="3"
          className={`d-flex p-0 flex-lg-row flex-column justify-items-center align-items-center usp-item hover-assembly-${assemblyIcon}`}
        ></MDBCol>
      )}
      {shipModal && (
        <MDBModal
          isOpen={shipModal}
          toggle={toggleShip}
          className={"modal-wrapper usps-modal"}
          centered
        >
          <MDBModalHeader onClick={() => toggleShip()}>
            <div className="close-btn">
              <button type="button">
                <i className="close-icon"></i>
              </button>
            </div>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBBox tag="h2" className="text-center">
              {props.data.cmsBlocks.items[0].title.split("|")[0]}
            </MDBBox>
            <div
              dangerouslySetInnerHTML={{
                __html: props.data.cmsBlocks.items[0].content,
              }}
            />
            <div className={"continue-shopping-wrapper"}>
              <Button
                outline={true}
                className={"right-arrow white-button"}
                children={"continue shopping"}
                direction={"ml-3"}
                righticon={"true"}
                icon={"arrow-right"}
                onClick={() => toggleShip()}
              />
            </div>
          </MDBModalBody>
        </MDBModal>
      )}
      {leadModal && (
        <MDBModal
          isOpen={leadModal}
          toggle={toggleLead}
          className={"modal-wrapper usps-modal"}
          centered
        >
          <MDBModalHeader onClick={() => toggleLead()}>
            <div className="close-btn">
              <button type="button">
                <i className="close-icon"></i>
              </button>
            </div>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBBox tag="h2" className="text-center">
              {props.data.cmsBlocks.items[1].title.split("|")[0]}
            </MDBBox>
            <div
              dangerouslySetInnerHTML={{
                __html: props.data.cmsBlocks.items[1].content,
              }}
            />
            <div className={"continue-shopping-wrapper"}>
              <Button
                outline={true}
                className={"right-arrow white-button"}
                children={"continue shopping"}
                direction={"ml-3"}
                righticon={"true"}
                icon={"arrow-right"}
                onClick={() => toggleLead()}
              />
            </div>
          </MDBModalBody>
        </MDBModal>
      )}
      {tearSheetModal && (
        <MDBModal
          isOpen={tearSheetModal}
          toggle={toggleTearSheet}
          className={"modal-wrapper usps-modal tearsheet-modal"}
          centered
        >
          <MDBModalHeader onClick={() => toggleTearSheet()}>
            <div className="close-btn">
              <button type="button">
                <i className="close-icon"></i>
              </button>
            </div>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBBox tag="h2" className="text-center">
              download tear sheet
            </MDBBox>
            <MDBBox tag="h4">select an option below</MDBBox>
            <MDBBox className="modal-radio-buttons">
              <MDBBox className="radio-content">
                <CheckButton
                  containerClass={"radio-container check-button"}
                  label={"Hide pricing"}
                  type={"radio"}
                  value={"no"}
                  checked={selectedOption === "hide-pricing"}
                  onChange={(e) => handleOptionChange("hide-pricing")}
                  wrapperClass={"radio"}
                />
              </MDBBox>
              <MDBBox className="radio-content">
                <CheckButton
                  containerClass={"radio-container check-button"}
                  label={"Display wholesale pricing"}
                  type={"radio"}
                  value={"no"}
                  checked={selectedOption === "display-wholesale-pricing"}
                  onChange={(e) =>
                    handleOptionChange("display-wholesale-pricing")
                  }
                  wrapperClass={"radio"}
                />
              </MDBBox>
              <MDBBox className="radio-with-input first">
                <CheckButton
                  containerClass={"radio-container check-button"}
                  label={`Mark up price`}
                  type={"radio"}
                  value={"no"}
                  checked={selectedOption === "markup-price"}
                  onChange={(e) => handleOptionChange("markup-price")}
                  wrapperClass={"radio"}
                />
                <InputField
                  disabled={selectedOption !== "markup-price" ? true : false}
                  type="number"
                  validate
                  error="wrong"
                  success="right"
                  value={markupPrice !== undefined ? markupPrice : 0}
                  onChange={(e) => setMarkupPrice(e.target.value)}
                />
                <span>times</span>
              </MDBBox>
              <MDBBox className="radio-with-input second">
                <CheckButton
                  containerClass={"radio-container check-button"}
                  label={"Custom price $"}
                  type={"radio"}
                  value={"no"}
                  checked={selectedOption === "custom-price"}
                  onChange={(e) => handleOptionChange("custom-price")}
                  wrapperClass={"radio"}
                />
                <InputField
                  disabled={selectedOption !== "custom-price" ? true : false}
                  type="number"
                  validate
                  error="wrong"
                  success="right"
                  label="price"
                  value={customPrice !== undefined ? customPrice : ""}
                  onChange={(e) => setCustomPrice(e.target.value)}
                />
              </MDBBox>
            </MDBBox>
            <MDBBox className="modal-textarea">
              <InputField
                className={`custom-input`}
                label={`enter your notes here`}
                group
                type="textarea"
                validate
                error="wrong"
                success="right"
                labelClass="text-area-label modal-text-label"
                value={description !== undefined ? description : ""}
                onChange={setDescriptionFn}
              />
            </MDBBox>
            <MDBBox className="modal-buttons">
              <Button
                outline={true}
                className={"right-arrow white-button"}
                righticon={"true"}
                icon={"arrow-right"}
                onClick={() => toggleTearSheet()}
                text={"cancel"}
              />
              <Button
                className={"right-arrow orange-button "}
                direction={"ml-3"}
                righticon={"true"}
                icon={"arrow-right"}
                onClick={(e) => generateDownloadLink(e)}
                text={"download"}
              />
            </MDBBox>
          </MDBModalBody>
        </MDBModal>
      )}
    </>
  );
};

export default Usps;
