import React from "react";
import ViewDataContainer from "./data-container";
import gql from "graphql-tag";
import DynamicGraphQlWrapper from "core/components/dynamicGraphQlWrapper";
import { useQuery } from "react-apollo-hooks";
import ReactLoader from "app/layout/loader";

const CUSTOMER_ORDER = gql`
  query CustomerOrders {
    customerOrders {
      items {
        order_number
        created_at
        grand_total
        status
        shippingPrice
        shippingMethod
        subtotal
        payment {
          method_title
          accountType
          ccLast4
        }
        productInformation {
          product_name
          quantity
          row_total
          sku
          unit_price
          url
        }
        addressInformation {
          billingAddress {
            city
            company
            country_code
            firstname
            lastname
            postcode
            street
            telephone
            region {
              region
              region_code
            }
          }
          shippingAddress {
            city
            company
            country_code
            firstname
            lastname
            postcode
            street
            telephone
            region {
              region
              region_code
            }
          }
        }
      }
    }
  }
`;
const ViewQueryContainer = (props) => {
  let { orderId } = props;
  const { data, loading, error } = useQuery(CUSTOMER_ORDER);

  if (loading) {
    return <ReactLoader />;
  }
  if (data) {
    return <ViewDataContainer data={data} orderId={orderId} />;
  }
};

export default ViewQueryContainer;
