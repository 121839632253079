import React, { useState } from "react";
import FaqTabs from "./tabs";
import Accordion from "./accordion";

const FaqItemStateContainer = (props) => {
  let { tabs, tabsData } = props;
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);

  const setActiveHandler = (tab) => {
    setActive(tab);
  };

  return (
    <>
      <FaqTabs
        active={active}
        setActiveHandler={setActiveHandler}
        tabs={tabs}
      />
      <Accordion
        active={active}
        tabsData={tabsData}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default FaqItemStateContainer;
