import React, { useState } from "react";
import Create from "./create";
const CreateStateContainer = props => {
  const [project, setProject] = useState("");
  const [loading, setLoading] = useState(false);
  return (
    <Create
      //getter
      activeWishlist={props.activeWishlist}
      project={project}
      loading={loading}
      wishlist={props.wishlist}
      //setter
      setActiveWishlist={props.setActiveWishlist}
      setProject={setProject}
      setLoading={setLoading}
      //redux
      lock={props.lock}
      createProject={props.createProject}
      deleteProject={props.deleteProject}
      addMessage={props.addMessage}
      //misc
      wrapperClass={props.wrapperClass}
      target={props.target}
      text={props.text}
      loadingtext={props.loadingtext}
    />
  );
};

export default CreateStateContainer;
