import React, { memo } from "react";
import { MDBBox } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs/breadcrumbs";
import banner from "app/assets/images/shopTheLook/banner.png";
import HeroImage from "app/layout/heroImage/heroImage";
// import Tabs from "../../../layout/tabs";
// import { shopTheLookTabs } from "./data";
import SeoHelmet from "app/layout/seoHelmet";
import ShopTheLookQueryContainer from "./shopTheLook-query-container";
import _ from "lodash";

const ShopTheLook = memo(
  (props) => {
    return (
      <div className="shop-the-look-page">
        <HeroImage id={"shop-the-look-img"} alt="Shop The Look Banner" />
        <SeoHelmet
          url={"/shop-the-look"}
          title={"shop the look"}
          image={banner}
        />
        <div className="shop-the-look-content">
          <div className="shop-the-look-top">
            <MDBBox tag="h3" className="w-100 mb-4 mt-5 text-center">
              shop the look
            </MDBBox>
          </div>
          <div className="shop-the-look-inner-content">
            <ShopTheLookQueryContainer />
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (_.isUndefined(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ShopTheLook;
