import React, { useState } from "react";
import CheckoutProcess from "./checkoutProcess";

const CheckoutProcessStateContainer = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <CheckoutProcess {...props} loading={loading} setLoading={setLoading} />
  );
};

export default CheckoutProcessStateContainer;
