import React, { useState } from "react";
import CheckoutLogin from "./checkoutLogin";

const CheckoutLoginStateContainer = (props) => {
  let {
    setGuestEmailOnCart,
    lock,
    login,
    customer,
    cart_email,
    setLoggingIn,
    addMessage,
  } = props;
  const [showLogin, setShowLogin] = useState(false);
  const [email, setEmail] = useState(cart_email);
  const [password, setPassword] = useState("");
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);

  return (
    <CheckoutLogin
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      showLogin={showLogin}
      setShowLogin={setShowLogin}
      touched={touched}
      setTouched={setTouched}
      setGuestEmailOnCart={setGuestEmailOnCart}
      loading={loading}
      setLoading={setLoading}
      lock={lock}
      login={login}
      customer={customer}
      setLoggingIn={setLoggingIn}
      addMessage={addMessage}
    />
  );
};

export default CheckoutLoginStateContainer;
