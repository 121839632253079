import React from "react";
import Edit from "./edit";
import { useLocation, Redirect } from "react-router-dom";
import { isUndefined } from "core/helpers/functions";

const EditStateContainer = (props) => {
  let pathname = useLocation().pathname;
  let path = pathname.split("/");
  let addressId = path[path.length - 1];
  let address = props.customer.addresses.find((address) => {
    return address.id === parseInt(addressId);
  });

  if (isUndefined(address)) {
    return <Redirect to={"/customer"} />;
  }
  return <Edit {...props} address={address} />;
};

export default EditStateContainer;
