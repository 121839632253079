import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MDBRow, MDBCol } from "mdbreact";
import Toolbar from "./toolbar";
import WishlistShareListing from "./wishlistShareListing";
import Sidebar from "./sidebar";

import WishlistShareFilterReduxContainer from "./wishlistShareFilters-redux-container";

const WishlistShareComponentContainer = (props) => {
  let { allCategories, categoryFilter } = props;
  const [showFilters, setShowFilters] = useState(false);
  const isTabOrMob = useMediaQuery({ maxWidth: 992 });
  const [toggle, setToggle] = useState(true);
  const toggleDropdown = (props) => {
    let currentToggle = toggle;
    setToggle(!currentToggle);
  };
  return (
    <>
      <>
        <MDBCol lg="3" className="sidebarcol">
          <Sidebar {...props} />
        </MDBCol>
        {isTabOrMob && <></>}
        <MDBCol lg="9" className="listing-with-toolbar">
          <Toolbar
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            toggle={toggle}
            toggleDropdown={toggleDropdown}
          />
          <MDBRow>
            <MDBCol md="12" className="listing-wrapper">
              <WishlistShareFilterReduxContainer {...props} />
            </MDBCol>
          </MDBRow>
          <Toolbar
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            toggle={toggle}
            toggleDropdown={toggleDropdown}
            paginationOnly={true}
          />
        </MDBCol>
      </>
    </>
  );
};

export default WishlistShareComponentContainer;
