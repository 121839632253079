import React from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import { commonActions } from "core/state/redux/data/common";
import CustomerInformationFormStateContainer from "./information-state-container";
import { messagesActions } from "core/state/redux/data/messages";

const CustomerLoginFormReduxContainer = (props) => {
  return <CustomerInformationFormStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  loading: state.customer.loading,
  locked: state.common.locked,
});

const mapDispatchToProps = (dispatch) => {
  return {
    login: (request) => {
      dispatch(customerActions.loginCustomer(request.email, request.password));
    },
    onSubmit: (request) => {
      dispatch(customerActions.updateCustomerInformation(request));
    },

    onSubmitCallback: () => {},
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLoginFormReduxContainer);
