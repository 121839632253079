import React, { memo, useMemo, useCallback } from "react";
import { MDBBox } from "mdbreact";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import Slide from "./slide";
import themeConfiguration from "config/themeConfiguration";
import PlaceholderImage from "app/assets/slider/homepage-blur.jpg";
import _ from "lodash";

const HomepageSlider = memo(
  (props) => {
    let { data } = props;

    var settings = {
      lazyLoad: "ondemand",
      dots: true,
      infinite: false,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:
        // typeof data !== "undefined" && typeof data.slider !== "undefined"
        //   ? data.slider.autoplay
        //   : true,
        false,
      autoplaySpeed:
        typeof data !== "undefined" && typeof data.slider !== "undefined"
          ? parseInt(data.slider.slide_animation)
          : 1000,
      vertical: true,
      verticalSwiping: true,
      customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
      appendDots: (dots) => (
        <div
          className={"test"}
          style={{
            padding: "15px",
            textAlign: "center",
          }}
        >
          <ul className={"main-dots"} style={{ margin: "0px" }}>
            {dots}
          </ul>
        </div>
      ),
      responsive: [
        {
          breakpoint: 480,
          settings: {
            vertical: false,
            verticalSwiping: false,
            dots: true,
            infinite: true,
            speed: 1000,
            cssEase: "linear",
            className: "slick-vertical",
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            autoplay:
              typeof data !== "undefined" && typeof data.slider !== "undefined"
                ? data.slider.autoplay
                : true,
            autoplaySpeed:
              typeof data !== "undefined" && typeof data.slider !== "undefined"
                ? parseInt(data.slider.slide_animation)
                : 1000,
          },
        },
      ],
    };

    const getSliders = useCallback((data) => {
      let items = [];
      let counter = 0;
      if (
        typeof data !== "undefined" &&
        typeof data.slider !== "undefined" &&
        data.slider.slides !== "undefined"
      ) {
        items = data.slider.slides.map((value, index) => {
          let slide = data.slider.slides[index];
          return (
            <Slide
              key={`slider-` + index}
              {...slide}
              image={themeConfiguration.magento_url + slide.media}
              mobileImage={themeConfiguration.magento_url + slide.mobile_media}
              //placeholderimage={PlaceholderImage}
              imgClass={`w-100 img-class`}
              className={`w-100 img-wrapper`}
            />
          );
        });
      } else {
        items.push(
          <Slide
            key={`slider-placeholder-some-key`}
            //image={PlaceholderImage}
            //mobileImage={PlaceholderImage}
            imgClass={`w-100 img-class`}
            className={`w-100 img-wrapper`}
          />
        );
      }

      return items;
    }, []);
    const computeContent = useMemo(() => getSliders(data), [data, getSliders]);
    if (
      computeContent.length < 2 &&
      typeof data !== "undefined" &&
      typeof data.slider !== "undefined" &&
      data.slider.slides !== "undefined"
    ) {
      return (
        <Slide
          key={`slider-0`}
          {...data.slider.slides[0]}
          image={computeContent[0].props.image}
          mobileImage={computeContent[0].props.mobileImage}
          //placeholderimage={PlaceholderImage}
          imgClass={`w-100 img-class`}
          className={`w-100 img-wrapper  main-slider  slider-one-child`}
        />
      );
    } else {
      return (
        <MDBBox className={"main-slider"}>
          <Slider {...settings} className={`w-100`}>
            {computeContent}
          </Slider>
        </MDBBox>
      );
    }
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default HomepageSlider;
