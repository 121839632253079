import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBBox,
  MDBTypography,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import CheckButton from "core/components/checkButton";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import CountrySelect from "core/components/countrySelect";
import Select2 from "core/components/select";
import { validate, shouldMarkError } from "./validation";
import MaskedInput from "react-text-mask";
import { Alert } from "reactstrap";
import gql from "graphql-tag";

const IS_EMAIL_SUBSCRIBED = gql`
  query isEmailSubscribed($email: String!) {
    isEmailSubscribed(email: $email) {
      is_subscribed
    }
  }
`;

const Subscribe = (props) => {
  let { state } = props;

  let [isChecked, setIsChecked] = useState(false);
  let [termsAccepted, setTermsAccepted] = useState("");
  const [zipCodeError, setZipCodeError] = useState(false);

  const [zipMask, setZipMask] = useState([/\d/, /\d/, /\d/, /\d/, /\d/]);
  useEffect(() => {
    if (state.countryValue.value === "CA")
      setZipMask([/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]);
    else setZipMask([/\d/, /\d/, /\d/, /\d/, /\d/]);
  }, [state.countryValue.value]);

  const toggle = () => {
    setTouched({
      email: false,
      firstname: false,
      lastname: false,
      companyname: false,
      zipcode: false,
      stateValue: false,
    });
    state.setEmail("");
    state.setFirstName("");
    state.setLastName("");
    state.setCompany("");
    state.setZipcode("");
    state.setCountryValue({
      label: "United States",
      value: "US",
    });
    state.setClient({
      label: ["Client Type"],
      value: ["1"],
    });
    state.setDescribe({
      label: "what best describes you?",
      value: "0",
    });
    state.setSubscribe(!state.subscribe);
  };
  const toggle2 = () => {
    setTouched({
      email: false,
      firstname: false,
      lastname: false,
      companyname: false,
      zipcode: false,
      stateValue: false,
    });
    state.setEmail("");
    state.setFirstName("");
    state.setLastName("");
    state.setCompany("");
    state.setZipcode("");
    state.setCountryValue({
      label: "United States",
      value: "US",
    });
    state.setClient({
      label: ["Client Type"],
      value: ["1"],
    });
    state.setDescribe({
      label: "what best describes you?",
      value: "0",
    });
    state.setSubscribe(false);
    state.setSubscribe2(!state.subscribe2);
  };
  function handleChange(e) {
    let value = "";
    state.setDescribe(e);
  }
  const [touched, setTouched] = useState({
    email: false,
    firstname: false,
    lastname: false,
    companyname: false,
    zipcode: false,
    stateValue: false,
  });

  let errors = validate(state.email, "-", "-", "-", "-");

  let errorsFormTwo = validate(
    state.email,
    state.firstname,
    state.lastname,
    state.company,
    state.zipcode
  );

  let isEmailSubscribed = false;
  const [loading, setLoading] = useState(false);
  const _handleSubmit = async (number) => {
    setLoading(true);
    isEmailSubscribed = await props.isEmailSubscribed();
    setLoading(false);

    if (number === 2) {
      setZipCodeError(zipMask.length !== state.zipcode.length);
    }

    let isTrue = true;
    setTouched({ email: true });
    if (number === 1) {
      Object.keys(errors).map((item) => {
        let error = errors[item];
        if (isTrue) {
          if (error === true) {
            isTrue = false;
          }
        }
      });
    } else {
      setTouched({
        email: true,
        firstname: true,
        lastname: true,
        companyname: true,
        zipcode: true,
        stateValue: true,
      });
      Object.keys(errorsFormTwo).map((item) => {
        let error = errorsFormTwo[item];
        if (
          item === "zipcode" &&
          !(
            state.countryValue.value === "US" ||
            state.countryValue.value === "CA"
          )
        ) {
          error = false;
        }
        if (item === "zipcode" && zipMask.length !== state.zipcode.length) {
          error = true;
        }
        if (isTrue) {
          if (error === true) {
            isTrue = false;
          }
        }
      });
    }
    if (!isEmailSubscribed) {
      if (isTrue) {
        if (number === 1) {
          props.submitEmail(number);
          setTimeout(() => {
            state.setSubscribe(false);
          }, 1000);
        } else if (
          state.countryValue.value === "US" ||
          state.countryValue.value === "CA"
        ) {
          if (state.stateValue !== "" && state.stateValue !== null) {
            if (isChecked) {
              props.submitEmail(number);
              setTimeout(() => {
                state.setSubscribe2(false);
              }, 1000);
            } else {
              setTermsAccepted("accept privacy policy");
            }
          }
        } else {
          if (isChecked) {
            props.submitEmail(number);
            setTimeout(() => {
              state.setSubscribe2(false);
            }, 1000);
          } else {
            setTermsAccepted("accept privacy policy");
          }
        }
      }
    } else {
      props.addMessage(
        "customer with same email address already subscribed",
        "danger"
      );
    }
  };

  return (
    <div className="text-center  subscribe">
      <div className="subscribe-text">
        <div>
          <MDBBox tag="h5">Subscribe to our newsletter</MDBBox>
          {/* <MDBBox tag="p">
            Subscribe to get lastest offers, news and events announcements.
          </MDBBox> */}
          <MDBContainer className="pl-0 pr-0">
            <Button
              onClick={() => toggle()}
              color={"primary"}
              className={"right-arrow orange-button subscribe-btn "}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
              text={"Subscribe"}
            />
            {state.subscribe && (
              <MDBModal
                isOpen={state.subscribe}
                toggle={toggle}
                className={"modal-wrapper footer-newsletter"}
                centered
              >
                <MDBModalHeader onClick={() => toggle()}>
                  <div className="close-btn">
                    <button type="button">
                      <i className="close-icon"></i>
                    </button>
                  </div>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBBox tag="h2" className="text-center">
                    stay in the know!
                  </MDBBox>
                  <MDBBox tag="span">
                    we know, email lists get a bad rap. but we promise - you’ll
                    love being on ours!
                  </MDBBox>
                  <div className={"join-wrapper"}>
                    <InputField
                      className={`custom-input form-control ${
                        shouldMarkError("email", errors, touched)
                          ? "validation-error"
                          : ""
                      }`}
                      label={`${
                        shouldMarkError("email", errors, touched)
                          ? "email is required*"
                          : "enter your email address*"
                      }`}
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      onChange={(e) => {
                        state.setEmail(e.target.value);
                      }}
                      value={state.email}
                    />
                    <Button
                      text={loading ? "verifying" : "join"}
                      disabled={loading}
                      color={"primary"}
                      className={"right-arrow orange-button"}
                      direction={"ml-3"}
                      righticon={"true"}
                      icon={loading ? "" : "arrow-right"}
                      onClick={() => _handleSubmit(1)}
                    />
                  </div>
                  <MDBBox className={"text-center"}>
                    <p>Are you a trade member?</p>
                    <p>
                      <button className={"sign-in-modal-btn"} onClick={toggle2}>
                        sign up here
                      </button>
                      for updates.
                    </p>
                  </MDBBox>
                </MDBModalBody>
              </MDBModal>
            )}

            {/* SECOND MODAL INSIDE NEWSLETTER MODAL */}
            <MDBBox>
              {state.subscribe2 && (
                <MDBModal
                  data-backdrop="static"
                  centered
                  toggle={toggle2}
                  isOpen={state.subscribe2}
                  onClick={state.subscribe2}
                  className={"modal-wrapper footer-newsletter second-modal"}
                >
                  <MDBModalHeader onClick={toggle2}>
                    <div className="close-btn">
                      <button type="button">
                        <i className="close-icon"></i>
                      </button>
                    </div>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <MDBBox tag="h2" className="text-center">
                      sign up for updates!
                    </MDBBox>
                    <MDBBox tag="span" className="text-center">
                      stay connected with all things ducduc, please complete the
                      form below
                    </MDBBox>
                    <MDBBox className={"input-fields"}>
                      <MDBBox className={"input-column one"}>
                        <InputField
                          className={`custom-input form-control ${
                            shouldMarkError("firstname", errorsFormTwo, touched)
                              ? "validation-error"
                              : ""
                          }`}
                          label={`${
                            shouldMarkError("firstname", errorsFormTwo, touched)
                              ? "first name is required*"
                              : "first name*"
                          }`}
                          group
                          type="text"
                          validate
                          error="wrong"
                          success="right"
                          value={state.firstName}
                          onChange={(e) => {
                            state.setFirstName(e.target.value);
                          }}
                        />
                        <InputField
                          className={`custom-input form-control ${
                            shouldMarkError("lastname", errorsFormTwo, touched)
                              ? "validation-error"
                              : ""
                          }`}
                          label={`${
                            shouldMarkError("lastname", errorsFormTwo, touched)
                              ? "last name is required*"
                              : "last name*"
                          }`}
                          group
                          type="email"
                          validate
                          error="wrong"
                          success="right"
                          value={state.lastname}
                          onChange={(e) => {
                            state.setLastName(e.target.value);
                          }}
                        />
                        <InputField
                          className={`custom-input form-control ${
                            shouldMarkError("email", errorsFormTwo, touched)
                              ? "validation-error"
                              : ""
                          }`}
                          label={`${
                            shouldMarkError("email", errorsFormTwo, touched)
                              ? "email is required*"
                              : "email address*"
                          }`}
                          group
                          type="email"
                          validate
                          error="wrong"
                          success="right"
                          value={state.email}
                          onChange={(e) => {
                            state.setEmail(e.target.value);
                          }}
                        />
                        <InputField
                          className={`custom-input form-control ${
                            shouldMarkError(
                              "companyname",
                              errorsFormTwo,
                              touched
                            )
                              ? "validation-error"
                              : ""
                          }`}
                          label={`${
                            shouldMarkError(
                              "companyname",
                              errorsFormTwo,
                              touched
                            )
                              ? "company name is required*"
                              : "company name*"
                          }`}
                          group
                          type="text"
                          validate
                          error="wrong"
                          success="right"
                          value={state.company}
                          onChange={(e) => {
                            state.setCompany(e.target.value);
                          }}
                        />
                      </MDBBox>

                      <MDBBox className={"input-column two"}>
                        <MDBBox className="select-div-container modal-select">
                          <Select2
                            className="browser-default custom-Select2"
                            onChange={(e) => handleChange(e)}
                            value={state.describe}
                            options={[
                              { label: "retail", value: "1" },
                              { label: "designer/design firm", value: "2" },
                            ]}
                            openIndicator="caret-up"
                            closedIndicator="caret-down"
                            useCustom={true}
                          />
                        </MDBBox>

                        <MDBBox className={"modal-country-select modal-select"}>
                          <CountrySelect
                            isSearchable={true}
                            setStateValue={state.setStateValue}
                            stateValue={state.stateValue}
                            statePlaceholder={
                              state.countryValue.value === "US" ||
                              state.countryValue.value === "CA"
                                ? state.countryValue.value === "US"
                                  ? "state*"
                                  : state.countryValue.value === "CA"
                                  ? "province*"
                                  : "province"
                                : "province"
                            }
                            stateLabel={"region"}
                            countryValue={state.countryValue}
                            setCountryValue={state.setCountryValue}
                            stateWrapperClass={
                              (state.stateValue === "" ||
                                state.stateValue === null) &&
                              touched.stateValue
                                ? "main-select dark validation-error"
                                : "main-select dark"
                            }
                          />
                        </MDBBox>

                        <MDBBox className="md-form form-group default-input-field-container">
                          <MaskedInput
                            id="mask-input"
                            placeholder={
                              shouldMarkError("zipcode", errorsFormTwo, touched)
                                ? state.countryValue.value === "US" ||
                                  state.countryValue.value === "CA"
                                  ? state.countryValue.value === "US" &&
                                    state.zipcode === ""
                                    ? "zip code is required*"
                                    : state.countryValue.value === "CA" &&
                                      state.zipcode === "" &&
                                      "postal code is required*"
                                  : state.countryValue.value === "US"
                                  ? "zip code*"
                                  : state.countryValue.value === "CA"
                                  ? "postal code*"
                                  : "postal code"
                                : state.countryValue.value === "US"
                                ? "zip code*"
                                : state.countryValue.value === "CA"
                                ? "postal code*"
                                : "postal code"
                            }
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={
                              state.zipcode !== undefined ? state.zipcode : ""
                            }
                            mask={zipMask}
                            guide={false}
                            onChange={(e) => {
                              setZipCodeError(false);
                              state.setZipcode(e.target.value);
                            }}
                            className={`
                          custom-input form-control zip-code
                    ${
                      shouldMarkError("zipcode", errorsFormTwo, touched) ||
                      zipCodeError
                        ? state.countryValue.value === "US" ||
                          state.countryValue.value === "CA"
                          ? "validation-error"
                          : ""
                        : ""
                    }`}
                            onBlur={(e) => {
                              setTouched({ ...touched, zipcode: true });
                            }}
                          />
                        </MDBBox>
                      </MDBBox>
                    </MDBBox>
                    <p>
                      We will be using your contact information for you to
                      receive updates straight to your inbox related to your
                      account and purchases along with news about our latest
                      products, promotions, and special events. You make
                      unsubscribe at any time.
                    </p>
                    {termsAccepted !== "" && !isChecked && (
                      <Alert
                        color="danger"
                        isOpen={true}
                        toggle={() => setTermsAccepted("")}
                      >
                        {termsAccepted}
                      </Alert>
                    )}
                    <MDBBox className={"custom-modal-btn"}>
                      <CheckButton
                        containerClass={"switch-container check-button"}
                        wrapperClass={"slider"}
                        label={`Yes, I accept the Privacy Policy of ducduc`}
                        value="Yes, I accept the Privacy Policy of ducduc"
                        type="checkbox"
                        id="customSwitches"
                        defaultChecked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                      <div className="req-block">
                        <div className="text-right required-text-block">
                          <span className="required-sign">*</span>
                          <span className="required-text">= required</span>
                        </div>
                      </div>
                    </MDBBox>
                    <MDBBox className={"subscribe-btn-modal"}>
                      <Button
                        text={loading ? "verifying" : "subscribe"}
                        color={"primary"}
                        className={
                          "right-arrow orange-button btn-modal-content"
                        }
                        disabled={loading}
                        direction={"ml-3"}
                        to={"#"}
                        righticon={"true"}
                        icon={loading ? "" : "arrow-right"}
                        onClick={() => _handleSubmit(2)}
                      />
                    </MDBBox>
                  </MDBModalBody>
                </MDBModal>
              )}
            </MDBBox>
            {/* END SECOND MODAL */}
          </MDBContainer>
        </div>
      </div>
      <div className="subscribe-icons">
        <div>
          <MDBTypography listInLine>
            <li className="list-inline-item">
              <a target="_blank" href="https://www.facebook.com/ducducnyc/">
                <MDBIcon fab icon="facebook-f" />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                target="_blank"
                href="https://www.instagram.com/ducducnyc/?hl=en"
              >
                <MDBIcon fab icon="instagram" />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCe1BRhEb-40lJ8fZoK0UFMg"
              >
                <MDBIcon fab icon="youtube" />
              </a>
            </li>
            {/* <li className="list-inline-item">
              <a target="_blank" href="https://www.pinterest.com/ducduc/">
                <MDBIcon fab icon="pinterest" />
              </a>
            </li> */}
            {/* <li className="list-inline-item">
              <a target="_blank" href="https://twitter.com/ducduc_nyc">
                <MDBIcon fab icon="twitter" />
              </a>
            </li> */}
          </MDBTypography>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
