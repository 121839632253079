import React from "react";
import Select2 from "core/components/select";
import { isEmpty } from "core/helpers/functions";
import InputField from "core/components/inputField";

const CountrySelect = props => {
  let {
    isSearchable,
    countriesData,
    loader,
    setCountryValueHandler,
    countryValue,
    countryRequired,
    countryWrapperClass,
    countryPlaceholder,
    countryName,
    regionsData,

    setStateValueHandler,
    stateValue,
    stateRequired,
    stateWrapperClass,
    statePlaceholder,
    stateName,
    stateLabel
  } = props;
  return (
    <>
      <Select2
        disabled={loader}
        name={countryName}
        type={"text"}
        value={countryValue}
        onChange={e => setCountryValueHandler(e)}
        required={countryRequired}
        wrapperClass={countryWrapperClass}
        placeholder={countryPlaceholder}
        options={countriesData}
        useCustom={true}
        isSearchable={isSearchable}
      />
      {!isEmpty(regionsData) ? (
        <Select2
          disabled={loader}
          name={stateName}
          type={"text"}
          required={stateRequired}
          value={stateValue}
          onChange={event => setStateValueHandler(event)}
          wrapperClass={stateWrapperClass}
          placeholder={statePlaceholder}
          options={regionsData}
          isSearchable={isSearchable}
        />
      ) : (
        <InputField
          name={stateName}
          type={"text"}
          wrapperClass={stateWrapperClass}
          required={stateRequired}
          value={stateValue}
          onChange={event => setStateValueHandler(event.target.value)}
          label={stateLabel}
        />
      )}
    </>
  );
};

export default CountrySelect;
