/*global google*/
import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { compose, withProps, withHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import Button from "core/components/button";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";
import Select2 from "core/components/select/select";
import InputField from "core/components/inputField/inputField";
import Icon from "app/assets/icon/icon";

//import { isEmpty } from "core/helpers/functions";
import Breadcrumbs from "app/layout/breadcrumbs";
// import { Circle } from "react-google-maps";
import lodash from "lodash";
import { isEmpty } from "core/helpers/functions";
import SeoHelmet from "app/layout/seoHelmet";

const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCIKajdxnw25suNPzUQIVQzbBmxN9n4XrE&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `766px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers();
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultOptions={{
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: true,
      styles: exampleMapStyles,
    }}
    defaultZoom={props.newPosition.radius.zoom}
    zoom={props.newPosition.radius.zoom}
    defaultCenter={{ lat: props.newPosition.lat, lng: props.newPosition.lng }}
    center={{ lat: props.newPosition.lat, lng: props.newPosition.lng }}
  >
    {/* store markers */}
    {props.isMarkerShown && (
      <>
        <MarkerClusterer
          onClick={props.onMarkerClustererClick}
          averageCenter
          enableRetinaIcons
          gridSize={60}
        >
          {props.stores.map((store) => {
            return (
              <Marker
                icon={{
                  url: require("../../../assets/icon/findPin.svg"),
                }}
                onClick={() => {
                  props.setShownStores({
                    [store.store_id]: !props.shownStores[store.store_id],
                  });
                }}
                position={{ lat: store.lat, lng: store.lng }}
              >
                {props.shownStores[store.store_id] && (
                  <InfoWindow
                    onCloseClick={() => {
                      props.setShownStores({
                        [store.store_id]: !props.shownStores[store.store_id],
                      });
                    }}
                  >
                    <div>{store.name}</div>
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        </MarkerClusterer>

        {/* <MarkerClusterer
          onClick={props.onMarkerClustererClick}
          averageCenter
          enableRetinaIcons
          gridSize={60}
        > */}
        {/* selected location */}
        {props.newPosition.lat !== null && props.showMarker && (
          <Marker
            position={{
              lat: props.newPosition.lat,
              lng: props.newPosition.lng,
            }}
          />
        )}
        {/* </MarkerClusterer> */}
      </>
    )}
  </GoogleMap>
));

const FindRetailer = (props) => {
  const [showMarker, setShowMarker] = useState(false);
  const [isMarkerShown, setIsMarkerShown] = useState(false);
  let defaultShownStores = {};
  if (props.stores) {
    props.stores.map((store) => {
      defaultShownStores[store.store_id] = false;
    });
  }
  const [shownStores, setShownStores] = useState(defaultShownStores);

  const [address, setAddress] = useState("");
  const [radius, setRadius] = useState({
    label: "10 mi",
    value: 10,
    zoom: 12,
  });
  const [newPosition, setNewPosition] = useState({
    lat: 40.745586,
    lng: -73.980707,
    radius: radius,
  });
  const [storesNearby, setStoresNearby] = useState({});
  const [coordinates, setCoordinates] = useState({
    lat: 40.745586,
    lng: -73.980707,
  });

  function handleChange(e) {
    setRadius(e);
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setCoordinates(latLng);
    setAddress(value);
  };

  var rad = function (x) {
    return (x * Math.PI) / 180;
  };

  var getDistance = function (lat1, lng1, lat2, lng2) {
    let distance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(lat1, lng1),
      new google.maps.LatLng(lat2, lng2)
    );
    return distance / 1000 / 1.609;
  };

  const findRetail = (lat, lng, radius) => {
    if (status) {
      setNewPosition({
        lat: 40.745586,
        lng: -73.980707,
        radius: {
          label: "10 km",
          value: 10,
          zoom: 12,
        },
      });
      setShowMarker(true);
    } else {
      setStoresNearby(
        props.stores
          .filter((store) => {
            let distance = getDistance(lat, lng, store.lat, store.lng);
            return distance <= radius.value;
          })
          .map((store) => store)
      );
      setNewPosition({
        lat,
        lng,
        radius,
      });
      setShowMarker(true);
    }
  };
  const [status, setStatus] = useState(false);
  const handleText = () => {
    setStatus(!status);
  };
  const [viewAll, setViewAll] = useState(false);
  const toggle = () => {
    setViewAll(!viewAll);
  };
  const onGetDirection = (lat, lng, radius) => {
    setNewPosition({
      lat,
      lng,
      radius,
    });
  };
  return (
    <MDBContainer className={"find-a-retailer"} fluid>
      <SeoHelmet
        url={"/find-a-retailer"}
        title={"find a retailer"}
        description={"looking for a retailer near you?"}
      />
      <div style={{ display: "hidden" }} id="map"></div>
      <MDBRow className="flex-column find-top">
        <MDBCol>
          <MDBBox tag="h3" className="w-100 text-center my-5">
            find a retailer
          </MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow className={"find-a-retailer-wrapper"}>
        <MDBCol className={"map"}>
          <MyMapComponent
            newPosition={newPosition}
            showMarker={showMarker}
            isMarkerShown={isMarkerShown}
            stores={storesNearby}
            shownStores={shownStores}
            setShownStores={setShownStores}
            etailers={props.etailers}
            radius={radius}
          />
        </MDBCol>
        <MDBCol className={"map-inputs"}>
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <MDBBox>
                <InputField
                  label="enter street address"
                  group
                  type="text"
                  containerClass="custom-input form-control search-input"
                  type="text"
                  {...getInputProps()}
                >
                  <Icon icon={"location"} />
                </InputField>

                <MDBBox className={"locations-wrapper"}>
                  {loading ? <div>...loading</div> : null}

                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#ced4d2" : "#fff",
                      marginTop: "1.5rem",

                      padding: "1rem",
                    };

                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </MDBBox>
              </MDBBox>
            )}
          </PlacesAutocomplete>
          <Select2
            className="browser-default custom-Select2"
            onChange={(e) => handleChange(e)}
            value={radius}
            options={[
              { label: "10 mi", value: "10", zoom: 12 },
              { label: "100 mi", value: "100", zoom: 8.5 },
              { label: "250 mi", value: "250", zoom: 7 },
              { label: "500 mi", value: "500", zoom: 6 },
              { label: "1000 mi", value: "1000", zoom: 5.5 },
              { label: "2000 mi", value: "2000", zoom: 5 },
            ]}
            openIndicator="caret-up"
            closedIndicator="caret-down"
            useCustom={true}
          />
          <Button
            className={`${
              status
                ? "right-arrow orange-button gray-button"
                : "right-arrow orange-button"
            }`}
            direction={"ml-3"}
            righticon={"true"}
            icon={"arrow-right"}
            text={`search`}
            disabled={isEmpty(address)}
            onClick={() => {
              setIsMarkerShown(true);
              findRetail(coordinates.lat, coordinates.lng, radius);
            }}
          />
          {showMarker && (
            <MDBBox className={"search-container"}>
              <MDBBox className="search-res" tag="span">
                search results:
              </MDBBox>
              {!lodash.isUndefined(storesNearby) &&
              !lodash.isEmpty(storesNearby) ? (
                <>
                  <ol className={`${viewAll ? "clicked" : "not-clicked"}`}>
                    {storesNearby.map((store) => {
                      return (
                        <li className={"store-content"}>
                          <h5> {store.name}</h5>
                          <div>
                            <p>{store.street}</p>
                            <p>{store.state}</p>
                          </div>
                          {store.website ? (
                            <div className={"website-content"}>
                              <a
                                href={
                                  store.website.includes("http")
                                    ? store.website
                                    : "http://" + store.website
                                }
                                target={"_blank"}
                              >
                                visit our site
                              </a>
                              {/* <a
                                href="#"
                                onClick={e => {
                                  onGetDirection(store.lat, store.lng, radius);
                                }}
                              >
                                get direction
                              </a> */}
                            </div>
                          ) : (
                            <div className={"website-content"}>
                              <a href={store.showroom} target={"_blank"}>
                                visit our showroom
                              </a>
                              {/* <a
                                href="#"
                                onClick={e => {
                                  onGetDirection(store.lat, store.lng, radius);
                                }}
                              >
                                get direction
                              </a> */}
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ol>

                  {!viewAll ? (
                    <h4
                      style={{ cursor: "pointer" }}
                      className={`text-center view-all`}
                      onClick={(e) => {
                        toggle();
                      }}
                    >
                      VIEW ALL
                    </h4>
                  ) : (
                    <h4
                      style={{ cursor: "pointer" }}
                      className={`text-center view-all`}
                      onClick={(e) => {
                        toggle();
                      }}
                    >
                      VIEW LESS
                    </h4>
                  )}
                </>
              ) : (
                <MDBBox className={"search-container"}>
                  <h5>we’re sorry, we couldn’t find any results</h5>
                  <p>Check out some of our other e-tailers below:</p>
                  <ol className={viewAll ? "clicked" : "not-clicked"}>
                    {props.etailers.map((etailer) => {
                      return (
                        <li className={"store-content"}>
                          <h5> {etailer.name}</h5>

                          {etailer.website && (
                            <div className={"website-content"}>
                              <a
                                href={
                                  etailer.website.includes("http")
                                    ? etailer.website
                                    : "http://" + etailer.website
                                }
                                target={"_blank"}
                              >
                                visit our site
                              </a>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ol>
                </MDBBox>
              )}
            </MDBBox>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const exampleMapStyles = [
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#d3d3d3",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        color: "#808080",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#b3b3b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        weight: 1.8,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d7d7d7",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ebebeb",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#a7a7a7",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#efefef",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#696969",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#737373",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d6d6d6",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {},
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
]; //Add some style

export default FindRetailer;
