// logic is inversed, true means that an error exists

export const validate = (
  firstName,
  lastName,
  email,
  companyName,
  phone,
  description
) => {
  return {
    email: email.length === 0 || ValidateEmail(email),
    firstName: firstName.length === 0,
    lastName: lastName.length === 0,
    companyName: companyName.length === 0,
    phone: phone.length === 0,
    description: description.length === 0
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return false;
  }
  return true;
}
