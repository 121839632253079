import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Image from "core/components/image";
import { isUndefined } from "core/helpers/functions";
import Icon from "app/assets/icon/icon";
import ProductRibbon from "app/layout/productRibbon";
import Wishlist from "app/pages/catalog/product/info/controls/wishlist";
import { determineIfInWishlist } from "app/pages/catalog/product/info/controls/wishlist-functions";
import { determineIfInCartOrWishlist } from "./finishItem-functions";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";

const FinishItem = (props) => {
  let { wishlist } = props;
  let location = useLocation();
  let productSearchUrl = props.productSearchUrl;
  let productUrl = "/" + props.productUrl;
  if (!productSearchUrl) {
    productUrl = location.pathname + productUrl;
  }

  let [amount, setAmount] = useState(1);
  let isInCart = determineIfInCartOrWishlist(
    props.cartItems,
    props.productNumber
  );

  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }

  let dependencies = {};
  let inWishlist = determineIfInWishlist(wishlist.items, props.productNumber);
  return (
    <div
      className={`product-item-content finish-item-content finish-cart-${isInCart}`}
      id={props.value}
      key={props.index}
    >
      <div
        className="product-item-image product-item-part"
        onClick={(e) => {
          props.addItemToCart(amount, props.productNumber);
        }}
      >
        {props.productRibbon && loggedIn && (
          <ProductRibbon val={props.productRibbon} />
        )}
        {!isUndefined(props.productImage) && (
          <Image
            source={props.productImage.url}
            imgContainer="product-image"
            image_alt={props.productName + " " + props.productImage.label}
          />
        )}
      </div>

      <div className="product-item-info product-item-part">
        <p
          className="product-name"
          alt={props.productName}
          title={props.productName}
        >
          {props.productName.length > 30
            ? props.productName.substr(0, 28) + "..."
            : props.productName}
        </p>
      </div>

      <div className="product-item-additional-info product-item-part">
        <p className="product-price">{"$" + props.productPriceRegularValue}</p>
        <p
          className="product-cart"
          onClick={(e) => {
            props.addItemToCart(amount, props.productNumber);
          }}
        >
          {(isInCart && <Icon icon={"cartOrange"} />) || (
            <Icon icon={"cartPlusGray"} />
          )}
        </p>
        <ModalConsumer value={{ useWrapper: false }}>
          {({ showModal, hideModal }) => (
            <p
              className="product-wishlist"
              onClick={(e) => {
                showModal(() => {
                  // options logic
                  let options = [];
                  if (Object.keys(options).length > 1) {
                    Object.keys(options).map((key) => {
                      let _option = options[key];
                      if (
                        _option === "" ||
                        key === "selectedPrice" ||
                        key === "value"
                      )
                        return;
                      let customizableOptionFormat;
                      if (_option.title) {
                        customizableOptionFormat = {
                          id: parseInt(key),
                          value_string: String(_option.option_type_id),
                        };
                      } else {
                        customizableOptionFormat = {
                          id: parseInt(key),
                          value_string: _option.swatch_name,
                        };
                      }
                      let addToOptions = true;
                      if (dependencies[key]) {
                        let depKey = Object.keys(dependencies[key])[0];
                        addToOptions =
                          options[depKey].option_type_id ===
                          dependencies[key][depKey];
                      }
                      if (addToOptions) {
                        return options.push(customizableOptionFormat);
                      }
                    });
                  }
                  if (!loggedIn) {
                    e.preventDefault();
                    return (
                      <Login
                        hideModal={hideModal}
                        component={
                          <Wishlist
                            inWishlist={inWishlist}
                            wishlist={wishlist}
                            hideModal={hideModal}
                            product={props.product}
                            options={options}
                          />
                        }
                      />
                    );
                  } else {
                    return (
                      <Wishlist
                        inWishlist={inWishlist}
                        wishlist={wishlist}
                        hideModal={hideModal}
                        product={props.product}
                        options={options}
                      />
                    );
                  }
                });
              }}
            >
              {(inWishlist && <Icon icon={"heartFull"} />) || (
                <Icon icon={"heartEmptyGray"} />
              )}
            </p>
          )}
        </ModalConsumer>
      </div>
    </div>
  );
};
export default FinishItem;
