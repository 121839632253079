export const determineIfInWishlist = (wishlist_items, sku) => {
  if (typeof wishlist_items === "undefined" || wishlist_items.length === 0)
    return false;

  let foundProductWithId = wishlist_items.find(
    item => item.product.sku === sku
  );

  if (typeof foundProductWithId === "undefined") return false;

  return foundProductWithId.id ? true : false;
};
