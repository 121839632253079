import React from "react";
import CheckoutBillingAddress from "./checkoutBillingAddress";
import countries from "config/data/countries_checkout_filtered";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { filterByCountryId } from "core/helpers/customer";

const CheckoutBillingAddressDataContainer = (props) => {
  let {
    setGuestEmailOnCart,
    setBillingAddressOnCart,
    customer,
    useSameAddress,
    setUseSameAddress,
    lock,
    setShippingAddressOnCart,
    billing_address,
    billingAddress,
    setBillingAddress,
    setShouldChange,
  } = props;
  let defaultBillingAddress = false;
  let customerAddresses = [];

  const _countries = countries.countries;
  let countryDataObject = getCountries(_countries);
  let countriesData;
  countriesData = Object.keys(countryDataObject).map((key) => {
    return countryDataObject[key];
  });

  let selectedCountry = filterByCountryId("US", countriesData);
  let regionsDataObject = getRegionData(countriesData, selectedCountry);
  if (customer === "undefined") return <></>;
  let { addresses } = customer;

  if (typeof addresses !== "undefined" && customer.token) {
    if (addresses.length !== 0) {
      addresses.map((address) => {
        if (address.default_billing) {
          defaultBillingAddress = {
            value: address.id,
            label: `${address.street}, ${address.city}, ${
              address.region.region_code
            } ${address.postcode}, ${
              filterByCountryId(address.country_code, countriesData).label
            }`,
          };
        }

        customerAddresses.push({
          value: address.id,
          label: `${address.street}, ${address.city}, ${
            address.region.region_code
          } ${address.postcode}, ${
            filterByCountryId(address.country_code, countriesData).label
          }`,
        });
      });
      customerAddresses.push({
        value: "new-address",
        label: "new address",
      });
    }
  }

  return (
    <CheckoutBillingAddress
      countries={_countries}
      regionsDataObject={regionsDataObject}
      setGuestEmailOnCart={setGuestEmailOnCart}
      loggedIn={customer.token}
      defaultBillingAddress={defaultBillingAddress}
      customerAddresses={customerAddresses}
      setBillingAddressOnCart={setBillingAddressOnCart}
      useSameAddress={useSameAddress}
      setUseSameAddress={setUseSameAddress}
      setShippingAddressOnCart={setShippingAddressOnCart}
      lock={lock}
      billing_address={billing_address}
      billingAddress={billingAddress}
      setBillingAddress={setBillingAddress}
      setShouldChange={setShouldChange}
    />
  );
};

export default CheckoutBillingAddressDataContainer;
