import React, { useState, memo } from "react";
import { MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";

const AccordionItem = memo(
  (props) => {
    let { item, open, setOpen } = props;

    const toggleOpen = () => {
      setOpen(item.title !== open ? item.title : "");
    };
    return (
      <MDBBox className={`accordion-item`}>
        <div className="accordion-section">
          <button className="accordion-title" onClick={toggleOpen}>
            <h3>{item.title}</h3>
            <span
              className={
                (item.title === open && "accordion-icon rotate") ||
                "accordion-icon"
              }
            >
              <Icon icon={"rightArrowOrange"} />
            </span>
          </button>
          <div
            className={`accordion-content ${
              item.title === open ? "open" : "closed"
            }`}
          >
            {item.content}
          </div>
        </div>
      </MDBBox>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps === nextProps) {
      return true;
    }

    return false;
  }
);

export default AccordionItem;
