import React, { memo } from "react";
import View from "./view";
import { isUndefined } from "core/helpers/functions";
import _ from "lodash";

const ViewDataContainer = memo(
  ({ data, orderId }) => {
    if (!isUndefined(data)) {
      let orderData = data.customerOrders;
      let currentOrder = orderData[0].items.find(
        (order) => order.order_number === orderId
      );

      return <View order={currentOrder} />;
    }
    return "";
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ViewDataContainer;
