import React from "react";
import { MDBBox } from "mdbreact";
import CmsBlock from "core/components/cmsBlock";

const ContactText = () => {
  return (
    <>
      <h2>contact us</h2>
      <MDBBox tag={"p"}>
        <CmsBlock dangHtml={"true"} id={"contact_us_text"} />
      </MDBBox>
    </>
  );
};

export default ContactText;
