import React, { useState } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBCollapse } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import Price from "core/components/price";
import Input from "core/components/input";
import Options from "./options";
import Button from "core/components/button";
import { isUndefined, isEmpty } from "core/helpers/functions";
import Usps from "./usps";
import { determineIfInWishlist } from "./wishlist-functions";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Wishlist from "./wishlist";
import Addons from "./addons";
import Login from "app/pages/customer/login";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import gql from "graphql-tag";
import apolloClient from "core/graphql/apolloClient";
import { useCompare } from "core/state/hooks/compareHook";

const ADD_PRODUCT_ALERT = gql`
  mutation addProductAlert($customer_email: String!, $product_id: String) {
    addProductAlert(customer_email: $customer_email, product_id: $product_id)
  }
`;

const Controls = (props) => {
  let product = props.data;
  let compareRequest = { productId: product.id };
  const [
    addCompare,
    removeCompare,
    ,
    ,
    ,
    checkIfProductIsInCompareById,
  ] = useCompare();
  let isProductInCompare = checkIfProductIsInCompareById(product.id);
  let { dependencies, wishlist, addProductToWishlist, lock } = props;
  let [amount, setAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [editAmount, setEditAmount] = useState(
    props.editMode
      ? typeof props.cartItem.quantity !== "undefined"
        ? props.cartItem.quantity
        : 0
      : 0
  );
  let [isSelectionsOpen, setIsSelectionsOpen] = useState(true);
  let [isAddonsOpen, setIsAddosOpen] = useState(false);
  let tmpAddonQtys = {};
  if (!isUndefined(props.data.addon_products)) {
    props.data.addon_products.map((addon) => {
      tmpAddonQtys[addon.sku] = 0;
    });
  }

  let [addonQtys, setAddonQtys] = useState(tmpAddonQtys);

  const setAddonAmmount = (sku, qty) => {
    if (!isUndefined(addonQtys[sku])) {
      setAddonQtys({ ...addonQtys, [sku]: qty });
    }
  };

  let addonProps = {
    isAddonsOpen,
    setIsAddosOpen,
    addonQtys,
    setAddonQtys,
    addon_products: props.data.addon_products,
    setAddonAmmount,
    lock,
    setIsSelectionsOpen,
  };

  let hasOptions = Boolean(props.data.options);
  let hasAddons = !isEmpty(props.data.addon_products);

  let inWishlist = determineIfInWishlist(wishlist.items, product.sku);
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  const [addProductAlertLoading, setAddProductAlertLoading] = useState(false);
  const [notLoggedIn, setNotLoggedIn] = useState(false);

  const notifyWhenInStock = async () => {
    if (typeof props.customer !== "undefined") {
      if (
        props.customer.token !== false &&
        typeof props.customer.token !== "undefined"
      ) {
        setAddProductAlertLoading(true);
        const { data } = await apolloClient.mutate({
          mutation: ADD_PRODUCT_ALERT,
          variables: {
            customer_email: props.customer.email,
            product_id: props.data.id,
          },
        });
        if (data.addProductAlert === "Succesfully alerted") {
          props.addMessage("succesfully alerted", "success");
          setAddProductAlertLoading(false);
        } else {
          props.addMessage(
            "customer already registered for notifying",
            "success"
          );
          setAddProductAlertLoading(false);
        }
      }
    }
  };
  if (typeof props.customer !== "undefined") {
    if (
      notLoggedIn &&
      typeof props.customer.email !== "undefined" &&
      props.customer.email !== false
    ) {
      notifyWhenInStock();
      setNotLoggedIn(false);
    }
  }

  if (redirect && props.cartItem !== false) {
    return <Redirect to={`/cart`} />;
  }
  // do a check to see if all colors are selected
  let optionsArray =
    typeof props.productOptions.options !== "undefined"
      ? Object.keys(props.productOptions.options).map((key) => {
          if (!_.isNaN(parseInt(key))) {
            let newOptionFormat = {
              ...props.productOptions.options[key],
              option_type_id: key,
            };
            return newOptionFormat;
          }
          return null;
        })
      : [];
  let colorOptionsArray = [];
  let temporary =
    typeof optionsArray !== "undefined"
      ? optionsArray.find((option) => {
          if (option !== null && typeof option.color_hex !== "undefined") {
            colorOptionsArray.push(option);
          }
          return false;
        })
      : null;
  let optionsSelected = colorOptionsArray.map((color) => {
    if (typeof dependencies[color.option_type_id] !== "undefined") {
      let keys = Object.keys(dependencies[color.option_type_id]);
      return keys.map((key) => {
        if (
          !dependencies[color.option_type_id][key].includes(
            props.productOptions.options[key].option_type_id
          )
        ) {
          return true;
        } else {
          return color.swatch_name !== null;
        }
      })[0];
    } else {
      return color.swatch_name !== null;
    }
  });

  return (
    <MDBRow className="sticky pdp-sticky-part">
      <MDBCol size="12">
        <Breadcrumbs />
      </MDBCol>
      <MDBCol size="12">
        <h3 className="pdp-main-title">{product.name}</h3>
      </MDBCol>
      {!isUndefined(product.description) && (
        <MDBCol size="12" className="description">
          <p dangerouslySetInnerHTML={{ __html: product.description.html }} />
        </MDBCol>
      )}
      {hasOptions && (
        <MDBCol size="12">
          <MDBCol
            size="12"
            className="selections-title border-bottom border-top py-4 mt-4"
            onClick={() => {
              setIsSelectionsOpen(!isSelectionsOpen);
            }}
          >
            <MDBIcon icon={`${isSelectionsOpen ? "minus" : "plus"}`} />
            <span>make your selections:</span>
          </MDBCol>
          <MDBCollapse
            isOpen={isSelectionsOpen}
            className="selections-content w-100"
          >
            <Options {...props} />
          </MDBCollapse>
        </MDBCol>
      )}

      <MDBCol size="12" className="mt-5 qty-price">
        <MDBRow>
          {product.stock_status === "OUT_OF_STOCK" ? (
            <MDBCol className={"out-of-stock-title"}>
              <h4>out of stock</h4>
              {/* <p>estimated delivery:</p> */}
            </MDBCol>
          ) : (
            <MDBCol
              md="6"
              sm="12"
              className={`qty-container ${
                (!props.editMode && amount === 1) ||
                (props.editMode && editAmount === 1)
                  ? " disable-minus"
                  : ""
              }`}
            >
              <span>QTY</span>
              {!props.editMode ? (
                <Input
                  name={``}
                  type={"number"}
                  wrapperClass={"quantity-field"}
                  className={"qty-control-wrapper"}
                  inputClass={""}
                  value={amount}
                  plusClick={(e) => setAmount(amount + 1)}
                  minusClick={(e) => {
                    setAmount(amount === 1 ? 1 : amount - 1);
                  }}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (value < 1 || value === "" || _.isNaN(value)) {
                      setAmount(1);
                    } else {
                      setAmount(value);
                    }
                  }}
                />
              ) : (
                <Input
                  name={``}
                  type={"number"}
                  wrapperClass={"quantity-field"}
                  className={"qty-control-wrapper"}
                  inputClass={""}
                  value={editAmount}
                  plusClick={(e) => setEditAmount(editAmount + 1)}
                  minusClick={(e) => {
                    setEditAmount(editAmount === 1 ? 1 : editAmount - 1);
                  }}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (value < 1 || value === "" || _.isNaN(value)) {
                      setEditAmount(1);
                    } else {
                      setEditAmount(value);
                    }
                  }}
                />
              )}
            </MDBCol>
          )}

          <MDBCol
            md="6"
            sm="12"
            className="d-flex price-container flex-row-reverse"
          >
            {!isUndefined(product.price_range) &&
            product.price_range.minimum_price.final_price.value !== "#" ? (
              <Price
                price={
                  typeof props.productOptions === "undefined" ||
                  typeof props.productOptions.options === "undefined" ||
                  typeof props.productOptions.options.totalPrice === "undefined"
                    ? parseFloat(
                        product.price_range.minimum_price.final_price.value
                      )
                    : parseFloat(props.productOptions.options.totalPrice)
                }
                currency={
                  product.price_range.minimum_price.final_price.currency
                }
                type={"fixed"}
              />
            ) : (
              <></>
            )}
          </MDBCol>
        </MDBRow>
      </MDBCol>

      <MDBCol size="12" className="ctas mt-5">
        {product.stock_status === "OUT_OF_STOCK" ? (
          <ModalConsumer value={{ useWrapper: true }}>
            {({ showModal, hideModal }) => (
              <Button
                disabled={addProductAlertLoading}
                color={"share-transparent"}
                direction={"mr-3"}
                leftIcon={true}
                className={"notify-btn btn white-button"}
                icon={"envelope"}
                children={`${
                  !addProductAlertLoading
                    ? "coming soon!"
                    : "notifying..."
                }`}
                onClick={(e) => {
                  if (!loggedIn) {
                    showModal(() => {
                      e.preventDefault();
                      setNotLoggedIn(true);
                      return <Login hideModal={hideModal} />;
                    });
                  } else {
                    notifyWhenInStock();
                  }
                }}
              ></Button>
            )}
          </ModalConsumer>
        ) : (
          <Button
            disabled={
              typeof product.sku !== "undefined"
                ? optionsSelected.includes(false)
                  ? true
                  : loading
                : true
            }
            className={"right-arrow large orange-button"}
            direction={"ml-3"}
            righticon={"true"}
            id={"cartWhite"}
            children={`${
              typeof product.sku === "undefined"
                ? "loading"
                : props.editMode === true
                ? loading === false
                  ? "edit item"
                  : "editing..."
                : loading === false
                ? "add to cart"
                : "adding..."
            }`}
            onClick={() => {
              let options = [];
              setLoading(true);
              if (Object.keys(props.productOptions.options).length > 1) {
                Object.keys(props.productOptions.options).map((key) => {
                  let _option = props.productOptions.options[key];
                  if (
                    _option === "" ||
                    key === "selectedPrice" ||
                    key === "specialtyPrice" ||
                    key === "shearlingPrice" ||
                    key === "totalPrice" ||
                    key === "value" ||
                    _option === false ||
                    _option.title === "none"
                  )
                    return;
                  let customizableOptionFormat;
                  if (_option.title) {
                    customizableOptionFormat = {
                      id: parseInt(key),
                      value_string: String(_option.option_type_id),
                    };
                  } else {
                    customizableOptionFormat = {
                      id: parseInt(key),
                      value_string: _option.swatch_name,
                    };
                  }
                  let addToOptions = true;
                  if (dependencies[key]) {
                    let depKey = Object.keys(dependencies[key])[0];
                    addToOptions = dependencies[key][depKey].includes(
                      props.productOptions.options[depKey].option_type_id
                    );
                  }
                  if (addToOptions) {
                    return options.push(customizableOptionFormat);
                  }
                });
              }
              // console.log(product.sku, options);
              let selectedOption;
              if (product.__typename === "ConfigurableProduct") {
                let findOptionBasedOnSelection = product.options.find(
                  (_option) => {
                    return _option.option_id === parseInt(options[0].id);
                  }
                );
                if (typeof findOptionBasedOnSelection !== "undefined") {
                  selectedOption = findOptionBasedOnSelection.value.find(
                    (x) =>
                      x.option_type_id === parseInt(options[0].value_string)
                  );
                }
                lock({
                  always: (e) => {},
                  fail: (e) => {
                    setLoading(false);
                  },
                  success: (e) => {
                    setLoading(false);
                  },
                });
                // props.addConfigurableItemToCart(
                //   amount,
                //   product.sku,
                //   [],
                //   typeof selectedOption !== "undefined"
                //     ? selectedOption.sku
                //     : undefined,
                //   product.__typename
                // );
                props.addItemToCart(amount, selectedOption.sku, options);
                // console.log(selectedOption, options[0].value_string);
                // console.log(product, product.options, options);
                // setLoading(false);
              } else {
                if (props.editMode === false) {
                  lock({
                    always: (e) => {},
                    fail: (e) => {
                      setLoading(false);
                    },
                    success: (e) => {
                      setLoading(false);
                    },
                  });
                  props.addItemToCart(
                    amount,
                    product.sku,
                    options,
                    typeof selectedOption !== "undefined"
                      ? selectedOption.sku
                      : undefined,
                    product.__typename
                  );
                } else {
                  lock({
                    always: (e) => {
                      // setLoading(true);
                    },
                    fail: (e) => {
                      setLoading(false);
                    },
                    success: (e) => {
                      setLoading(false);
                      setRedirect(true);
                    },
                  });
                  props.updateCart([
                    {
                      cart_item_id: props.cartItem.id,
                      customizable_options: options,
                      quantity: props.editMode === false ? amount : editAmount,
                    },
                  ]);
                }

                if (!isUndefined(addonQtys)) {
                  Object.keys(addonQtys).map((sku) => {
                    if (addonQtys[sku] > 0) {
                      props.addItemToCart(addonQtys[sku], sku, []);
                    }
                  });
                }
              }
            }}
          ></Button>
        )}

        <ModalConsumer value={{ useWrapper: true }}>
          {({ showModal, hideModal }) => (
            <Button
              className={"wishlist-btn"}
              color={"#FF5100"}
              onClick={(e) => {
                showModal(() => {
                  // options logic
                  let options = [];
                  if (Object.keys(props.productOptions.options).length > 1) {
                    Object.keys(props.productOptions.options).map((key) => {
                      let _option = props.productOptions.options[key];
                      if (
                        _option === "" ||
                        key === "selectedPrice" ||
                        key === "totalPrice" ||
                        key === "specialityPrice" ||
                        key === "shearlingPrice" ||
                        key === "value"
                      )
                        return;
                      let customizableOptionFormat;
                      if (_option.title) {
                        customizableOptionFormat = {
                          id: parseInt(key),
                          value_string: String(_option.option_type_id),
                        };
                      } else {
                        customizableOptionFormat = {
                          id: parseInt(key),
                          value_string: _option.swatch_name,
                        };
                      }
                      let addToOptions = true;
                      if (dependencies[key]) {
                        let depKey = Object.keys(dependencies[key])[0];
                        addToOptions =
                          props.productOptions.options[depKey]
                            .option_type_id === dependencies[key][depKey];
                      }
                      if (addToOptions) {
                        return options.push(customizableOptionFormat);
                      }
                    });
                  }
                  if (!loggedIn) {
                    e.preventDefault();
                    return (
                      <Login
                        hideModal={hideModal}
                        component={
                          <Wishlist
                            inWishlist={inWishlist}
                            wishlist={wishlist}
                            hideModal={hideModal}
                            product={product}
                            options={options}
                          />
                        }
                      />
                    );
                  } else {
                    return (
                      <Wishlist
                        inWishlist={inWishlist}
                        wishlist={wishlist}
                        hideModal={hideModal}
                        product={product}
                        options={options}
                      />
                    );
                  }
                });
              }}
            >
              {!inWishlist ? (
                <MDBIcon far icon="heart" />
              ) : (
                <MDBIcon icon="heart" />
              )}
              <MDBIcon icon="caret-down" />
            </Button>
          )}
        </ModalConsumer>
        {/* COMPARE */}
        <Button
          onClick={() => {
            if (isProductInCompare) {
              removeCompare(compareRequest);
            } else {
              addCompare(compareRequest);
            }
          }}
          className={"compare-btn ml-3"}
          color={"#FF5100"}
        >
          {!isProductInCompare && (
            <>
              <MDBIcon far icon="clone" />
              <MDBIcon icon="plus" />
            </>
          )}
          {isProductInCompare && (
            <>
              <MDBIcon icon="clone" />
              <MDBIcon icon="minus" />
            </>
          )}
        </Button>
      </MDBCol>

      {hasAddons && !isUndefined(props.data.addon_products) && (
        <MDBCol>
          <MDBRow>
            <Addons {...addonProps} addItemToCart={props.addItemToCart} />
          </MDBRow>
        </MDBCol>
      )}
      <MDBCol size="12" className="usps ml-4 mr-4">
        <MDBRow className="d-flex justify-content-between">
          <Usps product={product} {...props} />
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default Controls;
