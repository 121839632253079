import React from "react";
import { connect } from "react-redux";
import WishlistShareQueryContainer from "./wishlistShare-query-container";

const WishlistShareReduxContainer = (props) => {
  return <WishlistShareQueryContainer {...props} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishlistShareReduxContainer);
