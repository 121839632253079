import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";

const TradeProgram = props => {
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <MDBBox tag="p">TradeProgram</MDBBox>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default TradeProgram;
