import React from "react";
import { MDBIcon } from "mdbreact";

const Pagination = props => {
  let { pageNumber, totalPages } = props;
  let showPrev = pageNumber > 1;
  let showNext = pageNumber < totalPages;
  let showLast = totalPages - pageNumber > 1;
  let showFirst = pageNumber === totalPages && totalPages > 2;

  return (
    <ul className="pagination page-pagination">
      {/* PREV ICON */}
      {showPrev && (
        <li
          onClick={e => {
            props.changePageNumber(pageNumber - 1);
          }}
          className="page-item pagination-icon"
        >
          <div className="pagination-button">
            <MDBIcon icon="caret-left" />
          </div>
        </li>
      )}

      {showFirst && (
        <>
          <li
            onClick={e => {
              props.changePageNumber(1);
            }}
            className="page-item"
          >
            <div className="pagination-button">{1}</div>
          </li>
          <div className="pagination-dots">...</div>
        </>
      )}

      {/* PREV NUMBER */}
      {showPrev && (
        <li
          onClick={e => {
            props.changePageNumber(pageNumber - 1);
          }}
          className="page-item"
        >
          <div className="pagination-button">{pageNumber - 1}</div>
        </li>
      )}

      {/* CURRENT */}
      {totalPages > 1 && (
        <li className="page-item active-page-item no-click">
          <div className="pagination-button">{pageNumber}</div>
        </li>
      )}

      {/* NEXT NUMBER */}
      {showNext && (
        <li
          onClick={e => {
            props.changePageNumber(pageNumber + 1);
          }}
          className="page-item"
        >
          <div className="pagination-button">{pageNumber + 1}</div>
        </li>
      )}

      {showLast && (
        <>
          <div className="pagination-dots">...</div>
          <li
            onClick={e => {
              props.changePageNumber(totalPages);
            }}
            className="page-item"
          >
            <div className="pagination-button">{totalPages}</div>
          </li>
        </>
      )}

      {/* NEXT ICON */}
      {showNext && (
        <li
          onClick={e => {
            props.changePageNumber(pageNumber + 1);
          }}
          className="page-item pagination-icon"
        >
          <div className="pagination-button">
            <MDBIcon icon="caret-right" />
          </div>
        </li>
      )}
    </ul>
  );
};

export default Pagination;
