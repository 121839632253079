import React, { useState } from "react";
import Summary from "./summary";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { filterByCountryId } from "core/helpers/customer";
import countries from "config/data/countries_tax_calculation_filtered";

const SummaryStateContainer = (props) => {
  let { cart } = props;
  const _countries = countries.countries;
  let countryDataObject = getCountries(_countries);
  let countriesData;
  countriesData = Object.keys(countryDataObject).map((key) => {
    return countryDataObject[key];
  });
  let selectedCountry = filterByCountryId("US", countriesData);
  let regionsDataObject = getRegionData(countriesData, selectedCountry);
  let usedZip = "";
  let usedState = "";

  if (cart.cart.shipping_addresses.length !== 0) {
    let shipping_addresses = cart.cart.shipping_addresses[0];
    usedState = regionsDataObject.find(
      (x) =>
        typeof x !== "undefined" && x.code === shipping_addresses.region.code
    );
    usedZip = shipping_addresses.postcode;
  }

  const [zip, setZip] = useState(cart === undefined ? "" : usedZip);
  const [state, setState] = useState(cart === undefined ? "" : usedState);
  const [touched, setTouched] = useState({});
  const [zipMask, setZipMask] = useState([/\d/, /\d/, /\d/, /\d/, /\d/]);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(true);
  const [discountCode, setDiscountCode] = useState("");
  const [couponLoading, setCouponLoading] = useState(false);

  return (
    <Summary
      {...props}
      zip={zip}
      setZip={setZip}
      state={state}
      setState={setState}
      loading={loading}
      setLoading={setLoading}
      touched={touched}
      setTouched={setTouched}
      zipMask={zipMask}
      setZipMask={setZipMask}
      regionsDataObject={regionsDataObject}
      changed={changed}
      setChanged={setChanged}
      discountCode={discountCode}
      setDiscountCode={setDiscountCode}
      couponLoading={couponLoading}
      setCouponLoading={setCouponLoading}
    />
  );
};

export default SummaryStateContainer;
