import React, { useState } from "react";
import OrderSuccess from "./order-success";

const OrderSuccessStateContainer = (props) => {
  const [orderNumber, setOrderNumber] = useState(props.order_id);

  return (
    <OrderSuccess
      orderNumber={orderNumber}
      setOrderNumber={setOrderNumber}
      placeOrderSuccess={props.placeOrderSuccess}
      customer={props.customer}
    />
  );
};

export default OrderSuccessStateContainer;
