import React, { useEffect, useState } from "react";
import { MDBBox } from "mdbreact";
import StaticPageTop from "app/layout/staticPagesTop/staticPagesTop";
import smallBanner from "./../../../../assets/images/custom/smallBanner.png";
import banner from "./../../../../assets/images/custom/banner.png";

const CustomTop = (props) => {
  const [screenwidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);
  if (screenwidth < 991.98) {
    return (
      <div className="custom-top">
        <MDBBox tag="h2" className="w-100 text-center my-5">
          ever open a crayon box and think, “yes please!”. us too.
        </MDBBox>
        <MDBBox tag="h5">ducduc customization</MDBBox>
        <StaticPageTop
          position={"right"}
          banner={banner}
          image={smallBanner}
          description={
            "not every company is as lucky as we are! Having our own factory in Connecticut means having control over the production of every part of every piece of furniture that lands in your kid’s room."
          }
          description2={
            " this is cool for the obvious reasons (ensured safety for your little ones) but also cool because it means the piece you receive is customized for your life."
          }
          //Button
          className={"right-arrow orange-button"}
          direction={"ml-3"}
          righticon={"true"}
          icon={"arrow-right"}
          children={"contact us"}
          href={"/contact"}
        />
      </div>
    );
  }
  return (
    <div className="custom-top">
      <MDBBox tag="h2" className="w-100 text-center my-5 py-5">
        ever open a crayon box and think, “yes please!”. us too.
      </MDBBox>
      <StaticPageTop
        position={"right"}
        title={"ducduc customization"}
        banner={banner}
        image={smallBanner}
        description={
          "not every company is as lucky as we are! Having our own factory in Connecticut means having control over the production of every part of every piece of furniture that lands in your kid’s room."
        }
        description2={
          " this is cool for the obvious reasons (ensured safety for your little ones) but also cool because it means the piece you receive is customized for your life."
        }
        //Button
        className={"right-arrow orange-button"}
        direction={"ml-3"}
        righticon={"true"}
        icon={"arrow-right"}
        children={"contact us"}
        href={"/contact"}
      />
    </div>
  );
};

export default CustomTop;
