import React, { useState } from "react";
import ProductDataContainer from "./data-container";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const PRODUCT = gql`
  query Product($filters: ProductAttributeFilterInput!) {
    products(filter: $filters) {
      total_count
      items {
        id
        name
        shearling_price
        specialty_price
        size_guide
        description {
          html
        }
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
            ... on CustomizableDropDownOption {
              option_id
              title
              sort_order
              value {
                title
                option_type_id
                price
                price_type
                sku
                width
                height
                depth
                cubbie
                note
                image
              }
            }
            ... on CustomizableCheckboxOption {
              option_id
              required
              sort_order
              title
              value {
                option_type_id
                price
                price_type
                sku
                sort_order
                title
              }
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_id
            id
            label
            position
            product_id
            use_default
            values {
              default_label
              label
              store_label
              use_default_value
              value_index
            }
          }
          variants {
            attributes {
              code
              label
              value_index
            }
            product {
              sku
            }
          }
        }

        sku
        url_key
        hotspots {
          hotspot_id
          hotspot_image
          content
        }
        getColors {
          accent_color {
            color_hex
            swatch_image
            swatch_name
            type
          }
          accent_color_default {
            color_hex
            swatch_image
            swatch_name
            type
          }
          fabric_color {
            color_hex
            swatch_image
            swatch_name
            type
          }
          fabric_color_default {
            color_hex
            swatch_image
            swatch_name
            type
          }
          frame_color {
            color_hex
            swatch_image
            swatch_name
            type
          }
          frame_color_default {
            color_hex
            swatch_image
            swatch_name
            type
          }
          ladder_color {
            color_hex
            swatch_image
            swatch_name
            type
          }
          ladder_color_default {
            color_hex
            swatch_image
            swatch_name
            type
          }
          trundle_color {
            color_hex
            swatch_image
            swatch_name
            type
          }
          trundle_color_default {
            color_hex
            swatch_image
            swatch_name
            type
          }
          changing_tray_color {
            color_hex
            swatch_image
            swatch_name
            type
          }
          changing_tray_color_default {
            color_hex
            swatch_image
            swatch_name
            type
          }
          drawer_interior_color {
            color_hex
            swatch_image
            swatch_name
            type
          }
          drawer_interior_color_default {
            color_hex
            swatch_image
            swatch_name
            type
          }
          storage_drawers_color {
            color_hex
            swatch_image
            swatch_name
            type
          }
          storage_drawers_color_default {
            color_hex
            swatch_image
            swatch_name
            type
          }
          hardware {
            color_hex
            swatch_image
            swatch_name
            type
          }
        }
        assembly_videos
        assembly_informations
        media_gallery_entries {
          id
          file
          media_type
          image_pdp
          shown_in_finishes
          photo_by
          content {
            base64_encoded_data
            name
            type
          }
          disabled
        }
        categories {
          id
        }
        image {
          label
          url
        }
        small_image {
          label
          url
        }

        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        tier_prices {
          customer_group_id
          percentage_value
          qty
          value
          website_id
        }
        addon_products {
          sku
          name
          price
          image
          url
        }
        related_products {
          id
          url_key
          name
          url_key
          price_range {
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
          }
          media_gallery_entries {
            id
            file
            content {
              base64_encoded_data
              name
              type
            }
            disabled
          }
          small_image {
            label
            url
          }
          image {
            label
            url
          }
        }
        stock_status
        meta_description
        meta_title
      }
    }
  }
`;

const ProductQueryContainer = (props) => {
  const [token, setToken] = useState(props.token);
  let locationStrings = props.variables.url.split("/");
  let productUrlKey = locationStrings[locationStrings.length - 1];
  let filter = { filters: { url_key: { eq: productUrlKey } } };
  const { loading, error, data, refetch } = useQuery(PRODUCT, {
    variables: filter,
  });

  if (loading) {
    return <ProductDataContainer {...props} data={{}} />;
  }

  if (error) {
    return <></>;
  }

  if (props.token !== token) {
    setToken(props.token);
    refetch();
  }

  return (
    <>
      <ProductDataContainer {...props} data={data} />
    </>
  );
};

export default ProductQueryContainer;
