import React from "react";
import { MDBNavItem, MDBLink } from "mdbreact";

const Items = (props) => {
  const { items } = props;

  const closeBurger = () => {
    document.getElementById("hamburger1").click();
  };
  return (
    <>
      {Object.keys(items).map((element, i) => {
        return (
          <MDBNavItem
            key={`mobile-navigation-item-${element}-${items[i].url_path}`}
          >
            <MDBLink
              className={`text-lowercase text-decoration-none text-uppercase`}
              to={`/${items[i].url_path}`}
              onClick={(e) => {
                props.toggleHamburger(props);
                closeBurger();
              }}
            >
              {items[i].name}
            </MDBLink>
          </MDBNavItem>
        );
      })}
    </>
  );
};

export default Items;
