import React from "react";
import Faq from "./faq";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { transform } from "core/components/cmsBlock/Parser";
import { isUndefined } from "core/helpers/functions";

const FaqDataContainer = props => {
  let tabs = [];
  let tabsData = [];
  if (typeof props.data === "undefined") return <></>;
  props.data.getFAQ.map((faq, index) => {
    let items = [];
    if (!isUndefined(faq.items)) {
      faq.items.map(item => {
        items.push({
          content: ReactHtmlParser(item.content, {
            decodeEntities: true,
            transform: transform
          }),
          title: item.title
        });
      });
    }
    tabs.push(faq);
    tabsData.push(items);
  });

  return <Faq {...props} tabs={tabs} tabsData={tabsData} />;
};

export default FaqDataContainer;
