import React, { useState } from "react";
import { MDBRow, MDBCol, MDBBox } from "mdbreact";
import { Alert } from "reactstrap";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import { validate, shouldMarkError } from "./validator";
import { getCountries } from "config/data/dataMapping";
import { findMaskByCountry } from "../b2bStepThree/functions";
import Select2 from "core/components/select";
import MaskedInput from "react-text-mask";
import gql from "graphql-tag";
import { isEmpty } from "core/helpers/functions";
import apolloClient from "core/graphql/apolloClient";

const IS_EMAIL_AVAILABLE = gql`
  query isEmailAvailable($email: String!) {
    isEmailAvailable(email: $email) {
      is_email_available
    }
  }
`;

const setCountrySelectHandler = (
  event,
  setMask,
  setMaskSanitizedArray,
  setCountrySelectShippingSecondStep
) => {
  let value = event;
  let mask = [];
  let maskShipping = [];

  mask = findMaskByCountry(value.value);
  setMask(mask);
  setCountrySelectShippingSecondStep(value);

  let maskSanitized = mask.mask.split("");
  setMaskSanitizedArray(generateSanitizedMaskArray(maskSanitized));
};
const generateSanitizedMaskArray = (object) => {
  return Object.keys(object).map((key) => {
    let regexChar = object[key];
    if (object[key] === "+") regexChar = "+";
    if (object[key] === "#") regexChar = /\d/;
    return regexChar;
  });
};

const RegisterStepTwo = (props) => {
  let {
    countries,
    countrySelectShippingSecondStep,
    setCountrySelectShippingSecondStep,
  } = props;
  let countriesData = [];
  const [mask, setMask] = useState(findMaskByCountry("US"));
  const [maskSanitizedArray, setMaskSanitizedArray] = useState(
    generateSanitizedMaskArray(findMaskByCountry("US").mask.split(""))
  );
  const [termsAccepted, setTermsAccepted] = useState("");
  const [touched, setTouched] = useState({});
  let errors = validate(
    props.firstName,
    props.lastName,
    props.phone,
    props.email,
    props.password,
    props.confirmPassword,
    maskSanitizedArray
  );
  let countryDataObject = getCountries(countries);
  countriesData = Object.keys(countryDataObject).map((key) => {
    return countryDataObject[key];
  });

  let isEmailValid = false;
  const [loading, setLoading] = useState(false);

  const validateInputStepTwo = async () => {
    setLoading(true);
    let data = undefined;
    if (props.email.length !== 0)
      data = await apolloClient.mutate({
        mutation: IS_EMAIL_AVAILABLE,
        variables: { email: props.email },
      });
    setLoading(false);

    if (!loading) {
      if (data === undefined) {
        isEmailValid = false;
      } else {
        if (data !== undefined && !isEmpty(data)) {
          isEmailValid = data.data.isEmailAvailable.is_email_available;
        }
      }
    }

    let isTrue = true;
    setTouched({
      firstname: true,
      lastname: true,
      phone: true,
      email: true,
      password: true,
    });

    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    if (isTrue) {
      if (isEmailValid) {
        props.setNextStep();
      } else {
        props.addMessage("customer with the same email already exists");
      }
    } else {
      props.addMessage("Please fill all required fields marked in red.");
    }
  };

  const setFirstName = (e) => {
    props.setFirstName(e.target.value);
  };
  const setLastName = (e) => {
    props.setLastName(e.target.value);
  };
  const setPhone = (e) => {
    props.setPhone(e.target.value);
  };
  const setEmail = (e) => {
    props.setEmail(e.target.value);
  };
  const setPassword = (e) => {
    props.setPassword(e.target.value);
  };
  const setConfirmPassword = (e) => {
    props.setConfirmPassword(e.target.value);
  };

  return (
    <MDBBox id={`trade-register-step-two`}>
      {termsAccepted !== "" && (
        <Alert
          color="warning"
          isOpen={true}
          toggle={() => setTermsAccepted("")}
        >
          {termsAccepted}
        </Alert>
      )}
      <MDBRow>
        <MDBCol md="6">
          <div className="grey-text">
            <InputField
              label={`${
                shouldMarkError("firstname", errors, touched)
                  ? "first name is required"
                  : "first name*"
              }`}
              group
              type="text"
              validate
              error="wrong"
              success="right"
              value={props.firstName}
              onChange={setFirstName}
              onBlur={(e) => {
                setTouched({ ...touched, firstname: true });
              }}
              className={
                shouldMarkError("firstname", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <InputField
              label={`${
                shouldMarkError("lastname", errors, touched)
                  ? "last name is required"
                  : "last name*"
              }`}
              group
              type="text"
              validate
              error="wrong"
              success="right"
              value={props.lastName}
              onChange={setLastName}
              onBlur={(e) => {
                setTouched({ ...touched, lastname: true });
              }}
              className={
                shouldMarkError("lastname", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <div className={`state-selection-container`}>
              <Select2
                name={"country"}
                type={"text"}
                icon="address"
                value={countrySelectShippingSecondStep}
                onChange={(event) =>
                  setCountrySelectHandler(
                    event,
                    setMask,
                    setMaskSanitizedArray,
                    setCountrySelectShippingSecondStep
                  )
                }
                required={true}
                wrapperClass="small"
                placeholder="country"
                options={countriesData}
                useCustom={true}
                isSearchable={true}
              />
            </div>
            <div className={`md-form form-group default-input-field-container`}>
              <MaskedInput
                id="mask-input"
                type="text"
                error="wrong"
                success="right"
                value={props.phone}
                onChange={setPhone}
                mask={maskSanitizedArray}
                guide={false}
                placeholder={`${
                  shouldMarkError("phone", errors, touched)
                    ? "phone is required"
                    : "phone*"
                }`}
                className={`form-control validate default-input-field ${
                  shouldMarkError("phone", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                render={(ref, inputProps) => {
                  return <input {...inputProps} ref={ref} label="phone" />;
                }}
              />
            </div>
          </div>
        </MDBCol>
        <MDBCol md="6">
          <div className="grey-text">
            <InputField
              label={`${
                shouldMarkError("email", errors, touched)
                  ? "email is required (example: info@ducduc.com)"
                  : "email*"
              }`}
              group
              type="email"
              autoComplete={false.toString()}
              validate
              error="wrong"
              success="right"
              labelClass="email"
              value={props.email}
              onChange={setEmail}
              onBlur={(e) => {
                setTouched({ ...touched, email: true });
              }}
              className={
                shouldMarkError("email", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <InputField
              label={`${
                shouldMarkError("password", errors, touched)
                  ? "password is required, should match confirm password"
                  : "password*"
              }`}
              group
              type="password"
              validate
              error="wrong"
              success="right"
              labelClass="password"
              value={props.password}
              onChange={setPassword}
              onBlur={(e) => {
                setTouched({ ...touched, password: true });
              }}
              className={
                shouldMarkError("password", errors, touched)
                  ? "validation-error"
                  : ""
              }
            />
            <InputField
              label="confirm password*"
              group
              type="password"
              validate
              error="wrong"
              success="right"
              labelClass="confirm-password"
              value={props.confirmPassword}
              onChange={setConfirmPassword}
            />
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="req-block">
          <div className="text-right required-text-block">
            <span className="required-sign">*</span>
            <span className="required-text">= required</span>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="step-two-buttons">
        <MDBCol md="6">
          <div className="text-left py-4 mt-3">
            <Button
              outline={true}
              className={"white-button"}
              children={"back"}
              direction={"ml-3"}
              onClick={() => props.setPreviousStep()}
            />
          </div>
        </MDBCol>
        <MDBCol md="6">
          <div className="text-right py-4 mt-3">
            <Button
              className={"right-arrow orange-button"}
              direction={"ml-3"}
              righticon={"true"}
              disabled={loading}
              icon={loading ? "" : "arrow-right"}
              text={loading ? "verifying" : "next"}
              onClick={() => {
                validateInputStepTwo();
              }}
            />
          </div>
        </MDBCol>
      </MDBRow>
    </MDBBox>
  );
};

export default RegisterStepTwo;
