import React, { useState } from "react";
import ShareBtn from "./shareBtn/shareBtn";
import { MDBBox, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import InputField from "core/components/inputField/inputField";

const Share = (props) => {
  let search = new URLSearchParams(props.location.search);
  let mainWishlist = search.get("main");
  let activeWishlist = search.get("active");

  let { shareEmails, setShareEmails, messages, setMessages } = props;
  const [touched, setTouched] = useState({
    shareEmails: false,
    messages: false,
  });
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  return (
    <MDBContainer className={"share-wishlist-container"}>
      <MDBRow>
        <MDBCol>
          <h3>share wishlist</h3>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <h3
            className="share-info"
            style={{ color: "#858B8B", marginTop: "5rem" }}
          >
            sharing information
          </h3>
        </MDBCol>
      </MDBRow>
      <MDBRow className="flex-column">
        <MDBCol className={"w-100"}>
          <MDBBox className={"w-100 textarea email-addresses share-text-area"}>
            <InputField
              className={`share-input ${emailError ? "validation-error" : ""}`}
              label={`${
                emailError
                  ? shareEmails.length === 0
                    ? "email is required*"
                    : "email is invalid*"
                  : "email addresses separated by commas*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, shareEmails: true });
              }}
              validate
              type="textarea"
              id="exampleFormControlTextarea1"
              error="wrong"
              success="right"
              value={shareEmails}
              onChange={(e) => {
                setTouched({ ...touched, shareEmails: true });
                setShareEmails(e.target.value);
              }}
            />
          </MDBBox>
        </MDBCol>
        <MDBCol className={"w-100"}>
          <MDBBox className={"w-100 textarea share-text-area"}>
            <InputField
              className={`share-input ${
                messageError ? "validation-error" : "tsetetetetet"
              }`}
              label={`${messageError ? "message is required*" : "message*"}`}
              onBlur={(e) => {
                setTouched({ ...touched, shareEmails: true });
              }}
              validate
              type="textarea"
              id="exampleFormControlTextarea1"
              error="wrong"
              success="right"
              value={messages}
              onChange={(e) => {
                setMessages(e.target.value);
              }}
            />
          </MDBBox>
        </MDBCol>
        <MDBCol className="req-block">
          <div className="text-right required-text-block">
            <span className="required-sign">*</span>
            <span className="required-text">= required</span>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBBox>
        <ShareBtn
          {...props}
          setTouched={setTouched}
          touched={touched}
          setEmailError={setEmailError}
          setMessageError={setMessageError}
          mainWishlist={mainWishlist}
          activeWishlist={activeWishlist}
        />
      </MDBBox>
    </MDBContainer>
  );
};
export default Share;
