import React from "react";
import { MDBBox } from "mdbreact";
import Button from "core/components/button/button";

const StaticPageTop = props => {
  return (
    <div className="static-page-top-block">
      {props.position === "right" ? (
        <>
          <div className="d-flex flex-wrap block-with-right-img">
            <div className="block-text block-part">
              <MDBBox tag="h5">{props.title}</MDBBox>
              <MDBBox tag="p">{props.description}</MDBBox>
              <br></br>
              <MDBBox tag="p">{props.description2}</MDBBox>
              <Button
                onClick={props.onClick}
                leftIcon={props.leftIcon}
                righticon={props.righticon}
                icon={props.icon}
                direction={props.direction}
                className={props.className}
                children={props.children}
                to={props.href}
              ></Button>
            </div>
            <div className="block-img block-part">
              <img src={props.image} className="img-fluid" alt="" />
            </div>
          </div>
        </>
      ) : (
          <>
            <div className="d-flex flex-wrap block-with-left-img">
              <div className="block-img block-part">
                <img src={props.image} className="img-fluid" alt="" />
              </div>
              <div className="block-text block-part">
                <MDBBox tag="h5">{props.title}</MDBBox>
                <MDBBox tag="p">{props.description}</MDBBox>
                <br></br>
                <MDBBox tag="p">{props.description2}</MDBBox>
                <Button
                  onClick={props.onClick}
                  leftIcon={props.leftIcon}
                  righticon={props.righticon}
                  icon={props.icon}
                  direction={props.direction}
                  className={props.className}
                  children={props.children}
                  to={props.href}
                ></Button>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default StaticPageTop;
