import React from "react";
import Image from "core/components/image";
import { GlassMagnifier } from "react-image-magnifiers";
import { default as themeConfiguration } from "config/themeConfiguration";

// mouse movement function variables
let isDown = false;
let startX;
let scrollLeft;

const product_url =
  themeConfiguration.magento_url +
  themeConfiguration.media_url +
  themeConfiguration.catalog_url;

export function getMediaGalleryEntries(
  images,
  currentIndex,
  setCurrentIndex,
  useMediaGallery = true
) {
  let media_gallery = [];
  if (useMediaGallery) {
    Object.keys(images).map((value, index) => {
      let image = images[index];
      let key = Math.random();
      let image_url = product_url + image.file;

      return (media_gallery[index] = (
        <GlassMagnifier
          key={`magnifier-index-${image_url}-${index}`}
          imageSrc={image_url}
          magnifierSize={"30%"}
          largeImageSrc={""}
          square={false}
          allowOverflow={true}
          magnifierBorderColor={"rgba(255, 255, 255, .5)"}
          magnifierBackgroundColor={"rgba(255, 255, 255, .5)"}
          cursorStyle={"none"}
        />
      ));
    });
  } else {
  }

  return media_gallery;
}

export function getThumbnailEntries(images, currentIndex, setCurrentIndex) {
  let media_gallery = images;
  let thumbnails = {};

  Object.keys(media_gallery).map((value, index) => {
    let image = media_gallery[index];
    let image_url = product_url + image.file;
    return (thumbnails[index] = (
      <div
        key={`media-gallery-entry-thumbnail-${index}`}
        onClick={(e) => setCurrentIndex(index)}
        className={`thumbnail-item ${
          (currentIndex === index && "thumbnail-item-active") || ""
        }`}
      >
        <Image
          key={`media-gallery-entry-${index}`}
          source={image_url}
          className={`media-gallery-item ${
            (index === currentIndex && "active-item") || ""
          }`}
          onClick={(e) => setCurrentIndex(index)}
        />
      </div>
    ));
  });
  return thumbnails;
}

export function getMediaGalleryConfiguration() {
  const mediaGallery = themeConfiguration.mediaGallery;

  let mediaGalleryConfiguration = {
    nav: mediaGallery.nav,
    background_color: "E88602",
    color: "FFFFFF",
  };

  if (window.innerWidth < 1000) {
    mediaGalleryConfiguration["device"] = mediaGallery.mobile;
    return mediaGalleryConfiguration;
  }

  mediaGalleryConfiguration["device"] = mediaGallery.desktop;
  return mediaGalleryConfiguration;
}

export function getPlaceholderImage(placeholderImage) {
  return <Image source={`${placeholderImage.url}`} key={placeholderImage} />;
}

// thumbnail movement function
export const mousedown = (e, setState, state) => {
  isDown = true;
  state.classList.add("active");
  startX = e.pageX - state.offsetLeft;
  scrollLeft = state.scrollLeft;
};
export const mouseleave = (e, setState, state) => {
  isDown = false;
  state.classList.remove("active");
};
export const mouseup = (e, setState, state) => {
  isDown = false;
  state.classList.remove("active");
};
export const mousemove = (e, setState, state) => {
  if (!isDown) return;
  e.preventDefault();
  const x = e.pageX - state.offsetLeft;
  const walk = (x - startX) * 3; //scroll-fast
  e.target.scrollLeft = scrollLeft - walk;
};
