import React from "react";
import {
  MDBRow,
  MDBNavLink,
  MDBNavbarNav,
  MDBBox,
  MDBCollapse,
  MDBHamburgerToggler,
} from "mdbreact";
import { UncontrolledDropdown } from "reactstrap";
import { default as StaticLinks } from "./data";

import HeaderDropdown from "../dropdown";

const Menu = (props) => {
  let {
    menuItems,
    position,
    setPosition,
    hoveredDropdown,
    setHoveredDropdown,
    showDropdown,
    setShowDropdown,
  } = props;

  const toggleCollapse = () => {
    props.setCollapseID(!props.collapseID);
  };

  return (
    <>
      <div
        className={`d-inline-flex flex-column justify-content-start align-items-start position-relative nav-items`}
        onMouseLeave={(e) => {
          e.preventDefault();
          setShowDropdown(false);
        }}
      >
        <MDBRow
          className="menu-items"
          onMouseLeave={(e) => {
            e.preventDefault();
            setShowDropdown(false);
          }}
        >
          <MDBHamburgerToggler
            color="#ff6d2d"
            id="hamburger1"
            onClick={() => toggleCollapse()}
          />
          <MDBCollapse isOpen={props.collapseID} navbar>
            <MDBNavbarNav left className={`align-items-center`}>
              {Object.keys(menuItems).map((element, index) => {
                let currentMenuItem = menuItems[index];
                if (menuItems[index].include_in_menu === 0) {
                  return (
                    <React.Fragment
                      key={`empty-menu-${index}`}
                    ></React.Fragment>
                  );
                }
                return (
                  (currentMenuItem.children.length > 0 && (
                    <UncontrolledDropdown
                      key={`uncontrolled-nav-dropdown-${index}`}
                      onMouseEnter={(e) => {
                        e.preventDefault();
                        setHoveredDropdown(index);
                        setShowDropdown(true);
                      }}
                    >
                      <MDBBox
                        className=" dropdown-toggle nav-link p-0 "
                        id={`nav-dropdown-${index}`}
                      >
                        <MDBNavLink
                          to={`/${currentMenuItem.url_path}`}
                          className={`mr-5 text-uppercase text-decoration-none`}
                        >
                          <div
                            className={`menu-item ${currentMenuItem.name}`}
                            id={`menu-item-${index}`}
                          >
                            {currentMenuItem.name}
                          </div>
                        </MDBNavLink>
                      </MDBBox>
                    </UncontrolledDropdown>
                  )) || (
                    <MDBBox
                      key={`uncontrolled-nav-box-${index}`}
                      className=" dropdown-toggle nav-link p-0 "
                      id={`nav-dropdown-${index}`}
                    >
                      <MDBNavLink
                        to={`/${currentMenuItem.url_path}`}
                        className={`mr-5 text-uppercase text-decoration-none`}
                        onMouseEnter={(e) => {
                          e.preventDefault();
                          setHoveredDropdown(false);
                          setShowDropdown(false);
                        }}
                      >
                        <div className="menu-item" id={`menu-item-${index}`}>
                          {currentMenuItem.name}
                        </div>
                      </MDBNavLink>
                    </MDBBox>
                  )
                );
              })}
              {StaticLinks.map((value, index) => {
                return (
                  <UncontrolledDropdown
                    key={`uncontrolled-nav-dropdown-${index}`}
                  >
                    <MDBBox className="dropdown-toggle nav-link static-link">
                      <MDBNavLink
                        to={value.url}
                        className={`mr-5 text-uppercase p-0 text-decoration-none`}
                        onMouseEnter={(e) => {
                          setShowDropdown(false);
                        }}
                      >
                        {value.name}
                      </MDBNavLink>
                    </MDBBox>
                  </UncontrolledDropdown>
                );
              })}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBRow>
      </div>
      <MDBRow className="dropdown-items">
        <HeaderDropdown
          hoveredDropdown={hoveredDropdown}
          setHoveredDropdown={setHoveredDropdown}
          menuItems={menuItems}
          position={position}
          setPosition={setPosition}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
        />
      </MDBRow>
    </>
  );
};

export default Menu;
