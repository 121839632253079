import React, { useState, useRef } from "react";
import { MDBRow, MDBCol, MDBInput, MDBFormInline, MDBBox } from "mdbreact";
import { Alert } from "reactstrap";
import CheckButton from "core/components/checkButton";
import Button from "core/components/button";
import axios from "axios";
import InputField from "core/components/inputField";
import Input from "core/components/input";
import { default as themeConfiguration } from "config/themeConfiguration";
import Select2 from "core/components/select";
import { validate, shouldMarkError } from "./validator";
import MaskedInput from "react-text-mask";

const RegisterStepOne = (props) => {
  let certificate = "#";
  if (props.data !== undefined) {
    certificate = props.data.forms.form
      .filter((item) => {
        return item.title === "cc authorization form";
      })
      .map((item) => item);
  }

  const downloadData = () => {
    fetch(certificate[0].document).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "CREDIT CARD AUTHORIZATION FORM.pdf";
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  const [termsAccepted, setTermsAccepted] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [touched, setTouched] = useState({});
  const [resaleCertificateError, setResaleCertificateError] = useState(false);

  const url =
    themeConfiguration.magento_url +
    "rest/V1/jola-customerregister/uploadDocument";
  const inputEl = useRef(null);

  let errors = validate(props.companyName, props.websiteUrl, props.taxIdNumber);
  const validateInputStepOne = () => {
    let isTrue = true;
    setTouched({
      company: true,
      website: true,
      tax: true,
    });

    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    if (props.resaleCertificate === "") {
      setResaleCertificateError(true);
      isTrue = false;
    }
    if (isTrue) {
      props.setNextStep();
    } else {
      props.addMessage("Please fill all required fields marked in red.");
      if (shouldMarkError("tax", errors, { tax: true })) {
        props.addMessage(
          "tax id number should be in US or CA formats (12-3456789 / 123-456-789)"
        );
      }
    }
  };
  const changeState = (nr) => () => {
    if (nr === 1) {
      props.setPlacedOrderBefore(true);
    } else {
      props.setPlacedOrderBefore(false);
    }
  };
  const decrease = (e) => {
    e.preventDefault();
    let value = parseInt(document.getElementById("input-plus-minus").value) - 1;
    if (value <= 0) {
      props.setSpecifyNumberOfStores(0);
    } else {
      props.setSpecifyNumberOfStores(value);
    }
  };

  const increase = (e) => {
    e.preventDefault();
    let value = parseInt(document.getElementById("input-plus-minus").value) + 1;
    props.setSpecifyNumberOfStores(value);
  };

  function handleChange(evt) {
    let value = "";
    props.setBusinessType(evt);
  }

  const handleOptionChange = (option) => {
    props.setSelectedOption(option);
  };

  const setCompanyName = (e) => {
    props.setCompanyName(e.target.value);
  };

  const setWebsiteUrl = (e) => {
    props.setWebsiteUrl(e.target.value);
  };

  const setTaxIdNumber = (e) => {
    props.setTaxIdNumber(e.target.value);
  };

  const setTradeShowSpecify = (e) => {
    props.setTradeShowSpecify(e.target.value);
  };

  const setOtherSpecify = (e) => {
    props.setOtherSpecify(e.target.value);
  };

  //ResaleCertificate upload
  const fileResaleCertificate = async (e) => {
    setResaleCertificateError(false);
    let file = e.target.files[0];
    if (typeof file !== "undefined" && file.type !== undefined) {
      if (
        (file.type === "application/pdf" ||
          file.type === "image/jpeg" ||
          file.type === "image/tiff") &&
        file.size / 1000000 <= 2
      ) {
        setTermsAccepted("");
        props.setResaleCertificate(file);

        const formData = new FormData();
        formData.append("file", file);
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        let res = await axios
          .post(url, formData, config)
          .then((data) => {
            if (data.status === 200) {
              props.addMessage("file uploaded successfully", "success");
              props.setResaleCertificateData(data.data);
            }
          })
          .catch((err) => {
            props.setResaleCertificate("");
            setTermsAccepted("something went wrong while uploading file");
          });
      } else {
        props.setResaleCertificate("");
        setTermsAccepted("File format not supported or file too large");
      }
    }
  };

  const uploadResaleCertificate = () => {
    document.getElementById("fileResaleCertificate").click();
  };

  //CreditCardCertification upload
  const fileCreditCardCertification = async (e) => {
    let file = e.target.files[0];
    if (typeof file !== "undefined" && file.type !== undefined) {
      if (
        (file.type === "application/pdf" ||
          file.type === "image/jpeg" ||
          file.type === "image/tiff") &&
        file.size / 1000000 <= 2
      ) {
        setTermsAccepted("");
        props.setCreditCardLabel(file);

        const formData = new FormData();
        formData.append("file", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        let res = await axios
          .post(url, formData, config)
          .then((data) => {
            if (data.status === 200) {
              props.addMessage("file uploaded successfully", "success");
              props.setCreditCardData(data.data);
            }
          })
          .catch((err) => {
            props.setCreditCardLabel("");
            setTermsAccepted("something went wrong while uploading file");
          });
      } else {
        props.setCreditCardLabel("");
        setTermsAccepted("File format not supported or file too large");
      }
    }
  };

  const uploadCreditCardCertification = () => {
    document.getElementById("fileCreditCardCertification").click();
  };


  return (
    <MDBBox id={`trade-register-step-one`}>
      {termsAccepted !== "" && (
        <Alert
          color="warning"
          isOpen={true}
          toggle={() => setTermsAccepted("")}
        >
          {termsAccepted}
        </Alert>
      )}
      {successMessage !== "" && (
        <Alert
          color="success"
          isOpen={true}
          toggle={() => setSuccessMessage("")}
        >
          {successMessage}
        </Alert>
      )}
      <MDBRow>
        <MDBCol md="12" lg="6">
          <div className="grey-text">
            <InputField
              label={`${
                shouldMarkError("company", errors, touched)
                  ? "company name is required*"
                  : "company name*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, company: true });
              }}
              value={props.companyName !== undefined ? props.companyName : ""}
              onChange={(e) => {
                setCompanyName(e);
              }}
              className={
                shouldMarkError("company", errors, touched)
                  ? "validation-error"
                  : ""
              }
              group
              type="text"
              validate
              error="wrong"
              success="right"
            />

            <InputField
              label={`${
                shouldMarkError("website", errors, touched)
                  ? "website url invalid (example: https://ducduc.com or ducduc.com)"
                  : "website url"
              }`}
              group
              type="text"
              validate
              error="wrong"
              success="right"
              className={
                shouldMarkError("website", errors, touched)
                  ? "validation-error"
                  : ""
              }
              value={props.websiteUrl !== undefined ? props.websiteUrl : ""}
              onChange={(e) => {
                props.setWebsiteUrl(e.target.value);
              }}
              onBlur={(e) => {
                setWebsiteUrl(e);
                setTouched({ ...touched, website: true });
              }}
            />
            <div className="md-form form-group default-input-field-container">
              <MaskedInput
                id="mask-input"
                value={props.taxIdNumber !== undefined ? props.taxIdNumber : ""}
                onChange={(e) => {
                  setTaxIdNumber(e);
                }}
                onBlur={(e) => {
                  setTaxIdNumber(e);
                  setTouched({ ...touched, tax: true });
                }}
                mask={[
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                  /^[0-9-]*$/,
                ]}
                placeholder={`${
                  shouldMarkError("tax", errors, touched)
                    ? "tax id number invalid (US & CA format)*"
                    : "tax id number*"
                }`}
                guide={false}
                className={`form-control validate default-input-field `}
                render={(ref, innerProps) => {
                  return (
                    <input
                      {...innerProps}
                      ref={ref}
                      group
                      type="text"
                      validate={"true"}
                      error="wrong"
                      success="right"
                      className={`
                        control validate default-input-field w-100
                        ${
                          shouldMarkError("tax", errors, touched)
                            ? "validation-error"
                            : ""
                        }`}
                    />
                  );
                }}
              />
            </div>

            <span className="span-big"> uploads </span>
            <MDBRow className={"browse-block"}>
              <MDBCol md="8">
                <InputField
                  disabled
                  label={
                    props.resaleCertificate !== ""
                      ? props.resaleCertificate.name
                      : resaleCertificateError
                      ? "resale certificate is required*"
                      : "resale certificate*"
                  }
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  className={`upload-field mb-4 ${
                    resaleCertificateError ? "validation-error" : ""
                  }`}
                />
                <span className="span-small"> (jpg, pdf, tiff) max 2mb </span>
              </MDBCol>
              <MDBCol md="4" className={"mt-4 browse-btn"}>
                <div className="">
                  <input
                    id="fileResaleCertificate"
                    hidden
                    accept=".pdf,.jpg,.tiff"
                    type="file"
                    onChange={fileResaleCertificate}
                    className={`upload-field`}
                  />
                </div>
                <Button
                  color={"orange-button btn-default without-icon upload-btn"}
                  icon={"share-alt"}
                  children={"browse"}
                  onClick={() => uploadResaleCertificate()}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className={"browse-block"}>
              <MDBCol lg="8" md="8" sm="12">
                <input
                  id="fileCreditCardCertification"
                  hidden
                  accept=".pdf,.jpg,.tiff"
                  type="file"
                  onChange={fileCreditCardCertification}
                />
                <InputField
                  disabled
                  label={
                    props.creditCardLabel !== ""
                      ? props.creditCardLabel.name
                      : "credit card certification form"
                  }
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  className={`upload-field mb-4`}
                />
                <span className="span-small"> (jpg, pdf, tiff) max 2mb </span>
              </MDBCol>
              <MDBCol lg="4" md="4" sm="12" className={"mt-4 browse-btn"}>
                <Button
                  color={"orange-button btn-default without-icon upload-btn"}
                  icon={"share-alt"}
                  children={"browse"}
                  onClick={() => uploadCreditCardCertification()}
                />
                <div className="download-form-detail">
                  <a
                    className="download-form"
                    target="_blank"
                    href={certificate !== "#" ? certificate[0].document : "#"}
                  >
                    download form
                  </a>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>
        <MDBCol md="12" lg="6">
          <MDBRow className="grey-text">
            <MDBCol md="12">
              <div className="select-div-container">
                <Select2
                  className="browser-default custom-Select2"
                  onChange={(e) => handleChange(e)}
                  value={props.businessType}
                  options={[
                    { label: "retail", value: "1" },
                    { label: "designer/design firm", value: "2" },
                  ]}
                  openIndicator="caret-up"
                  closedIndicator="caret-down"
                  useCustom={true}
                >
                  <option value="0" selected>
                    select bussines type
                  </option>
                  <option value="1">retail</option>
                  <option value="2">designer/design firm</option>
                </Select2>
              </div>
            </MDBCol>
          </MDBRow>
          {props.businessType.value === "1" && (
            <MDBRow className="step-one-radio-btns">
              <MDBCol md="12">
                <CheckButton
                  containerClass={"radio-container check-button"}
                  wrapperClass={"radio"}
                  label={"national"}
                  value="national"
                  checked={props.selectedOption === "national"}
                  onChange={(e) => handleOptionChange("national")}
                  type={"radio"}
                />
                <CheckButton
                  containerClass={"radio-container check-button"}
                  wrapperClass={"radio"}
                  label={"regional"}
                  value="regional"
                  checked={props.selectedOption === "regional"}
                  onChange={(e) => handleOptionChange("regional")}
                  type={"radio"}
                />
                <CheckButton
                  containerClass={"radio-container check-button"}
                  wrapperClass={"radio"}
                  label={"independent"}
                  value="independent"
                  checked={props.selectedOption === "independent"}
                  onChange={(e) => handleOptionChange("independent")}
                  type={"radio"}
                />
                {props.selectedOption === "independent" && (
                  <MDBRow>
                    <MDBCol className={`ml-5 specify-store-container`}>
                      <span className="span-big specify-stores">
                        specify the number of stores:
                      </span>
                      <div className="def-number-input number-input d-none">
                        <button
                          onClick={(e) => decrease(e)}
                          className="minus"
                        ></button>
                        <input
                          className="quantity"
                          name="quantity"
                          value={props.specifyNumberOfStores}
                          type="number"
                        />
                        <button
                          onClick={(e) => increase(e)}
                          className="plus"
                        ></button>
                      </div>
                      <Input
                        wrapperClassName="quantity-field"
                        name="quantity"
                        id="input-plus-minus"
                        //min={"0"}
                        // max={"5000"}
                        value={props.specifyNumberOfStores}
                        onChange={(e) => {
                          let value = e.target.value.replace("e", "");
                          if (e.target.value !== "")
                            props.setSpecifyNumberOfStores(value);
                        }}
                        type="number"
                        ref={inputEl}
                        minusClick={(e) => decrease(e)}
                        plusClick={(e) => increase(e)}
                      ></Input>
                    </MDBCol>
                  </MDBRow>
                )}

                <CheckButton
                  containerClass={"radio-container check-button"}
                  wrapperClass={"radio"}
                  label={"other"}
                  value="other"
                  checked={props.selectedOption === "other"}
                  onChange={(e) => handleOptionChange("other")}
                  type={"radio"}
                />
              </MDBCol>
            </MDBRow>
          )}
          <div className="how-did-u">
            <span className="span-big">how did you hear about us?</span>
          </div>
          <MDBRow>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"trade magazine"}
                onChange={() => props.setTradeMagazine(!props.tradeMagazine)}
                checked={props.tradeMagazine}
                type={"checkbox"}
              />
            </MDBCol>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"company website"}
                onChange={() => props.setCompanyWebsite(!props.companyWebsite)}
                checked={props.companyWebsite}
                type={"checkbox"}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"instagram"}
                onChange={() => props.setInstagram(!props.instagram)}
                checked={props.instagram}
                type={"checkbox"}
              />
            </MDBCol>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"facebook"}
                onChange={() => props.setFacebook(!props.facebook)}
                checked={props.facebook}
                type={"checkbox"}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"pinterest"}
                onChange={() => props.setPinterest(!props.pinterest)}
                checked={props.pinterest}
                type={"checkbox"}
              />
            </MDBCol>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"linkedin"}
                onChange={() => props.setLinkedin(!props.linkedin)}
                checked={props.linkedin}
                type={"checkbox"}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"retailer"}
                onChange={() => props.setRetailer(!props.retailer)}
                checked={props.retailer}
                type={"checkbox"}
              />
            </MDBCol>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"in-house show"}
                onChange={() => props.setInHouseShow(!props.inHouseShow)}
                checked={props.inHouseShow}
                type={"checkbox"}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"word of mouth"}
                onChange={() => props.setWordOfMouth(!props.wordOfMouth)}
                checked={props.wordOfMouth}
                type={"checkbox"}
              />
            </MDBCol>
            <MDBCol md="6"></MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"trade show"}
                onChange={() => {
                  props.setTradeShow(!props.tradeShow);
                  if (props.tradeShow) {
                    props.setTradeShowSpecify(undefined);
                  }
                }}
                checked={props.tradeShow}
                type={"checkbox"}
              />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                containerClass="specify-input-container"
                className="specify-input-field my-0"
                disabled={!props.tradeShow}
                labelClass="please-specify"
                label="please specify"
                group
                type="text"
                value={
                  props.tradeShowSpecify !== undefined
                    ? props.tradeShowSpecify
                    : ""
                }
                onChange={setTradeShowSpecify}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <CheckButton
                containerClass={
                  "checkbox-container check-button multiselect-how-did-u"
                }
                wrapperClass={"checkmark"}
                label={"others"}
                onChange={() => {
                  props.setOther(!props.other);
                  if (props.other) {
                    props.setOtherSpecify(undefined);
                  }
                }}
                checked={props.other}
                type={"checkbox"}
              />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                containerClass="specify-input-container"
                className="specify-input-field"
                disabled={!props.other}
                labelClass="please-specify"
                label="please specify"
                group
                type="text"
                value={
                  props.otherSpecify !== undefined ? props.otherSpecify : ""
                }
                onChange={setOtherSpecify}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className={`mb-4`}>
              <span className="span-big">
                have you placed an order with ducduc before?
              </span>
            </MDBCol>
          </MDBRow>
          <MDBFormInline className="step-one-bottom-radio-btns">
            <CheckButton
              containerClass={"radio-container check-button radio-yes-no"}
              wrapperClass={"radio"}
              label={"yes"}
              value="yes"
              onChange={changeState(1)}
              checked={props.placedOrderBefore ? true : false}
              type={"radio"}
            />
            <CheckButton
              containerClass={"radio-container check-button radio-yes-no"}
              wrapperClass={"radio"}
              label={"no"}
              value="no"
              onChange={changeState(2)}
              checked={!props.placedOrderBefore ? true : false}
              type={"radio"}
            />
          </MDBFormInline>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="req-block">
          <div className="text-right required-text-block">
            <span className="required-sign">*</span>
            <span className="required-text">= required</span>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="12" className="b2b-next">
          <div className="text-right">
            <Button
              className={"right-arrow orange-button"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
              text={"next"}
              onClick={() => validateInputStepOne()}
            />
          </div>
        </MDBCol>
      </MDBRow>
    </MDBBox>
  );
};

export default RegisterStepOne;
