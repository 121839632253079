import React, { memo, useState } from "react";
import Coupon from "./coupon";
import { isEqual } from "lodash";

const CouponStateContainer = memo(
  ({ cart, lock, applyCouponOnCart, removeCouponFromCart }) => {
    const [couponLoading, setCouponLoading] = useState(false);
    const [discountCode, setDiscountCode] = useState("");
    const [discountOpen, setDiscountOpen] = useState(false);
    return (
      <Coupon
        cart={cart}
        lock={lock}
        applyCouponOnCart={applyCouponOnCart}
        removeCouponFromCart={removeCouponFromCart}
        couponLoading={couponLoading}
        setCouponLoading={setCouponLoading}
        discountCode={discountCode}
        setDiscountCode={setDiscountCode}
        discountOpen={discountOpen}
        setDiscountOpen={setDiscountOpen}
      />
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default CouponStateContainer;
