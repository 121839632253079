import React from "react";
import { connect } from "react-redux";
import { customerActions } from "core/state/redux/data/customer";
import { searchActions } from "core/state/redux/data/search";
import MobileCartContainer from "./mobile-cart-container";

const MobileReduxContainer = (props) => {
  let { customer } = props;
  let token = false;
  if (
    typeof customer !== "undefined" &&
    typeof customer.data !== "undefined" &&
    customer.data.token !== false
  ) {
    token = customer.data.token;
  }
  return <MobileCartContainer {...props} token={token} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  cartToken:
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cartToken !== "undefined"
      ? state.customer.data.cartToken
      : undefined,
  showSearch: state.search.show,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logoutCustomer: () => {
      dispatch(customerActions.logoutCustomer());
    },
    createEmptyCart: () => {
      dispatch(customerActions.createEmptyCart());
    },
    getCartInformation: () => {
      dispatch(customerActions.getCartInformation());
    },
    toggleSearch: (toggle) => {
      dispatch(searchActions.toggleSearch(toggle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileReduxContainer);
