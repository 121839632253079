import React from "react";
import { connect } from "react-redux";
import { default as commonActions } from "core/state/redux/data/common/actions";
import ProductRegistration from "./productRegistration";
import { messagesActions } from "core/state/redux/data/messages";

const ProductRegistrationReduxContainer = props => {
  return <ProductRegistration {...props} />;
};
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    sendEmail: data => {
      dispatch(commonActions.sendEmail(data));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: callbackFunction => {
      dispatch(commonActions.lock(callbackFunction));
    },
    addMessage: (messageText, type = "danger") => {
      dispatch(messagesActions.addMessage(messageText, type));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductRegistrationReduxContainer);
