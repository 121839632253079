import React, { memo } from "react";
import CheckButton from "core/components/checkButton";
import Price, { getOnlyPrice } from "core/components/price";
import { isEqual } from "lodash";
import { changeOption } from "./functions";
const CheckboxItem = memo(
  ({
    value,
    state,
    setState,
    reduceProductOptions,
    product,
    optionProps,
    option,
  }) => {
    return (
      <>
        <CheckButton
          containerClass={"checkbox-container check-button"}
          wrapperClass={"checkmark gray"}
          label={`${value.title} (+${getOnlyPrice({
            price: value.price,
            type: "fixed",
          })})`}
          value={value.option_type_id}
          onChange={(e) => {
            if (state.selectedValue === value.option_type_id) {
              setState({ ...state, selectedValue: false });
            } else {
              setState({ ...state, selectedValue: value.option_type_id });
            }
            changeOption(
              state.selectedValue === false ? value : false,
              option,
              product,
              reduceProductOptions,
              optionProps,
              value.price
            );
          }}
          checked={
            state.selectedValue === value.option_type_id /**some state check */
          }
          type={"checkbox"}
        ></CheckButton>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default CheckboxItem;
