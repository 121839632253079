import React, { useState, memo } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBCol,
  MDBRow,
  MDBBox,
  MDBModalHeader,
} from "mdbreact";
import Create from "app/pages/customer/account/pages/favorites/create";
import { mapWishlistRender } from "./functions";
import _ from "lodash";
import SessionMessages from "core/components/sessionMessages";

const Wishlist = memo(
  ({
    product,
    hideModal,
    wishlist,
    wishlistArray,
    addProductToWishlist,
    removeItemFromWishlist,
    options,
    lock,
    inWishlist,
  }) => {
    const [toggleView, setToggleView] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isRemoving, setIsRemoving] = useState(undefined);
    let productItemsRelation = {};
    productItemsRelation[product.id] = [];

    wishlist.items.map((_item) => {
      if (_item.product.id === product.id) {
        productItemsRelation[_item.product.id].push({
          id: _item.id,
          wishlist: _item.multi_wishlist_id,
        });
      }
      return _item;
    });

    const toggle = () => {
      setToggleView(!toggleView);
    };

    return (
      <>
        <MDBContainer id={`pdp-add-product-to-wishlist`}>
          <MDBModal
            className="modal-wrapper"
            isOpen={toggleView}
            id={`copy-modal`}
            toggle={(e) => {
              toggle();
              hideModal();
            }}
          >
            <MDBModalHeader>
              <div className="close-btn">
                <button
                  type="button"
                  className=""
                  onClick={() => {
                    toggle();
                    hideModal();
                  }}
                >
                  <i className="close-icon"></i>
                </button>
              </div>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer fluid className="text-white">
                <MDBRow>
                  <MDBCol md="12">
                    <MDBBox tag="h2" className="text-center">
                      {loading
                        ? isRemoving
                          ? "removing favorite"
                          : "adding to favorites"
                        : "add to favorites"}
                    </MDBBox>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12" className="pb-3 border-bottom">
                    <MDBBox className={`wishlist-items-container`}>
                      {mapWishlistRender(
                        wishlist,
                        wishlistArray,
                        product,
                        addProductToWishlist,
                        removeItemFromWishlist,
                        options,
                        lock,
                        loading,
                        setLoading,
                        setIsRemoving
                      )}
                    </MDBBox>
                    <Create
                      wrapperClass={"px-0"}
                      target={`wishlist-popup`}
                      text={`add`}
                      loadingtext={`adding...`}
                    />
                    <SessionMessages target="wishlist-popup" />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
        {/* <Login hideModal={hideModal} /> */}
      </>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Wishlist;
