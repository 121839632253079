import React, { useState } from "react";
import InputField from "core/components/inputField/inputField";
import {
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBContainer,
  MDBBox
} from "mdbreact";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";

const Controls = ({
  projectName,
  setProjectName,
  lock,
  loading,
  setLoading,
  setActiveWishlist,
  deleteProject,
  activeWishlist,
  wishlist,
  renameProject,
  addMessage,
  isEditing,
  setIsEditing
}) => {
  // const [tmpProjectName, setTmpProjectName] = useState(projectName);

  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  const toggleDeleteProject = () => {
    setDeleteProjectModal(!deleteProjectModal);
  };
  return (
    <>
      {activeWishlist !== 0 ? (
        <MDBCol id={`wishlist-controls`} className="my-4" lg={"12"} md={"12"}>
          <MDBRow>
            <MDBCol className="d-flex align-items-center name-container">
              <h3 className="pb-0">{projectName}</h3>
              {isEditing && (
                <InputField
                  label={`${
                    projectName.length === 0 ? "enter project name" : ""
                  }`}
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  wrapperClass="rename-input my-0"
                  className="my-0"
                  value={projectName}
                  onChange={e => {
                    if (e.target.value.length <= 24)
                      setProjectName(e.target.value);
                  }}
                />
              )}
            </MDBCol>
            <MDBCol className={`md-form d-flex align-items-center my-0`}>
              {!isEditing && (
                <Icon
                  id={`edit`}
                  className={`mr-4`}
                  onClick={e => {
                    setIsEditing(!isEditing);
                  }}
                >
                  rename
                </Icon>
              )}

              {isEditing && (
                <MDBIcon
                  far
                  className="mr-4"
                  icon="check-circle"
                  onClick={e => {
                    lock({
                      always: e => {
                        setLoading(true);
                      },
                      fail: e => {
                        setLoading(false);
                      },
                      success: e => {
                        setLoading(false);
                      }
                    });
                    setProjectName(projectName);
                    renameProject(activeWishlist, projectName);

                    setIsEditing(!isEditing);
                  }}
                />
              )}
              {isEditing && (
                <MDBIcon
                  far
                  className="mr-4"
                  icon="times-circle"
                  onClick={e => {
                    setIsEditing(!isEditing);
                  }}
                />
              )}
              {!isEditing && (
                <Icon
                  id={`trashCanOrange`}
                  onClick={() => toggleDeleteProject()}
                >
                  {!loading ? "delete" : "deleting..."}
                </Icon>
              )}
              {deleteProjectModal && (
                <MDBContainer>
                  <MDBModal
                    centered
                    isOpen={deleteProjectModal}
                    toggle={toggleDeleteProject}
                    className={"modal-wrapper"}
                    id={`delete-project-modal`}
                  >
                    <MDBModalHeader onClick={() => toggleDeleteProject()}>
                      <div className="close-btn">
                        <button type="button">
                          <i className="close-icon"></i>
                        </button>
                      </div>
                    </MDBModalHeader>
                    <MDBModalBody className={`copy`}>
                      <MDBBox tag="h2">delete this project?</MDBBox>
                      <MDBBox className="del-project-subtitle">
                        If you delete this project all the products will be
                        removed from the list
                      </MDBBox>
                      <MDBBox className="del-project-buttons d-flex">
                        <Button
                          outline={true}
                          className={"right-arrow white-button"}
                          children={"cancel"}
                          onClick={() => toggleDeleteProject(false)}
                        />
                        <Button
                          className={"right-arrow orange-button"}
                          direction={"ml-3"}
                          righticon={"true"}
                          icon={"arrow-right"}
                          children={"delete"}
                          onClick={e => {
                            lock({
                              always: e => {
                                setLoading(true);
                              },
                              fail: e => {
                                setLoading(false);
                              },
                              success: e => {
                                setLoading(false);
                                setActiveWishlist(parseInt(0));
                              }
                            });
                            deleteProject(activeWishlist);
                          }}
                        />
                      </MDBBox>
                    </MDBModalBody>
                  </MDBModal>
                </MDBContainer>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCol>
      ) : (
        <></>
      )}
    </>
  );
};

export default Controls;
