import { memo, useCallback, useMemo } from "react";
import { contentRender } from "../render";
import { findById } from "core/helpers/category";
import lodash from "lodash";

const ParentCategoryContent = memo(
  ({ open, allCategories }) => {
    const findByIdCallBack = useCallback(
      (allCategories, open) => {
        let category = findById(open, allCategories);
        let category_items = [];
        if (typeof category.children !== "undefined") {
          category_items = category.children.map(element => {
            return contentRender(element);
          });
        }

        return category_items;
      },
      [allCategories, open]
    );

    const computeContent = useMemo(
      () => findByIdCallBack(allCategories, open),
      [allCategories, open]
    );
    return computeContent;
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ParentCategoryContent;
