import React from "react";
import { isUndefined, isFunction } from "core/helpers/functions";
import { responsiveData } from "./carousel-data";
import "react-alice-carousel/lib/alice-carousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "core/components/image/image";

const Carousel = (props) => {
  let responsive;

  if (isUndefined(props.responsive)) {
    responsive = responsiveData;
  } else {
    responsive = props.responsive;
  }

  if (isUndefined(props.setCurrentIndex)) return "";
  return (
    <div id={`carousel-${props.carouselId}`} className={props.className}>
      <Image
        source={props.children[props.currentIndex].props.imageSrc}
        // key={`magnifier-index-${key}`}
        alt="mobile-image"
      />
      {!isUndefined(props.slidePrevSlide) &&
        isFunction(props.slidePrevSlide) &&
        !isUndefined(props.slideNextSlide) &&
        isFunction(props.slideNextSlide) &&
        props.itemsInSlide < props.children.length && (
          <div className={`carousel-actions`}>
            <button className={"btn-prev"} onClick={props.slidePrevSlide}>
              <FontAwesomeIcon icon="chevron-left" />
            </button>
            <button className={"btn-next"} onClick={props.slideNextSlide}>
              <FontAwesomeIcon icon="chevron-right" />
            </button>
          </div>
        )}
    </div>
  );
};

export default Carousel;
