import React, { useState } from "react";
import { MDBBox } from "mdbreact";
import CoreImage from "core/components/image";

const CardImage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className={`card-image `}>
      <div className={`card-image-content ${props.cardClass}`}>
        {props.useCmsBlockImage && typeof props.bottomImage === "object" ? (
          props.bottomImage
        ) : (
          <CoreImage
            source={props.bottomImage}
            className={`img-fluid`}
            alt=""
          />
        )}
      </div>

      <h3>{props.imageTitle}</h3>
      <MDBBox tag="p" className={`expandable-text-${isOpen} expandable-text`}>
        {props.imageDescription}
      </MDBBox>
      {(isOpen && (
        <p
          onClick={(e) => {
            toggle();
          }}
          className={`clicked-${isOpen} click-to-show`}
        >
          {props.imageLinkLessText}
        </p>
      )) || (
        <p
          onClick={(e) => {
            toggle();
          }}
          className={`clicked-${isOpen} click-to-show`}
        >
          {props.imageLinkText}
        </p>
      )}
    </div>
  );
};

export default CardImage;
