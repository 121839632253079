import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBLink,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBContainer,
  MDBBox,
} from "mdbreact";
import Image from "core/components/image/image";
import Icon from "app/assets/icon/icon";
import { determineCurrency } from "config/currency";
import { useMediaQuery } from "react-responsive";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Button from "core/components/button";
import Wishlist from "app/pages/catalog/product/info/controls/wishlist";
import { determineIfInWishlist } from "app/pages/catalog/product/info/controls/wishlist-functions";
import { Link } from "react-router-dom";

const Item = ({
  item,
  deleteItem,
  lock,
  addMessage,
  loading,
  setLoading,
  addItemToCart,
  copyWishlistItem,
  wishlist,
  addItemToWishlist,
}) => {
  let { product } = item;
  const [open, isOpen] = useState(false);
  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const isTabOrMob = useMediaQuery({ maxWidth: 992 });
  const toggle = () => {
    isOpen(!open);
  };
  const toggleDeleteProduct = () => {
    setDeleteProductModal(!deleteProductModal);
  };

  return (
    <MDBRow className={`wishlist-item`}>
      <MDBCol className={`wishlist-item-img item`}>
        <MDBLink to={`/` + product.url_key}>
          <Image source={product.image.url} />
        </MDBLink>
      </MDBCol>
      <MDBCol
        className={`col d-flex flex-column justify-content-center align-self-end item`}
      >
        <MDBRow className={`d-flex flex-column`}>
          <MDBCol className={"align-self-end wishlist-item-info"}>
            <MDBRow className={`d-flex flex-column`}>
              <MDBCol className={`wishlist-item-name `}>
                <MDBLink to={`/` + product.url_key}>
                  <span>{product.name}</span>
                </MDBLink>
              </MDBCol>
              <MDBCol>
                <MDBRow className={`wishlist-item-price`}>
                  <MDBCol>
                    <span className={`wishlist-item-starting-label`}>
                      starting
                    </span>
                  </MDBCol>
                  <MDBCol className={`p-0`}>
                    <span className={`wishlist-item-starting-price`}>
                      {determineCurrency(
                        product.price_range.minimum_price.regular_price.currency
                      ) + product.price_range.minimum_price.regular_price.value}
                    </span>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        {isTabOrMob ? (
          <></>
        ) : (
          <MDBRow className={"wishlist-item-copy-btn "}>
            <MDBCol className={"align-self-end item"}>
              <MDBRow>
                <ModalConsumer value={{ useWrapper: false }}>
                  {({ showModal, hideModal }) => (
                    <MDBCol
                      className="wishlist-copy-item align-self-end"
                      onClick={(e) => {
                        showModal(() => {
                          let options = [];
                          return (
                            <Wishlist
                              inWishlist={true}
                              wishlist={wishlist}
                              hideModal={hideModal}
                              product={item.product}
                              options={options}
                            />
                          );
                        });
                      }}
                    >
                      <Button className={"compare-btn"} color={"#FF5100"}>
                        <Icon icon={`moveFavorite`} />
                      </Button>
                    </MDBCol>
                  )}
                </ModalConsumer>
                {/* <MDBCol
                onClick={e => {
                  console.log("move this item");
                }}
              >
                Move
              </MDBCol> */}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )}
      </MDBCol>
      <MDBCol className={"item"}>
        {isTabOrMob ? (
          <MDBRow className={"wishlist-item-copy-btn "}>
            <MDBCol className={"align-self-end item"}>
              <MDBRow>
                <ModalConsumer value={{ useWrapper: false }}>
                  {({ showModal, hideModal }) => (
                    <MDBCol
                      className="wishlist-copy-item align-self-end"
                      onClick={(e) => {
                        showModal(() => {
                          let options = [];
                          return (
                            <Wishlist
                              inWishlist={true}
                              wishlist={wishlist}
                              hideModal={hideModal}
                              product={item.product}
                              options={options}
                            />
                          );
                        });
                      }}
                    >
                      <Button className={"compare-btn"} color={"#FF5100"}>
                        <Icon icon={`moveFavorite`} />
                      </Button>
                    </MDBCol>
                  )}
                </ModalConsumer>
                {/* <MDBCol
                onClick={e => {
                  console.log("move this item");
                }}
              >
                Move
              </MDBCol> */}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        ) : (
          <></>
        )}
      </MDBCol>
      <MDBCol className={`align-self-end wishlist-item-cart-remove item`}>
        <MDBRow className={`d-flex flex-row align-items-center`}>
          {typeof product.options !== "undefined" ? (
            <>
              {(isTabOrMob && (
                <Link to={"/" + product.url_key} className={`edit-link`}>
                  <Icon icon={`edit`} />
                </Link>
              )) || (
                <Link to={"/" + product.url_key} className={`edit-link`}>
                  <Button
                    disabled={loading}
                    className={`wishlist-button orange-button mr-5`}
                    children={"configure"}
                  />
                </Link>
              )}
            </>
          ) : (
            <>
              {(isTabOrMob && (
                <Button
                  disabled={loading}
                  className={`wishlist-button icon-btn mr-5`}
                  children={<Icon icon={`addToCart`} />}
                  onClick={() => {
                    let options = [];
                    addItemToCart(1, product.sku, options);
                  }}
                />
              )) || (
                <Button
                  disabled={loading}
                  className={`wishlist-button orange-button mr-5`}
                  children={"add to cart"}
                  onClick={() => {
                    let options = [];
                    // do customizable option selection from buyRequest opossed from the product itself
                    // if (Object.keys(props.productOptions.options).length > 1) {
                    //   Object.keys(props.productOptions.options).map(key => {
                    //     let _option = props.productOptions.options[key];
                    //     if (
                    //       _option === "" ||
                    //       key === "selectedPrice" ||
                    //       key === "value"
                    //     )
                    //       return;
                    //     let customizableOptionFormat;
                    //     if (_option.title) {
                    //       customizableOptionFormat = {
                    //         id: parseInt(key),
                    //         value_string: String(_option.option_type_id)
                    //       };
                    //     } else {
                    //       customizableOptionFormat = {
                    //         id: parseInt(key),
                    //         value_string: _option.swatch_name
                    //       };
                    //     }
                    //     return options.push(customizableOptionFormat);
                    //   });
                    // }
                    addItemToCart(1, product.sku, options);
                  }}
                />
              )}
            </>
          )}

          <div onClick={() => toggleDeleteProduct()}>
            <Icon
              id={`trashCanOrange`}
              className={`${loading ? "active" : "not-active"}`}
            />
            {deleteProductModal && (
              <MDBContainer>
                <MDBModal
                  centered
                  isOpen={deleteProductModal}
                  toggle={toggleDeleteProduct}
                  className={"modal-wrapper"}
                  id={`delete-product-modal`}
                >
                  <MDBModalHeader onClick={() => toggleDeleteProduct()}>
                    <div className="close-btn">
                      <button type="button">
                        <i className="close-icon"></i>
                      </button>
                    </div>
                  </MDBModalHeader>
                  <MDBModalBody className={`copy`}>
                    <MDBBox tag="h2">delete this product?</MDBBox>
                    <MDBBox className="del-project-subtitle">
                      Are you sure you want to delete this product?
                    </MDBBox>
                    <MDBBox className="del-project-buttons d-flex">
                      <Button
                        outline={true}
                        className={"right-arrow white-button"}
                        children={"cancel"}
                        onClick={() => toggleDeleteProduct(false)}
                      />
                      <Button
                        className={"right-arrow orange-button"}
                        direction={"ml-3"}
                        righticon={"true"}
                        icon={"arrow-right"}
                        children={"delete"}
                        onClick={(e) => {
                          setLoading(true);
                          lock({
                            always: () => {},
                            fail: () => {
                              setLoading(false);
                            },
                            success: () => {
                              setLoading(false);
                            },
                          });
                          deleteItem(item.id);
                        }}
                      />
                    </MDBBox>
                  </MDBModalBody>
                </MDBModal>
              </MDBContainer>
            )}
          </div>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default Item;
