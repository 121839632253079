import React from "react";
import MediaGallery from "app/layout/mediaGallery";
import { MDBRow, MDBCol, MDBBox } from "mdbreact";
import { isUndefined } from "core/helpers/functions";

const Images = (props) => {
  const img_desc = props.data.image_description;
  const images = props.data.media_gallery_entries;
  const placeholder = props.data.image;

  if (!isUndefined(images) && !isUndefined(placeholder)) {
    return (
      <MDBRow className="pdp-media-gallery">
        <MDBCol size="12">
          <MediaGallery images={images} placeholderImage={props.data.image} />
          {img_desc && <MDBBox id="img-material-color-desc">{img_desc}</MDBBox>}
        </MDBCol>
      </MDBRow>
    );
  }
  return "";
};

export default Images;
