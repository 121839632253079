import React, { memo } from "react";
import SearchResults from "./searchResults";
import { gql } from "graphql.macro";
import { useQuery } from "react-apollo-hooks";
import _ from "lodash";

const query = gql`
  query Products(
    $search: String
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      search: $search
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      total_count
      items {
        id
        name
        sku
        url_key
        brands
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
            ... on CustomizableDropDownOption {
              option_id
              title
              value {
                title
                option_type_id
                price
                price_type
                sku
              }
            }
          }
        }
        media_gallery_entries {
          id
          file
          content {
            base64_encoded_data
            name
            type
          }
          disabled
        }
        categories {
          id
        }
        image {
          label
          url
        }
        small_image {
          label
          url
        }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        tier_prices {
          customer_group_id
          percentage_value
          qty
          value
          website_id
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
      sort_fields {
        options {
          label
          value
        }
        default
      }
    }
  }
`;
const SearchResultsQueryContainer = memo(
  ({ search, allCategories, closeSearch, mobileonclick, changeSearch }) => {
    const { data, loading, error } = useQuery(query, {
      variables: { search: `${search}`, pageSize: 100 },
    });

    if (loading)
      return (
        <SearchResults
          data={{ products: { items: [] } }}
          search={search}
          allCategories={allCategories}
          closeSearch={closeSearch}
          loading={loading}
        />
      );
    if (error) return "Error!";

    return (
      <SearchResults
        data={data}
        search={search}
        allCategories={allCategories}
        closeSearch={closeSearch}
        loading={loading}
        mobileonclick={mobileonclick}
        changeSearch={changeSearch}
      />
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default SearchResultsQueryContainer;
