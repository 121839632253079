import React, { useState } from "react";
import Product from "./product";
import { isUndefined, isEmpty } from "core/helpers/functions";
import { Redirect } from "react-router-dom";

const ProductDataContainer = (props) => {
  let { data, items } = props;

  // edit functionality
  let editMode = false;
  let editId = false;
  let cartItem = false;

  let product =
    !isUndefined(data) &&
    !isUndefined(data.products) &&
    !isUndefined(data.products.items[0])
      ? { ...data.products.items[0] }
      : {
          name: "loading...",
          description: { html: "<p>retrieving information...</p>" },
          price_range: { minimum_price: { final_price: { value: "#" } } },
        };

  if (product.__typename === "ConfigurableProduct") {
    if (
      typeof data.products.items[1] !== "undefined" &&
      data.products.items[1].__typename === "SimpleProduct" &&
      typeof product.price_range.minimum_price.final_price !== "undefined"
    ) {
      product.price_range.minimum_price.final_price.value =
        data.products.items[1].price_range.minimum_price.final_price.value;
    }
  }

  let assemblySize = 6;
  let relatedSize = 6;
  let showAssemblyRelated = true;
  if (!isUndefined(product)) {
    let hasAssembly =
      !isUndefined(product.assembly_videos) && product.assembly_videos;
    let hasRelated =
      !isUndefined(product.related_products) &&
      !isEmpty(product.related_products) &&
      product.related_products;
    if (hasAssembly && !hasRelated) {
      assemblySize = 12;
      relatedSize = 0;
      showAssemblyRelated = true;
    } else if (hasRelated && !hasAssembly) {
      relatedSize = 12;
      assemblySize = 0;
      showAssemblyRelated = true;
    } else if (!hasRelated && !hasAssembly) {
      showAssemblyRelated = false;
    }
  }

  const [selectorContent, setSelectorContent] = useState("");

  // edit functionality
  let { location } = window;
  let { search } = location;
  if (search !== "") {
    if (search.includes("edit")) {
      editId = search.split("?edit=");
      if (typeof editId[1] !== undefined) {
        editId = editId[1];
        cartItem = items.find(
          (x) => x.id === editId && x.product.sku === product.sku
        );
        editMode = true;
        if (cartItem === undefined) {
          cartItem = false;
        }
      } else {
        editId = 0;
      }
    }
  }

  let stateProps = {
    assemblySize,
    relatedSize,
    showAssemblyRelated,
    selectorContent,
    setSelectorContent,
  };

  return (
    <Product
      {...props}
      data={product}
      stateProps={stateProps}
      editId={editId}
      editMode={editMode}
      cartItem={cartItem}
      cartItems={items}
    />
  );
};

export default ProductDataContainer;
