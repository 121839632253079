import React from "react";
import SidebarItem from "./sidebar-item/sidebar-item";

const Sidebar = props => {
  let { allCategories } = props;
  let rootChildren = allCategories.children;

  if (window.location.pathname.includes("finishes")) {
    rootChildren = rootChildren.filter(rootChild => {
      return window.location.pathname.includes(rootChild.url_path);
    });
  } else {
    rootChildren = rootChildren.filter(rootChild => {
      return !rootChild.url_path.includes("finishes");
    });
  }

  return (
    <div>
      {rootChildren.map(parentCategory => {
        return (
          <SidebarItem
            key={parentCategory.name + "-" + parentCategory.id}
            category={parentCategory}
            open={props.open}
            setOpen={props.setOpen}
          />
        );
      })}
    </div>
  );
};

export default Sidebar;
