// logic is inversed, true means that an error exists

export const validate = (
  firstname,
  lastname,
  address,
  city,
  zipcode,
  phone,
  country,
  state
) => {
  return {
    firstname: firstname.length === 0,
    lastname: lastname.length === 0,
    address: address.length === 0,
    city: city.length === 0,
    zipcode: zipcode.length === 0 && zipcode.length < 4,
    phone: phone.length === 0,
    state: country === "CA" || country === "US" ? state.length === 0 : false,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};
