import React from "react";
import { MDBRow, MDBCol, MDBBox, MDBContainer } from "mdbreact";
import HeroImage from "app/layout/heroImage";
import HeroImageSrc from "app/assets/images/register/ducduc-register-trade.jpg";
import Button from "core/components/button";
import Login from "app/pages/customer/login";
import { ModalConsumer } from "core/components/modal/ModalContext";

const ErrorPage = (props) => {
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  return (
    <>
      <HeroImage
        id={"error-page-img"}
        className="b2b-banner"
        alt="Shop The Look Banner"
      />
      <MDBContainer className={"page-main-content error-page"}>
        <MDBRow className="error-page-text">
          <MDBCol md="12">
            <MDBBox tag="h3">Sorry, that page could not be found</MDBBox>
            <ul className="custom-list">
              <li>
                - Double check and see if you typed the URL directly, please
                make sure the spelling is correct.
              </li>
              <li>
                - If you clicked on a link to get here, the link is outdated.
              </li>
            </ul>
            <MDBBox tag="h4">what can you do?</MDBBox>
            <MDBBox tag="p">
              Have no fear, help is near! There are many ways you can get back
              on track.
            </MDBBox>
            <ul className="custom-list">
              <li>- Go back to the previous page.</li>
              <li>
                - Use the search bar at the top of the page to search for your
                products.
              </li>
              <li>- Follow these links to get you back on track!</li>
            </ul>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="8" className={"error-page-buttons"}>
            <Button
              outline={true}
              className={"right-arrow white-button"}
              children={"homepage"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
              to={"/"}
            />
            <ModalConsumer value={{ useWrapper: false }}>
              {({ showModal, hideModal }) => (
                <Button
                  className={"right-arrow orange-button account-btn"}
                  direction={"ml-3"}
                  to={"/kids"}
                  children={"continue shopping"}
                  onClick={(e) => {
                    showModal(() => {
                      if (!loggedIn) {
                        e.preventDefault();
                        return <Login hideModal={hideModal} />;
                      } else {
                        return <></>;
                      }
                    });
                  }}
                />
              )}
            </ModalConsumer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default ErrorPage;
