import React, { memo } from "react";
import Items from "./items";
import { chunk, isEqual } from "lodash";

const ItemsDataContainer = memo(
  ({
    items,
    productPerPage,
    pageNumber,
    deleteItem,
    addMessage,
    lock,
    addItemToCart,
    copyWishlistItem,
    wishlist,
    addItemToWishlist
  }) => {
    let chunks = chunk(items, productPerPage);
    return (
      <Items
        items={chunks[pageNumber - 1]}
        deleteItem={deleteItem}
        addMessage={addMessage}
        lock={lock}
        addItemToCart={addItemToCart}
        copyWishlistItem={copyWishlistItem}
        wishlist={wishlist}
        addItemToWishlist={addItemToWishlist}
      />
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ItemsDataContainer;
