import React from "react";
import { connect } from "react-redux";
import SearchResultsQueryContainer from "./searchResults-query-container";
import { searchActions } from "core/state/redux/data/search";
import _ from "lodash";

const SearchResultsReduxContainer = props => {
  let { search, closeSearch, mobileonclick, changeSearch } = props;
  if (typeof search === "undefined" || search.length < 2) {
    return <></>;
  }
  let allCategories = [];
  if (_.isUndefined(props.allCategories[2]) || _.isEmpty(props.allCategories)) {
    allCategories = props.allCategoriesTmp[2].data.categoryList[0];
  } else {
    allCategories = props.allCategories[2].data.categoryList[0];
  }

  return (
    <SearchResultsQueryContainer
      search={search}
      allCategories={allCategories}
      closeSearch={closeSearch}
      mobileonclick={mobileonclick}
      changeSearch={changeSearch}
    />
  );
};
const mapStateToProps = state => ({
  search: state.search.search,
  allCategories: state.queries.data,
  allCategoriesTmp: state.queries.temp
});

const mapDispatchToProps = dispatch => {
  return {
    closeSearch: () => {
      dispatch(searchActions.toggleSearch(false));
    },
    changeSearch: searchTerm => {
      dispatch(searchActions.changeSearch(searchTerm));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsReduxContainer);
