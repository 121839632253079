import { isEmpty, isUndefined } from "core/helpers/functions";
export function getActiveFilters(filters) {
  let active_filters = {};

  if (isEmpty(filters) || isUndefined(filters.items)) {
    return active_filters;
  }

  Object.keys(filters.items).map((index, value) => {
    let filter = filters.items[index];
    let rangeFilter = ifRangeFilter(filter);

    if (rangeFilter) {
      active_filters[index] = {};
      return Object.keys(filter).map((eIndex, eValue) => {
        return (active_filters[index][eIndex] = createFilter(
          index,
          filter[eIndex].filter_value,
          filter[eIndex].filter_label,
          filter[eIndex].filter_type
        ));
      });
    } else if (filter.filter_type === "multi") {
      filter.filter_value.reduce((accumulator, current) => {
        if (
          typeof filters.data.aggregations.find(
            (f) => f.attribute_code === index
          ) !== "undefined" &&
          typeof filters.data.aggregations
            .find((f) => f.attribute_code === index)
            .options.find((i) => i.value === current) !== "undefined"
        ) {
          accumulator[`${index}-${current}`] = createFilter(
            index,
            filter.filter_value,
            filters.data.aggregations
              .find((f) => f.attribute_code === index)
              .options.find((i) => i.value === current).label,
            filter.filter_type
          );
        } else {
          accumulator[`${index}-${current}`] = {};
        }

        return accumulator;
      }, active_filters);
    } else {
      return (active_filters[index] = createFilter(
        index,
        filter.filter_value,
        filter.filter_label,
        filter.filter_type
      ));
    }

    return filter;
  });
  return active_filters;
}

const ifRangeFilter = (filter) => {
  if ("from" in filter && "to" in filter) {
    // get currency based on store
    if (filter.filter_id === "price")
      return "$" + filter.from.filter_value + " - $" + filter.to.filter_value;

    return filter.from.filter_value + " - " + filter.to.filter_value;
  }

  return false;
};

const createFilter = (filter_id, filter_value, filter_label, filter_type) => {
  return {
    filter_id: filter_id,
    filter_value: filter_value,
    filter_label: filter_label,
    filter_type: filter_type,
  };
};
