import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import OrderSuccessStateContainer from "./order-success-state-container";
import { Redirect } from "react-router-dom";

const OrderSuccessReduxContainer = (props) => {
  return <OrderSuccessStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  cart:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined"
      ? state.customer.data.cart
      : {},
  order_id:
    typeof state.customer !== "undefined" &&
    typeof state.customer.order_id !== "undefined"
      ? state.customer.order_id
      : null,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    placeOrderSuccess: () => {
      dispatch(customerActions.placeOrderSuccess(null));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSuccessReduxContainer);
