import React, { memo } from "react";
import { MDBBox, MDBLink } from "mdbreact";
import lodash from "lodash";

const FooteBottom = memo(
  (props) => {
    return (
      <>
        <div className="text-center text-md-left footer-bottom-content  pt-md-4 pt-sm-5 pb-md-4 pb-sm-5 pb-5 pt-5">
          <div className="row align-items-center footer-bottom-content-inner">
            <div className="col-md-3 col-sm-12">
              <a
                href="https://www.tntcommercial.com/"
                className="text-decoration-none"
                target={`_blank`}
              >
                tntcommercial.com
              </a>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="footer-bottom-links">
                {/* <div className="left">
              <div className="">
                <MDBLink to="#" link className="text-decoration-none">
                  Shipping
                </MDBLink>
              </div>
              <div className="">
                <MDBLink to="#" link className="text-decoration-none">
                  Return Policy
                </MDBLink>
              </div>
            </div> */}
                <div className=" right">
                  <div className="">
                    <MDBLink
                      to="terms-and-conditions"
                      link
                      className="text-decoration-none"
                    >
                      Terms & Conditions
                    </MDBLink>
                  </div>
                  <div className="">
                    <MDBLink
                      to="/privacy-policy"
                      link
                      className="text-decoration-none"
                    >
                      Privacy Policy
                    </MDBLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 text-md-right text-sm-center">
              <MDBBox tag="div">© 2019 ducduc</MDBBox>
            </div>
          </div>
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default FooteBottom;
