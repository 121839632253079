import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";
import banner from "app/assets/images/faq/FAQ.png";
import bannerMobile from "app/assets/images/faq/banner-mobile.png";
import Breadcrumbs from "app/layout/breadcrumbs/breadcrumbs";
import HeroImage from "app/layout/heroImage";
import FaqItemStateContainer from "./faq-item-state-container";
import SeoHelmet from "app/layout/seoHelmet";

const Faq = (props) => {
  let { tabs, tabsData } = props;

  return (
    <MDBContainer fluid className="faq-page">
      <MDBRow>
        <MDBCol md="12" className={"p-0"}>
          <div className="img item d-lg-block d-none desktop-image">
            <HeroImage id={"faq-img"} alt="FAQ banner" />
          </div>
          <div className="img item d-lg-none d-block mobile-image">
            <HeroImage banner={bannerMobile} alt="FAQ banner" />
          </div>
          <SeoHelmet
            url={"/faq"}
            title={"faq"}
            description={"frequently asked questions"}
            image={banner}
          />
        </MDBCol>
        <MDBCol md="12" className={"faq-top"}>
          <MDBBox tag="h2" className="w-100 mx-auto text-center my-5">
            faq
          </MDBBox>
        </MDBCol>
        <MDBCol md="12" className={"faq-content"}>
          <FaqItemStateContainer tabs={tabs} tabsData={tabsData} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Faq;
