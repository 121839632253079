import React, { memo, useMemo, useCallback } from "react";
import AssemblyModal from "./assemblyModal";
import AssemblyPDF from "./assemblyPDF";
import { MDBBox } from "mdbreact";
import lodash from "lodash";

const AccordionAssemblyComputed = memo(
  ({ items }) => {
    const computeChildren = useCallback(() => {
      let products = items.map((product) => {
        return (
          <div className={"assembly-inner-item-container"}>
            <MDBBox className={"assembly-item-content name"}>
              {product.product_name}
            </MDBBox>
            <MDBBox className={"assembly-item-content video"}>
              <AssemblyModal video={product.video} />
            </MDBBox>
            <MDBBox className={"assembly-item-content pdf"}>
              <AssemblyPDF
                instructions={product.instructions}
                name={product.product_name}
              />
            </MDBBox>
          </div>
        );
      });
      return products;
    }, []);

    const computedChildren = useMemo((items) => computeChildren(items), [
      items,
      computeChildren,
    ]);

    return computedChildren;
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default AccordionAssemblyComputed;
