import React from "react";
import { connect } from "react-redux";
import { commonActions } from "core/state/redux/data/common";
import messageActions from "core/state/redux/data/messages/actions";
import ProductItem from "./productItem";
import { customerActions } from "core/state/redux/data/customer";

const ProductItemReduxContainer = props => {
  return <ProductItem {...props} />;
};

const mapStateToProps = state => ({
  wishlist:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.wishlist !== "undefined"
      ? state.customer.data.wishlist
      : {}
});

const mapDispatchToProps = dispatch => {
  return {
    lock: callback => {
      dispatch(commonActions.lock(callback));
    },
    addMessage: (text, type) => {
      dispatch(messageActions.addMessage(text, type));
    },
    addItemToWishlist: (wishlist_id, product_id, options = []) => {
      dispatch(
        customerActions.addProductToWishlist(wishlist_id, product_id, options)
      );
    },
    removeItemFromWishlist: wishlist_id => {
      dispatch(customerActions.removeItemFromWishlist(wishlist_id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductItemReduxContainer);
