import React from "react";
import MainModal from "./mainModal";

const MainModalStateContainer = props => {
  let { newsletterCustomerSubscribe } = props;
  const submitEmail = formNumber => {
    if (formNumber === 1) {
      if (props.validateEmailField()) {
        newsletterCustomerSubscribe(
          props.state.email,
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-"
        );
      } else {
        props.state.setError(true);
      }
    } else {
      if (props.validateEmailField()) {
        newsletterCustomerSubscribe(
          props.state.email,
          props.state.firstname,
          props.state.lastname,
          props.state.company,
          props.state.describe.value,
          props.state.countryValue.value,
          props.state.stateValue !== null
            ? props.state.stateValue.value !== undefined
              ? props.state.stateValue.value
              : props.state.stateValue
            : "-",
          props.state.zipcode
        );
      }
    }
  };

  return <MainModal {...props} submitEmail={submitEmail} state={props.state} />;
};

export default MainModalStateContainer;
