import React, { useState } from "react";
import RegisterB2CReduxContainer from "./RegisterB2C-redux-container";

const RegisterDataContainer = props => {
  const [isRegistered, setIsRegistered] = useState(false);

  return (
    <RegisterB2CReduxContainer
      isRegistered={isRegistered}
      setIsRegistered={setIsRegistered}
    />
  );
};

export default RegisterDataContainer;
