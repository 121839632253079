import React from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import { useMediaQuery } from "react-responsive";
import Summary from "./summary";
import CartItem from "./cartItem";
import MobileCartItem from "./mobileCartItem";

const Cart = (props) => {
  let {
    headers,
    discountCode,
    setDiscountCode,
    customer,
    zip,
    setZip,
    setShippingAddress,
    lock,
    addMessage,
    cart,
    applyCouponToCart,
    removeCouponFromCart,
  } = props;
  let summaryProps = {
    items:
      typeof customer.data.cart !== "undefined" ? customer.data.cart.items : [],
    prices:
      typeof customer.data.cart !== "undefined"
        ? customer.data.cart.prices
        : [],
    discountCode,
    setDiscountCode,
    zip,
    setZip,
    setShippingAddress,
    lock,
    addMessage,
    cart,
    applyCouponToCart,
    removeCouponFromCart,
  };
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      <MDBContainer className="cart-page mt-4 pb-5">
        <MDBRow className="title-container">
          <MDBCol className="page-title">
            <h3 className="large">shopping cart</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow className="content-container">
          <MDBCol lg="9" size="12" className="items-table">
            {!isMobile && (
              <>
                <MDBRow className="headers-container border-bottom mb-3">
                  <MDBCol
                    size={headers[0].size}
                    className={`header ${headers[0].title}`}
                  >
                    <h4 className="gray heavy pb-4">{headers[0].title}</h4>
                  </MDBCol>
                  <MDBCol size={12 - headers[0].size}>
                    <MDBRow>
                      {headers.map((header) => {
                        if (headers[0] === header) {
                          return "";
                        }
                        return (
                          <MDBCol
                            size={header.size}
                            className={`header ${header.title}`}
                          >
                            <h4 className="gray heavy pb-4">{header.title}</h4>
                          </MDBCol>
                        );
                      })}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="cart-items-listing-container">
                  {typeof props.customer.data.cart !== "undefined" ? (
                    props.customer.data.cart.items.map((item) => {
                      let itemQuantity = props.itemQuantities.find(
                        (it) => it.id === item.id
                      );
                      let itemQuantityValue =
                        typeof itemQuantity !== "undefined"
                          ? itemQuantity.quantity
                          : 0;
                      return (
                        <CartItem
                          key={`cart-item-${item.id}`}
                          item={item}
                          quantity={itemQuantityValue}
                          headers={headers}
                          removeItem={() => props.removeItem(item.id)}
                          setQuantity={props.setItems(item.id)}
                          increaseQuantity={() => props.increaseItems(item.id)}
                          decreaseQuantity={() => props.decreaseItems(item.id)}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
                </MDBRow>
              </>
            )}
            {isMobile && (
              <>
                <MDBRow>
                  <MDBCol
                    size="12"
                    className="mobile-title pb-5 mt-5 border-bottom"
                  >
                    <h4 className="xl gray">items</h4>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="cart-items-listing-container">
                  {typeof props.customer.data.cart !== "undefined" ? (
                    props.customer.data.cart.items.map((item) => {
                      let itemQuantity = props.itemQuantities.find(
                        (it) => it.id === item.id
                      );
                      let itemQuantityValue =
                        typeof itemQuantity !== "undefined"
                          ? itemQuantity.quantity
                          : 0;
                      return (
                        <MobileCartItem
                          key={`mobile-cart-item-${item.id}`}
                          item={item}
                          quantity={itemQuantityValue}
                          headers={headers}
                          removeItem={() => props.removeItem(item.id)}
                          setQuantity={props.setItems(item.id)}
                          increaseQuantity={() => props.increaseItems(item.id)}
                          decreaseQuantity={() => props.decreaseItems(item.id)}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
                </MDBRow>
              </>
            )}
          </MDBCol>
          <MDBCol lg="3" size="12" className="pl-lg-5 mb-4 mb-lg-0">
            {props.cart !== undefined && props.cart.cart !== undefined ? (
              <Summary {...summaryProps} />
            ) : (
              <></>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Cart;
