import React, { Fragment } from "react";
import { MDBBox, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import CheckButton from "core/components/checkButton";
import Price from "core/components/price";
import ReactLoader from "app/layout/loader";

const CheckoutShippingMethod = (props) => {
  let { cart, setShippingMethodsOnCart, lock, loading, setLoading } = props;

  if (typeof cart === "undefined") return <></>;
  let { shipping_addresses } = cart;
  let available_shipping_methods = [];
  let selected_shipping_method = {};

  if (typeof shipping_addresses === "undefined") return <></>;
  shipping_addresses.map((address) => {
    address.available_shipping_methods.map((method) => {
      available_shipping_methods[method.method_code] = { ...method };
    });
    if (typeof address.selected_shipping_method !== "undefined") {
      selected_shipping_method = address.selected_shipping_method;
    }
    if (
      address.available_shipping_methods.length > 0 &&
      address.selected_shipping_method === null
    ) {
      if (
        address.available_shipping_methods[0].carrier_code ===
        address.available_shipping_methods[0].method_code
      ) {
        setShippingMethodsOnCart([
          {
            carrier_code: address.available_shipping_methods[0].carrier_code,
            method_code: address.available_shipping_methods[0].method_code,
          },
        ]);
      }
    }
  });

  if (
    typeof available_shipping_methods["upsmatrix_doortodoormatrix"] !==
    "undefined"
  ) {
    available_shipping_methods["upsmatrix_doortodoormatrix"]["options"] = [];
    if (
      typeof available_shipping_methods[
        "upsmatrix_doortodoormatrix_white_glove"
      ] !== "undefined"
    ) {
      let whiteGlove = {
        ...available_shipping_methods["upsmatrix_doortodoormatrix_white_glove"],
      };
      let originalPrice = {
        ...available_shipping_methods["upsmatrix_doortodoormatrix"],
      };

      if (typeof whiteGlove.amount.changed === "undefined") {
        whiteGlove.amount.value = Math.fround(
          whiteGlove.amount.value - originalPrice.amount.value
        ).toFixed(2);
        whiteGlove.amount.changed = true;
      }
      available_shipping_methods["upsmatrix_doortodoormatrix"]["options"].push(
        whiteGlove
      );
      delete available_shipping_methods[
        "upsmatrix_doortodoormatrix_white_glove"
      ];
    }
  }

  if (typeof available_shipping_methods["doortodoormatrix"] !== "undefined") {
    available_shipping_methods["doortodoormatrix"]["options"] = [];
    if (typeof available_shipping_methods["whiteglovematrix"] !== "undefined") {
      let whiteGlove = { ...available_shipping_methods["whiteglovematrix"] };
      let originalPrice = { ...available_shipping_methods["doortodoormatrix"] };
      if (typeof whiteGlove.amount.changed === "undefined") {
        whiteGlove.amount.value = Math.fround(
          whiteGlove.amount.value - originalPrice.amount.value
        ).toFixed(2);
        whiteGlove.amount.changed = true;
      }
      available_shipping_methods["doortodoormatrix"]["options"].push(
        whiteGlove
      );
      delete available_shipping_methods["whiteglovematrix"];
    }
  }

  if (typeof available_shipping_methods["ordersubtotal"] !== "undefined") {
    available_shipping_methods["ordersubtotal"]["options"] = [];
    if (
      typeof available_shipping_methods["ordersubtotal_extra_stairs"] !==
      "undefined"
    ) {
      let extraStairs = {
        ...available_shipping_methods["ordersubtotal_extra_stairs"],
        display: "checkbox",
      };
      let originalPrice = { ...available_shipping_methods["ordersubtotal"] };
      if (typeof extraStairs.amount.changed === "undefined") {
        extraStairs.amount.value = Math.fround(
          extraStairs.amount.value - originalPrice.amount.value
        ).toFixed(2);
        extraStairs.amount.changed = true;
      }
      available_shipping_methods["ordersubtotal"]["options"].push(extraStairs);

      delete available_shipping_methods["ordersubtotal_extra_stairs"];
    }
  }
  return (
    <MDBContainer fluid className={"shipping-method-container"}>
      <MDBRow>
        <MDBCol>
          <MDBBox className={"shipping-methods"}>
            <h3>shipping methods</h3>
            {typeof available_shipping_methods !== "undefined" ? (
              Object.keys(available_shipping_methods).length > 0 ? (
                Object.keys(available_shipping_methods).map((key) => {
                  let method = available_shipping_methods[key];
                  return (
                    <Fragment key={`${method.method_code}-${key}`}>
                      <MDBRow
                        className={`${
                          selected_shipping_method !== null
                            ? selected_shipping_method.carrier_code ===
                              method.carrier_code
                              ? "active"
                              : method.carrier_code.includes(
                                  selected_shipping_method.carrier_code
                                )
                              ? "active"
                              : ""
                            : ""
                        }`}
                      >
                        <MDBCol size={`8`}>
                          <h5
                            onClick={(e) => {
                              if (!loading) {
                                setLoading(true);
                                lock({
                                  always: () => {},
                                  success: () => {
                                    setLoading(false);
                                  },
                                  fail: () => {
                                    setLoading(false);
                                  },
                                });
                                setShippingMethodsOnCart([
                                  {
                                    carrier_code: method.carrier_code,
                                    method_code: method.method_code,
                                  },
                                ]);
                              }
                            }}
                          >
                            {method.carrier_title.toLowerCase() ===
                            "standard delivery + curb side" ? (
                              <>
                                <span>standard shipping</span>
                                <br />
                                <span className={`silver`}>
                                  includes door to door service
                                </span>
                              </>
                            ) : (
                              method.carrier_title
                            )}
                          </h5>
                        </MDBCol>
                        <MDBCol size={`4`} className="method-price">
                          <h5>
                            +
                            <Price
                              currency={method.amount.currency}
                              type={`fixed`}
                              price={method.amount.value}
                            />
                          </h5>
                        </MDBCol>
                      </MDBRow>
                      <MDBBox className="box-inner">
                        {typeof method.options !== "undefined" &&
                        method.options.length > 0 ? (
                          method.options.map((option, index) => {
                            let checked = false;
                            let complementary = false;

                            if (selected_shipping_method !== null) {
                              checked =
                                option.method_code ===
                                selected_shipping_method.method_code;
                              if (
                                selected_shipping_method.method_code ===
                                "doortodoormatrix_upsmatrix_doortodoormatrix_white_glove"
                              ) {
                                checked = true;
                              }
                            }

                            if (option.amount.value <= 0) {
                              complementary = true;
                            }

                            return (
                              <div key={`${option.method_code}-${index}`}>
                                {option.display === "checkbox" ? (
                                  <CheckButton
                                    containerClass={
                                      "checkbox-container check-button stairs-label"
                                    }
                                    wrapperClass={"checkmark"}
                                    label={
                                      <div className={`stairs-label`}>
                                        <span>{option.carrier_title}</span>
                                        <span>
                                          +
                                          <Price
                                            currency={option.amount.currency}
                                            type={`fixed`}
                                            price={parseFloat(
                                              option.amount.value
                                            )}
                                          />
                                        </span>
                                      </div>
                                    }
                                    onChange={(e) => {
                                      if (!loading) {
                                        setLoading(true);
                                        lock({
                                          always: () => {},
                                          success: () => {
                                            setLoading(false);
                                          },
                                          fail: () => {
                                            setLoading(false);
                                          },
                                        });
                                        if (!checked) {
                                          if (!loading) {
                                            lock({
                                              always: () => {},
                                              success: () => {
                                                setLoading(false);
                                              },
                                              fail: () => {
                                                setLoading(false);
                                              },
                                            });
                                            setShippingMethodsOnCart([
                                              {
                                                carrier_code:
                                                  option.carrier_code,
                                                method_code: option.method_code,
                                              },
                                            ]);
                                          }
                                        } else {
                                          if (!loading) {
                                            lock({
                                              always: () => {},
                                              success: () => {
                                                setLoading(false);
                                              },
                                              fail: () => {
                                                setLoading(false);
                                              },
                                            });
                                            setShippingMethodsOnCart([
                                              {
                                                carrier_code:
                                                  method.carrier_code,
                                                method_code: method.method_code,
                                              },
                                            ]);
                                          }
                                        }
                                      }
                                    }}
                                    checked={checked}
                                    type={"checkbox"}
                                  />
                                ) : (
                                  <CheckButton
                                    containerClass={
                                      "switch-container check-button shipping-method-check"
                                    }
                                    wrapperClass={"slider"}
                                    label={
                                      <>
                                        {option.method_title}
                                        <span>
                                          {complementary ? "" : "+"}

                                          {complementary ? (
                                            "free"
                                          ) : (
                                            <Price
                                              price={option.amount.value}
                                              type={"fixed"}
                                            />
                                          )}
                                        </span>
                                      </>
                                    }
                                    value={option.method_title}
                                    checked={checked}
                                    onChange={(e) => {
                                      setLoading(true);

                                      if (!checked) {
                                        if (!loading) {
                                          lock({
                                            always: () => {},
                                            success: () => {
                                              setLoading(false);
                                            },
                                            fail: () => {
                                              setLoading(false);
                                            },
                                          });
                                          setShippingMethodsOnCart([
                                            {
                                              carrier_code: option.carrier_code,
                                              method_code: option.method_code,
                                            },
                                          ]);
                                        }
                                      } else {
                                        if (!loading) {
                                          lock({
                                            always: () => {},
                                            success: () => {
                                              setLoading(false);
                                            },
                                            fail: () => {
                                              setLoading(false);
                                            },
                                          });
                                          setShippingMethodsOnCart([
                                            {
                                              carrier_code: method.carrier_code,
                                              method_code: method.method_code,
                                            },
                                          ]);
                                        }
                                      }
                                    }}
                                    type={"checkbox"}
                                  />
                                )}
                                <p></p>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </MDBBox>
                    </Fragment>
                  );
                })
              ) : (
                <>
                  <p>no shipping methods are available</p>
                </>
              )
            ) : (
              <></>
            )}
            {loading ? (
              <MDBBox display={`flex`} flex={`row`}>
                <span style={{ paddingRight: "1rem" }}>updating...</span>
                <ReactLoader
                  visible={loading}
                  type={`ThreeDots`}
                  height={`20`}
                  width={`20`}
                />
              </MDBBox>
            ) : (
              <p></p>
            )}
            {/* <h5>ups ground</h5>
            <MDBBox className="box-inner">
              <div>
                <CheckButton
                  containerClass={"switch-container check-button"}
                  wrapperClass={"slider"}
                  label={"curb side"}
                  value="curb side"
                  checked={curbe}
                  onChange={() => setCurbe(!curbe)}
                  type={"checkbox"}
                />
              </div>
              <div>
                <CheckButton
                  containerClass={"switch-container check-button"}
                  wrapperClass={"slider"}
                  label={"white glove"}
                  value="white glove"
                  checked={white}
                  onChange={() => setWhite(!white)}
                  type={"checkbox"}
                />
              </div>
            </MDBBox>
            */}
          </MDBBox>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default CheckoutShippingMethod;
