import React from "react";
import { Link } from "react-router-dom";
const Breadcrumbs = props => {
  let links = props.links;
  if (typeof props.links === "undefined") {
    links = props.backupLinks;
  }
  return (
    <div className={`breadcrumbs`}>
      <ul>
        <li>
          {typeof links !== "undefined"
            ? links.map(link => (
                <Link key={link.url} to={link.url}>
                  <span>{link.title}</span>
                </Link>
              ))
            : ""}
        </li>
      </ul>
    </div>
  );
};

export default Breadcrumbs;
