import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import CheckoutOrderReviewStateContainer from "./state-container";

const CheckoutOrderReviewReduxContainer = (props) => {
  return <CheckoutOrderReviewStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  cart:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined"
      ? state.customer.data.cart
      : {},
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
    placeOrder: () => {
      dispatch(customerActions.placeOrder());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutOrderReviewReduxContainer);
