import React from "react";
import { isUndefined } from "core/helpers/functions";
import "./filterWrapper.scss";

const FilterWrapper = props => {
  if (!props.children) return "";
  if (!props.wrapper) return props.children;

  return (
    <ul
      className={`filter-wrapper filter-ul ${(props.toggle && "visible") ||
        "hidden" ||
        (!isUndefined(props.isOpen) && props.isOpen && "visible") ||
        "hidden"}`}
    >
      {props.children}
    </ul>
  );
};

export default FilterWrapper;
