import React from "react";
import ActiveFilter from "./activeFilter";
import { isUndefined, isEmpty } from "core/helpers/functions";
import { generateFilterRender } from "./functions";
import { MDBRow, MDBCol } from "mdbreact";

const Sidebar = (props) => {
  let {
    category,
    filtered_category,
    selected_category,
    addFilter,
    locked,
    filters,
  } = props;

  if (isUndefined(filtered_category)) return "";
  let hasItems = !isEmpty(filtered_category.items);
  let itemCount = 0;

  if (typeof filters.page_data !== "undefined") {
    itemCount = hasItems ? filters.page_data.totalCount : 0;
  }

  return (
    <>
      {hasItems && itemCount > 1 ? (
        <MDBRow id="sidebar" className={`sidebar`}>
          <MDBCol md="12" className="filter-title">
            <h3>Filter by:</h3>
            <div className={`locked-${locked}`}></div>
            <div className="filters">
              <ActiveFilter
                removeFilter={props.removeFilter}
                clearFilters={props.clearFilters}
              />
              <>
                {category.initialFilters &&
                  generateFilterRender(
                    props,
                    category,
                    filtered_category,
                    selected_category,
                    addFilter,
                    locked
                  )}
              </>
            </div>
          </MDBCol>
        </MDBRow>
      ) : typeof props.filters.items !== "undefined" &&
        Object.keys(props.filters.items).length > 0 ? (
        <MDBRow id="sidebar" className={`sidebar`}>
          <MDBCol md="12" className="filter-title">
            <h3>Filter by:</h3>
            <div className={`locked-${locked}`}></div>
            <div className="filters">
              <ActiveFilter
                removeFilter={props.removeFilter}
                clearFilters={props.clearFilters}
              />
              <>
                {category.initialFilters &&
                  generateFilterRender(
                    props,
                    category,
                    filtered_category,
                    selected_category,
                    addFilter,
                    locked
                  )}
              </>
            </div>
          </MDBCol>
        </MDBRow>
      ) : (
        <></>
      )}
    </>
  );
};
export default Sidebar;
