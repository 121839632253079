export default {
  name: "ducduc",
  legalName: "ducduc",
  url: "https://ducducnyc.com/",
  logo:
    "https://inhabitat.com/wp-content/blogs.dir/24/files/2008/08/btbb-ducduc1.jpg",
  foundingDate: "2009",
  address: {
    streetAddress: "200 lexington ave",
    addressLocality: "no 701 ",
    addressRegion: "ny",
    postalCode: "10016",
    addressCountry: "USA",
  },
  contactPoint: {
    telephone: "[+1 212-226-1868]",
    email: "quack@ducduc.com",
    hoursAvailable: [
      {
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "09:00",
        closes: "18:00",
      },
    ],
    socialNetworks: [
      "https://www.pinterest.com/ducduc/",
      "https://www.instagram.com/ducducnyc/",
      "https://www.facebook.com/ducducnyc",
    ],
  },
};
