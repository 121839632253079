import React from "react";

import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WhatLookingBlock from "./whatLookingBlock";
import UspBlocks from "./uspBlocks/uspBlocks";
import BannerStudio from "./bannerStudio";
import ShopLook from "./shopLook/shopLook";
import ShopLookSlider from "./shopLookSlider";
import HomepageSlider from "./slider/slider-query-container";
import Juicer from "./juicer/juicer";
import MainModal from "./mainModal/";
import SeoHelmet from "app/layout/seoHelmet";

const Home = (props) => {
  return (
    <>
      <SeoHelmet title={""} url={""} />
      <MDBContainer className={"no-header-offset home-container"} fluid>
        <MDBRow>
          <HomepageSlider />
          <MDBCol>
            <MainModal {...props} />
          </MDBCol>
          <MDBCol size="12">
            <WhatLookingBlock />
          </MDBCol>
          <MDBCol size="12" className={"usp-container"}>
            <UspBlocks />
          </MDBCol>
          <MDBCol size="12" className={"p-0"}>
            <BannerStudio />
          </MDBCol>
        </MDBRow>
        <MDBRow className={"banner-and-shop"}>
          <MDBCol className={"shop-look-background-div"}></MDBCol>
          <MDBCol className={"shop-look"} size="12">
            <ShopLook />
          </MDBCol>
          <MDBCol className={"shop-slider"}>
            <MDBRow className={"d-block"}>
              <ShopLookSlider
                slidesToShow={2}
                DotsAlign={"center"}
                id={`homepage-hotspots`}
              />
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="pl-0 pr-0">
            <Juicer
              feedId=""
              imagesPer={4}
              sliderTitle={"follow along!"}
              sliderSubTitle={
                <>
                  {/* Show us your style using <span>#ducduc</span> and make sure you
                tag us */}
                  <span> @ducducnyc</span>
                </>
              }
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Home;
