import React from "react";
import firstImage from "app/assets/images/about/first.png";
// import secondImage from "app/assets/images/about/second.png";
import thirdImage from "app/assets/images/about/third.png";
import CardImage from "app/layout/cardImage";
import CmsBlock from "core/components/cmsBlock";

export default {
  two: (
    <CardImage
      bottomImage={
        <CmsBlock dangHtml={"true"} id={"about_us_block_one_image"} />
      }
      imageTitle={
        <CmsBlock dangHtml={"true"} id={"about_us_block_one_title"} />
      }
      imageLinkLessText={"read less"}
      imageDescription={
        <CmsBlock dangHtml={"true"} id={"about_us_block_one_description"} />
      }
      useCmsBlockImage={true}
      imageLinkText={"read more"}
    />
  ),
  one: (
    <CardImage
      bottomImage={
        <CmsBlock dangHtml={"true"} id={"about_us_block_two_image"} />
      }
      imageTitle={
        <CmsBlock dangHtml={"true"} id={"about_us_block_two_title"} />
      }
      imageLinkLessText={"read less"}
      imageDescription={
        <CmsBlock dangHtml={"true"} id={"about_us_block_two_description"} />
      }
      useCmsBlockImage={true}
      imageLinkText={"read more"}
    />
  ),
  three: (
    <CardImage
      bottomImage={
        <CmsBlock dangHtml={"true"} id={"about_us_block_three_image"} />
      }
      imageTitle={
        <CmsBlock dangHtml={"true"} id={"about_us_block_three_title"} />
      }
      imageLinkLessText={"read less"}
      imageDescription={
        <CmsBlock dangHtml={"true"} id={"about_us_block_three_description"} />
      }
      useCmsBlockImage={true}
      imageLinkText={"read more"}
    />
  ),
};
