import React from "react";
import { connect } from "react-redux";
import { commonActions } from "core/state/redux/data/common";
import messageActions from "core/state/redux/data/messages/actions";
import { customerActions } from "core/state/redux/data/customer";
import ShareFavoritesBtn from "./shareFavoritesBtn";

const ShareReduxContainer = (props) => {
  return <ShareFavoritesBtn {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    shareWishlist: (emails, messages, wishlist_id, multiwishlist_id) => {
      dispatch(
        customerActions.shareWishlist(
          emails,
          messages,
          wishlist_id,
          multiwishlist_id
        )
      );
    },
    addMessage: (text, type, target) => {
      dispatch(messageActions.addMessage(text, type, target));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareReduxContainer);
