import React, { useState } from "react";
import AddonItem from "./addon-item";

const AddonItemStateContainer = ({ addon, addItemToCart, lock }) => {
  const [qty, setQty] = useState(1);
  const [loading, setLoading] = useState(false);

  return (
    <AddonItem
      addon={addon}
      qty={qty}
      setQty={setQty}
      addItemToCart={addItemToCart}
      lock={lock}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default AddonItemStateContainer;
