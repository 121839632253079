import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBox,
} from "mdbreact";
import Button from "core/components/button";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Popout from "./poput";
import Icon from "app/assets/icon/icon";
import themeConfiguration from "config/themeConfiguration";
import Image from "core/components/image/image";

const Dropdown = (props) => {
  let { option, selections } = props.optionProps;
  let sizes = {
    title: 3,
    selector: 6,
    guide: 3,
  };
  const [openModal, setOpenModal] = useState(false);
  const toggle = () => {
    setOpenModal(!openModal);
  };
  const [matressIcon, setMatressIcon] = useState(false);
  // this line will check if you load another product, and if the options are missing
  // if selections has a length of 1, it is only for the selected price
  if (Object.keys(selections).length === 1) return <></>;

  if (typeof option.value === "undefined" || option.value.length < 1)
    return <></>;
  return (
    <MDBRow className={`${option.title}-container pb-4 option-container`}>
      <MDBCol lg={`${sizes.title}`} size="6">
        <span className="option-title">{option.title}</span>
      </MDBCol>
      <MDBCol lg={`${sizes.selector}`} size="6" className="pl-0">
        <ModalConsumer>
          {({ showModal, hideModal, rerender }) => (
            <Button
              onClick={() => {
                showModal(
                  () => {
                    return (
                      <Popout
                        {...props}
                        option={option}
                        hideModal={hideModal}
                      />
                    );
                  },
                  { withWrapper: false }
                );
              }}
              className="w-100 floating-right-arrow"
              righticon={"true"}
              icon={"caret-right"}
              direction="right"
            >
              {typeof selections[option.option_id] !== "undefined"
                ? selections[option.option_id].title
                : ""}
            </Button>
          )}
        </ModalConsumer>
      </MDBCol>
      <MDBCol
        onMouseEnter={() => setMatressIcon(true)}
        onMouseLeave={() => setMatressIcon(false)}
        lg={`${sizes.guide}`}
        size="12"
      >
        {props.size_guide && (
          <div
            className="product-page-pdf"
            onClick={() => {
              toggle();
            }}
          >
            {openModal && (
              <MDBModal
                isOpen={openModal}
                toggle={toggle}
                className={
                  "modal-wrapper footer-newsletter modal-product-page-pdf"
                }
                centered
              >
                <MDBModalHeader onClick={() => toggle()}>
                  <div className="close-btn">
                    <button type="button">
                      <i className="close-icon"></i>
                    </button>
                  </div>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBBox>
                    <Image
                      source={
                        themeConfiguration.magento_url +
                        "pub/media/catalog/product/guide/" +
                        `${props.size_guide}`
                      }
                    />
                  </MDBBox>
                </MDBModalBody>
              </MDBModal>
            )}
            {(matressIcon && (
              <Icon className={"orange-icon"} id={"matressIconHover"} />
            )) || <Icon icon={"matressIcon"} />}
            <span className={matressIcon ? "option-guide" : "grey-text"}>
              mattress sizing guide
            </span>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default Dropdown;
