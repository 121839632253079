import React, { useRef } from "react";
import MiniCart from "../MiniCart";

const MiniCartLabel = props => {
  const cartLabel = useRef();

  return (
    <div
      onMouseLeave={props.hideMiniCart}
      ref={cartLabel}
      className={`minicart-icon ${props.showMiniCart ? "active" : ""}`}
    >
      <MiniCart
        cartLabel={cartLabel.cartOrange}
        hideMiniCart={props.hideMiniCart}
      />
    </div>
  );
};

export default MiniCartLabel;
