import React, { useState } from "react";
import Button from "core/components/button";
import { useCompare } from "core/state/hooks/compareHook";
import { useMediaQuery } from "react-responsive";

const CompareContributer = (props) => {
  let request = { productId: props.productId };
  const children = props.children;
  const isTabOrMob = useMediaQuery({ maxWidth: 767 });

  const [
    addCompare,
    removeCompare,
    getCompareData,
    getCompareItems,
    clearCompareList,
    checkIfProductIsInCompareById,
  ] = useCompare();
  let compareClass = false;
  let isInCompareList = checkIfProductIsInCompareById(props.productId);

  if (children === undefined) {
    return (
      <>
        {!isTabOrMob && (
          <>
            {!isInCompareList ? (
              <button
                className={`show-${compareClass}`}
                style={{ background: "none", border: "none" }}
                onClick={(e) => {
                  if (props.compareList.length !== 5) {
                    addCompare(request);
                  } else {
                    props.addMessage(
                      "Compare is limited to 5 products at a time",
                      "danger"
                    );
                  }
                }}
              />
            ) : (
              <button
                onClick={(e) => {
                  removeCompare(request);
                }}
                style={{ background: "none", border: "none" }}
              />
            )}
          </>
        )}
      </>
    );
  }

  return children.map((Child, index) => {
    return <Child key={`compare-contributer-${index}`} />;
  });
};

export default CompareContributer;
