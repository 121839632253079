import React, { useState } from "react";
import RegisterStepOne from "./b2bStepOne/registerStepOne-query-container";
import RegisterStepTwo from "./b2bStepTwo/registerStepTwo";
import RegisterStepThree from "./b2bStepThree/registerStepThree-redux-container";
import { determineState } from "./functions";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { default as customerAttributes } from "app/config/customer/attributes";
import companyDetails from "app/assets/registerIcons/companyDetails.svg";
import contactInfoGray from "app/assets/registerIcons/contactInfoGray.svg";
import contactInfoOrange from "app/assets/registerIcons/contactInfoOrange.svg";
import billingInfoGray from "app/assets/registerIcons/billingInfoGray.svg";
import billingInfoOrange from "app/assets/registerIcons/billingInfoOrange.svg";
import confirmedIcon from "app/assets/registerIcons/confirmedIcon.svg";
import { MDBCol, MDBRow } from "mdbreact";
import { Redirect } from "react-router-dom";
import defaultRegions from "./regions";
import { useCallback } from "react";

const RegisterB2BStepsContainer = (props) => {
  let { registerB2BCustomer, lock, step, setStep } = props;

  //steps begin
  //first step
  const [companyName, setCompanyName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [tradeMagazine, setTradeMagazine] = useState(0);
  const [instagram, setInstagram] = useState(0);
  const [pinterest, setPinterest] = useState(0);
  const [retailer, setRetailer] = useState(0);
  const [wordOfMouth, setWordOfMouth] = useState(0);
  const [tradeShow, setTradeShow] = useState(0);
  const [other, setOther] = useState(0);
  const [companyWebsite, setCompanyWebsite] = useState(0);
  const [facebook, setFacebook] = useState(0);
  const [linkedin, setLinkedin] = useState(0);
  const [inHouseShow, setInHouseShow] = useState(0);
  const [tradeShowSpecify, setTradeShowSpecify] = useState("");
  const [otherSpecify, setOtherSpecify] = useState("");
  const [placedOrderBefore, setPlacedOrderBefore] = useState(false);
  const [option, setOption] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [specifyNumberOfStores, setSpecifyNumberOfStores] = useState(0);
  const [creditCardLabel, setCreditCardLabel] = useState("");
  const [resaleCertificate, setResaleCertificate] = useState("");
  const [resaleCertificateData, setResaleCertificateData] = useState("");
  const [creditCardData, setCreditCardData] = useState("");
  const [businessType, setBusinessType] = useState({
    label: "Select business type",
    value: "0",
  });
  //second step
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //third step
  const [billingContactName, setBillingContactName] = useState("");
  const [billingAddress1, setBillingAddress1] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingZipCode, setBillingZipCode] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [shippingContactName, setShippingContactName] = useState("");
  const [shippingAddress1, setShippingAddress1] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingZipCode, setShippingZipCode] = useState("");
  const [shippingEmail, setShippingEmail] = useState("");
  const [shippingPhone, setShippingPhone] = useState("");
  const [useSameAddress, setUseSameAddress] = useState(false);
  const [joinOurMailingList, setJoinOurMailingList] = useState(true);
  const [terms, setTerms] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSame, setIsSame] = useState(true);
  const [countrySelect, setCountrySelect] = useState({
    label: "United States",
    value: "US",
    regions: defaultRegions,
  });
  const [countrySelectShipping, setCountrySelectShipping] = useState({
    label: "United States",
    value: "US",
    regions: defaultRegions,
  });

  const [
    countrySelectShippingSecondStep,
    setCountrySelectShippingSecondStep,
  ] = useState({
    label: "United States",
    value: "US",
  });
  const [regionsData, setRegionsData] = useState(props.regionsDataObject);
  const [regionsDataShipping, setRegionsDataShipping] = useState(
    props.regionsDataObject
  );

  //end

  let retail_options = null;
  if (selectedOption === "national") retail_options = 1;
  if (selectedOption === "regional") retail_options = 2;
  if (selectedOption === "independent") retail_options = 3;
  if (selectedOption === "other") retail_options = 4;
  let hear_about = "";
  if (tradeMagazine) hear_about = hear_about + "trade magazine,";
  if (companyWebsite) hear_about = hear_about + "company website,";
  if (instagram) hear_about = hear_about + "instagram,";
  if (facebook) hear_about = hear_about + "facebook,";
  if (pinterest) hear_about = hear_about + "pinterest,";
  if (linkedin) hear_about = hear_about + "linkedin,";
  if (retailer) hear_about = hear_about + "retailer,";
  if (inHouseShow) hear_about = hear_about + "in-house show,";
  if (wordOfMouth) hear_about = hear_about + "word of mouth,";
  if (tradeShow) hear_about = hear_about + "trade show,";
  if (other) hear_about = hear_about + "other,";
  hear_about = hear_about.substring(0, hear_about.length - 1);

  const submitRegister = useCallback(() => {
    let customerRequest = {
      [customerAttributes.firstname]: firstName,
      [customerAttributes.lastname]: lastName,
      [customerAttributes.password]: password,
      [customerAttributes.email]: email,
      [customerAttributes.business_type]: businessType.value,
      [customerAttributes.retail_options]: retail_options,
      [customerAttributes.tradeshow_specify]: tradeShowSpecify,
      [customerAttributes.other_specify]: otherSpecify,
      [customerAttributes.hear_about]: hear_about,
      [customerAttributes.stores_count]: specifyNumberOfStores,
      [customerAttributes.placed_order]: placedOrderBefore,
      [customerAttributes.card_certificate]: "/" + creditCardData,
      [customerAttributes.resale_certificate]: "/" + resaleCertificateData,
      [customerAttributes.taxvat]: taxIdNumber,
      is_trade: true,
    };

    // regions
    let regionObject = determineState(billingState, regionsData);
    let regionObjectShipping = determineState(
      shippingState,
      regionsDataShipping
    );

    let addressRequest;
    if (isSame) {
      addressRequest = {
        city: billingCity,
        company: companyName,
        country_id: countrySelect.value,
        default_billing: true,
        default_shipping: true,
        fax: "",
        firstname: billingContactName.split(" ")[0],
        lastname:
          typeof billingContactName.split(" ")[1] !== "undefined"
            ? billingContactName.split(" ")[1]
            : "#",
        postcode: billingZipCode,
        region: regionObject,
        street: [billingAddress1, billingAddress2],
        telephone: billingPhone === "" ? phone : billingPhone,
        [customerAttributes.website]: websiteUrl,
      };
    } else {
      addressRequest = {
        address: {
          city: billingCity,
          company: companyName,
          country_id: countrySelect.value,
          default_billing: true,
          default_shipping: false,
          fax: "",
          firstname: billingContactName.split(" ")[0],

          lastname:
            typeof billingContactName.split(" ")[1] !== "undefined"
              ? billingContactName.split(" ")[1]
              : "#",
          postcode: billingZipCode,
          region: regionObject,
          street: [billingAddress1, billingAddress2],
          telephone: billingPhone === "" ? phone : billingPhone,
          [customerAttributes.website]: websiteUrl,
        },
        address2: {
          city: shippingCity,
          company: companyName,
          country_id: countrySelectShipping.value,
          default_billing: false,
          default_shipping: true,
          fax: "",
          firstname: shippingContactName.split(" ")[0],

          lastname:
            typeof shippingContactName.split(" ")[1] !== "undefined"
              ? shippingContactName.split(" ")[1]
              : "#",
          postcode: shippingZipCode,
          region: regionObjectShipping,
          street: [shippingAddress1, shippingAddress2],
          telephone: shippingPhone === "" ? phone : shippingPhone,
          [customerAttributes.website]: websiteUrl,
        },
      };
    }

    lock({
      always: setLoader(true),
      fail: () => {
        setLoader(false);
      },
      success: () => {
        setLoader(false);
        // setStep(3);
      },
    });

    registerB2BCustomer(customerRequest, addressRequest);
  }, [
    billingAddress1,
    billingAddress2,
    billingCity,
    billingContactName,
    billingPhone,
    billingState,
    billingZipCode,
    businessType.value,
    companyName,
    countrySelect.value,
    countrySelectShipping.value,
    creditCardData,
    email,
    firstName,
    hear_about,
    isSame,
    lastName,
    lock,
    otherSpecify,
    password,
    phone,
    placedOrderBefore,
    regionsData,
    regionsDataShipping,
    resaleCertificateData,
    retail_options,
    shippingAddress1,
    shippingAddress2,
    shippingCity,
    shippingContactName,
    shippingPhone,
    shippingState,
    shippingZipCode,
    specifyNumberOfStores,
    taxIdNumber,
    tradeShowSpecify,
    websiteUrl,
    registerB2BCustomer,
  ]);

  const sameAsBilling = (isSame) => {
    if (isSame) {
      setShippingContactName(billingContactName);
      setShippingAddress1(billingAddress1);
      setShippingAddress2(billingAddress2);
      setShippingCity(billingCity);
      setShippingState(billingState);
      setShippingZipCode(billingZipCode);
      setShippingEmail(billingEmail);
      setShippingPhone(billingPhone);
    } else {
      setShippingContactName("");
      setShippingAddress1("");
      setShippingAddress2("");
      setShippingCity("");
      setShippingState("");
      setShippingZipCode("");
      setShippingEmail("");
      setShippingPhone("");
    }
  };
  const setNextStep = () => {
    setStep(step + 1);
  };
  const setPreviousStep = () => {
    setStep(step - 1);
  };
  const stepArray = [
    <RegisterStepOne
      //set
      setNextStep={setNextStep}
      setCompanyName={setCompanyName}
      setWebsiteUrl={setWebsiteUrl}
      setTaxIdNumber={setTaxIdNumber}
      setTradeMagazine={setTradeMagazine}
      setInstagram={setInstagram}
      setPinterest={setPinterest}
      setRetailer={setRetailer}
      setWordOfMouth={setWordOfMouth}
      setTradeShow={setTradeShow}
      setOther={setOther}
      setCompanyWebsite={setCompanyWebsite}
      setFacebook={setFacebook}
      setLinkedin={setLinkedin}
      setInHouseShow={setInHouseShow}
      setTradeShowSpecify={setTradeShowSpecify}
      setOtherSpecify={setOtherSpecify}
      setPlacedOrderBefore={setPlacedOrderBefore}
      setOption={setOption}
      setSelectedOption={setSelectedOption}
      setSpecifyNumberOfStores={setSpecifyNumberOfStores}
      setCreditCardLabel={setCreditCardLabel}
      setResaleCertificate={setResaleCertificate}
      setResaleCertificateData={setResaleCertificateData}
      setCreditCardData={setCreditCardData}
      //values
      creditCardLabel={creditCardLabel}
      resaleCertificate={resaleCertificate}
      companyName={companyName}
      websiteUrl={websiteUrl}
      taxIdNumber={taxIdNumber}
      tradeMagazine={tradeMagazine}
      instagram={instagram}
      pinterest={pinterest}
      retailer={retailer}
      wordOfMouth={wordOfMouth}
      tradeShow={tradeShow}
      other={other}
      companyWebsite={companyWebsite}
      facebook={facebook}
      linkedin={linkedin}
      inHouseShow={inHouseShow}
      tradeShowSpecify={tradeShowSpecify}
      otherSpecify={otherSpecify}
      placedOrderBefore={placedOrderBefore}
      option={option}
      selectedOption={selectedOption}
      specifyNumberOfStores={specifyNumberOfStores}
      resaleCertificateData={resaleCertificateData}
      creditCardData={creditCardData}
      businessType={businessType}
      setBusinessType={setBusinessType}
      // dispatch
      addMessage={props.addMessage}
    />,
    <RegisterStepTwo
      //set
      setNextStep={setNextStep}
      setPreviousStep={setPreviousStep}
      setFirstName={setFirstName}
      setLastName={setLastName}
      setPhone={setPhone}
      setEmail={setEmail}
      setPassword={setPassword}
      setConfirmPassword={setConfirmPassword}
      setCountrySelectShippingSecondStep={setCountrySelectShippingSecondStep}
      //values
      firstName={firstName}
      lastName={lastName}
      phone={phone}
      email={email}
      password={password}
      confirmPassword={confirmPassword}
      countries={props.countries}
      countrySelectShippingSecondStep={countrySelectShippingSecondStep}
      // dispatch
      addMessage={props.addMessage}
    />,
    <RegisterStepThree
      //set
      setNextStep={setNextStep}
      setPreviousStep={setPreviousStep}
      setBillingContactName={setBillingContactName}
      setBillingAddress1={setBillingAddress1}
      setBillingAddress2={setBillingAddress2}
      setBillingCity={setBillingCity}
      setBillingState={setBillingState}
      setBillingZipCode={setBillingZipCode}
      setBillingEmail={setBillingEmail}
      setBillingPhone={setBillingPhone}
      setShippingContactName={setShippingContactName}
      setShippingAddress1={setShippingAddress1}
      setShippingAddress2={setShippingAddress2}
      setShippingCity={setShippingCity}
      setShippingState={setShippingState}
      setShippingZipCode={setShippingZipCode}
      setShippingEmail={setShippingEmail}
      setShippingPhone={setShippingPhone}
      setUseSameAddress={setUseSameAddress}
      sameAsBilling={sameAsBilling}
      setJoinOurMailingList={setJoinOurMailingList}
      setTerms={setTerms}
      setCountrySelect={setCountrySelect}
      setCountrySelectShipping={setCountrySelectShipping}
      setRegionsData={setRegionsData}
      setRegionsDataShipping={setRegionsDataShipping}
      //values
      billingContactName={billingContactName}
      billingAddress1={billingAddress1}
      billingAddress2={billingAddress2}
      billingCity={billingCity}
      billingState={billingState}
      billingZipCode={billingZipCode}
      billingEmail={billingEmail}
      billingPhone={billingPhone}
      shippingContactName={shippingContactName}
      shippingAddress1={shippingAddress1}
      shippingAddress2={shippingAddress2}
      shippingCity={shippingCity}
      shippingState={shippingState}
      shippingZipCode={shippingZipCode}
      shippingEmail={shippingEmail}
      shippingPhone={shippingPhone}
      useSameAddress={useSameAddress}
      joinOurMailingList={joinOurMailingList}
      terms={terms}
      countries={props.countries}
      regionsDataObject={props.regionsDataObject}
      countrySelect={countrySelect}
      countrySelectShipping={countrySelectShipping}
      regionsData={regionsData}
      regionsDataShipping={regionsDataShipping}
      //submit
      submitRegister={submitRegister}
      loader={loader}
      setLoader={setLoader}
      isSame={isSame}
      setIsSame={setIsSame}
      // dispatch
      addMessage={props.addMessage}
    />,
    <Redirect
      to={{
        pathname: "/register/success",
        state: { id: "hide" },
      }}
    />,
    // <ThankYouPage />
  ];

  const nextImages = () => {
    let currentStep = step;
    if (currentStep <= 3) {
      switch (currentStep) {
        case 0:
          return (
            <React.Fragment>
              <div className="text-center step-icon-content">
                <figure className="figure padding-right">
                  <img src={companyDetails} className="img-fluid" alt="" />
                  <figcaption className="figure-caption figure-padding-top text-orange">
                    company details
                  </figcaption>
                </figure>
                <figure className="figure padding-right">
                  <img src={contactInfoGray} className="img-fluid" alt="" />
                  <figcaption className="figure-caption figure-padding-top">
                    contact info
                  </figcaption>
                </figure>
                <figure className="figure ">
                  <img src={billingInfoGray} className="img-fluid" alt="" />
                  <figcaption className="figure-caption figure-padding-top">
                    billing + shipping
                  </figcaption>
                </figure>
              </div>
            </React.Fragment>
          );
        case 1:
          return (
            <React.Fragment>
              <div className="text-center step-icon-content">
                <figure className="figure padding-right">
                  <img src={confirmedIcon} className="img-fluid" alt="" />
                  <figcaption className="figure-caption figure-padding-top text-orange">
                    company details
                  </figcaption>
                </figure>
                <figure className="figure padding-right">
                  <img src={contactInfoOrange} className="img-fluid" alt="" />
                  <figcaption className="figure-caption figure-padding-top text-orange">
                    contact info
                  </figcaption>
                </figure>
                <figure className="figure ">
                  <img src={billingInfoGray} className="img-fluid" alt="" />
                  <figcaption className="figure-caption figure-padding-top">
                    billing + shipping
                  </figcaption>
                </figure>
              </div>
            </React.Fragment>
          );
        case 2:
          return (
            <React.Fragment>
              <div className="text-center step-icon-content">
                <figure className="figure padding-right">
                  <img src={confirmedIcon} className="img-fluid" alt="" />
                  <figcaption className="figure-caption figure-padding-top text-orange">
                    company details
                  </figcaption>
                </figure>
                <figure className="figure padding-right">
                  <img src={confirmedIcon} className="img-fluid" alt="" />
                  <figcaption className="figure-caption figure-padding-top text-orange">
                    contact info
                  </figcaption>
                </figure>
                <figure className="figure ">
                  <img src={billingInfoOrange} className="img-fluid" alt="" />
                  <figcaption className="figure-caption figure-padding-top text-orange">
                    billing + shipping
                  </figcaption>
                </figure>
              </div>
            </React.Fragment>
          );
        default:
          return <></>;
      }
    }
  };

  return (
    <>
      <MDBRow className="step-image-padding">
        <MDBCol md="12">{nextImages()}</MDBCol>
      </MDBRow>
      <div className="b2b-form">
        <TransitionGroup component={null}>
          {stepArray.map((component, index) => {
            return (
              <CSSTransition
                key={`step-${step}`}
                // in={step}
                classNames={`stepper`}
                timeout={{
                  appear: 500,
                  enter: 300,
                  exit: 500,
                }}
                appear={true}
              >
                {stepArray[step]}
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>
    </>
  );
};

export default RegisterB2BStepsContainer;
