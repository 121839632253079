import React from "react";
import { connect } from "react-redux";
import { commonActions } from "core/state/redux/data/common";
import ContactInfo from "./contact";
import { messagesActions } from "core/state/redux/data/messages";

const ContactReduxContainer = (props) => {
  return <ContactInfo {...props} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (data) => {
      dispatch(commonActions.sendEmail(data));
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactReduxContainer);
