export const changeOption = (
  value,
  option,
  product,
  reduceProductOptions,
  optionProps,
  optionPrice
) => {
  let { selections } = optionProps;
  let selectionTmp = {};
  let newPrice =
    value !== false
      ? product.options.totalPrice + value.price
      : product.options.totalPrice - optionPrice;

  if (selections.value === undefined) {
    let _value = { [option.option_id]: value };
    selectionTmp["value"] = _value;
  } else {
    let _value = { [option.option_id]: value };
    selectionTmp["value"] = { ...selections.value, ..._value };
  }
  let newSelection = {
    [option.option_id]: value,
    ...selectionTmp,
    selectedPrice: newPrice,
    totalPrice: newPrice,
  };
  let newSelections = { ...selections, ...newSelection };

  reduceProductOptions({ ...product, options: newSelections });
};
