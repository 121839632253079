import React, { memo } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { isEqual } from "lodash";
import { getCountryById } from "core/helpers/functions";
import ShippingNames from "config/checkout/shipping_names_mapper";

const OrderInformation = memo(
  ({ billingAddress, shippingAddress, shippingMethod, payment }) => {
    return (
      <>
        <MDBCol xs={`6`} sm={`6`} md={`3`} className={`order-information-item`}>
          <MDBRow>
            <MDBCol>
              <h4>billing address</h4>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <p>
                {billingAddress.firstname} {billingAddress.lastname}
                <br />
                {billingAddress.street.map((street) => {
                  return street + ", ";
                })}
                <br />
                {billingAddress.region.region} {billingAddress.postcode}
                <br />
                {getCountryById(billingAddress.country_code).full_name_english}
                <br />
                {billingAddress.telephone}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol xs={`6`} sm={`6`} md={`3`} className={`order-information-item`}>
          <MDBRow>
            <MDBCol>
              <h4>shipping address</h4>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <p>
                {shippingAddress.firstname} {shippingAddress.lastname}
                <br />
                {shippingAddress.street.map((street) => {
                  return street + ", ";
                })}
                <br />
                {shippingAddress.region.region} {shippingAddress.postcode}
                <br />
                {getCountryById(shippingAddress.country_code).full_name_english}
                <br />
                {shippingAddress.telephone}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol xs={`6`} sm={`6`} md={`3`} className={`order-information-item`}>
          <MDBRow>
            <MDBCol>
              <h4>shipping methods</h4>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <p>{ShippingNames[shippingMethod]}</p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol xs={`6`} sm={`6`} md={`3`} className={`order-information-item`}>
          <MDBRow>
            <MDBCol>
              <h4>payment</h4>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBRow>
                <MDBCol>
                  <p>{payment.method_title}</p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {payment.accountType ? (
                    <div className={`credit-cards`}>
                      <span>{payment.accountType}</span>
                      <p>
                        {payment.ccLast4
                          ? "xxxx xxxx xxxx " + payment.ccLast4
                          : ""}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default OrderInformation;
