import React, { useState, useCallback } from "react";
import CheckoutPaymentMethod from "./checkoutPaymentMethod";

const CheckoutPaymentMethodStateContainer = (props) => {
  // used previously
  const [inputs, setInputs] = useState({
    paymentMethod:
      typeof props.customer !== "undefined" &&
      typeof props.customer.data !== "undefined" &&
      typeof props.customer.data.cart !== "undefined" &&
      typeof props.customer.data.cart.selected_payment_method !== "undefined" &&
      typeof props.customer.data.cart.selected_payment_method.code !==
        "undefined" &&
      props.customer.data.cart.selected_payment_method.code.length > 0
        ? props.available_payment_methods[0].code
        : undefined,
    ccName: undefined,
    ccNumber: undefined,
    ccExpirationDate: undefined,
    ccValidationNumber: undefined,
  });

  const handleInputChange = useCallback(
    (target, value) => {
      if (target === "paymentMethod") {
        setInputs({
          ...inputs,
          paymentMethod: value,
        });
      } else {
        setInputs({
          ...inputs,
          [target]: target === "paymentMethod" ? value : value,
        });
      }
      if (target === "paymentMethod") {
        if (value === "checkmo" || value === "terms" || value === "free") {
          props.setPaymentMethod({
            method_code: value,
          });
        }
      }
    },
    [setInputs, inputs]
  );

  return (
    <CheckoutPaymentMethod
      inputs={inputs}
      handleInputChange={handleInputChange}
      {...props}
    />
  );
};

export default CheckoutPaymentMethodStateContainer;
