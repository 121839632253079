import React, { useEffect } from "react";
import Pagination from "./pagination";
import { MDBCol, MDBBox } from "mdbreact";
import Select2 from "core/components/select";

const Toolbar = ({
  itemcount,
  pageNumber,
  productPerPage,
  setProductPerPage,
  setPageNumber,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNumber]);

  if (itemcount === 0) return <></>;
  let productPerPageValue = productPerPage.value;
  let firstNumber =
    pageNumber === 1
      ? 1
      : productPerPageValue * pageNumber - productPerPageValue + 1;

  let number = productPerPageValue * pageNumber;

  if (itemcount > productPerPageValue) {
    number = productPerPageValue;
    if (firstNumber > number && firstNumber > itemcount) {
      number = firstNumber;
    } else {
      if (pageNumber !== 1) {
        number = productPerPageValue * pageNumber;
      }
    }
    if (number > itemcount) {
      number = itemcount;
    }
  } else {
    if (firstNumber > number) {
      number = firstNumber;
    }
    if (number > itemcount) {
      number = itemcount;
    }
  }

  let numberOfPages = Math.ceil(itemcount / productPerPageValue);

  return (
    <MDBBox id={`wishlist-toolbar`} className={`d-flex w-100 flex-wrap`}>
      <MDBCol className="number-of-items d-flex align-items-center" lg={`4`}>
        <div>
          items {firstNumber} to {number} of {itemcount}
        </div>
      </MDBCol>
      <MDBCol lg={`3`} className={"pl-0"}>
        <Pagination
          pageNumber={pageNumber}
          totalPages={numberOfPages}
          changePageNumber={setPageNumber}
        />
      </MDBCol>
      <MDBCol className={`d-flex flex-row align-items-center p-0`} lg={`5`}>
        <MDBCol lg={`2`} md={`4`} className={"show"}>
          <span>Show</span>
        </MDBCol>
        <MDBCol lg={`6`} md={`4`}>
          <Select2
            name={"pageSize"}
            type={"text"}
            required={true}
            value={productPerPage}
            onChange={(event) => setProductPerPage(event)}
            wrapperClass="small select-show-products"
            placeholder={`page size`}
            options={[
              { label: 6, value: 6 },
              { label: 12, value: 12 },
              { label: 24, value: 24 },
            ]}
          />
        </MDBCol>
        <MDBCol className={"pr-0"} md={`4`}>
          <span> per page</span>
        </MDBCol>
      </MDBCol>
    </MDBBox>
  );
};

export default Toolbar;
