import React from "react";
import gql from "graphql-tag";
import HeaderDataContainer from "./header-data-container";
import GraphQlWrapper from "core/components/graphQlWrapper";

/**
 * Refactor GraphQl Wrapper to use this approach? addQuery middleware
 */
const MENU_QUERY = gql`
  query Category($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      id
      name
      default_sort_by
      include_in_menu
      url_path
      position
      description
      products {
        total_count
      }
      mobile_image
      children {
        id
        name
        default_sort_by
        image
        url_key
        url_path
        include_in_menu
        position
        description
        products {
          total_count
        }
        mobile_image
        children {
          id
          name
          default_sort_by
          image
          url_key
          url_path
          include_in_menu
          position
          description
          products {
            total_count
          }
          mobile_image
        }
      }
    }
  }
`;

const HeaderQueryContainer = (props) => {
  return (
    <GraphQlWrapper query={MENU_QUERY} variables={{ id: "2" }} saveQuery={true}>
      <HeaderDataContainer toggleSearch={props.toggleSearch} />
    </GraphQlWrapper>
  );
};

export default HeaderQueryContainer;
