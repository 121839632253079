import React from "react";
import card1 from "app/assets/images/custom/Card1.png";
import card2 from "app/assets/images/custom/Card2.png";
import card3 from "app/assets/images/custom/Card3.png";
import CardImage from "app/layout/cardImage/cardImage";
import Slider from "react-slick";

const CustomCards = (props) => {
  const settings = {
    dots: false,
    dotsClass: props.dotsClass,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 1,
    customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
    appendDots: (dots) => (
      <div
        className={"about-top-slider-dots"}
        style={{
          padding: "15px 0",
          textAlign: "center",
          width: "100%",
          maxHeight: "5rem",
        }}
      >
        <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          variableWidth: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="custom-cards">
      <Slider {...settings} className="d-flex flex-wrap custom-card-inner">
        <CardImage
          cardClass={"card-1"}
          bottomImage={card1}
          imageTitle={"START WITH A SILHOUETTE"}
          imageDescription={
            "Choose a ducduc piece that suits your style and aesthetic. Like this dresser/changer from the Savannah collection for an elegant and modern addition to a nursery or youth room."
          }
          // imageLinkText={"Read More"}
          // imageLinkLessText={"Read Less"}
          imageLinkHref={"#"}
        />
        <CardImage
          cardClass={"card-2"}
          bottomImage={card2}
          imageTitle={"get creative with color"}
          imageDescription={
            "ever open a crayola box and think, “yes please!”. us too. all of our finishes are hand-mixed and applied so you can choose from our range of gorgeous neutrals to show-stopping brights. or pantone match, benjamin moore match, favorite nailpolish match, whatever!"
          }
          // imageLinkText={"Read More"}
          // imageLinkLessText={"Read Less"}
          imageLinkHref={"#"}
        />
        <CardImage
          cardClass={"card-3"}
          bottomImage={card3}
          imageTitle={"details, details, details"}
          imageDescription={
            "have a piece of your own fabric that you want to use? send it over. have a specific type of hardware you have to incorporate? Just ask. We’ll work with to help accomplish your design dreams."
          }
          // imageLinkText={"Read More"}
          // imageLinkLessText={"Read Less"}
          imageLinkHref={"#"}
        />
        {/* <CardImage
          cardClass={"card-4"}
          bottomImage={card3}
          imageTitle={
            <>
              fit your
              <br />
              lifestyle
            </>
          }
          imageDescription={
            "you shouldn’t have to change your space to fit your furniture. We’ll work with you on custom dimensions (to ensure your armoire will fit up the walk-up) and custom configuration (because sometimes you just need that 5th drawer)."
          }
          // imageLinkText={"Read More"}
          // imageLinkLessText={"Read Less"}
          imageLinkHref={"#"}
        /> */}
      </Slider>
    </div>
  );
};

export default CustomCards;
