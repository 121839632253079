import React, { useState, useEffect } from "react";
import Right from "./right";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBContainer,
  MDBBox,
  MDBRow,
  MDBCol,
} from "mdbreact";
import Menu from "./menu";
import Icon from "app/assets/icon/icon";
import Mobile from "./mobile";

// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout definition for the website header
// this file should only be used to bootstrap new header components and transfer props, it should not contain logic
// You can also include an scss file that would contain CSS for the header container
// Keep in mind that this is loaded on every page of the website, so try to keep it and it's children light
// --------------------------------------------------------------------------------------------------------------------------------------------------------

const Header = (props) => {
  let { menu, toggleSearch } = props;

  const [width, setWidth] = useState(document.documentElement.clientWidth);

  const onResizeSetWidth = () => {
    setWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);

  let containerHeight = { height: 71 };

  return (
    <header style={containerHeight}>
      {(width > 991 && (
        <MDBNavbar
          expand="lg"
          scrolling
          fixed="top"
          className={`white z-index-overwrite`}
        >
          <MDBContainer
            id={`main-navigation-header`}
            className="navbar-container position-relative"
          >
            <MDBRow className={`position-relative logo-container`}>
              <MDBCol
                id={`duc-duc-letter-logo`}
                className={`position-relative`}
              >
                <MDBNavbarBrand
                  href="/"
                  onClick={(e) => {
                    if (window.location.pathname === "/") {
                      e.preventDefault();
                    }
                    toggleSearch();
                  }}
                >
                  <Icon icon={`ducducLetterLogo`} />
                </MDBNavbarBrand>
              </MDBCol>
              <MDBCol id={`duc-duc-logo`} className={`position-relative`}>
                <MDBNavbarBrand
                  href="/"
                  onClick={(e) => {
                    if (window.location.pathname === "/") {
                      e.preventDefault();
                    }
                    toggleSearch();
                  }}
                >
                  <Icon icon={"ducducLogo"} />
                </MDBNavbarBrand>
                <MDBBox id={`logo-shape`} className={`position-absolute`}>
                  <Icon icon={`logoShape`} />
                </MDBBox>
              </MDBCol>
            </MDBRow>
            {/* <MDBNavbarToggler onClick={changeToggle} />
          <MDBCollapse isOpen={toggle} navbar></MDBCollapse> */}
            <Menu menu={menu} width={width} setWidth={setWidth} />
            <Right {...props} />
          </MDBContainer>
        </MDBNavbar>
      )) || <Mobile menu={menu} />}
    </header>
  );
};

export default Header;
