import React, { useState, memo } from "react";
import FilterWrapper from "./filterWrapper";
import Button from "core/components/button/button";
import CheckButton from "core/components/checkButton/checkButton";
import _ from "lodash";
const FilterItem = memo(
  props => {
    const [toggle, setToggle] = useState(true);
    const toggleDropdown = () => {
      let currentToggle = toggle;
      setToggle(!currentToggle);
    };

    return (
      <>
        <FilterWrapper {...props}>
          <li
            id={props.value}
            key={props.index}
            className={`children-${toggle} open-dropdown category-dropdown${(props.children &&
              " brand-dropdown") ||
              ""} filter-list`}
            onClick={props.onClick}
          >
            {props.listWithCloseButton &&
              props.selectedCategory &&
              props.option_id === props.selected_category && (
                <Button className="close-btn" />
              )}
            {props.frontend_type !== "color" ? (
              <CheckButton
                containerClass={"checkbox-container check-button"}
                wrapperClass={"checkmark gray"}
                label={props.categoryName}
                onClick={props.onClick}
                checked={props.isActive ? true : false}
                type={"checkbox"}
              />
            ) : (
              <div
                className={`color-filter ${props.categoryName} ${
                  props.isActive ? "active" : "inactive"
                }`}
              >
                <div className={`${props.categoryName}`}></div>
              </div>
            )}
            <span className="icon" onClick={toggleDropdown}>
              {props.icon}
            </span>
            {props.children && (
              <FilterWrapper wrapper={true} toggle={toggle}>
                {props.children}
              </FilterWrapper>
            )}
          </li>
        </FilterWrapper>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default FilterItem;
