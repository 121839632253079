import React, { useState } from "react";
import Selector from "app/layout/selector";
import CheckButton from "core/components/checkButton";
import { MDBRow, MDBCol, MDBIcon, MDBBox } from "mdbreact";
import PlaceholderImage from "app/assets/images/parentCategory/parentCat.png";
import Price from "core/components/price/price";
import ColorListing from "app/pages/catalog/product/info/controls/options/colorPicker/colorListing";
import Button from "core/components/button/button";
import themeConfiguration from "config/themeConfiguration";
import { useEffect } from "react";
import { withRouter } from "react-router";

const Popout = (props) => {
  let {
    hideModal,
    option,
    optionProps,
    reduceProductOptions,
    product,
    isCustom,
    contentClass,
    isColorPicker,
    availableOptions,
    changeOption,
    history,
  } = props;
  let { selections } = optionProps;
  let [isDetails, setIsDetails] = useState(false);
  let [currentUrl, setCurrentUrl] = useState(history.location.pathname);
  if (typeof changeOption === "undefined") {
    changeOption = (value) => {
      let selectionTmp = {};
      let previousPrice = product.options[option.option_id].price;

      let newPrice =
        product.options.selectedPrice - previousPrice + value.price;

      if (selections.value === undefined) {
        let _value = { [option.option_id]: value };
        selectionTmp["value"] = _value;
      } else {
        let _value = { [option.option_id]: value };
        selectionTmp["value"] = { ...selections.value, ..._value };
      }
      let newSelection = {
        [option.option_id]: value,
        ...selectionTmp,
        selectedPrice: newPrice,
        totalPrice: newPrice,
      };
      let newSelections = { ...selections, ...newSelection };

      reduceProductOptions({ ...product, options: newSelections });
    };
  }

  let [tmpSelection, changeTmpSelection] = useState(
    product.options[option.option_id]
  );

  let [tmpPrice, changeTmpPrice] = useState(product.options.totalPrice);

  let setTmpSelection = (value) => {
    let previousPrice = product.options[option.option_id].price;
    let newPrice = product.options.totalPrice - previousPrice + value.price;

    if (typeof value.price === "undefined") {
      newPrice = product.options.totalPrice;
      if (value.type === "specialty") {
        // Add logic for specialty price
        newPrice += 0;
      }
    }
    changeTmpPrice(newPrice);
    changeTmpSelection(value);
  };

  useEffect(() => {
    if (currentUrl !== history.location.pathname) {
      hideModal();
    }
  }, [history.location.pathname, hideModal, currentUrl]);
  let flags = [],
    types = [],
    l =
      typeof availableOptions !== "undefined" ? availableOptions.length : false,
    i;

  if (l !== false) {
    for (i = 0; i < l; i++) {
      if (flags[availableOptions[i].type]) continue;
      if (availableOptions[i].type === null) continue;
      flags[availableOptions[i].type] = true;
      types.push(availableOptions[i].type);
    }
  }

  let [activeTab, setActiveTab] = useState(types[0]);

  return (
    <>
      <Selector className="flex flex-column">
        <MDBRow className="selector-header">
          <MDBCol
            // lg="2"
            size="3"
            onClick={() => {
              setIsDetails(!isDetails);
            }}
            className="back-button"
          >
            {isDetails && (
              <>
                <MDBIcon far icon="arrow-alt-circle-left" />
                <span>back</span>
              </>
            )}
          </MDBCol>
          <MDBCol
            // lg={`${isDetails ? "9" : "9"}`}
            size="6"
            className="selector-header-title"
          >
            {!isDetails && <h3>{option.title}</h3>}
            {isDetails && <h3>details</h3>}
          </MDBCol>
          <MDBCol size="3" className="d-flex justify-content-end">
            {!isDetails && (
              <MDBIcon
                onClick={() => {
                  hideModal();
                }}
                far
                icon="times-circle"
              />
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow
          className={`selector-content ${
            isColorPicker ? contentClass : "size-container"
          }`}
        >
          <MDBCol size="12">
            <MDBRow>
              {isDetails && isColorPicker && (
                <MDBCol size="12" className="view-details-container">
                  <MDBRow className="h-100">
                    <MDBCol size="12" className="details-background">
                      <div
                        style={{
                          backgroundImage: `url(${tmpSelection.swatch_image})`,
                          backgroundColor: `${tmpSelection.color_hex}`,
                          borderColor: "#fff",
                        }}
                        className={`color-swatch`}
                      ></div>
                      <h3>{tmpSelection.swatch_name}</h3>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              )}
              {isDetails && !isColorPicker && (
                <MDBCol size="12" className="view-details-container">
                  <MDBRow className="h-100">
                    <MDBCol size="12" className="details-size">
                      {tmpSelection.image &&
                        ((
                          <img
                            src={tmpSelection.image}
                            alt={`${tmpSelection.title} image`}
                          />
                        ) || (
                          <img
                            src={PlaceholderImage}
                            alt={`placeholder image`}
                          />
                        ))}
                      <MDBBox tag="h4">{tmpSelection.title}</MDBBox>
                      <MDBBox className="dimensions d-flex">
                        <MDBCol size="3" className="dimension-part">
                          {tmpSelection.width && (
                            <MDBBox tag="h5">width</MDBBox>
                          )}
                          {tmpSelection.height && (
                            <MDBBox tag="h5">height</MDBBox>
                          )}
                          {tmpSelection.depth && (
                            <MDBBox tag="h5">depth</MDBBox>
                          )}
                          {tmpSelection.cubbie && (
                            <MDBBox tag="h5">cubbie</MDBBox>
                          )}
                        </MDBCol>
                        <MDBCol size="9" className="dimension-part">
                          <MDBBox tag="h5">{tmpSelection.width}</MDBBox>
                          <MDBBox tag="h5">{tmpSelection.height}</MDBBox>
                          <MDBBox tag="h5">{tmpSelection.depth}</MDBBox>
                          <MDBBox tag="h5">{tmpSelection.cubbie}</MDBBox>
                        </MDBCol>
                      </MDBBox>
                      <MDBBox className="notes">
                        {tmpSelection.note && (
                          <>
                            <MDBBox tag="h4">notes</MDBBox>
                            <MDBBox tag="h5">{tmpSelection.note}</MDBBox>
                          </>
                        )}
                      </MDBBox>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              )}
              {!isDetails &&
                !isCustom &&
                option.value.map((value, index) => {
                  return (
                    <MDBCol
                      key={`option-value-${value.title}-${value.option_type_id}-${index}`}
                      size="12"
                    >
                      <CheckButton
                        containerClass={
                          "radio-container check-button radio-yes-no"
                        }
                        wrapperClass={"radio"}
                        label={value.title}
                        value={value.option_type_id}
                        onChange={() => {
                          setTmpSelection(value);
                        }}
                        checked={isChecked(value, tmpSelection)}
                        type={"radio"}
                      />
                    </MDBCol>
                  );
                })}
              {!isDetails && isCustom && isColorPicker && (
                <ColorListing
                  selected={tmpSelection}
                  hideModal={hideModal}
                  {...props}
                  changeOption={setTmpSelection}
                  availableOptions={availableOptions}
                  activeTab={activeTab}
                  types={types}
                  setActiveTab={setActiveTab}
                />
              )}
              {!isDetails && isCustom && !isColorPicker && props.children}
            </MDBRow>
          </MDBCol>
        </MDBRow>
        {!isDetails && (
          <MDBRow className="selector-footer">
            <MDBCol lg="6" size="12">
              <MDBRow className="d-flex flex-column">
                <MDBCol size="12" className="px-lg-1 pb-3 pb-lg-0 px-0">
                  <span className="d-inline-block selected-title mr-4">
                    selected:
                  </span>
                  <span className="d-inline-block selected-value">
                    {tmpSelection.title || (
                      <>
                        <div
                          style={{
                            backgroundImage: `url(${tmpSelection.swatch_image})`,
                            backgroundColor: `${tmpSelection.color_hex}`,
                            borderColor: "#fff",
                            backgroundSize: "contain",
                          }}
                          className={`color-swatch small`}
                        ></div>
                        <div className="selected-value-text">
                          {tmpSelection.swatch_name}
                        </div>
                      </>
                    )}
                  </span>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol lg="6" size="12">
              <MDBRow className="d-flex flex-column">
                <MDBCol size="12" className="pb-3 pb-lg-0 px-0">
                  <span className="d-inline-block selected-title mr-4">
                    subtotal:
                  </span>
                  <span className="d-inline-block selected-value">
                    <Price price={tmpPrice} type={`fixed`} />
                  </span>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            {/* {isColorPicker && ( */}
            <MDBCol lg="6" size="12" className="mt-4 d-flex align-items-center">
              <MDBRow className="d-flex view-selected-details-container flex-column">
                <MDBCol size="12" className="px-lg-0 ">
                  <span
                    onClick={() => {
                      setIsDetails(!isDetails);
                    }}
                    className="d-lg-inline-block d-flex view-selected mr-4"
                  >
                    view selected details
                  </span>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            {/* )} */}
            <MDBCol lg="6" size="12">
              <MDBRow className="d-flex flex-column">
                <MDBCol
                  size="12"
                  className={`mt-4 d-flex px-0 px-lg-0 align-items-center ${
                    isColorPicker ? "" : "px-lg-0"
                  }`.trim()}
                >
                  <Button
                    onClick={() => {
                      changeOption(tmpSelection);
                      hideModal();
                    }}
                    color={"primary"}
                    className={"right-arrow w-100 orange-button"}
                    direction={"ml-3"}
                    righticon={"true"}
                    icon={"arrow-right"}
                    text={"save"}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )}
      </Selector>
    </>
  );
};

export default withRouter(Popout);

function isChecked(value, tmpSelection) {
  if (tmpSelection.option_type_id === value.option_type_id) {
    return true;
  }
  return false;
}
