import React from "react";
import { MDBCol } from "mdbreact";
import ShopLook from "../../../home/shopLook";
import ShopLookSlider from "../../../home/shopLookSlider";

const CustomShopTheLook = props => {
  return (
    <div className="custom-shop-the-look">
      <div className="banner-and-shop">
        <MDBCol className={"shop-look"} size="12">
          <ShopLook />
        </MDBCol>
        <MDBCol className={"shop-slider"}>
          <ShopLookSlider slidesToShow={2} DotsAlign={"center"} />
        </MDBCol>
      </div>
    </div>
  );
};

export default CustomShopTheLook;
