import React, { useState, useEffect } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import InputField from "core/components/inputField";
import CountrySelect from "core/components/countrySelect";
import CheckButton from "core/components/checkButton";
import Button from "core/components/button/button";
import { isUndefined } from "core/helpers/functions";
import { validate, shouldMarkError } from "./validation";
import MaskedInput from "react-text-mask";
import { findMaskByCountry } from "./functions";

const Form = (props) => {
  let {
    // Getters
    firstname,
    lastname,
    companyname,
    phone,
    address1,
    address2,
    city,
    zipcode,
    countryValue,
    stateValue,
    //Setters
    setFirstname,
    setLastname,
    setCompanyname,
    setPhone,
    setAddress1,
    setAddress2,
    setCity,
    setZipcode,
    // Checkboxes
    defaultBilling,
    defaultShipping,
    setDefaultBilling,
    setDefaultShipping,
    //State
    lock,
    loading,
    setLoading,
  } = props;

  const [touched, setTouched] = useState({
    firstname: false,
    lastname: false,
    address: false,
    city: false,
    zipcode: false,
    phone: false,
    stateValue: false,
  });

  const generateSanitizedMaskArray = (object) => {
    return Object.keys(object).map((key) => {
      let regexChar = object[key];
      if (object[key] === "+") regexChar = "+";
      if (object[key] === "#") regexChar = /\d/;
      return regexChar;
    });
  };

  const [mask, setMask] = useState(findMaskByCountry("US"));
  const [maskSanitizedArray, setMaskSanitizedArray] = useState(
    generateSanitizedMaskArray(findMaskByCountry("US").mask.split(""))
  );

  const setCountrySelectHandler = (setMask, setMaskSanitizedArray) => {
    let value = props.countryValue;
    let mask = [];
    mask = findMaskByCountry(value.value);
    setMask(mask);
    let maskSanitized = mask.mask.split("");
    setMaskSanitizedArray(generateSanitizedMaskArray(maskSanitized));
  };

  const [zipMask, setZipMask] = useState([/\d/, /\d/, /\d/, /\d/, /\d/]);
  const [zipCodeError, setZipCodeError] = useState(false);

  useEffect(() => {
    setCountrySelectHandler(setMask, setMaskSanitizedArray);
    if (countryValue.value === "CA") {
      setZipMask([/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]);
    } else if (countryValue.value === "US") {
      setZipMask([/\d/, /\d/, /\d/, /\d/, /\d/]);
    } else {
      setZipMask([]);
    }
  }, [countryValue.value]);

  let errors = validate(
    firstname,
    lastname,
    address1,
    city,
    zipcode,
    phone,
    countryValue.value,
    stateValue
  );
  let lockButton = false;

  const submitHandle = async (e) => {
    setZipCodeError(zipMask.length !== zipcode.length);
    let isTrue = true;
    setTouched({
      firstname: true,
      lastname: true,
      address: true,
      city: true,
      zipcode: true,
      phone: true,
      stateValue: true,
    });
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    e.preventDefault();
    let regionObject = {};
    if (!isUndefined(props.stateValue)) {
      if (props.stateValue !== null) {
        if (props.stateValue.value === undefined) {
          regionObject = {
            region_code: props.stateValue,
            region: props.stateValue,
            region_id: 0,
          };
        } else {
          regionObject = {
            region_code: props.stateValue.code,
            region: props.stateValue.label,
            region_id: props.stateValue.value,
          };
        }
      }
    }
    let requestAdd = {};
    let requestEdit = {};
    if (props.title === "Add Address") {
      requestAdd = {
        firstname: firstname,
        lastname: lastname,
        company: companyname,
        telephone: phone,
        street: [address1, address2],
        country_id: props.countryValue.value,
        city: city,
        region: regionObject,
        postcode: zipcode,
        default_billing: defaultBilling,
        default_shipping: defaultShipping,
      };
    }
    if (props.title !== "Add Address") {
      requestEdit = {
        id: props.address.id,
        input: {
          firstname: firstname,
          lastname: lastname,
          company: companyname,
          telephone: phone,
          street: [address1, address2],
          country_id: props.countryValue.value,
          city: city,
          region: regionObject,
          postcode: zipcode,
          default_billing: defaultBilling,
          default_shipping: defaultShipping,
        },
      };
    }
    if (isTrue) {
      setLoading(true);
      lock({
        always: () => {},
        success: () => {
          setLoading(false);
        },
        fail: () => {
          setLoading(false);
        },
      });
      if (props.title === "Add Address") {
        props.createCustomerAddress(requestAdd);
      } else props.editCustomerAddress(requestEdit);
    }
  };

  return (
    <MDBContainer className="account-addressform-container mb-5">
      <MDBRow className="account-addressform">
        <MDBCol lg="12" className="">
          <h3 className="large">{props.title}</h3>
        </MDBCol>
        <MDBCol lg="6" md="12" className="address-contact-info">
          <h4 className="gray heavy large mb-5">contact information</h4>
          <InputField
            className={`custom-input form-control ${
              shouldMarkError("firstname", errors, touched)
                ? "validation-error"
                : ""
            }`}
            label={`${
              shouldMarkError("firstname", errors, touched)
                ? "first name is required*"
                : "first name*"
            }`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={firstname}
            onChange={(e) => {
              setFirstname(e.target.value);
            }}
          />
          <InputField
            className={`custom-input form-control ${
              shouldMarkError("lastname", errors, touched)
                ? "validation-error"
                : ""
            }`}
            label={`${
              shouldMarkError("lastname", errors, touched)
                ? "last name is required*"
                : "last name*"
            }`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={lastname}
            onChange={(e) => {
              setLastname(e.target.value);
            }}
          />
          <InputField
            label="company name"
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={companyname}
            onChange={(e) => {
              setCompanyname(e.target.value);
            }}
          />
          <MaskedInput
            id="mask-input"
            type="text"
            error="wrong"
            success="right"
            value={phone !== undefined ? phone : ""}
            onChange={(e) => setPhone(e.target.value)}
            mask={maskSanitizedArray}
            guide={false}
            placeholder={`${
              shouldMarkError("phone", errors, touched)
                ? "phone is required*"
                : "phone*"
            }`}
            className={`form-control validate default-input-field ${
              shouldMarkError("phone", errors, touched)
                ? "validation-error"
                : ""
            }`}
            render={(ref, inputProps) => {
              return <input {...inputProps} ref={ref} label="phone" />;
            }}
          />
        </MDBCol>
        <MDBCol lg="6" md="12">
          <h4 className="gray heavy large mb-5">address</h4>
          <CountrySelect
            isSearchable={true}
            setStateValue={props.setStateValue}
            stateValue={props.stateValue}
            countryWrapperClass={"main-select dark indented"}
            statePlaceholder={
              props.countryValue.value === "US" ||
              props.countryValue.value === "CA"
                ? props.countryValue.value === "US"
                  ? "state*"
                  : props.countryValue.value === "CA"
                  ? "province*"
                  : "province"
                : "province"
            }
            stateLabel={"region"}
            countryValue={props.countryValue}
            setCountryValue={props.setCountryValue}
            stateWrapperClass={
              (props.stateValue === "" || props.stateValue === null) &&
              touched.stateValue
                ? "main-select dark validation-error"
                : "main-select dark"
            }
          />
          <InputField
            className={`custom-input form-control ${
              shouldMarkError("address", errors, touched)
                ? "validation-error"
                : ""
            }`}
            label={`${
              shouldMarkError("address", errors, touched)
                ? "address 1 is required*"
                : "address 1*"
            }`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={address1}
            onChange={(e) => {
              setAddress1(e.target.value);
            }}
          />
          <InputField
            label="address 2"
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={address2}
            onChange={(e) => {
              setAddress2(e.target.value);
            }}
          />
          <InputField
            className={`custom-input form-control ${
              shouldMarkError("city", errors, touched) ? "validation-error" : ""
            }`}
            label={`${
              shouldMarkError("city", errors, touched)
                ? "city is required*"
                : "city*"
            }`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
          {zipMask.length > 0 ? (
            <MaskedInput
              id="mask-input"
              placeholder={
                shouldMarkError("zipcode", errors, touched)
                  ? props.countryValue.value === "US" ||
                    props.countryValue.value === "CA"
                    ? props.countryValue.value === "US" && props.zipcode === ""
                      ? "zip code is required*"
                      : props.countryValue.value === "CA" &&
                        props.zipcode === "" &&
                        "postal code is required*"
                    : props.countryValue.value === "US"
                    ? "zip code*"
                    : props.countryValue.value === "CA"
                    ? "postal code*"
                    : "postal code"
                  : props.countryValue.value === "US"
                  ? "zip code*"
                  : props.countryValue.value === "CA"
                  ? "postal code*"
                  : "postal code"
              }
              group
              type="text"
              validate
              error="wrong"
              success="right"
              value={zipcode}
              mask={zipMask}
              onChange={(e) => {
                setZipcode(e.target.value);
              }}
              className={`
                          custom-input form-control zip-code default-input-field 
                    ${
                      shouldMarkError("zipcode", errors, touched) ||
                      zipCodeError
                        ? "validation-error"
                        : ""
                    }`}
              onBlur={(e) => {
                setTouched({ ...touched, zipcode: true });
              }}
              guide={false}
              render={(ref, inputProps) => {
                return <input {...inputProps} ref={ref} value={zipcode} />;
              }}
            />
          ) : (
            <InputField
              label={
                shouldMarkError("zipcode", errors, touched)
                  ? props.countryValue.value === "US" ||
                    props.countryValue.value === "CA"
                    ? props.countryValue.value === "US" && props.zipcode === ""
                      ? "zip code is required*"
                      : props.countryValue.value === "CA" &&
                        props.zipcode === "" &&
                        "postal code is required*"
                    : props.countryValue.value === "US"
                    ? "zip code*"
                    : props.countryValue.value === "CA"
                    ? "postal code*"
                    : "postal code"
                  : props.countryValue.value === "US"
                  ? "zip code*"
                  : props.countryValue.value === "CA"
                  ? "postal code*"
                  : "postal code"
              }
              group
              type="text"
              validate
              error="wrong"
              success="right"
              value={zipcode}
              onChange={(e) => {
                setZipcode(e.target.value);
              }}
            />
          )}
          <CheckButton
            containerClass={"switch-container check-button mt-5"}
            wrapperClass={"slider"}
            checked={defaultBilling}
            onChange={() => {
              setDefaultBilling(!defaultBilling);
            }}
            label={"use as my default billing address"}
            type={"checkbox"}
          />
          <CheckButton
            containerClass={"switch-container check-button mt-5"}
            wrapperClass={"slider"}
            checked={defaultShipping}
            onChange={() => {
              setDefaultShipping(!defaultShipping);
            }}
            label={"use as my default shipping address"}
            type={"checkbox"}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className={"border-bottom"}>
        <MDBCol className="req-block">
          <div className="text-right required-text-block">
            <span className="required-sign">*</span>
            <span className="required-text">= required</span>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg="4" className="offset-lg-8" md="12">
          <Button
            disabled={loading}
            color={"primary"}
            className={"right-arrow orange-button mt-5 w-100"}
            direction={"ml-3"}
            righticon={"true"}
            icon={"arrow-right"}
            text={loading ? "Processing" : "Submit"}
            onClick={(e) => {
              submitHandle(e);
            }}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Form;
