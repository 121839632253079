import React from "react";
import CheckoutShippingAddress from "./checkoutShippingAddress";
import countries from "config/data/countries_checkout_filtered";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { filterByCountryId } from "core/helpers/customer";

const CheckoutShippingAddressDataContainer = (props) => {
  let {
    setShippingAddressOnCart,
    customer,
    shipping_address,
    lock,
    shippingAddress,
    setShippingAddress,
    setShouldChange,
  } = props;
  if (typeof customer.cart === "undefined") return <></>;
  let defaultShippingAddress = false;
  let customerAddresses = [];

  const _countries = countries.countries;
  let countryDataObject = getCountries(_countries);
  let countriesData;
  countriesData = Object.keys(countryDataObject).map((key) => {
    return countryDataObject[key];
  });

  let selectedCountry = filterByCountryId("US", countriesData);
  let regionsDataObject = getRegionData(countriesData, selectedCountry);

  let { addresses } = customer;

  if (typeof addresses !== "undefined" && customer.token) {
    if (addresses.length !== 0) {
      addresses.map((address) => {
        if (address.default_shipping) {
          defaultShippingAddress = {
            value: address.id,
            label: `${address.street}, ${address.city}, ${
              address.region.region_code
            } ${address.postcode}, ${
              filterByCountryId(address.country_code, countriesData).label
            }`,
          };
        }
        customerAddresses.push({
          value: address.id,
          label: `${address.street}, ${address.city}, ${
            address.region.region_code
          } ${address.postcode}, ${
            filterByCountryId(address.country_code, countriesData).label
          }`,
        });
      });
      customerAddresses.push({
        value: "new-address",
        label: "new address",
      });
    }
  }

  if (customer.cart.shipping_addresses.length === 0) {
    if (defaultShippingAddress) {
      // setShippingAddressOnCart({
      //   customer_address_id: defaultShippingAddress.value,
      // });
    }
  }

  return (
    <CheckoutShippingAddress
      countries={_countries}
      regionsDataObject={regionsDataObject}
      loggedIn={customer.token}
      customerAddresses={customerAddresses}
      setShippingAddressOnCart={setShippingAddressOnCart}
      defaultShippingAddress={defaultShippingAddress}
      shipping_address={shipping_address}
      lock={lock}
      shippingAddress={shippingAddress}
      setShippingAddress={setShippingAddress}
      setShouldChange={setShouldChange}
    />
  );
};

export default CheckoutShippingAddressDataContainer;
