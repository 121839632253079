import React from "react";
import { MDBCol, MDBLink } from "mdbreact";
import Image from "core/components/image";
export const renderItem = (item, index, category) => {
  let active = true;
  if (
    typeof item.category[0] !== "undefined" &&
    category !== parseInt(item.category[0].category_id) &&
    category !== 0
  )
    active = false;

  let image =
    item.image === null ? "pub/media/galleryImages/Image_165.png" : item.image;
  return (
    <MDBCol
      key={index}
      lg={"4"}
      md={"4"}
      sm={"6"}
      xs={"12"}
      className={`shop-the-look-item ${active ? "active" : "inactive"}`}
    >
      <MDBLink
        to={`/shop-the-look-detail/${item.name
          .split(" ")
          .join("-")
          .toLowerCase()
          .replace(/\-$/, "") +
          "-" +
          index}`}
        className={`shop-the-look-link`}
      >
        <Image source={`${image}`} backend={true} />
        <h3>{item.name}</h3>
      </MDBLink>
    </MDBCol>
  );
};
