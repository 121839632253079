import React, { useEffect } from "react";
import { MDBCol, MDBRow, MDBIcon, MDBCollapse, MDBBox } from "mdbreact";
import Price from "core/components/price";
import Input from "core/components/input";
import { isEmpty } from "core/helpers/functions";
import { Link } from "react-router-dom";
import _ from "lodash";
import ReactLoader from "app/layout/loader";
import Image from "core/components/image";
import Icon from "app/assets/icon/icon";

const updateCartItemFn = (
  item,
  value,
  updateCart,
  lock,
  quantity,
  setQuantity,
  setLoading
) => {
  if (value < 1 || value === "" || _.isNaN(value)) {
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setQuantity(1);
      },
      fail: () => {
        setLoading(false);
      },
    });
    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: 1,
      },
    ]);
  } else {
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setQuantity(value);
      },
      fail: () => {
        setLoading(false);
      },
    });
    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: quantity === 1 ? 1 : value,
      },
    ]);
  }
};

const MobileCartItem = (props) => {
  let {
    product,
    item,
    options,
    headers,
    removeItem,
    quantity,
    setQuantity,
    detailsExpanded,
    setDetailsExpanded,
    loading,
    setLoading,
    lock,
    updateCart,
  } = props;
  useEffect(() => {
    if (item.quantity !== quantity) {
      setQuantity(item.quantity);
    }
  }, [item, setQuantity]);
  return (
    <MDBCol size="12" className="pb-4 mobile-cart-items border-bottom px-5">
      <MDBRow className={`item-container mt-5`}>
        <MDBCol className="item-image-container mb-5" size="6">
          <MDBRow>
            <MDBCol size="12">
              <Link to={product.url_key}>
                <div className={`position-relative`}>
                  <Image
                    source={product.small_image.url}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </Link>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol
          className="item-detail-container mb-5 d-flex align-items-center"
          size={6}
        >
          <MDBRow>
            <MDBCol size="12">
              <MDBCol size={"12"}>
                <Link to={product.url_key}>
                  <h5 className="item-name orange-nav">{product.name}</h5>
                </Link>
              </MDBCol>
              <MDBCol className={`item-controls`}>
                <MDBRow>
                  <MDBCol
                    size="12"
                    className="item-remove-container d-flex justify-content-between"
                  >
                    {item.customizable_options.length > 0 ? (
                      <Link
                        to={product.url_key + `?edit=${item.id}`}
                        className={`edit-link`}
                      >
                        <Icon icon={`edit`} className={`edit-icon`} />
                      </Link>
                    ) : (
                      <></>
                    )}
                    <MDBBox
                      onClick={() => {
                        if (!loading) {
                          setLoading(true);
                          lock({
                            always: () => {},
                            success: () => {
                              setLoading(false);
                            },
                            fail: () => {
                              setLoading(false);
                            },
                          });
                          removeItem();
                        }
                      }}
                    >
                      <Icon icon={`trashCanOrange`} />
                    </MDBBox>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol
          className="item-price-container d-flex align-items-center"
          size={12}
        >
          <MDBRow className="w-100 mb-5">
            <MDBCol size="6">
              <h5>{headers[2].title}</h5>
            </MDBCol>
            <MDBCol size="6">
              <Price price={item.prices.price.value} type={`fixed`} />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol className="item-qty-container" size={12}>
          <MDBRow className="w-100 mb-5">
            <MDBCol size="6" className="d-flex align-items-center">
              <h5>{headers[3].title}</h5>
            </MDBCol>
            <MDBCol
              size="6"
              className={`d-flex align-items-center${
                quantity === 1 ? " disable-minus" : ""
              }`}
            >
              <Input
                name={``}
                type={"number"}
                wrapperClass={"quantity-field"}
                className={"qty-control-wrapper"}
                inputClass={""}
                value={quantity}
                plusClick={(e) => {
                  if (!loading) {
                    setLoading(true);
                    updateCartItemFn(
                      item,
                      quantity + 1,
                      updateCart,
                      lock,
                      quantity,
                      setQuantity,
                      setLoading
                    );
                  }
                }}
                minusClick={(e) => {
                  if (quantity > 1) {
                    if (!loading) {
                      setLoading(true);
                      updateCartItemFn(
                        item,
                        quantity - 1,
                        updateCart,
                        lock,
                        quantity,
                        setQuantity,
                        setLoading
                      );
                    }
                  }
                }}
                onBlur={(e) => {
                  let value = parseInt(e.target.value);
                  if (!loading) {
                    setLoading(true);
                    updateCartItemFn(
                      item,
                      value,
                      updateCart,
                      lock,
                      quantity,
                      setQuantity,
                      setLoading
                    );
                  }
                }}
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  if (!loading) {
                    if (value < 1 || value === "" || _.isNaN(value)) {
                      setQuantity(1);
                    } else {
                      setQuantity(value);
                    }
                  }
                }}
              />
              <ReactLoader
                visible={loading}
                type={`ThreeDots`}
                height={`35`}
                width={`35`}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol
          className="item-subtotal-container d-flex align-items-center"
          size={12}
        >
          <MDBRow className="w-100 mb-3 ml-0">
            <MDBCol size="6" className="pl-0">
              <h5>{headers[4].title}</h5>
            </MDBCol>
            <MDBCol size="6" className="pl-0">
              <Price price={item.prices.row_total.value} type={`fixed`} />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>

      {!isEmpty(options) && (
        <MDBRow className="mt-3">
          <MDBCol size="12">
            <MDBRow
              onClick={() => setDetailsExpanded(!detailsExpanded)}
              className="px-4"
            >
              <MDBCol
                size="12"
                className={`expandable-header pt-5 px-0 border-top`.trim()}
              >
                <MDBIcon icon={detailsExpanded ? "minus" : "plus"} />
                <h5 className="orange-nav ml-3 pb-4 heavy d-inline-block">
                  details
                </h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="12" className="px-0">
                <MDBCollapse
                  className="expandable-content item-options-container pb-3 pl-4"
                  isOpen={detailsExpanded}
                >
                  <MDBRow>
                    {Object.keys(options).map((key) => {
                      return (
                        <>
                          <MDBCol size="6" className="item-option-name mb-3">
                            {key}
                          </MDBCol>
                          <MDBCol size="6" className="item-option-value mb-3">
                            {options[key]}
                          </MDBCol>
                        </>
                      );
                    })}
                  </MDBRow>
                </MDBCollapse>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      )}
    </MDBCol>
  );
};

export default MobileCartItem;
