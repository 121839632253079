import React, { memo } from "react";
import InputField from "core/components/inputField";
import Button from "core/components/button";
import { MDBCol, MDBRow, MDBIcon, MDBCollapse } from "mdbreact";
import { isEqual } from "lodash";

const Coupon = memo(
  ({
    discountCode,
    couponLoading,
    discountOpen,
    setDiscountCode,
    setCouponLoading,
    setDiscountOpen,
    lock,
    applyCouponOnCart,
    cart,
    removeCouponFromCart,
  }) => {
    return (
      <MDBCol
        size="12"
        className="discount-code border-top border-bottom py-4 mt-3 pl-3 pr-4"
      >
        <MDBRow onClick={() => setDiscountOpen(!discountOpen)}>
          <MDBCol size="11" className="discount-title">
            discount code
          </MDBCol>
          <MDBCol size="1" className="discount-caret">
            <MDBIcon icon={discountOpen ? "caret-up" : "caret-down"} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <MDBCollapse isOpen={discountOpen} className="discount-content">
              {typeof cart.prices !== "undefined" &&
              cart.prices.discounts !== null ? (
                <Button
                  color={"primary"}
                  className={`right-arrow mt-4 mb-4 w-100 ${
                    couponLoading ? "gray-button" : ""
                  }`}
                  direction={"ml-3"}
                  righticon={"true"}
                  icon={"arrow-right"}
                  text={`${
                    !couponLoading ? "remove coupon code" : "removing..."
                  }`}
                  disabled={couponLoading}
                  onClick={(e) => {
                    setCouponLoading(true);
                    lock({
                      always: () => {},
                      success: () => {
                        setCouponLoading(false);
                        setDiscountOpen(false);
                      },
                      fail: () => {
                        setCouponLoading(false);
                      },
                    });
                    removeCouponFromCart();
                  }}
                />
              ) : (
                <>
                  <InputField
                    className={`custom-input mt-5 w-100`}
                    label={`enter discount code`}
                    type="text"
                    wrapperClass={"w-100"}
                    value={discountCode}
                    onChange={(e) => {
                      setDiscountCode(e.target.value);
                    }}
                  />

                  <Button
                    color={"primary"}
                    className={`right-arrow mb-4 w-100 ${
                      discountCode === "" || couponLoading ? "gray-button" : ""
                    }`}
                    direction={"ml-3"}
                    righticon={"true"}
                    icon={"arrow-right"}
                    text={`${!couponLoading ? "apply" : "applying..."}`}
                    disabled={couponLoading || discountCode.length === 0}
                    onClick={(e) => {
                      setCouponLoading(true);
                      if (discountCode.length > 0) {
                        lock({
                          always: () => {},
                          success: () => {
                            setCouponLoading(false);
                            setDiscountCode("");
                            setDiscountOpen(false);
                          },
                          fail: () => {
                            setCouponLoading(false);
                          },
                        });
                        applyCouponOnCart(discountCode);
                      }
                    }}
                  />
                </>
              )}
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Coupon;
