import { isUndefined } from "core/helpers/functions";

export const findGetParameter = (parameterName) => {
  var result = null,
    tmp = [];
  var items = window.location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

export const createRequestFromFilters = (filters, search) => {
  let searchValue = findGetParameter("search");
  if (search !== searchValue) {
    searchValue = search;
  }
  if (isUndefined(filters)) return { search: searchValue, filter: {} };

  let request = { search: searchValue, filter: {} };

  Object.keys(filters).forEach((filter) => {
    let _filter = remapFilterId(filter);
    if (!isUndefined(filters[filter].filter_value)) {
      if (filters[filter].filter_type === "multi") {
        request["filter"][_filter] = { in: filters[filter].filter_value };
      } else {
        if (_filter === "room_type") {
          request["filter"][_filter] = {
            like: `%${filters[filter].filter_value}%`,
          };
        } else {
          request["filter"][_filter] = { eq: filters[filter].filter_value };
        }
      }
    } else {
      let keys = Object.keys(filters[_filter]);

      // check to see if it's from/to filter
      if ("from" === keys[0] && "to" === keys[1]) {
        request["filter"][_filter] = {
          [keys[0]]: filters[_filter].from.filter_value,
          [keys[1]]: filters[_filter].to.filter_value,
        };
      }
    }
    return true;
  });
  return request;
};

export const createSortVariable = (sort) => {
  if (isUndefined(sort)) {
    return { name: "ASC" };
  }

  return sort;
};

const remapFilterId = (filter) => {
  let _filter = filter;
  if (filter === "category") {
    _filter = "category_id";
  }
  return _filter;
};
