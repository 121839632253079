import React, { memo } from "react";
import { isEqual } from "lodash";
import Item from "./item";
import { MDBContainer, MDBCol } from "mdbreact";

const Items = memo(
  ({
    items,
    deleteItem,
    addMessage,
    lock,
    addItemToCart,
    copyWishlistItem,
    wishlist,
    addItemToWishlist
  }) => {
    if (typeof items === "undefined")
      return (
        <MDBCol className="empty-wishlist w-responsive text-center mx-auto p-3 my-5">
          This wishlist is empty
        </MDBCol>
      );
    let itemComponents = items.map(item => {
      return (
        <Item
          key={`wishlist-item-${item.id}`}
          item={item}
          deleteItem={deleteItem}
          addMessage={addMessage}
          addItemToCart={addItemToCart}
          copyWishlistItem={copyWishlistItem}
          lock={lock}
          wishlist={wishlist}
          addItemToWishlist={addItemToWishlist}
        />
      );
    });

    return <MDBContainer fluid>{itemComponents}</MDBContainer>;
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps) ? true : false;
  }
);

export default Items;
