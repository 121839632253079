// logic is inversed, true means that an error exists

export const validate = (email, password) => {
  return {
    email: email.length === 0 || ValidateEmail(email),
    password: password.length === 0,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return false;
  }
  return true;
}
