import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBox,
} from "mdbreact";
import InputField from "core/components/inputField/inputField";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";

const CreateWishlistCore = (props) => {
  const [
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    getWishlistItems,
    moveItemToWishlist,
    editWishlist,
    wishlist,
    myWishlists,
    createWishlist,
    deleteWishlist,
  ] = useWishlist();
  let { project, setProject } = props;
  return (
    <MDBBox className="wishlist-create-wrapper">
      <h4>Create New Project</h4>
      <MDBBox className={"flex-item text-center wishlist-create-content "}>
        <InputField
          className={"wishlist-input"}
          label={
            typeof project !== "undefined" && typeof project.length === 0
              ? "enter project name"
              : "project name"
          }
          group
          type="text"
          value={project}
          onChange={(e) => {
            if (e.target.value.length <= 24) {
              setProject(e.target.value);
            }
          }}
        />

        <MDBBtn
          className={"primary"}
          onClick={(e) => {
            createWishlist(project);
          }}
        >
          Create wishlist
        </MDBBtn>
      </MDBBox>
    </MDBBox>
  );
};
export default CreateWishlistCore;
