import React from "react";
import ShopTheLookDataContainer from "./shopTheLook-data-container";
import GraphQlWrapper from "core/components/graphQlWrapper";
import { gql } from "graphql.macro";

const query = gql`
  query {
    getAllGalleryData {
      name
      image
      description
      category {
        category_id
        name
      }
      hotspot {
        image
        content
        name
      }
    }
  }
`;
const ShopTheLookQueryContainer = () => {
  return (
    <GraphQlWrapper query={query} variables={{ id: `shop-the-look-data` }}>
      <ShopTheLookDataContainer />
    </GraphQlWrapper>
  );
};

export default ShopTheLookQueryContainer;
