// logic is inversed, true means that an error exists

export const validate = (
  modelname,
  modelnumber,
  manufacturedate,
  firstname,
  lastname,
  email,
  countryselect,
  address1,
  city,
  selectstate,
  zipcode
) => {
  return {
    modelname: modelname.length === 0,
    modelnumber: modelnumber.length === 0,
    manufacturedate: manufacturedate.length === 0,
    firstname: firstname.length === 0,
    lastname: lastname.length === 0,
    email: email.length === 0 || ValidateEmail(email),
    countryselect: countryselect.length === 0,
    address1: address1.length === 0,
    city: city.length === 0,
    selectstate: selectstate.length === 0,
    zipcode: zipcode.length === 0
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return false;
  }
  return true;
}
