import React, { useState, useEffect } from "react";
import ActiveFilterItem from "./activeFilterItem/activeFilterItem";
import { getActiveFilters } from "./functions";
import { isEmpty } from "core/helpers/functions";
import { MDBBox, MDBCol } from "mdbreact";

const ActiveFilter = (props) => {
  let active_filters = getActiveFilters(props.filters);
  /*Mobile start*/
  const [screenwidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);
  /*Mobile end*/
  if (screenwidth < 992) {
    return (
      <>
        {isEmpty(active_filters) === false && (
          <MDBCol flex={`column`}>
            <div
              id={"active-filter"}
              className={"selected-filter filter-content active-filter-mobile"}
            >
              {Object.keys(active_filters).map((index, value) => {
                return (
                  <p className="active-filter">
                    <ActiveFilterItem
                      key={`active-filter-${index}`}
                      index={index}
                      filter={active_filters[index]}
                      removeFilter={props.removeFilter}
                    />
                  </p>
                );
              })}
            </div>
          </MDBCol>
        )}
      </>
    );
  } else {
    return (
      <>
        {isEmpty(active_filters) === false && (
          <MDBBox flex={`column`}>
            <div className="clear-all-wrapper position-absolute">
              <span
                className={`clear-selected`}
                text={`Clear all`}
                onClick={props.clearFilters}
              >
                reset All
              </span>
            </div>
            <div
              id={"active-filter"}
              className={"selected-filter filter-content"}
            >
              <p className="filt-shop">Currently shopping by</p>

              {Object.keys(active_filters).map((index, value) => {
                return (
                  <p className="active-filter" key={`active-filter-${index}`}>
                    <ActiveFilterItem
                      key={`active-filter-${index}`}
                      index={index}
                      filter={active_filters[index]}
                      removeFilter={props.removeFilter}
                    />
                  </p>
                );
              })}
            </div>
          </MDBBox>
        )}
      </>
    );
  }
};

export default ActiveFilter;
