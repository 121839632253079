import React from "react";
import { connect } from "react-redux";
import { commonActions } from "core/state/redux/data/common";
import { searchActions } from "core/state/redux/data/search";
import { customerActions } from "core/state/redux/data/customer";
import FinishItem from "./finishItem";

const FinishItemReduxContainer = props => {
  return <FinishItem {...props} />;
};

const mapStateToProps = state => ({
  wishlist:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.wishlist !== "undefined"
      ? state.customer.data.wishlist
      : {},
  cartItems: state.customer.data.cart.items
});

const mapDispatchToProps = dispatch => {
  return {
    addItemToCart: (amount, productNumber) => {
      dispatch(
        customerActions.addProductsToCart([
          {
            data: {
              quantity: amount,
              sku: productNumber
            }
          }
        ])
      );
    },
    updateCart: items => {
      dispatch(customerActions.updateCart(items));
    },
    showLogin: () => {
      window.scrollTo(0, 0);
      dispatch(searchActions.toggleSearch(false));
      dispatch(customerActions.toggleLogin(true));
    },
    lock: callback => {
      dispatch(commonActions.lock(callback));
    },
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    addItemToWishlist: (wishlist_id, product_id = []) => {
      dispatch(customerActions.addProductToWishlist(wishlist_id, product_id));
    },
    removeItemFromWishlist: wishlist_id => {
      dispatch(customerActions.removeItemFromWishlist(wishlist_id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinishItemReduxContainer);
