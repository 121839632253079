import React from "react";
import { MDBAnimation, MDBContainer } from "mdbreact";

const Selector = props => {
  return (
    <MDBAnimation className="options-selector" type={"slideInRight"}>
      <MDBContainer className="options-container" fluid>
        {props.children}
      </MDBContainer>
    </MDBAnimation>
  );
};

export default Selector;
