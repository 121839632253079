import React from "react";
import SearchInput from "./searchInput";
import { searchActions } from "core/state/redux/data/search";
import { connect } from "react-redux";

const SearchInputReduxContainer = (props) => {
  return <SearchInput {...props} />;
};
const mapStateToProps = (state) => ({
  search: state.search.search,
  showSearch:
    typeof state.search.show !== "undefined" ? state.search.show : false,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearch: (searchTerm) => {
      dispatch(searchActions.changeSearch(searchTerm));
    },
    toggleSearch: (status) => {
      dispatch(searchActions.toggleSearch(status));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchInputReduxContainer);
