import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

const Overlay = props => {
  let { isOpen, setIsOpen } = props;
  let content = typeof props.content === "undefined" ? <></> : props.content;
  return (
    <MDBContainer
      className={`overlay  ${props.className} ${
        isOpen ? "" : "d-none"
      } fixed-top min-vw-100 min-vh-100`}
    >
      <MDBRow>
        <MDBCol>{<content>{props.children}</content>}</MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Overlay;
