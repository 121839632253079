import React from "react";
import RangeFilter from "./rangeFilter";
import Icon from "app/assets/icon/icon";

const ActiveFilterItem = (props) => {
  let filter = props.filter;
  let { filter_id, filter_value, filter_label, index } = filter;

  let filter_keys = Object.keys(filter);
  let filter_logic = "";
  if (filter_keys[0] === "from") {
    filter_logic = "range";
  } else {
    filter_logic = "standard";
  }

  return (
    (filter_logic === "standard" && (
      <p>
        <span
          className={"close-btn"}
          onClick={(e) => {
            props.removeFilter({
              filter_code: filter_id,
              filter_value: props.index.split(filter_id + "-")[1],
            });
          }}
        >
          <Icon icon="removeFilterButtonGray" />
        </span>
        <span className={`filter-item filter-option-id-${filter_value}`}>
          <span className="category-name">{filter_label}</span>
        </span>
      </p>
    )) ||
    (filter_logic === "range" && (
      <>
        <RangeFilter
          filter={filter}
          removeFilter={props.removeFilter}
          undex={index}
        />
      </>
    ))
  );
};

export default ActiveFilterItem;
