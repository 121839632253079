import React from "react";
import Customer from "./customer";
import { isUndefined } from "core/helpers/functions";

const CustomerDataContainer = props => {
  if (!isUndefined(props.data)) {
    let orderData = props.data.customerOrders;
    return <Customer {...props} orderData={orderData} />;
  }
  return "";
};

export default CustomerDataContainer;
