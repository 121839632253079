import { gql } from "graphql.macro";

export const authorizeNetConfiguration = gql`
  query {
    tokenBaseCheckoutConfig(method: "authnetcim") {
      achAccountTypes {
        key
        value
      }
      achImage
      apiLoginId
      availableTypes {
        key
        value
      }
      canSaveCard
      canStoreBin
      clientKey
      cvvImageUrl
      defaultSaveCard
      forceSaveCard
      hasVerification
      isCcDetectionEnabled
      logoImage
      method
      months {
        key
        value
      }
      requireCcv
      sandbox
      useVault
      years {
        key
        value
      }
    }
  }
`;
