import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Listing from "./listing";
import { isEmpty, isUndefined } from "core/helpers/functions";
import { createRequestFromFilters, createSortVariable } from "./functions";
import { useState } from "react";

const query = gql`
  query Products(
    $filters: ProductAttributeFilterInput!
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      filter: $filters
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        count
        label
        options {
          value
          label
        }
      }
      total_count
      items {
        id
        name
        sku
        url_key
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
            ... on CustomizableDropDownOption {
              option_id
              title
              value {
                title
                option_type_id
                price
                price_type
                sku
              }
            }
          }
        }
        media_gallery_entries {
          id
          file
          content {
            base64_encoded_data
            name
            type
          }
          disabled
        }
        categories {
          id
        }
        image {
          label
          url
        }
        small_image {
          label
          url
        }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        tier_prices {
          customer_group_id
          percentage_value
          qty
          value
          website_id
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
      sort_fields {
        options {
          label
          value
        }
        default
      }
    }
  }
`;

const ListingQueryContainer = (props) => {
  const [token, setToken] = useState(props.customer.token);
  const {
    category_id,
    category,
    filters,
    showFilters,
    addFilteredData,
  } = props;
  let pageNumber = props.pageNumber;

  if (typeof category !== "undefined") {
    if (category.id !== category_id) {
      filters.items = {};
      pageNumber = 1;
    }
  }

  let variables;
  let request = createRequestFromFilters(filters.items, category_id);
  let sort = createSortVariable(
    filters.sort,
    filters?.category?.default_sort_by
  );
  let pageSize = 12;

  if (!isEmpty(request)) {
    if (request.filter.category_id.eq === 0) {
      variables = {
        filters: request.filter,
        pageSize:
          filters && filters.data ? filters.data.page_info.page_size : pageSize,
        currentPage:
          typeof pageNumber !== "undefined" && typeof pageNumber === "number"
            ? pageNumber
            : 1,
      };
      variables["filters"]["category_id"]["eq"] = category_id;
      variables["sort"] = sort;
    } else {
      variables = {
        filters: request.filter,
        sort: sort,
        pageSize:
          filters && filters.data ? filters.data.page_info.page_size : pageSize,
        currentPage:
          typeof pageNumber !== "undefined" && typeof pageNumber === "number"
            ? pageNumber
            : 1,
      };
    }
  }

  const { loading, error, data, refetch } = useQuery(query, {
    variables: variables,
    skip: !variables,
  });

  if (isUndefined(data) && !loading) {
    return <Listing {...props} refetch={refetch} data={props.category} />;
  }

  if (loading) {
    return (
      <Listing
        {...props}
        showFilters={showFilters}
        data={{
          category: {
            id: 0,
            products: { total_count: 0, items: undefined },
            children: {},
          },
        }}
      />
    );
  }
  if (error) return `Error! ${error}`;

  if (token !== props.customer.token) {
    setToken(props.customer.token);
    refetch();
  }
  return (
    <Listing
      {...props}
      refetch={refetch}
      data={data}
      showFilters={showFilters}
      addFilteredData={addFilteredData}
      category={category}
    />
  );
};

export default ListingQueryContainer;
