import React from "react";
import { connect } from "react-redux";
import { customerActions } from "core/state/redux/data/customer";
import ThankYouPage from "./thankYouPage";

const ThankYouPageReduxContainer = props => {
  if (typeof props.customer.status !== "undefined" && props.customer.status) {
    props.reudceCustomer({ ...props.customer, hide: true });
  }
  return <ThankYouPage {...props} />;
};
const mapStateToProps = state => ({
  customer: state.customer
});

const mapDispatchToProps = dispatch => {
  return {
    reudceCustomer: customer => {
      dispatch(customerActions._reduceCustomer({ ...customer, status: false }));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYouPageReduxContainer);
