import React, { memo } from "react";
import { MDBBox } from "mdbreact";
import { MDBLink, MDBIcon } from "mdbreact";
import Hotspot from "app/layout/hotspot";
import TitleDescription from "app/layout/titleDescription";
import _ from "lodash";

const ShopLookDetail = memo(
  ({ data }) => {
    return (
      <div className='shop-the-look-detail'>
        <div className='shop-the-look-detail-content'>
          <div className='shop-the-look-detail-top'>
            <MDBBox
              className={`shop-the-look-detail-title d-flex flex-row align-content-between justify-content-between`}
            >
              <MDBBox
                tag='div'
                className={`d-flex align-items-center justify-content-center`}
              >
                <MDBLink
                  className='d-flex align-items-center'
                  to={`/shop-the-look`}
                >
                  <MDBIcon icon='arrow-left' />
                  back
                </MDBLink>
              </MDBBox>
              <MDBBox tag='h3' className='w-100 text-center my-3'>
                {data.name}
              </MDBBox>
            </MDBBox>
          </div>
          <div className='shop-the-look-detail-inner'>
            <TitleDescription description={data.description} />
            <div className='shop-detail-hotspot'>
              {typeof data !== "undefined" &&
                data.hotspot.map((hotspot, index) => (
                  <div key={`shop-detail-` + index}>
                    <Hotspot contentArray={hotspot} isHome={true}></Hotspot>
                    <p>
                      {data.hotspot[index].photo_by &&
                        data.hotspot[index].photo_by}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default ShopLookDetail;
