import React from "react";
import { MDBCol, MDBIcon, MDBCollapse } from "mdbreact";
import AddonItem from "./addon-item";

const Addons = (props) => {
  let {
    isAddonsOpen,
    setIsAddosOpen,
    addon_products,
    addItemToCart,
    lock,
    setIsSelectionsOpen,
  } = props;

  return (
    <MDBCol size="12" className={`mt-5`}>
      <MDBCol
        size="12"
        className="addons-title border-top border-bottom py-4"
        onClick={() => {
          if (isAddonsOpen) {
            setIsAddosOpen(!isAddonsOpen);
          } else {
            setIsAddosOpen(!isAddonsOpen);
            setIsSelectionsOpen(isAddonsOpen);
          }
        }}
      >
        <MDBIcon icon={`${isAddonsOpen ? "minus" : "plus"}`} />
        <span>additional options</span>
      </MDBCol>
      <MDBCollapse
        isOpen={isAddonsOpen}
        className="addons-content border-bottom"
      >
        {addon_products.map((addon, index) => {
          return (
            <AddonItem
              key={`addon-item-${addon.sku}-${index}`}
              addon={addon}
              addItemToCart={addItemToCart}
              lock={lock}
            />
          );
        })}
      </MDBCollapse>
    </MDBCol>
  );
};

export default Addons;
