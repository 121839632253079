import React from "react";
import { MDBBox } from "mdbreact";
import Slider from "react-slick";
import ProductItem from "app/pages/catalog/category/listing/productItem";
const Crossell = (props) => {
  const settings = {
    dots: false,
    dotsClass: props.dotsClass,
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    variableWidth: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let { cart } = props;
  let crossellProducts = [];
  if (typeof cart === "undefined" || typeof cart.items === "undefined")
    return <></>;
  cart.items.map((item) => {
    item.product.crosssell_products.map((crossell) => {
      crossellProducts.push(crossell);
    });
  });
  // if (lodash.isUndefined(cart)) return <></>;
  return (
    <>
      {crossellProducts.length > 0 ? (
        <MDBBox className={"crossell-container"}>
          <h2>you might be interested in</h2>
          <Slider
            {...settings}
            focusOnSelect={false}
            className="related-products d-flex flex-wrap"
          >
            {crossellProducts.map((crossell) => {
              return (
                <>
                  <ProductItem
                    productName={crossell.name}
                    productImage={crossell.image}
                    productPriceRegularValue={
                      crossell.price_range.minimum_price.final_price.value
                    }
                  />
                </>
              );
            })}
          </Slider>
        </MDBBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default Crossell;
