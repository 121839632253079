import React, { useState } from "react";
import Mobile from "./mobile";
const MobileStateContainer = (props) => {
  let { menu, token, logoutCustomer, showSearch, toggleSearch } = props;
  const [open, setOpen] = useState(false);
  const [collapseID, setCollapseID] = useState(false);
  const [menuToggle, setMenuToggle] = useState(0);

  let menuItems =
    typeof menu !== "undefined" && typeof menu.children !== "undefined"
      ? menu.children
      : {};

  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(undefined);

  const openMobileDropdown = (index) => {
    if (categoryIndex === index) {
      if (showMobileDropdown === true) {
        setShowMobileDropdown(false);
      } else {
        setShowMobileDropdown(true);
      }
    } else {
      setCategoryIndex(index);
      setShowMobileDropdown(true);
    }
  };

  return (
    <Mobile
      open={open}
      setOpen={setOpen}
      menuItems={menuItems}
      categoryIndex={categoryIndex}
      setCategoryIndex={setCategoryIndex}
      setCollapseID={setCollapseID}
      collapseID={collapseID}
      openMobileDropdown={openMobileDropdown}
      token={token}
      menuToggle={menuToggle}
      setMenuToggle={setMenuToggle}
      logoutCustomer={logoutCustomer}
      showSearch={showSearch}
      toggleSearch={toggleSearch}
    />
  );
};

export default MobileStateContainer;
