import schemaConfiguration from "config/schemaConfiguration";

export const ProductSchema = (product) => {
  const data = product && {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: product.name,
    image: product.image && product.image.url,
    sku: product.sku,
    mpn: product.id,
    url: schemaConfiguration.url + product.url_key,
    description: product.description && product.description.html,
    brand: {
      "@type": "Thing",
      name: schemaConfiguration.name,
    },
    offers: {
      "@type": "Demand",
      seller: {
        "@type": "Organization",
        name: schemaConfiguration.name,
      },
    },
  };

  return JSON.stringify(data);
};
