import React, { useState, useEffect } from "react";
import { MDBBox, MDBModalHeader, MDBModalBody, MDBModal } from "mdbreact";
import Button from "core/components/button";
import CheckButton from "core/components/checkButton";
import Icon from "app/assets/icon/icon";
import InputField from "core/components/inputField/inputField";
import { validate, shouldMarkError } from "./validation";
import Select2 from "core/components/select/select";
import CountrySelect from "core/components/countrySelect";
import MaskedInput from "react-text-mask";
import { Alert } from "reactstrap";

const MainModal = (props) => {
  let { state } = props;
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  let [isChecked, setIsChecked] = useState(false);
  let [termsAccepted, setTermsAccepted] = useState("");

  const [zipMask, setZipMask] = useState([/\d/, /\d/, /\d/, /\d/, /\d/]);
  const [zipCodeError, setZipCodeError] = useState(false);
  useEffect(() => {
    if (state.countryValue.value === "CA")
      setZipMask([/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]);
    else setZipMask([/\d/, /\d/, /\d/, /\d/, /\d/]);
  }, [state.countryValue.value]);

  function handleChange(e) {
    state.setDescribe(e);
  }

  const getCookie = (name) => {
    var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
  };
  const Cookie = () => {
    if (getCookie("main-modal") !== "seen") {
      setIsLoaded(true);
      var d = new Date();
      d.setDate(d.getDate() + 60 * 60 * 24 * 1000 * 7);
      var expires = "expires=" + d.toUTCString();
      document.cookie = "main-modal=seen;" + expires + "path=/";
      toggleOne();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      Cookie();
    }, 1000);
  }, [Cookie]);

  const toggleOne = () => {
    setModal(!modal);
  };

  const toggleTwo = () => {
    setTouched({
      email: false,
      firstname: false,
      lastname: false,
      companyname: false,
      zipcode: false,
      stateValue: false,
    });
    state.setEmail("");
    state.setFirstName("");
    state.setLastName("");
    state.setCompany("");
    state.setZipcode("");
    state.setCountryValue({
      label: "United States",
      value: "US",
    });
    state.setClient({
      label: ["Client Type"],
      value: ["1"],
    });
    state.setDescribe({
      label: "what best describes you?",
      value: "0",
    });
    setModal(false);
    setModal2(!modal2);
  };

  const [touched, setTouched] = useState({
    email: false,
    firstname: false,
    lastname: false,
    companyname: false,
    zipcode: false,
    stateValue: false,
  });

  let errors = validate(state.email, "-", "-", "-", "-");

  let errorsFormTwo = validate(
    state.email,
    state.firstname,
    state.lastname,
    state.company,
    state.zipcode
  );

  let isEmailSubscribed = false;
  const [loading, setLoading] = useState(false);

  const _handleSubmit = async (number) => {
    setLoading(true);
    isEmailSubscribed = await props.isEmailSubscribed();
    setLoading(false);
    if (number === 2) setZipCodeError(zipMask.length !== state.zipcode.length);
    let isTrue = true;
    setTouched({ email: true });
    if (number === 1) {
      Object.keys(errors).map((item) => {
        let error = errors[item];
        if (isTrue) {
          if (error === true) {
            isTrue = false;
          }
        }
      });
    } else {
      setTouched({
        email: true,
        firstname: true,
        lastname: true,
        companyname: true,
        zipcode: true,
        stateValue: true,
      });
      Object.keys(errorsFormTwo).map((item) => {
        let error = errorsFormTwo[item];
        if (
          item === "zipcode" &&
          !(
            state.countryValue.value === "US" ||
            state.countryValue.value === "CA"
          )
        ) {
          error = false;
        }
        if (item === "zipcode" && zipMask.length !== state.zipcode.length) {
          error = true;
        }
        if (isTrue) {
          if (error === true) {
            isTrue = false;
          }
        }
      });
    }
    if (!isEmailSubscribed) {
      if (isTrue) {
        if (number === 1) {
          props.submitEmail(number);
          setTimeout(() => {
            toggleOne();
          }, 1000);
        } else if (
          state.countryValue.value === "US" ||
          state.countryValue.value === "CA"
        ) {
          if (state.stateValue !== "" && state.stateValue !== null) {
            if (isChecked) {
              props.submitEmail(number);
              setTimeout(() => {
                toggleTwo();
              }, 1000);
            } else {
              setTermsAccepted("accept privacy policy");
            }
          }
        } else {
          if (isChecked) {
            props.submitEmail(number);
            setTimeout(() => {
              toggleTwo();
            }, 1000);
          } else {
            setTermsAccepted("accept privacy policy");
          }
        }
      }
    } else {
      props.addMessage(
        "customer with same email address already subscribed",
        "danger"
      );
    }
  };

  return (
    <MDBBox>
      {isLoaded && (
        <>
          <MDBBox>
            {modal && (
              <MDBModal
                isOpen={true}
                toggle={toggleOne}
                onClick={modal}
                className={"modal-wrapper main-modal w-100"}
                centered
              >
                <MDBModalHeader onClick={toggleOne}></MDBModalHeader>
                <MDBModalBody className={"modal-wrapper"}>
                  <MDBBox className={"modal-inner one"}>
                    <MDBBox className={"modal-one-inner text-center"}>
                      <Icon icon={"ducducWhiteLogo"} />
                      <h2>
                        sorry,
                        <br /> our <span className="mom">mom</span> marketing
                        department made us do it...
                      </h2>
                      <span>
                        we know, email lists get a bad rap. but we promise -
                        you’ll love being on ours!
                      </span>
                      <div>
                        <p>are you a trade member?</p>
                        <p>
                          <button
                            className={"sign-in-modal-btn"}
                            onClick={toggleTwo}
                          >
                            sign up here
                          </button>
                          for updates.
                        </p>
                      </div>
                    </MDBBox>
                  </MDBBox>
                  <MDBBox className={"modal-inner two"}>
                    <MDBBox className={"modal-inner-content-two"}>
                      <MDBBox className="close-btn" onClick={toggleOne}>
                        <button type="button">
                          <i className="close-icon"></i>
                        </button>
                      </MDBBox>
                      <InputField
                        className={`custom-input form-control ${
                          shouldMarkError("email", errors, touched)
                            ? "validation-error"
                            : ""
                        }`}
                        label={`${
                          shouldMarkError("email", errors, touched)
                            ? "email is required*"
                            : "enter your email address*"
                        }`}
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        value={state.email}
                        onChange={(e) => {
                          state.setEmail(e.target.value);
                        }}
                      />
                      <Button
                        color={"primary"}
                        className={"right-arrow orange-button join-btn"}
                        direction={"ml-3"}
                        righticon={"true"}
                        icon={loading ? "" : "arrow-right"}
                        text={loading ? "verifying" : "join"}
                        disabled={loading}
                        onClick={() => {
                          _handleSubmit(1);
                        }}
                      />
                    </MDBBox>
                  </MDBBox>
                </MDBModalBody>
              </MDBModal>
            )}
          </MDBBox>
          <MDBBox>
            {modal2 && (
              <MDBModal
                data-backdrop="static"
                centered
                isOpen={modal2}
                toggle={toggleTwo}
                onClick={modal2}
                className={"modal-wrapper footer-newsletter second-modal"}
              >
                <MDBModalHeader onClick={toggleTwo}>
                  <div className="close-btn">
                    <button type="button">
                      <i class="close-icon"></i>
                    </button>
                  </div>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBBox tag="h2" className="text-center">
                    sign up for updates!
                  </MDBBox>
                  <MDBBox tag="span" className="text-center">
                    stay connected with all things ducduc, please complete the
                    form below
                  </MDBBox>
                  <MDBBox className={"input-fields"}>
                    <MDBBox className={"input-column one"}>
                      <InputField
                        className={`custom-input form-control ${
                          shouldMarkError("firstname", errorsFormTwo, touched)
                            ? "validation-error"
                            : ""
                        }`}
                        label={`${
                          shouldMarkError("firstname", errorsFormTwo, touched)
                            ? "first name is required*"
                            : "first name*"
                        }`}
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        value={state.firstName}
                        onChange={(e) => {
                          state.setFirstName(e.target.value);
                        }}
                      />
                      <InputField
                        className={`custom-input form-control ${
                          shouldMarkError("lastname", errorsFormTwo, touched)
                            ? "validation-error"
                            : ""
                        }`}
                        label={`${
                          shouldMarkError("lastname", errorsFormTwo, touched)
                            ? "last name is required*"
                            : "last name*"
                        }`}
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        value={state.lastname}
                        onChange={(e) => {
                          state.setLastName(e.target.value);
                        }}
                      />
                      <InputField
                        className={`custom-input form-control ${
                          shouldMarkError("email", errorsFormTwo, touched)
                            ? "validation-error"
                            : ""
                        }`}
                        label={`${
                          shouldMarkError("email", errorsFormTwo, touched)
                            ? "email is required*"
                            : "email address*"
                        }`}
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        value={state.email}
                        onChange={(e) => {
                          state.setEmail(e.target.value);
                        }}
                      />
                      <InputField
                        className={`custom-input form-control ${
                          shouldMarkError("companyname", errorsFormTwo, touched)
                            ? "validation-error"
                            : ""
                        }`}
                        label={`${
                          shouldMarkError("companyname", errorsFormTwo, touched)
                            ? "company name is required*"
                            : "company name*"
                        }`}
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        value={state.company}
                        onChange={(e) => {
                          state.setCompany(e.target.value);
                        }}
                      />
                    </MDBBox>

                    <MDBBox className={"input-column two"}>
                      <MDBBox className="select-div-container modal-select">
                        <Select2
                          className="browser-default custom-Select2"
                          onChange={(e) => handleChange(e)}
                          value={state.describe}
                          options={[
                            { label: "retail", value: "1" },
                            { label: "designer/design firm", value: "2" },
                          ]}
                          openIndicator="caret-up"
                          closedIndicator="caret-down"
                          useCustom={true}
                        />
                      </MDBBox>

                      <MDBBox className={"modal-country-select modal-select"}>
                        <CountrySelect
                          isSearchable={true}
                          setStateValue={state.setStateValue}
                          stateValue={state.stateValue}
                          countryValue={state.countryValue}
                          setCountryValue={state.setCountryValue}
                          statePlaceholder={
                            state.countryValue.value === "US" ||
                            state.countryValue.value === "CA"
                              ? state.countryValue.value === "US"
                                ? "state*"
                                : state.countryValue.value === "CA"
                                ? "province*"
                                : "province"
                              : "province"
                          }
                          stateLabel={"region"}
                          stateWrapperClass={
                            (state.stateValue === "" ||
                              state.stateValue === null) &&
                            touched.stateValue
                              ? "main-select dark validation-error"
                              : "main-select dark"
                          }
                        />
                      </MDBBox>
                      <MDBBox className="md-form form-group default-input-field-container">
                        <MaskedInput
                          id="mask-input"
                          placeholder={
                            shouldMarkError("zipcode", errorsFormTwo, touched)
                              ? state.countryValue.value === "US" ||
                                state.countryValue.value === "CA"
                                ? state.countryValue.value === "US" &&
                                  state.zipcode === ""
                                  ? "zip code is required*"
                                  : state.countryValue.value === "CA" &&
                                    state.zipcode === "" &&
                                    "postal code is required*"
                                : state.countryValue.value === "US"
                                ? "zip code*"
                                : state.countryValue.value === "CA"
                                ? "postal code*"
                                : "postal code"
                              : state.countryValue.value === "US"
                              ? "zip code*"
                              : state.countryValue.value === "CA"
                              ? "postal code*"
                              : "postal code"
                          }
                          group
                          type="text"
                          validate
                          error="wrong"
                          success="right"
                          value={
                            state.zipcode !== undefined ? state.zipcode : ""
                          }
                          mask={zipMask}
                          guide={false}
                          onChange={(e) => {
                            setZipCodeError(false);
                            state.setZipcode(e.target.value);
                          }}
                          className={`
                          custom-input form-control zip-code
                    ${
                      shouldMarkError("zipcode", errorsFormTwo, touched) ||
                      zipCodeError
                        ? state.countryValue.value === "US" ||
                          state.countryValue.value === "CA"
                          ? "validation-error"
                          : ""
                        : ""
                    }`}
                          onBlur={(e) => {
                            setTouched({ ...touched, zipcode: true });
                          }}
                        />
                      </MDBBox>
                    </MDBBox>
                  </MDBBox>
                  <p>
                    We will be using your contact information for you to receive
                    updates straight to your inbox related to your account and
                    purchases along with news about our latest products,
                    promotions, and special events. You make unsubscribe at any
                    time.
                  </p>
                  {termsAccepted !== "" && !isChecked && (
                    <Alert
                      color="danger"
                      isOpen={true}
                      toggle={() => setTermsAccepted("")}
                    >
                      {termsAccepted}
                    </Alert>
                  )}
                  <MDBBox className={"custom-modal-btn"}>
                    <CheckButton
                      containerClass={"switch-container check-button"}
                      wrapperClass={"slider"}
                      label={`Yes, I accept the Privacy Policy of ducduc`}
                      value="Yes, I accept the Privacy Policy of ducduc"
                      type="checkbox"
                      // className="custom-control-input"
                      id="customSwitches"
                      defaultChecked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    <div className="req-block">
                      <div className="text-right required-text-block">
                        <span className="required-sign">*</span>
                        <span className="required-text">= required</span>
                      </div>
                    </div>
                  </MDBBox>
                  <MDBBox className={"subscribe-btn-modal"}>
                    <Button
                      text={loading ? "verifying" : "subscribe"}
                      disabled={loading}
                      color={"primary"}
                      className={"right-arrow orange-button btn-modal-content"}
                      direction={"ml-3"}
                      to={"#"}
                      righticon={"true"}
                      icon={loading ? "" : "arrow-right"}
                      onClick={() => _handleSubmit(2)}
                    />
                  </MDBBox>
                </MDBModalBody>
              </MDBModal>
            )}
          </MDBBox>
        </>
      )}
    </MDBBox>
  );
};

export default MainModal;
