import React, { memo, useState } from "react";
import { MDBInput } from "mdbreact";
import { useMediaQuery } from "react-responsive";
import Icon from "app/assets/icon/icon";
import _ from "lodash";

const SearchInput = memo(
  ({ search, changeSearch, toggleSearch, showSearch }) => {
    const isMobile = useMediaQuery({ maxWidth: 992 });
    const [tmpSearch, setTmpSearch] = useState(search);
    return (
      <div style={{ position: "relative", width: "100%" }}>
        <MDBInput
          id={`search-input`}
          label={`${search === "" ? "what are you looking for?" : ""}`}
          value={isMobile ? tmpSearch : search}
          onChange={(e) => {
            if (isMobile) {
              setTmpSearch(e.target.value);
              toggleSearch(false);
            } else {
              changeSearch(e.target.value);
            }
          }}
        />
        <div
          className={`search-icon`}
          onClick={(e) => {
            if (
              (typeof search !== "undefined" &&
                search.length > 0 &&
                showSearch) ||
              (typeof tmpSearch !== "undefined" &&
                tmpSearch.length > 0 &&
                showSearch)
            ) {
              changeSearch("");
              setTmpSearch("");
              toggleSearch(false);
            } else {
              changeSearch(tmpSearch);
              toggleSearch(true);
            }
          }}
        >
          <Icon
            id={`${
              typeof search === "undefined"
                ? "searchGray"
                : search.length === 0 || showSearch === false
                ? "searchGray"
                : "removeFilterButtonGray"
            }`}
          />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default SearchInput;
