import React, { Suspense } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

const ReactJuicer = React.lazy(() => import("react-juicer-jola-interactive"));

const Juicer = (props) => {
  let sliderTitle = props.sliderTitle;
  let sliderSubTitle = props.sliderSubTitle;

  return (
    <div className="juicerWrapper">
      <MDBContainer fluid className="text-center juicer-header m-0 p-0">
        <MDBRow className="m-0 pl-0 pr-0">
          <MDBCol className="responsive-font-size  m-0 pl-0 pr-0">
            <h2 className="title-top">{sliderTitle}</h2>
            <p className="title-bottom">{sliderSubTitle}</p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="image-wrapper m-0 pl-0 pr-0">
          <Suspense fallback={""}>
            <ReactJuicer
              feedId="DUCDUCNYC"
              imagesPer={5}
              className={"juicer-item"}
              initialLimit={5}
              disableLoadMore={true}
              disableOnScroll={true}
              theme={{
                columns: {
                  default: 1,
                  medium: 3,
                  large: 5,
                },
              }}
            />
          </Suspense>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default Juicer;
