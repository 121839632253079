import React from "react";
import Item from "./sessionMessagesItem";
import { uniqBy } from "lodash";

const SessionMessages = props => {
  let { target } = props;

  if (!props.messages || !props.messages.length) {
    return <></>;
  }

  let uniqueItems = uniqBy(props.messages, message => {
    return [message.text, message.target].join();
  });

  return (
    <>
      {uniqueItems.map((message, index) => {
        if (target === message.target) {
          return (
            <Item
              key={`session-message-${message.target}-${message.text}`}
              text={message.text}
              type={message.type}
              onClose={() => props.onMessageClose(message.text, message.type)}
              closeTime={message.closeTime}
              dismissible={props.dismissible}
              autoClose
            />
          );
        }
        return "";
      })}
    </>
  );
};
export default SessionMessages;
