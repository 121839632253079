import React, { memo, Fragment } from "react";
import Price from "core/components/price";
import { MDBCol, MDBRow } from "mdbreact";
import { isEqual } from "lodash";

const DiscountSummary = memo(
  ({ cart }) => {
    let prices = typeof cart !== "undefined" ? cart.prices : [];

    return typeof prices.discounts !== "undefined" &&
      prices.discounts !== null ? (
      <MDBRow className="my-3">
        {prices.discounts.length > 0 ? (
          prices.discounts.map((discount) => {
            return (
              <Fragment key={`discount-${discount.label}`}>
                <MDBCol
                  size="6"
                  className="discount-col summary-item-title d-flex justify-content-start"
                  key={`discount-${discount.label}`}
                >
                  {discount.label}
                </MDBCol>
                <MDBCol
                  size="6"
                  className="discount-col summary-item-value d-flex justify-content-end"
                >
                  - <Price price={discount.amount.value} type={`fixed`} />
                </MDBCol>
              </Fragment>
            );
          })
        ) : (
          <></>
        )}
      </MDBRow>
    ) : (
      <></>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default DiscountSummary;
