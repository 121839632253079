import React, { memo } from "react";
import Toolbar from "./toolbar";
import Items from "./items";
import { MDBContainer, MDBRow } from "mdbreact";
import { isEqual } from "lodash";

const Listing = memo(
  ({
    lock,
    addMessage,
    deleteItem,
    items,
    productPerPage,
    pageNumber,
    setProductPerPage,
    setPageNumber,
    addItemToCart,
    copyWishlistItem,
    wishlist,
    addItemToWishlist
  }) => {
    return (
      <MDBContainer fluid>
        <MDBRow className={"top-toolbar"}>
          <Toolbar
            itemcount={items.length}
            pageNumber={pageNumber}
            productPerPage={productPerPage}
            setProductPerPage={setProductPerPage}
            setPageNumber={setPageNumber}
          />
        </MDBRow>
        <MDBRow>
          <Items
            addMessage={addMessage}
            lock={lock}
            deleteItem={deleteItem}
            items={items}
            pageNumber={pageNumber}
            productPerPage={productPerPage.value}
            addItemToCart={addItemToCart}
            copyWishlistItem={copyWishlistItem}
            wishlist={wishlist}
            addItemToWishlist={addItemToWishlist}
          />
        </MDBRow>
        <MDBRow  className={"bottom-toolbar"}>
          <Toolbar
            itemcount={items.length}
            pageNumber={pageNumber}
            productPerPage={productPerPage}
            setProductPerPage={setProductPerPage}
            setPageNumber={setPageNumber}
          />
        </MDBRow>
      </MDBContainer>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps) ? true : false;
  }
);

export default Listing;
