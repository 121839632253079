import schemaConfiguration from "config/schemaConfiguration";

export const OrganizationSchema = () => {
  const data = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: schemaConfiguration.name,
    legalName: schemaConfiguration.legalName,
    url: schemaConfiguration.url,
    logo: schemaConfiguration.logo,
    foundingDate: schemaConfiguration.foundingDate,
    address: {
      "@type": "PostalAddress",
      streetAddress: schemaConfiguration.address.streetAddress,
      addressLocality: schemaConfiguration.address.addressLocality,
      addressRegion: schemaConfiguration.address.addressRegion,
      postalCode: schemaConfiguration.address.postalCode,
      addressCountry: schemaConfiguration.address.addressCountry,
    },
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "customer service",
      telephone: schemaConfiguration.contactPoint.telephone,
      email: schemaConfiguration.contactPoint.email,
      hoursAvailable: [
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: schemaConfiguration.contactPoint.hoursAvailable.dayOfWeek,
          opens: schemaConfiguration.contactPoint.hoursAvailable.opens,
          closes: schemaConfiguration.contactPoint.hoursAvailable.closes,
        },
      ],
      sameAs: schemaConfiguration.contactPoint.socialNetworks,
    },
  };
  return JSON.stringify(data);
};
