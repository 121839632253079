import React from "react";
import GraphQlWrapper from "core/components/graphQlWrapper";
import queryLoader from "app/graphql/queryLoader";
import ShopLookSlider from "./shopLookSlider";

const query = queryLoader("Hotspots");

const ShopLookSliderQueryLoader = (props) => {
  return (
    <>
      <GraphQlWrapper
        query={query}
        variables={{ id: `hotspot-${props.id}`, url: props.url }}
        url={props.url}
        loader={true}
      >
        <ShopLookSlider {...props}></ShopLookSlider>
      </GraphQlWrapper>
    </>
  );
};

export default ShopLookSliderQueryLoader;
