import React, { memo, useEffect } from "react";
import { MDBCol, MDBRow, MDBIcon, MDBCollapse, MDBBox } from "mdbreact";
import Price from "core/components/price";
import Input from "core/components/input";
import { isEmpty } from "core/helpers/functions";
import { Link } from "react-router-dom";
import Image from "core/components/image/image";
import Icon from "app/assets/icon/icon";
import ReactLoader from "app/layout/loader";
import _ from "lodash";

const updateCartItemFn = (
  item,
  value,
  updateCart,
  lock,
  quantity,
  setQuantity,
  setLoading
) => {
  if (value < 1 || value === "" || _.isNaN(value)) {
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setQuantity(1);
      },
      fail: () => {
        setLoading(false);
      },
    });
    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: 1,
      },
    ]);
  } else {
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setQuantity(value);
      },
      fail: () => {
        setLoading(false);
      },
    });

    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: value === 1 ? 1 : value,
      },
    ]);
  }
};

const CartItem = memo(
  ({
    product,
    item,
    options,
    headers,
    removeItem,
    quantity,
    setQuantity,
    increaseQuantity,
    decreaseQuantity,
    detailsExpanded,
    setDetailsExpanded,
    additionalOptionsExpanded,
    setAdditionalOptionsExpanded,
    loading,
    setLoading,
    lock,
    updateCart,
  }) => {
    useEffect(() => {
      if (item.quantity !== quantity) {
        setQuantity(item.quantity);
      }
    }, [item, setQuantity]);
    return (
      <MDBCol size="12" className="py-4 border-bottom">
        <MDBRow className={`item-container`}>
          <MDBCol className="item-image-container" size={headers[0].size}>
            <MDBRow>
              <MDBCol size="12">
                <Link to={product.url_key}>
                  <div className={`position-relative`}>
                    <Image
                      source={product.small_image.url}
                      className="img-fluid"
                      alt=""
                    />
                    {typeof item.customizable_options !== "undefined" &&
                    item.customizable_options.length > 0 ? (
                      <Link
                        to={product.url_key + `?edit=${item.id}`}
                        className={`edit-link`}
                      >
                        <Icon icon={`edit`} className={`edit-icon`} />
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </Link>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol size={12 - headers[0].size}>
            <MDBRow>
              <MDBCol
                className="item-detail-container d-flex align-items-center"
                size={headers[1].size}
              >
                <MDBRow>
                  <MDBCol size="12">
                    <Link to={product.url_key}>
                      <h5 className="item-name orange-nav">{product.name}</h5>
                    </Link>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol
                className="item-price-container d-flex align-items-center"
                size={headers[2].size}
              >
                <MDBRow>
                  <MDBCol size="12">
                    <Price price={item.prices.price.value} type={`fixed`} />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol className="item-qty-container" size={headers[3].size}>
                <MDBRow>
                  <MDBCol
                    size="12"
                    className={`d-flex align-items-center${
                      quantity === 1 ? " disable-minus" : ""
                    }`}
                  >
                    <Input
                      name={``}
                      type={"number"}
                      wrapperClass={"quantity-field"}
                      className={"qty-control-wrapper"}
                      inputClass={""}
                      value={quantity}
                      plusClick={(e) => {
                        if (!loading) {
                          setLoading(true);
                          updateCartItemFn(
                            item,
                            quantity + 1,
                            updateCart,
                            lock,
                            quantity,
                            setQuantity,
                            setLoading
                          );
                        }
                      }}
                      minusClick={(e) => {
                        if (quantity > 1) {
                          if (!loading) {
                            setLoading(true);
                            updateCartItemFn(
                              item,
                              quantity - 1,
                              updateCart,
                              lock,
                              quantity,
                              setQuantity,
                              setLoading
                            );
                          }
                        }
                      }}
                      onBlur={(e) => {
                        let value = parseInt(e.target.value);
                        if (!loading) {
                          setLoading(true);
                          updateCartItemFn(
                            item,
                            value,
                            updateCart,
                            lock,
                            quantity,
                            setQuantity,
                            setLoading
                          );
                        }
                      }}
                      onChange={(e) => {
                        let value = parseInt(e.target.value);
                        if (!loading) {
                          if (value < 1 || value === "" || _.isNaN(value)) {
                            setQuantity(1);
                          } else {
                            setQuantity(value);
                          }
                        }
                      }}
                    />
                    <ReactLoader
                      visible={loading}
                      type={`ThreeDots`}
                      height={`35`}
                      width={`35`}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol
                className="item-subtotal-container d-flex align-items-center"
                size={headers[4].size}
              >
                <MDBRow className="w-100 align-items-center">
                  <MDBCol size="9">
                    <Price price={item.prices.row_total.value} type={`fixed`} />
                  </MDBCol>
                  <MDBCol size="2" className="item-remove-container">
                    <MDBBox
                      onClick={() => {
                        if (!loading) {
                          setLoading(true);
                          lock({
                            always: () => {},
                            success: () => {
                              setLoading(false);
                            },
                            fail: () => {
                              setLoading(false);
                            },
                          });
                          removeItem();
                        }
                      }}
                    >
                      <Icon icon={`trashCanOrange`} />
                    </MDBBox>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>

            {!isEmpty(options) && (
              <MDBRow className="mt-3">
                <MDBCol size="12">
                  <MDBRow onClick={() => setDetailsExpanded(!detailsExpanded)}>
                    <MDBCol size="12" className="expandable-header">
                      <MDBIcon icon={detailsExpanded ? "minus" : "plus"} />
                      <h5 className="orange-nav ml-3 pb-4 heavy d-inline-block">
                        details
                      </h5>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="12">
                      <MDBCollapse
                        className="expandable-content item-options-container pb-3"
                        isOpen={detailsExpanded}
                      >
                        <MDBRow>
                          {Object.keys(options).map((key) => {
                            return (
                              <>
                                <MDBCol
                                  size="2"
                                  className="item-option-name mb-3"
                                >
                                  {key}
                                </MDBCol>
                                <MDBCol
                                  size="10"
                                  className="item-option-value mb-3"
                                >
                                  {options[key]}
                                </MDBCol>
                              </>
                            );
                          })}
                        </MDBRow>
                      </MDBCollapse>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            )}
          </MDBCol>
        </MDBRow>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default CartItem;
