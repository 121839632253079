import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "core/graphql/queryLoader";
import messagesActions from "../../messages/actions";
import queryActions from "../../queries/actions";
import { isEmpty } from "../../../../../helpers/functions";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.query({
      query: queryLoader("getCustomer"),
      variables: action,
      fetchPolicy: "no-cache",
    });

    if (!data.customer || !data.customer) {
      console.log("GraphQL response", data);
      throw new Error("No customer information retrieved.");
    }

    if (data.customer && action.isLoggedIn === false) {
      store.dispatch(customerActions.setCustomerInformation(data.customer));
      store.dispatch(
        customerActions.getCartInformation(undefined, action.cartToken)
      );
      store.dispatch(
        messagesActions.addMessage(
          "You have successfully logged in.",
          "success"
        )
      );
      // store.dispatch(queryActions.removeQuery());
    } else {
      if (action.email !== data.customer.email && action.email !== "") {
        data.customer.email = action.email;
      }
      store.dispatch(
        customerActions.setCustomerInformation(data.customer, action.isLoggedIn)
      );
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
