import React from "react";
import { MDBBox } from "mdbreact";
import StaticPageTop from "app/layout/staticPagesTop/staticPagesTop";
import items from "./items";
import smallBanner from "app/assets/images/about/smallBanner.png";
import Breadcrumbs from "app/layout/breadcrumbs";
import Slider from "react-slick";

const AboutUsTop = (props) => {
  const settings = {
    dots: false,
    dotsClass: props.dotsClass,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 1,
    customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
    appendDots: (dots) => (
      <div
        className={"about-top-slider-dots"}
        style={{
          padding: "15px 0",
          textAlign: "center",
          width: "100%",
          maxHeight: "5rem",
        }}
      >
        <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          variableWidth: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
          focusOnSelect: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
          focusOnSelect: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          dotsClass: props.dotsClass,
          arrows: false,
          focusOnSelect: false,
        },
      },
    ],
  };
  return (
    <div className="about-us-top">
      <MDBBox tag="h1" className="w-100 mx-auto text-center">
        made just for your family, and just for your style
      </MDBBox>
      <MDBBox tag="h2" className="w-100 mx-auto text-center">
        the ducduc story
      </MDBBox>
      <StaticPageTop
        position={"left"}
        image={smallBanner}
        description={
          "for a few years, ducduc manufactured all pieces in amish country, in lancaster, pennsylvania, chosen for its “old-school kind of crafting,” wilcox says, until the brand grew too big. in 2009 production moved to a restored 1890s barn in torrington, connecticut, where 40 employees design and manufacture the pieces."
        }
        description2={
          "all pieces are made to order, typically in eight to 10 weeks, though a select number of the brand’s most popular pieces, including cribs, dressers, and changers, are part of ducduc’s “quick-ship program,” which has a four-week turnaround."
        }
        //Button
        className={"right-arrow orange-button"}
        direction={"ml-3"}
        righticon={"true"}
        tag="a"
        icon={"arrow-right"}
        href={"/contact"}
        children={"contact us"}
      />
      <Slider
        {...settings}
        focusOnSelect={false}
        className="about-us-cards d-flex flex-wrap"
      >
        {Object.keys(items).map((item, index) => {
          let _item = items[item];
          return _item;
        })}
      </Slider>
    </div>
  );
};
export default AboutUsTop;
