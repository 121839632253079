import React from "react";
import { MDBBox, MDBIcon } from "mdbreact";
import Items from "./items";

const ToggleMenu = (props) => {
  let {
    items,
    menuItem,
    isOpen,
    setIsOpen,
    toggleHamburger,
    open,
    setOpen,
  } = props;

  return (
    <div className={`toggle-menu-item`}>
      <div
        className={`menu-item text-uppercase ${menuItem.name}`}
        id={`menu-item-${menuItem.name}`}
        onClick={(e) => {
          setIsOpen(!isOpen);
        }}
      >
        {menuItem.name}
        {(isOpen && <MDBIcon className="toggle-menu-icon" icon="minus" />) || (
          <MDBIcon className="toggle-menu-icon" icon="plus" />
        )}
      </div>
      <MDBBox
        className={`toggle-dropdown p-0 text-uppercase ${
          (isOpen && "active") || "not-active"
        }`}
      >
        <Items
          items={items}
          toggleHamburger={toggleHamburger}
          open={open}
          setOpen={setOpen}
        />
      </MDBBox>
    </div>
  );
};

export default ToggleMenu;
