import React, { useEffect } from "react";
import { connect } from "react-redux";
import { filterActions } from "core/state/redux/data/filters";
import Sidebar from "./sidebar";

const SidebarReduxContainer = (props) => {
  let { clearFilters } = props;
  let categoryID;
  if (props.filters.category !== undefined) {
    categoryID = props.filters.category.id;
  }

  useEffect(() => {
    clearFilters();
  }, [categoryID, clearFilters]);

  return (
    <Sidebar
      categoryID={categoryID}
      toggleFilter={props.toggleFilter}
      setToggleFilter={props.setToggleFilter}
      addFilter={props.addFilter}
      allCategories={props.allCategories}
      category={props.filters.category}
      clearFilters={props.clearFilters}
      filters={props.filters}
      hideFilters={props.hideFilters}
      removeFilter={props.removeFilter}
      selected_category={props.category_id}
      filtered_category={props.filtered_category}
      locked={props.locked}
      variables={props.variables}
      query={props.query}
      resetPageNumber={props.resetPageNumber}
    />
  );
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  filtered_category: state.filters.data,
  locked: state.common.locked,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addFilter: (filter) => {
      dispatch(filterActions.addFilter(filter));
    },
    removeFilter: (filter) => {
      dispatch(filterActions.removeFilter(filter));
    },
    clearFilters: () => {
      dispatch(filterActions.clearFilters());
    },
    resetPageNumber: () => {
      dispatch(filterActions.changePageNumber(1));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarReduxContainer);
