import React from "react";
import ListingStateContainer from "./listing-state-container";
import { filter } from "lodash";

const ListingQueryContainer = (props) => {
  let { wishlist, activeWishlist } = props;
  let { multiwishlist_data } = wishlist;
  let itemsInMultiWishlists = [];
  let filteredItems = [];

  multiwishlist_data.map((multiwishlist) => {
    multiwishlist.items.map((item) => {
      return itemsInMultiWishlists.push(item);
    });
    return multiwishlist;
  });
  let findMultiWishlist = multiwishlist_data.filter((wishlist) => {
    return wishlist.id === activeWishlist;
  });

  if (props.activeWishlist === 0) {
    filteredItems = filter(
      wishlist.items,
      (item) => item !== null && itemsInMultiWishlists.indexOf(item.id) === -1
    );
  } else {
    if (typeof findMultiWishlist[0] !== "undefined") {
      let multiwishlistItemIndexes = findMultiWishlist[0].items;
      filteredItems = filter(
        wishlist.items,
        (item) => multiwishlistItemIndexes.indexOf(item.id) !== -1
      );
    }
  }
  return (
    <>
      <ListingStateContainer {...props} items={filteredItems} />
    </>
  );
};

export default ListingQueryContainer;
