import React from "react";
import DefaultAddress from "./addressbook";
import { isUndefined } from "core/helpers/functions";

const AddressbookStateContainer = props => {
  let { addresses } = props.customer;
  let doesHaveAddesses = isUndefined(addresses)
    ? 0
    : props.customer.addresses.length;
  let isTrade =
    typeof props.customer.trade !== "undefined" ||
    props.customer.trade === true;

  let defaultShipping;
  let defaultBilling;

  if (doesHaveAddesses) {
    defaultBilling = addresses.filter(address => {
      if (address.default_billing) {
        return address;
      }
    })[0];
    defaultShipping = addresses.filter(address => {
      if (address.default_shipping) {
        return address;
      }
    })[0];
  }

  return (
    <DefaultAddress
      {...props}
      defaultBilling={defaultBilling}
      defaultShipping={defaultShipping}
      isTrade={isTrade}
      doesHaveAddesses={doesHaveAddesses}
    />
  );
};

export default AddressbookStateContainer;
