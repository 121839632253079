import React from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import Accountinformation from "./accountinformation";
import Addressbook from "./addressbook";
import Recentorders from "./recentorders";

const Dashboard = props => {
  return (
    <MDBContainer className="account-dashboard-container mb-5">
      <MDBRow className="account-dashboard">
        <MDBCol lg="12">
          <h3 className="large">account dashboard</h3>
        </MDBCol>
        <MDBCol lg="12">
          <Accountinformation {...props} />
        </MDBCol>
        <MDBCol lg="12">
          <Addressbook {...props} />
        </MDBCol>
        <MDBCol lg="12">
          <Recentorders {...props} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Dashboard;
