import React, { useState } from "react";
import MobileCartItem from "./mobileCartItem";

const CartItemStateContainer = props => {
  let [detailsExpanded, setDetailsExpanded] = useState(false);
  let [additionalOptionsExpanded, setAdditionalOptionsExpanded] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(props.item.quantity);
  let stateProps = {
    detailsExpanded,
    setDetailsExpanded,
    additionalOptionsExpanded,
    setAdditionalOptionsExpanded,
    loading,
    setLoading,
    quantity,
    setQuantity
  };

  return <MobileCartItem {...props} {...stateProps} />;
};

export default CartItemStateContainer;
