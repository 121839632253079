import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Alert = props => {
  return (
    <div className="session-messages-inner">
      <div className={"session " + props.variant}>
        {props.variant === "success" ? (
          <FontAwesomeIcon icon="check-circle" />
        ) : (
          <FontAwesomeIcon icon="times-circle" />
        )}
        <span>{props.children}</span>
      </div>
    </div>
  );
};

export default Alert;
