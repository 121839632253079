import React from "react";
import { connect } from "react-redux";
import { commonActions } from "core/state/redux/data/common";
import messageActions from "core/state/redux/data/messages/actions";
import ControlsStateContainer from "./controls-state-container";
import { customerActions } from "core/state/redux/data/customer";

const CreateReduxContainer = props => {
  return (
    <ControlsStateContainer
      activeWishlist={props.activeWishlist}
      setActiveWishlist={props.setActiveWishlist}
      wishlist={props.wishlist}
      lock={props.lock}
      renameProject={props.renameProject}
      deleteProject={props.deleteProject}
      addMessage={props.addMessage}
      projectName={props.projectName}
      setProjectName={props.setProjectName}
      setIsEditing={props.setIsEditing}
      isEditing={props.isEditing}
    />
  );
};

const mapStateToProps = state => ({
  wishlist: state.customer.data.wishlist
});

const mapDispatchToProps = dispatch => {
  return {
    lock: callback => {
      dispatch(commonActions.lock(callback));
    },
    addMessage: (text, type) => {
      dispatch(messageActions.addMessage(text, type));
    },
    deleteProject: id => {
      dispatch(customerActions.deleteWishlist(id));
    },
    renameProject: (id, name) => {
      dispatch(customerActions.renameWishlist(id, name));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReduxContainer);
