import React from "react";
import CartItemStateContainer from "./state-container";

const CartItemDataContainer = (props) => {
  let { product, customizable_options: options } = props.item;

  options = options.reduce((acc, current) => {
    let value = current.values[0];
    acc[current.label] =
      value.label === ""
        ? value.value
        : `${value.label}${
            value.price.value > 0 ? " ( +" + value.price.value + "$ )" : ""
          }`;
    return acc;
  }, {});

  return (
    <CartItemStateContainer {...props} product={product} options={options} />
  );
};

export default CartItemDataContainer;
