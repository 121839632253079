import React from "react";
import HeroImage from "../../../layout/heroImage/heroImage";
import banner from "./../../../assets/images/about/banner.png";
import bannerMobile from "./../../../assets/images/about/about-mobile.png";
import AboutUsTop from "../about-us/aboutUsTop";
// import AboutUsCustom from "../about-us/aboutUsCustom";
// import AboutUsInstagram from "../about-us/aboutUsInstagram";
// import AboutUsStatic from "../about-us/aboutUsStatic";
// import AboutUsStudioDuc from "./aboutUsStudioDac/aboutUsStudioDuc";
import SeoHelmet from "app/layout/seoHelmet";

const About = () => {
  return (
    <div className="about-us-page">
      <div className="img item d-lg-block d-none desktop-image">
        <HeroImage id={"about-us-img"} alt="Shop The Look Banner" />
      </div>
      <div className="img item d-lg-none d-block mobile-image">
        <HeroImage banner={bannerMobile} alt="Shop The Look Banner" />
      </div>
      <SeoHelmet
        url={"/about-us"}
        title={"about us"}
        description={
          "all pieces are made to order, typically in eight to 10 weeks, though a select number of the brand’s most popular pieces, including cribs, dressers, and changers, are part of ducduc’s “quick-ship program,” which has a four-week turnaround."
        }
        image={banner}
      />
      <div className="about-us-content">
        <AboutUsTop />
        {/* <AboutUsCustom /> */}
        {/* <AboutUsInstagram /> */}
        {/* <AboutUsStatic /> */}
        {/* <AboutUsStudioDuc /> */}
      </div>
    </div>
  );
};

export default About;
