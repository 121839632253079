import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
// import customerActionTypes from "../types";
import queryLoader from "core/graphql/queryLoader";
import messagesActions from "../../messages/actions";
import queryActions from "../../queries/actions";
import { commonActions } from "../../common";
// const LOGOUT_CUSTOMER = customerActionTypes.LOGOUT_CUSTOMER;
const customerMutation = queryLoader("registerCustomer");

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: customerMutation,
      variables: {
        input: action.request,
      },
      fetchPolicy: "no-cache",
    });

    if (!data.createCustomer || !data.createCustomer.customer) {
      console.log("GraphQL response", data);
      throw new Error("No customer information retrieved.");
    }

    store.dispatch(commonActions.unlock("success"));
    return new Promise(function (resolve, reject) {
      resolve(true);
    });
  } catch (error) {
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(
      messagesActions.addMessage(error.toString(), "danger", "global")
    );
    store.dispatch(customerActions._reduceCustomer());
    return new Promise(function (resolve, reject) {
      reject(error.toString());
    });
  }
};
