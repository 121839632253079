import React, { memo } from "react";
import { MDBCol } from "mdbreact";
const ShopTheLookTabs = memo(
  ({ category, setCategory, categories, shopLookRef, scrollToRef }) => {
    let items = [];
    let counter = 0;
    items.push(
      <MDBCol
        lg={"3"}
        md={"3"}
        sm={"6"}
        xs={"12"}
        key={`shop-the-look-tab-counter-${counter}`}
      >
        <MDBCol
          className={`tabs-item px-0 ${category === 0 ? `active` : `inactive`}`}
          onClick={(e) => {
            setCategory(0);
            scrollToRef(shopLookRef);
          }}
        >
          All
        </MDBCol>
      </MDBCol>
    );

    categories.map((_category) => {
      if (typeof _category === "undefined") return <></>;
      counter++;
      if (_category.name !== "all") {
        return items.push(
          <MDBCol
            lg={"3"}
            md={"3"}
            sm={"6"}
            xs={"12"}
            key={`shop-the-look-tab-content-${counter}`}
          >
            <MDBCol
              className={`tabs-item px-0 ${
                category === parseInt(_category.category_id)
                  ? `active`
                  : `inactive`
              }`}
              onClick={(e) => {
                setCategory(parseInt(_category.category_id));
                scrollToRef(shopLookRef);
              }}
            >
              {_category.name}
            </MDBCol>
          </MDBCol>
        );
      }
    });

    return <>{items}</>;
  },
  (prevProps, nextProps) => {}
);

export default ShopTheLookTabs;
