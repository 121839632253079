import React, { useState } from "react";
import {
  MDBCol,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBRow,
  MDBNavItem,
  MDBBox,
  MDBNavLink,
} from "mdbreact";

const ColorListing = (props) => {
  let {
    availableOptions,
    selected,
    changeOption,
    activeTab,
    setActiveTab,
    types,
  } = props;

  let [currentSelected, setCurrentSelected] = useState(selected);

  return (
    <>
      <MDBCol size="12">
        <MDBNav className="nav-tabs mt-5">
          {types.map((type) => {
            return (
              <MDBNavItem>
                <MDBBox
                  to="#"
                  active={activeTab === type}
                  onClick={() => setActiveTab(type)}
                  role="tab"
                  className={`nav-link nav ${
                    activeTab === type ? "activated" : ""
                  }`}
                >
                  <div className="nav-tab-container">{type}</div>
                </MDBBox>
              </MDBNavItem>
            );
          })}
        </MDBNav>
      </MDBCol>
      <MDBCol size="12">
        <MDBTabContent activeItem={activeTab} className="px-5">
          {types.map((type) => {
            return (
              <MDBTabPane tabId={type} role="tabpanel">
                <MDBRow className="swatch-listing">
                  {availableOptions.map((availableOption) => {
                    if (availableOption.type === type) {
                      return (
                        <MDBBox
                          className="swatch-container"
                          onClick={() => {
                            changeOption(availableOption);
                            setCurrentSelected(availableOption);
                          }}
                        >
                          <div
                            className={`color-swatch ${
                              availableOption === currentSelected
                                ? "selected"
                                : ""
                            } `}
                          >
                            <div
                              className="background-image"
                              style={{
                                backgroundImage: `url(${availableOption.swatch_image})`,
                              }}
                            ></div>
                          </div>
                        </MDBBox>
                      );
                    }
                  })}
                </MDBRow>
              </MDBTabPane>
            );
          })}
        </MDBTabContent>
      </MDBCol>
    </>
  );
};

export default ColorListing;
