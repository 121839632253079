import React from "react";
import DataTable from "./dataTable";
import { MDBLink } from "mdbreact";
import Price from "core/components/price";
import ShippingNames from "config/checkout/shipping_names_mapper";

const DataTableDataContainer = (props) => {
  let { products, order } = props;
  let summaryData = [];
  let productDataFormatted = products.map((item) => {
    let newItem = {};
    newItem.product_name =
      item.url !== "" ? (
        <MDBLink to={`/${item.url}`}>{item.product_name}</MDBLink>
      ) : (
        item.product_name
      );
    newItem.unit_price = <Price price={item.unit_price} type={`fixed`} />;
    newItem.quantity = item.quantity;
    newItem.row_total = <Price price={item.row_total} type={`fixed`} />;

    return newItem;
  });

  summaryData.push({
    product_name: "",
    unit_price: "",
    quantity: "subtotal",
    row_total: <Price price={order.subtotal} type={`fixed`} />,
  });
  summaryData.push({
    product_name: "",
    unit_price: "",
    quantity: ShippingNames[order.shippingMethod],
    row_total: <Price price={order.shippingPrice} type={`fixed`} />,
  });
  summaryData.push({
    product_name: "",
    unit_price: "",
    quantity: "tax",
    row_total: (
      <Price
        price={order.grand_total - order.subtotal - order.shippingPrice}
        type={`fixed`}
      />
    ),
  });
  summaryData.push({
    product_name: "",
    unit_price: "",
    quantity: "order total",
    row_total: <Price price={order.grand_total} type={`fixed`} />,
    dummy: "in",
  });
  const data = {
    columns: [
      {
        label: "product info",
        field: "product_name",
        sort: "asc",
        type: "text",
        width: 700,
      },
      {
        label: "unit price",
        field: "unit_price",
        sort: "asc",
        width: 150,
      },
      {
        label: "qty",
        field: "quantity",
        sort: "asc",
        width: 150,
      },
      {
        label: "subtotal",
        field: "row_total",
        sort: "asc",
        width: 150,
      },
    ],
    rows: productDataFormatted,
  };

  const summary = {
    columns: [
      {
        label: "product info",
        field: "product_name",
        sort: "asc",
        type: "text",
        width: 480,
      },
      {
        label: "unit price",
        field: "unit_price",
        sort: "asc",
        width: 150,
      },
      {
        label: "qty",
        field: "quantity",
        sort: "asc",
        width: 150,
      },
      {
        label: "subtotal",
        field: "row_total",
        sort: "asc",
        width: 150,
      },
      {
        label: "dummy",
        field: "dummy",
        sort: "asc",
        width: 10,
      },
    ],
    rows: summaryData,
  };
  return <DataTable data={data} summary={summary} />;
};

export default DataTableDataContainer;
