import React, { useState } from "react";
import CategoryQueryContainer from "./category";
import SeoHelmet from "app/layout/seoHelmet";

const CategoryStateContainer = (props) => {
  const [filters, setFilters] = useState(props.filters);

  if (props.filters !== filters) {
    setFilters(props.filters);
  }

  if (typeof props.category !== "undefined") {
    return (
      <>
        <CategoryQueryContainer
          {...props}
          allCategories={props.allCategories}
          data={props.category}
          setFilters={props.setFilters}
          filters={props.filters}
        />
      </>
    );
  }
  return (
    <CategoryQueryContainer
      allCategories={props.allCategories}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

export default CategoryStateContainer;
