import React from "react";
import Addressbook from "./addressbook";

const AddressbookStateContainer = (props) => {
  let { addresses } = props.customer;
  let additionalAddresses = addresses.filter((address) => {
    if (!address.default_billing && !address.default_shipping) {
      return address;
    }
    return null;
  });
  let hasAdditionalAddresses = additionalAddresses.length;

  return (
    <Addressbook
      {...props}
      additionalAddresses={additionalAddresses}
      hasAdditionalAddresses={hasAdditionalAddresses}
    />
  );
};

export default AddressbookStateContainer;
