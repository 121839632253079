import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import CartDataContainer from "./data-container";
import SeoHelmet from "app/layout/seoHelmet";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { messagesActions } from "core/state/redux/data/messages";

const CartReduxContainer = (props) => {
  if (
    props.cart === undefined ||
    (props.customer.data === "undefined" &&
      props.customer.data.cart === "undefined") ||
    (typeof props.customer.data.cart !== "undefined" &&
      typeof props.customer.data.cart.items !== "undefined" &&
      props.customer.data.cart.items.length === 0)
  ) {
    return (
      <MDBContainer className="cart-page pb-5">
        <SeoHelmet title="Cart" url={`/cart`} />
        <MDBRow className="title-container">
          <MDBCol className="page-title">
            <h3>shopping cart</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow className="content-container">
          <p className={`ml-4`}>your shopping cart is empty</p>
        </MDBRow>
      </MDBContainer>
    );
  }
  return (
    <>
      <SeoHelmet title="Cart" url={`/cart`} />
      <CartDataContainer {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  cart:
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cartToken !== "undefined"
      ? state.customer.data
      : undefined,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    removeItem: (id) => {
      dispatch(customerActions.removeProductFromCart(id));
    },
    updateCart: (items) => {
      dispatch(customerActions.updateCart(items));
    },
    clearCart: () => {
      dispatch(customerActions.clearCart());
    },
    setShippingAddress: (shipping_address) => {
      dispatch(customerActions.setShippingAddressOnCart(shipping_address));
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
    applyCouponToCart: (coupon) => {
      dispatch(customerActions.applyCouponToCart(coupon));
    },
    removeCouponFromCart: () => {
      dispatch(customerActions.removeCouponFromCart());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartReduxContainer);
