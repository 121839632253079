import React from "react";
import { Link, withRouter } from "react-router-dom";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import Image from "core/components/image";
import themeConfiguration from "config/themeConfiguration";
import { isUndefined } from "lodash";
import { MDBLink } from "mdbreact";
export function transform(node, index) {
  let stylesArray = [];

  if (node.type === "tag" && node.name === "a") {
    if (typeof node.attribs.style === "undefined") {
      node.attribs.style = {};
    } else {
      let parsedStyle = [];
      stylesArray = node.attribs.style.split(";");

      stylesArray.forEach((element) => {
        let miniArray = element.split(":");
        let newSanitizedArray = [];
        miniArray.forEach((piece, index) => {
          let sanitizedPiece = piece.replace(/^ /, "");
          sanitizedPiece =
            "'" +
            sanitizedPiece
              .replace("border-style", "borderStyle")
              .replace("border-color", "borderColor") +
            "'";
          newSanitizedArray[index] = sanitizedPiece;
        });

        if (typeof newSanitizedArray[1] !== "undefined") {
          parsedStyle[newSanitizedArray[0]] = newSanitizedArray[1].replace(
            /"/g,
            ""
          );
        }
      });
      parsedStyle.join(",");
      let finalStyle = JSON.parse(
        JSON.stringify({ ...parsedStyle })
          .replace(/'/g, '"')
          .replace(/""/g, '"')
      );

      node.attribs.style = { ...finalStyle };
    }

    if (!isUndefined(node.attribs.href) && node.attribs.href.includes("http")) {
      return convertNodeToElement(node, index, transform);
    }

    if (typeof node.attribs.class !== "undefined") {
      node.attribs.className = node.attribs.class;
      delete node.attribs.class;
    }
    if (
      typeof node.attribs.href !== "undefined" &&
      (node.attribs.href.includes("tel") ||
        node.attribs.href.includes("mailto"))
    ) {
      return (
        <a {...node.attribs} key={`anchor-${{ ...node.attribs }}`}>
          {node.children.map((item) => {
            {
              return convertNodeToElement(item, index, transform);
            }
          })}
        </a>
      );
    }

    let linkComponent = (
      <MDBLink
        key={`parsed-link-${index}`}
        to={`${node.attribs.href}`}
        {...node.attribs}
      >
        {node.children.map((item) => {
          {
            let icon = {
              type: "tag",
              name: "i",
              attribs: { class: "fas fa-arrow-right" },
              children: [],
              next: null,
            };
            if (item.name === "button") {
              if (
                item.children.length === 1 &&
                item.attribs.class.includes("show-arrow")
              ) {
                item.children.push(icon);
              }
            }
            return convertNodeToElement(item, index, transform);
          }
        })}
      </MDBLink>
    );
    return linkComponent;
  }

  if (node.type === "tag" && node.name === "img") {
    if (
      node.attribs.src.includes("https") ||
      node.attribs.src.includes("http")
    ) {
      return (
        <Image
          key={`node-attribs-` + node.type + node.name + index}
          className={node.attribs.class}
          source={node.attribs.src}
          alt={node.attribs.alt}
        />
      );
    }
    let imageSource = node.attribs.src.split('"');
    return (
      <Image
        className={node.attribs.class}
        source={
          themeConfiguration.magento_url +
          themeConfiguration.media_url +
          "/" +
          imageSource[1]
        }
        alt={node.attribs.alt}
      />
    );
  }

  return convertNodeToElement(node, index, transform);
}
const Parser = (props) => {
  if (!props.data || !props.data.cmsBlocks || !props.data.cmsBlocks.items) {
    return null;
  }

  const cmsBlock = props.data.cmsBlocks.items[0];

  let newContent = cmsBlock.content;
  return (
    <div
      id={"cmsblock-" + cmsBlock.identifier}
      className={`cmsblock ${props.cssClass && props.cssClass}`}
    >
      {ReactHtmlParser(newContent, {
        decodeEntities: true,
        transform: !isUndefined(props.transform) ? props.transform : transform,
      })}
    </div>
  );
};

export const PageParser = (props) => {
  if (isUndefined(props.data) || isUndefined(props.data.content)) {
    return <></>;
  }

  const page_data = props.data;

  let newContent = page_data.content;
  return (
    <div
      id={"page-data-" + page_data.identifier}
      className={`page-data ${props.cssClass && props.cssClass}`}
    >
      {ReactHtmlParser(newContent, {
        decodeEntities: true,
        transform: !isUndefined(props.transform) ? props.transform : transform,
      })}
    </div>
  );
};

export default withRouter(Parser);
