import React, { useState } from "react";
import ToolbarQueryContainer from "./toolbar-query-container";

const ToolbarStateContainer = props => {
  let { data, changeSortFilter } = props;
  const [sort, setSort] = useState(data.sort_fields.default);
  const [sortOrder, setSortOrder] = useState("ASC");

  const changeSortFilterHandler = (data, sortOrderValue) => {
    setSort(data);
    changeSortFilter({ [data.value]: sortOrderValue });
  };

  return (
    <ToolbarQueryContainer
      {...props}
      sort={sort}
      setSort={setSort}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      changeSortFilterHandler={changeSortFilterHandler}
    />
  );
};

export default ToolbarStateContainer;
