import React, { useState } from "react";
import Menu from "./menu";

const MenuState = props => {
  let { menu } = props;

  const [position, setPosition] = useState(1);
  const [hoveredDropdown, setHoveredDropdown] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [collapseID, setCollapseID] = useState(false);

  let menuItems =
    typeof menu !== "undefined" &&
    typeof menu !== "undefined" &&
    typeof menu.children !== "undefined"
      ? menu.children
      : {};

  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(undefined);

  const openMobileDropdown = index => {
    if (categoryIndex === index) {
      if (showMobileDropdown === true) {
        setShowMobileDropdown(false);
      } else {
        setShowMobileDropdown(true);
      }
    } else {
      setCategoryIndex(index);
      setShowMobileDropdown(true);
    }
  };

  return (
    <Menu
      menuItems={menuItems}
      position={position}
      setPosition={setPosition}
      hoveredDropdown={hoveredDropdown}
      setHoveredDropdown={setHoveredDropdown}
      showDropdown={showDropdown}
      setShowDropdown={setShowDropdown}
      collapseID={collapseID}
      setCollapseID={setCollapseID}
      openMobileDropdown={openMobileDropdown}
      showMobileDropdown={showMobileDropdown}
      setShowMobileDropdown={setShowMobileDropdown}
      categoryIndex={categoryIndex}
      setCategoryIndex={setCategoryIndex}
    />
  );
};

export default MenuState;
