import React from "react";
import Image from "core/components/image/image";
import { MDBBox } from "mdbreact";
import { determineCurrency } from "config/currency";
import Price from "core/components/price";
import { useMediaQuery } from "react-responsive";

const CheckoutReviewItem = (props) => {
  let { cart } = props;
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      {cart.items.map((item, index) => {
        return (
          <MDBBox
            key={`checkout-review-item-${item.id}`}
            className={"checkout-review-info"}
          >
            <Image source={item.product.small_image.url} />
            <MDBBox className={"checkout-review-info-inner"}>
              <div>{item.product.name}</div>
              {isMobile ? (
                <div>
                  <Price price={item.prices.price.value} type={`fixed`} />
                </div>
              ) : (
                <></>
              )}
              <div>
                qty <span className="item-qty">{item.quantity}</span>
              </div>
            </MDBBox>
            {!isMobile ? (
              <MDBBox className={"checkout-review-price"}>
                <Price price={item.prices.price.value} type={`fixed`} />
              </MDBBox>
            ) : (
              <></>
            )}
          </MDBBox>
        );
      })}
    </>
  );
};

export default CheckoutReviewItem;
