import React, { useState, memo } from "react";
import { isEqual } from "lodash";
import Checkbox from "./checkbox";

const CheckboxStateContainer = memo(
  ({ option, sizes, reduceProductOptions, product, optionProps }) => {
    const [state, setState] = useState({
      selectedValue:
        typeof optionProps.selections[option.option_id] !== "undefined" &&
        optionProps.selections[option.option_id] !== false &&
        option.value[0].option_type_id ===
          optionProps.selections[option.option_id].option_type_id
          ? optionProps.selections[option.option_id].option_type_id
          : false,
    });
    return (
      <Checkbox
        sizes={sizes}
        option={option}
        state={state}
        setState={setState}
        reduceProductOptions={reduceProductOptions}
        product={product}
        optionProps={optionProps}
      />
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default CheckboxStateContainer;
