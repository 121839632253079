import React from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import Popout from "./popout";
import { productActions } from "core/state/redux/data/product";

const ControlsReduxContainer = (props) => {
  return <Popout {...props} />;
};

const mapStateToProps = (state) => ({
  product: state.product,
});

const mapDispatchToProps = (dispatch) => {
  return {
    lock: () => {
      dispatch(customerActions.lock());
    },
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    reduceProductOptions: (options) => {
      dispatch(productActions._reduceOptions(options));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlsReduxContainer);
